import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import {auth} from '../firebase-config';
import {db} from '../firebase-config';
import logo_header from '../assets/images/logo_header.png';
import {Link} from 'react-router-dom';
import * as RB from "react-bootstrap";
import swal from 'sweetalert2';
import firebase from 'firebase/app';

class EditarCurso extends React.Component{
	//constructor del componente
	constructor(props){
		super(props)
		//Estado inicial del componente
		this.state = {
			clave: "",
			nombre: "",
			horasSemanales: "",
			pagoHora: "",
			horaIniciolunes: "",
			horaFinlunes: "",
			horaIniciomartes: "",
			horaFinmartes: "",
			horaIniciomiercoles: "",
			horaFinmiercoles: "",
			horaIniciojueves: "",
			horaFinjueves: "",
			horaInicioviernes: "",
			horaFinviernes: "",
			horaIniciosabado: "",
			horaFinsabado: "",
			horaIniciodomingo: "",
			horaFindomingo: "",
			lunes: false,
			martes: false,
			miercoles: false,
			jueves: false,
			viernes: false,
			sabado: false,
			domingo: false
		}
	}
	//Datos iniciales del producto
	datosIniciales = {
		uid: ""
	}
	//Se ejecuta al cargar componente
	componentDidMount() {
		//Se verifican las props
		if(typeof this.props.match.params !== 'undefined') {
			//Se guarda el id del documento, recibido por props
			const {uid} = this.props.match.params;
			this.datosIniciales.uid = uid;
			//----------------------------------------------------------------------------------
			//---------Se actualizan los valores iniciales con los datos del usuario------------
			//----------------------------------------------------------------------------------
			db.collection("actividades").doc(uid).get().then(doc => {
			    if (doc.exists) {
			    	//************************************************************************
			        //Se guardan los datos iniciales--------------------
			        this.setState({clave: doc.data().clave});
			        this.setState({nombre: doc.data().nombre});
			        this.setState({horasSemanales: doc.data().horasSemanales});
			        this.setState({pagoHora: doc.data().pagoHora});
			        //--------------------------------------------------
			        if(doc.data().horario[0] !== null){
			        	document.getElementById("checklunes").click();
			        	this.setState({horaIniciolunes: doc.data().horario[0].horaInicio});
			        	this.setState({horaFinlunes: doc.data().horario[0].horaFin});
			        }
			        if(doc.data().horario[1] !== null){
			        	document.getElementById("checkmartes").click();
			        	this.setState({horaIniciomartes: doc.data().horario[1].horaInicio});
			        	this.setState({horaFinmartes: doc.data().horario[1].horaFin});
			        }
			        if(doc.data().horario[2] !== null){
			        	document.getElementById("checkmiercoles").click();
			        	this.setState({horaIniciomiercoles: doc.data().horario[2].horaInicio});
			        	this.setState({horaFinmiercoles: doc.data().horario[2].horaFin});
			        }
			        if(doc.data().horario[3] !== null){
			        	document.getElementById("checkjueves").click();
			        	this.setState({horaIniciojueves: doc.data().horario[3].horaInicio});
			        	this.setState({horaFinjueves: doc.data().horario[3].horaFin});
			        }
			        if(doc.data().horario[4] !== null){
			        	document.getElementById("checkviernes").click();
			        	this.setState({horaInicioviernes: doc.data().horario[4].horaInicio});
			        	this.setState({horaFinviernes: doc.data().horario[4].horaFin});
			        }
			        if(doc.data().horario[5] !== null){
			        	document.getElementById("checksabado").click();
			        	this.setState({horaIniciosabado: doc.data().horario[5].horaInicio});
			        	this.setState({horaFinsabado: doc.data().horario[5].horaFin});
			        }
			        if(doc.data().horario[6] !== null){
			        	document.getElementById("checkdomingo").click();
			        	this.setState({horaIniciodomingo: doc.data().horario[6].horaInicio});
			        	this.setState({horaFindomingo: doc.data().horario[6].horaFin});
			        }
			        //************************************************************************
			    } else {
			    	//Sí documento no existe, se regresa a página anterior
			        document.getElementById("boton_RA").click();
			    }
			}).catch(error => {
			    //Error al leer documento
			});
			//----------------------------------------------------------------------------------
			//----------------------------------------------------------------------------------
			//----------------------------------------------------------------------------------
		} else {
			//Sí no se pueden leer las props se regresa a página anterior
			document.getElementById("boton_RA").click();
		}
	}
	render(){
		//-------------------------------------------------------------
		//-------------------------------------------------------------------------------------------------------------------------
		//--------------------------------------------------INICIAR REGISTRO-------------------------------------------------------
		//-------------------------------------------------------------------------------------------------------------------------
		const handleSubmit = e =>{
			e.preventDefault();//Evita la recarga de la página

			//***************************************************************************************
			//************************** VALIDACIÓN DE CAMPOS ***************************************
			//***************************************************************************************
			document.getElementById("alerta-RA").innerHTML = '';//Borra alertas pasadas
			var formulario_incorrecto = false;
			const aux_inputs = e.target.querySelectorAll('input');
			aux_inputs.forEach((input) => {
				switch(input.name){
					case "lunes":
						if(input.checked){
							if(this.state.lunes){
								//Error en horario
								formulario_incorrecto = true;
							}
						}
					break;
					case "martes":
						if(input.checked){
							if(this.state.martes){
								//Error en horario
								formulario_incorrecto = true;
							}
						}
					break;
					case "miercoles":
						if(input.checked){
							if(this.state.miercoles){
								//Error en horario
								formulario_incorrecto = true;
							}
						}
					break;
					case "jueves":
						if(input.checked){
							if(this.state.jueves){
								//Error en horario
								formulario_incorrecto = true;
							}
						}
					break;
					case "viernes":
						if(input.checked){
							if(this.state.viernes){
								//Error en horario
								formulario_incorrecto = true;
							}
						}
					break;
					case "sabado":
						if(input.checked){
							if(this.state.sabado){
								//Error en horario
								formulario_incorrecto = true;
							}
						}
					break;
					case "domingo":
						if(input.checked){
							if(this.state.domingo){
								//Error en horario
								formulario_incorrecto = true;
							}
						}
					break;
				}
				if(formulario_incorrecto){
					document.getElementById("alerta-RA").innerHTML = '<p>Complete los campos correctamente</p>';
				}
			});
			//***************************************************************************************
			//***************************************************************************************
			//***************************************************************************************

			if(!formulario_incorrecto){
				//Alert "loading"----------------------
				const alert_cargando = swal.fire({
				  width: '300px',
				  allowOutsideClick: false,
				  showConfirmButton: false,
				  didOpen: () => {
				    swal.showLoading()
				  },
				  customClass: {
					   title: 'titulo-alert-loading',
				     actions: 'alert-loader'
					}
				})
				//-------------------------------------
				//*******************************************************************************************
				//*************************SE ACTUALIZA EL ARREGLO DE 'HORARIO'******************************
				//*******************************************************************************************
				aux_inputs.forEach((input) => {
					switch(input.name){
						case "lunes": actualizarHorario(input, 0);break;
						case "martes": actualizarHorario(input, 1);break;
						case "miercoles": actualizarHorario(input, 2);break;
						case "jueves": actualizarHorario(input, 3);break;
						case "viernes": actualizarHorario(input, 4);break;
						case "sabado": actualizarHorario(input, 5);break;
						case "domingo": actualizarHorario(input, 6);break;
					}
				});
				//*******************************************************************************************
				//*******************************************************************************************
				//*******************************************************************************************
				//-------------------------------------------------------------------------------------------------
				//Ahora se crea su documento en la colección "actividades"-----------------------------------------
				//-------------------------------------------------------------------------------------------------
				db.collection("actividades").doc(this.datosIniciales.uid).update({
					clave: document.getElementById("claveRA").value.trim().toUpperCase(),
					horario: horarioRA,
					horasSemanales: document.getElementById("horasSemanalesRA").value,
				    nombre: document.getElementById("nombreRA").value.trim().toUpperCase(),
				    pagoHora: document.getElementById("pagoHoraRA").value
				})
				.then(function() {
					//EL REGISTRO SE COMPLETÓ POR COMPLETO CON ÉXITO-------------
					  alert_cargando.close();
					  swal.fire({
					      title:"<p style='font-size: 13pt;font-weight:600;color: #282828;'>Cambios guardados</p>",
					      icon: 'success',
					      iconColor: '#17a2b8',
					      width: '350px',
					      showConfirmButton: false,
					      timer: 2500
					    }).then((result) => {
						  	//Se regresa a inicio
					    	document.getElementById("boton_RA").click();
						});
				    //-----------------------------------------------------------
				})
				.catch(function(error) {
					alert_cargando.close();
				    //alerta por error
					document.getElementById("alerta-RA").innerHTML = 
					'<p">'+
						'Error al registrar: '+
						error+
					'</p>';
				});
				//-------------------------------------------------------------------------------------------------
				//-------------------------------------------------------------------------------------------------
				//-------------------------------------------------------------------------------------------------
			}
		}
		//-------------------------------------------------------------------------------------------------------------------------
		//-------------------------------------------------------------------------------------------------------------------------
		//-------------------------------------------------------------------------------------------------------------------------
		//*************************************************************************************************************************************
		//***************************************************VALIDACIÓN DE LOS DATOS***********************************************************
		//*************************************************************************************************************************************
		const validarFormulario = (e) => {
			switch(e.target.name){
				case "clave": this.setState({clave: e.target.value});break;
				case "nombre": this.setState({nombre: e.target.value});break;
				case "horasSemanales": this.setState({horasSemanales: e.target.value});break;
				case "pagoHora": this.setState({pagoHora: e.target.value});break;
				case "horaIniciolunes": this.setState({horaIniciolunes: e.target.value});this.setState({lunes: validarHora(e.target.name,true,"horaFinlunes")});break;
				case "horaFinlunes": this.setState({horaFinlunes: e.target.value});this.setState({lunes: validarHora(e.target.name,false,"horaIniciolunes")});break;
				case "horaIniciomartes": this.setState({horaIniciomartes: e.target.value});this.setState({martes: validarHora(e.target.name,true,"horaFinmartes")});break;
				case "horaFinmartes": this.setState({horaFinmartes: e.target.value});this.setState({martes: validarHora(e.target.name,false,"horaIniciomartes")});break;
				case "horaIniciomiercoles": this.setState({horaIniciomiercoles: e.target.value});this.setState({miercoles: validarHora(e.target.name,true,"horaFinmiercoles")});break;
				case "horaFinmiercoles": this.setState({horaFinmiercoles: e.target.value});this.setState({miercoles: validarHora(e.target.name,false,"horaIniciomiercoles")});break;
				case "horaIniciojueves": this.setState({horaIniciojueves: e.target.value});this.setState({jueves: validarHora(e.target.name,true,"horaFinjueves")});break;
				case "horaFinjueves": this.setState({horaFinjueves: e.target.value});this.setState({jueves: validarHora(e.target.name,false,"horaIniciojueves")});break;
				case "horaInicioviernes": this.setState({horaInicioviernes: e.target.value});this.setState({viernes: validarHora(e.target.name,true,"horaFinviernes")});break;
				case "horaFinviernes": this.setState({horaFinviernes: e.target.value});this.setState({viernes: validarHora(e.target.name,false,"horaInicioviernes")});break;
				case "horaIniciosabado": this.setState({horaIniciosabado: e.target.value});this.setState({sabado: validarHora(e.target.name,true,"horaFinsabado")});break;
				case "horaFinsabado": this.setState({horaFinsabado: e.target.value});this.setState({sabado: validarHora(e.target.name,false,"horaIniciosabado")});break;
				case "horaIniciodomingo": this.setState({horaIniciodomingo: e.target.value});this.setState({domingo: validarHora(e.target.name,true,"horaFindomingo")});break;
				case "horaFindomingo": this.setState({horaFindomingo: e.target.value});this.setState({domingo: validarHora(e.target.name,false,"horaIniciodomingo")});break;
			}
		}
		function validarHora(selectedHour, isStartTime, complementHour, selectedDay) {
			if(isStartTime){
				if(document.getElementById(selectedHour+"RA").value>=document.getElementById(complementHour+"RA").value){
					document.getElementById(selectedHour+"RA").classList.remove('input_correcto');
					document.getElementById(selectedHour+"RA").classList.add('input_incorrecto');
					if(document.getElementById(selectedHour+"RA").value==document.getElementById(complementHour+"RA").value){
						document.getElementById("error-"+selectedHour+"RA").innerHTML = 'Inicio igual que fin';
					} else {
						document.getElementById("error-"+selectedHour+"RA").innerHTML = 'Inicio mayor que fin';
					}
					return true;
				} else {
					document.getElementById(selectedHour+"RA").classList.remove('input_incorrecto');
					document.getElementById(selectedHour+"RA").classList.add('input_correcto');
					document.getElementById("error-"+selectedHour+"RA").innerHTML = '';
					document.getElementById(complementHour+"RA").classList.remove('input_incorrecto');
					document.getElementById(complementHour+"RA").classList.add('input_correcto');
					document.getElementById("error-"+complementHour+"RA").innerHTML = '';
					return false;
				}
			} else {
				if(document.getElementById(complementHour+"RA").value>=document.getElementById(selectedHour+"RA").value){
					document.getElementById(selectedHour+"RA").classList.remove('input_correcto');
					document.getElementById(selectedHour+"RA").classList.add('input_incorrecto');
					if(document.getElementById(complementHour+"RA").value==document.getElementById(selectedHour+"RA").value){
						document.getElementById("error-"+selectedHour+"RA").innerHTML = 'Fin igual que inicio';
					} else {
						document.getElementById("error-"+selectedHour+"RA").innerHTML = 'Fin menor que inicio';
					}
					return true;
				} else {
					document.getElementById(selectedHour+"RA").classList.remove('input_incorrecto');
					document.getElementById(selectedHour+"RA").classList.add('input_correcto');
					document.getElementById("error-"+selectedHour+"RA").innerHTML = '';
					document.getElementById(complementHour+"RA").classList.remove('input_incorrecto');
					document.getElementById(complementHour+"RA").classList.add('input_correcto');
					document.getElementById("error-"+complementHour+"RA").innerHTML = '';
					return false;
				}
			}
		}
		//Solo permite ingresar numeros
		const validarNumerico = (e) => {
     		if(isNaN(e.target.value+String.fromCharCode(e.charCode))){
        		e.preventDefault();
     		}
  		}
		//*************************************************************************************************************************************
		//*************************************************************************************************************************************
		//*************************************************************************************************************************************
		//CONTROL REGISTRO DE HORARIO-----------------------------------------------------------------------------
		const horarioRA = [null, null, null, null, null, null, null];

		function controlDias(e) {
		  if(e.target.checked){
		    document.getElementById("div-check-"+e.target.name).classList.add('div-check-dias-seleccionado');
		    document.getElementById("div-"+e.target.name).classList.add('div-dia-seleccionado');
		    document.getElementById("horas-"+e.target.name).style.display = "block";
		    document.getElementById("horaInicio"+e.target.name+"RA").required = true;
		    document.getElementById("horaFin"+e.target.name+"RA").required = true;
		    document.getElementById("horaInicio"+e.target.name+"RA").focus();
		  } else {
		    document.getElementById("div-check-"+e.target.name).classList.remove('div-check-dias-seleccionado');
		    document.getElementById("div-"+e.target.name).classList.remove('div-dia-seleccionado');
		    document.getElementById("horas-"+e.target.name).style.display = "none";
		    document.getElementById("horaInicio"+e.target.name+"RA").required = false;
		    document.getElementById("horaFin"+e.target.name+"RA").required = false;
		  }
		}

		function actualizarHorario(input, index_array) {
			if(input.checked){
				horarioRA[index_array] = {
					horaInicio: document.getElementById("horaInicio"+input.name+"RA").value,
					horaFin: document.getElementById("horaFin"+input.name+"RA").value
				};
			} else {
				horarioRA[index_array] = null;
			}
		}
		//--------------------------------------------------------------------------------------------------------
		return(
			<React.Fragment>

			<div className="global-container">
				<div className="card login-form" id="cardFRA" style={{ width: '800px', paddingTop:'10px' }}>
					<div className="card-body" id="tarjeta">
						<h4 className="card-title" style={{fontSize:'18pt',fontWeight:'500'}}>Editar registro de curso</h4>
						<div className="card-text" style={{marginTop:'40px'}}>
							<form autoComplete="off" onSubmit={handleSubmit} style={{margin:'0px',overflow:'hidden'}}>

								<div className="row">
									<div className="col-sm-12 col-md-6 col-lg-6">
										<div className="form-group input-field" style={{margin: 'auto'}}>
											<input className="input_correcto input" type="text" id="claveRA" name="clave" onChange={validarFormulario} value={this.state.clave} required />
											<label className="label" for="claveRA">Clave</label>
											<p id="error-claveRA"></p>
										</div>
									</div>

									<div className="col-sm-12 col-md-6 col-lg-6">
										<div className="form-group input-field" style={{margin: 'auto'}}>
											<input className="input_correcto input" type="text" id="nombreRA" name="nombre" onChange={validarFormulario} value={this.state.nombre} required />
											<label className="label" for="nombreRA">Nombre</label>
											<p id="error-nombreRA"></p>
										</div>
									</div>

									<div className="col-sm-12 col-md-6 col-lg-6">
										<div className="form-group input-field" style={{margin: 'auto'}}>
											<input className="input_correcto input" type="text" id="horasSemanalesRA" name="horasSemanales" onChange={validarFormulario} onKeyPress={validarNumerico} value={this.state.horasSemanales} required />
											<label className="label" for="horasSemanalesRA">Horas por semana</label>
											<p id="error-horasSemanalesRA"></p>
										</div>
									</div>

									<div className="col-sm-12 col-md-6 col-lg-6">
										<div className="form-group input-field" style={{margin: 'auto'}}>
											<input className="input_correcto input" type="text" id="pagoHoraRA" name="pagoHora" onChange={validarFormulario} onKeyPress={validarNumerico} value={this.state.pagoHora} required />
											<label className="label" for="pagoHoraRA">Pago por hora</label>
											<p id="error-pagoHoraRA"></p>
										</div>
									</div>
								</div>

								<div className="row">
								  <div className="col-sm-12 col-md-12 col-lg-12">
								    <h5 id="subtitulo" className="card-title" style={{fontSize:'13pt',fontWeight:'500',color:'#17a2b8'}}>
								      Horario
								    </h5>
								    <p style={{color:'#5f6368',fontSize:'10pt'}}>Seleccione los días a guardar en el horario del curso</p>
								  </div>
								  
								  <div className="col-sm-12 col-md-4 col-lg-4">
								    <div id="div-lunes" className="div-dia">
								      <div id="div-check-lunes" className="div-check-dias">
								        <input type="checkbox" id="checklunes" name="lunes" onChange={(e) => {controlDias(e);}} />
								        <label for="checklunes">
								          Lunes
								        </label>
								      </div>

								      <div id="horas-lunes" style={{display:'none'}}>
								        <div className="form-group input-field" style={{margin:'auto',width:'140px'}}>
								          <input className="input_correcto input" type="time" id="horaIniciolunesRA" name="horaIniciolunes" onChange={validarFormulario} value={this.state.horaIniciolunes} />
								          <label className="label" for="horaIniciolunesRA">Hora inicio</label>
								          <p id="error-horaIniciolunesRA"></p>
								        </div>

								        <div className="form-group input-field" style={{margin:'auto',width:'140px'}}>
								          <input className="input_correcto input" type="time" id="horaFinlunesRA" name="horaFinlunes" onChange={validarFormulario} value={this.state.horaFinlunes} />
								          <label className="label" for="horaFinlunesRA">Hora fin</label>
								          <p id="error-horaFinlunesRA"></p>
								        </div>
								      </div>
								    </div>
								  </div>
								  
								  <div className="col-sm-12 col-md-4 col-lg-4">
								    <div id="div-martes" className="div-dia">
								      <div id="div-check-martes" className="div-check-dias">
								        <input type="checkbox" id="checkmartes" name="martes" onChange={(e) => {controlDias(e);}} />
								        <label for="checkmartes">
								          Martes
								        </label>
								      </div>

								      <div id="horas-martes" style={{display:'none'}}>
								        <div className="form-group input-field" style={{margin:'auto',width:'140px'}}>
								          <input className="input_correcto input" type="time" id="horaIniciomartesRA" name="horaIniciomartes" onChange={validarFormulario} value={this.state.horaIniciomartes} />
								          <label className="label" for="horaIniciomartesRA">Hora inicio</label>
								          <p id="error-horaIniciomartesRA"></p>
								        </div>

								        <div className="form-group input-field" style={{margin:'auto',width:'140px'}}>
								          <input className="input_correcto input" type="time" id="horaFinmartesRA" name="horaFinmartes" onChange={validarFormulario} value={this.state.horaFinmartes} />
								          <label className="label" for="horaFinmartesRA">Hora fin</label>
								          <p id="error-horaFinmartesRA"></p>
								        </div>
								      </div>
								    </div>
								  </div>
								  
								  <div className="col-sm-12 col-md-4 col-lg-4">
								    <div id="div-miercoles" className="div-dia">
								      <div id="div-check-miercoles" className="div-check-dias">
								        <input type="checkbox" id="checkmiercoles" name="miercoles" onChange={(e) => {controlDias(e);}} />
								        <label for="checkmiercoles">
								          Miércoles
								        </label>
								      </div>

								      <div id="horas-miercoles" style={{display:'none'}}>
								        <div className="form-group input-field" style={{margin:'auto',width:'140px'}}>
								          <input className="input_correcto input" type="time" id="horaIniciomiercolesRA" name="horaIniciomiercoles" onChange={validarFormulario} value={this.state.horaIniciomiercoles} />
								          <label className="label" for="horaIniciomiercolesRA">Hora inicio</label>
								          <p id="error-horaIniciomiercolesRA"></p>
								        </div>

								        <div className="form-group input-field" style={{margin:'auto',width:'140px'}}>
								          <input className="input_correcto input" type="time" id="horaFinmiercolesRA" name="horaFinmiercoles" onChange={validarFormulario} value={this.state.horaFinmiercoles} />
								          <label className="label" for="horaFinmiercolesRA">Hora fin</label>
								          <p id="error-horaFinmiercolesRA"></p>
								        </div>
								      </div>
								    </div>
								  </div>

								  <div className="col-sm-12 col-md-4 col-lg-4">
								    <div id="div-jueves" className="div-dia">
								      <div id="div-check-jueves" className="div-check-dias">
								        <input type="checkbox" id="checkjueves" name="jueves" onChange={(e) => {controlDias(e);}} />
								        <label for="checkjueves">
								          Jueves
								        </label>
								      </div>

								      <div id="horas-jueves" style={{display:'none'}}>
								        <div className="form-group input-field" style={{margin:'auto',width:'140px'}}>
								          <input className="input_correcto input" type="time" id="horaIniciojuevesRA" name="horaIniciojueves" onChange={validarFormulario} value={this.state.horaIniciojueves} />
								          <label className="label" for="horaIniciojuevesRA">Hora inicio</label>
								          <p id="error-horaIniciojuevesRA"></p>
								        </div>

								        <div className="form-group input-field" style={{margin:'auto',width:'140px'}}>
								          <input className="input_correcto input" type="time" id="horaFinjuevesRA" name="horaFinjueves" onChange={validarFormulario} value={this.state.horaFinjueves} />
								          <label className="label" for="horaFinjuevesRA">Hora fin</label>
								          <p id="error-horaFinjuevesRA"></p>
								        </div>
								      </div>
								    </div>
								  </div>

								  <div className="col-sm-12 col-md-4 col-lg-4">
								    <div id="div-viernes" className="div-dia">
								      <div id="div-check-viernes" className="div-check-dias">
								        <input type="checkbox" id="checkviernes" name="viernes" onChange={(e) => {controlDias(e);}} />
								        <label for="checkviernes">
								          Viernes
								        </label>
								      </div>

								      <div id="horas-viernes" style={{display:'none'}}>
								        <div className="form-group input-field" style={{margin:'auto',width:'140px'}}>
								          <input className="input_correcto input" type="time" id="horaInicioviernesRA" name="horaInicioviernes" onChange={validarFormulario} value={this.state.horaInicioviernes} />
								          <label className="label" for="horaInicioviernesRA">Hora inicio</label>
								          <p id="error-horaInicioviernesRA"></p>
								        </div>

								        <div className="form-group input-field" style={{margin:'auto',width:'140px'}}>
								          <input className="input_correcto input" type="time" id="horaFinviernesRA" name="horaFinviernes" onChange={validarFormulario} value={this.state.horaFinviernes} />
								          <label className="label" for="horaFinviernesRA">Hora fin</label>
								          <p id="error-horaFinviernesRA"></p>
								        </div>
								      </div>
								    </div>
								  </div>

								  <div className="col-sm-12 col-md-4 col-lg-4">
								    <div id="div-sabado" className="div-dia">
								      <div id="div-check-sabado" className="div-check-dias">
								        <input type="checkbox" id="checksabado" name="sabado" onChange={(e) => {controlDias(e);}} />
								        <label for="checksabado">
								          Sábado
								        </label>
								      </div>

								      <div id="horas-sabado" style={{display:'none'}}>
								        <div className="form-group input-field" style={{margin:'auto',width:'140px'}}>
								          <input className="input_correcto input" type="time" id="horaIniciosabadoRA" name="horaIniciosabado" onChange={validarFormulario} value={this.state.horaIniciosabado} />
								          <label className="label" for="horaIniciosabadoRA">Hora inicio</label>
								          <p id="error-horaIniciosabadoRA"></p>
								        </div>

								        <div className="form-group input-field" style={{margin:'auto',width:'140px'}}>
								          <input className="input_correcto input" type="time" id="horaFinsabadoRA" name="horaFinsabado" onChange={validarFormulario} value={this.state.horaFinsabado} />
								          <label className="label" for="horaFinsabadoRA">Hora fin</label>
								          <p id="error-horaFinsabadoRA"></p>
								        </div>
								      </div>
								    </div>
								  </div>
								  
								  <div className="col-sm-12 col-md-4 col-lg-4">
								    <div id="div-domingo" className="div-dia">
								      <div id="div-check-domingo" className="div-check-dias">
								        <input type="checkbox" id="checkdomingo" name="domingo" onChange={(e) => {controlDias(e);}} />
								        <label for="checkdomingo">
								          Domingo
								        </label>
								      </div>

								      <div id="horas-domingo" style={{display:'none'}}>
								        <div className="form-group input-field" style={{margin:'auto',width:'140px'}}>
								          <input className="input_correcto input" type="time" id="horaIniciodomingoRA" name="horaIniciodomingo" onChange={validarFormulario} value={this.state.horaIniciodomingo} />
								          <label className="label" for="horaIniciodomingoRA">Hora inicio</label>
								          <p id="error-horaIniciodomingoRA"></p>
								        </div>

								        <div className="form-group input-field" style={{margin:'auto',width:'140px'}}>
								          <input className="input_correcto input" type="time" id="horaFindomingoRA" name="horaFindomingo" onChange={validarFormulario} value={this.state.horaFindomingo} />
								          <label className="label" for="horaFindomingoRA">Hora fin</label>
								          <p id="error-horaFindomingoRA"></p>
								        </div>
								      </div>
								    </div>
								  </div>
								</div>

								<div id="alerta-RA" style={{fontSize:'11pt',color:'#D45454',marginBottom:"10px",marginTop:"10px"}}></div>

								<RB.Button variant="info" id="boton1_RA" className="boton-presionado" type="submit" style={{float:'right',marginRight:'10px',width:'140px',fontSize:'10pt'}}>Actualizar datos</RB.Button>

								<Link to="/administrador/cursos" id="boton_RA" style={{float:'right',marginRight:'35px',lineHeight:'2.4',color:'#17a2b8',fontSize:'10pt',textDecoration:'none'}}>Cancelar</Link>
							</form>
						</div>
					</div>
				</div>
			</div>

			</React.Fragment>
		);
	}
}

export default EditarCurso;