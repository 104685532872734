import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import * as RB from "react-bootstrap";
import {auth} from '../firebase-config';
import {db} from '../firebase-config';
import logo_header from '../assets/images/logo_header.png';
import logout from '../assets/images/logout.png';
import moment from 'moment';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useLocation,
  useParams
} from "react-router-dom";

import TablaAdministradores from '../components/TablaAdministradores';
import RegistroAdministrador from '../components/RegistroAdministrador';
import EditarAdministrador from '../components/EditarAdministrador';

import TablaAlumnos from '../components/TablaAlumnos';
import RegistroAlumno from '../components/RegistroAlumno';
import InfoAlumno from '../components/InfoAlumno';

import TablaTutores from '../components/TablaTutores';
import RegistroTutor from '../components/RegistroTutor';
import EditarTutor from '../components/EditarTutor';
import InfoTutor from '../components/InfoTutor';

import TablaResponsables from '../components/TablaResponsables';
import RegistroResponsable from '../components/RegistroResponsable';
import EditarResponsable from '../components/EditarResponsable';

import TablaInstructores from '../components/TablaInstructores';
import RegistroInstructor from '../components/RegistroInstructor';
import EditarInstructor from '../components/EditarInstructor';

import TablaCursos from '../components/TablaCursos';
import RegistroCurso from '../components/RegistroCurso';
import EditarCurso from '../components/EditarCurso';

import TablaProductos from '../components/TablaProductos';
import RegistroProducto from '../components/RegistroProducto';
import EditarProducto from '../components/EditarProducto';

import TablaSesiones from '../components/TablaSesiones';
import RegistroSesion from '../components/RegistroSesion';

import ReporteAPA from '../components/ReporteAPA';
import ReporteIngresos from '../components/ReporteIngresos';
import ReporteHPI from '../components/ReporteHPI';

class InicioAdministrador extends React.Component{

	//Se ejecuta al cargar componente
	componentDidMount() {
		//---------------------------------------------------------------------------------------------------------
			document.title ="Chiquifit - Administrador";//Se cambia el título de la página
			//************************************************************************************************
			//**********************************Se verifica el tipo de sesión*********************************
			//************************************************************************************************
			auth.onAuthStateChanged(function(user) {
			  if (user) {
			    // Existe una sesión activa
			    var tipo_cuenta = "";
			    var user = auth.currentUser;
					var uid = user.uid;
					db.collection("usuarios").where("id_usuario", "==", uid).get().then(function(querySnapshot) {
				    querySnapshot.forEach(function(doc) {
				    	tipo_cuenta = doc.data().tipo_usuario;
							switch(tipo_cuenta){
								case "alumno": {document.getElementById("to_Alumno").click();}break;
								case "administrador": {/*SE MANTIENE EL LA PÁGINA*/}break;
								case "instructor": {document.getElementById("to_Instructor").click();}break;
								case "responsable": {document.getElementById("to_Responsable").click();}break;
								case "tutor": {document.getElementById("to_Tutor").click();}break;
								default: {
									//---------------------------------
									//Se cierra sesión
									auth.signOut().then(function() {
										document.getElementById("to_IniciarSesion").click();
									}).catch(function(error) {alert("Error al cerrar sesión");});
									//---------------------------------
								}break;
							}
				    });
				  }).catch(function(error) {/*Error al leer tipo de sesión*/});
			  } else {
			  	//No hay una sesión activa
			    document.getElementById("to_IniciarSesion").click();
			  }
			});
			//************************************************************************************************
			//************************************************************************************************
			//************************************************************************************************
		//---------------------------------------------------------------------------------------------------------
	}

	render(){
		//-------------------------------------------------------------
		function cerrar_sesion() {
			auth.signOut().then(function() {
				document.getElementById("to_IniciarSesion").click();//Se cerró sesión
			}).catch(function(error) {/*Error al cerrar sesión*/});
		}
		//-------------------------------------------------------------
		return(
			<React.Fragment>

				<Link id="to_IniciarSesion" to="/"></Link>
				<Link id="to_Alumno" to="/alumno/inicio"></Link>
				<Link id="to_Instructor" to="/instructor"></Link>
				<Link id="to_Responsable" to="/responsable/inicio"></Link>
				<Link id="to_Tutor" to="/tutor/inicio"></Link>

				<Link id="to_Administadores" to="/administrador/administradores"></Link>
				<Link id="to_Alumnos" to="/administrador/alumnos"></Link>
				<Link id="to_Tutores" to="/administrador/tutores"></Link>
				<Link id="to_Responsables" to="/administrador/responsables"></Link>
				<Link id="to_Instructores" to="/administrador/instructores"></Link>
				<Link id="to_Cursos" to="/administrador/cursos"></Link>
				<Link id="to_Sesiones" to="/administrador/sesiones"></Link>
				<Link id="to_Productos" to="/administrador/productos"></Link>
				<Link id="to_ReporteAPA" to="/administrador/reporte/APA"></Link>
				<Link id="to_ReporteIngresos" to="/administrador/reporte/ingresos"></Link>
				<Link id="to_ReporteHPI" to="/administrador/reporte/HPI"></Link>

		    <RB.Navbar collapseOnSelect expand="lg" variant="light" bg="white" style={{fontSize:'11pt', fontWeight:'500'}}>
			  <RB.Navbar.Brand className="mr-sm-5"><Link id="to_InicioAdministrador" to="/administrador"><img src={logo_header} width="180" /></Link></RB.Navbar.Brand>
			  <RB.Navbar.Toggle aria-controls="responsive-navbar-nav" />
			  <RB.Navbar.Collapse id="responsive-navbar-nav">
			    <RB.Nav className="mr-auto">
			      <RB.NavDropdown title="Personas" id="collasible-nav-dropdown" className="mr-sm-4">
			        <RB.NavDropdown.Item className="itemDropdown" onClick={() => document.getElementById('to_Administadores').click()}>Administradores</RB.NavDropdown.Item>
			        <RB.NavDropdown.Item className="itemDropdown" onClick={() => document.getElementById('to_Alumnos').click()}>Alumnos</RB.NavDropdown.Item>
			        <RB.NavDropdown.Item className="itemDropdown" onClick={() => document.getElementById('to_Tutores').click()}>Tutores</RB.NavDropdown.Item>
			        <RB.NavDropdown.Item className="itemDropdown" onClick={() => document.getElementById('to_Responsables').click()}>Responsables</RB.NavDropdown.Item>
			        <RB.NavDropdown.Item className="itemDropdown" onClick={() => document.getElementById('to_Instructores').click()}>Instructores</RB.NavDropdown.Item>
			      </RB.NavDropdown>
			      <RB.NavDropdown title="Actividades" id="collasible-nav-dropdown" className="mr-sm-4">
			        <RB.NavDropdown.Item className="itemDropdown" onClick={() => document.getElementById('to_Cursos').click()}>Cursos</RB.NavDropdown.Item>
			        <RB.NavDropdown.Item className="itemDropdown" onClick={() => document.getElementById('to_Sesiones').click()}>Sesiones</RB.NavDropdown.Item>
			      </RB.NavDropdown>
			      <RB.Nav.Link onClick={() => document.getElementById('to_Productos').click()} className="mr-sm-4">Productos</RB.Nav.Link>
			      <RB.NavDropdown title="Reportes" id="collasible-nav-dropdown" className="mr-sm-4">
			      	<RB.NavDropdown.Item className="itemDropdown" onClick={() => document.getElementById('to_ReporteHPI').click()}>Sesiones por instructor</RB.NavDropdown.Item>
			        <RB.NavDropdown.Item className="itemDropdown" onClick={() => document.getElementById('to_ReporteAPA').click()}>Inscripciones</RB.NavDropdown.Item>
			        <RB.NavDropdown.Item className="itemDropdown" onClick={() => document.getElementById('to_ReporteIngresos').click()}>Ingresos</RB.NavDropdown.Item>
			      </RB.NavDropdown>
			    </RB.Nav>
			    <RB.Nav>
			      <RB.Nav.Link onClick={cerrar_sesion} style={{fontSize:'10pt'}} className="logout"><img src={logout} width="15" style={{marginRight:'3px',opacity:'0.5',marginTop:'-4px'}}/>Cerrar sesión</RB.Nav.Link>
			    </RB.Nav>
			  </RB.Navbar.Collapse>
				</RB.Navbar>

				<div>
					<Switch>
					  <Route path="/administrador" exact children={<TablaAlumnos />} />
	
	          <Route path="/administrador/administradores" children={<TablaAdministradores />} />
	          <Route path="/administrador/alta/administrador" children={<RegistroAdministrador />} />
	          <Route path="/administrador/editar/administrador/:uid" render={(props) => <EditarAdministrador {...props} />}/>
	
	          <Route path="/administrador/alumnos" children={<TablaAlumnos />} />
	          <Route path="/administrador/alta/alumno" children={<RegistroAlumno />} />
	          <Route path="/administrador/informacion/alumno/:uid" render={(props) => <InfoAlumno {...props} />}/>
	
	          <Route path="/administrador/tutores" children={<TablaTutores />} />
	          <Route path="/administrador/alta/tutor" children={<RegistroTutor />} />
	          <Route path="/administrador/editar/tutor/:uid" render={(props) => <EditarTutor {...props} />}/>
	          <Route path="/administrador/informacion/tutor/:uid" render={(props) => <InfoTutor {...props} />}/>
	
	          <Route path="/administrador/responsables" children={<TablaResponsables />} />
	          <Route path="/administrador/alta/responsable" children={<RegistroResponsable />} />
	          <Route path="/administrador/editar/responsable/:uid" render={(props) => <EditarResponsable {...props} />}/>
	
	          <Route path="/administrador/instructores" children={<TablaInstructores />} />
	          <Route path="/administrador/alta/instructor" children={<RegistroInstructor />} />
	          <Route path="/administrador/editar/instructor/:uid" render={(props) => <EditarInstructor {...props} />}/>
	
	          <Route path="/administrador/cursos" children={<TablaCursos />} />
	          <Route path="/administrador/alta/curso" children={<RegistroCurso />} />
	          <Route path="/administrador/editar/curso/:uid" render={(props) => <EditarCurso {...props} />}/>
	
	          <Route path="/administrador/productos" children={<TablaProductos />} />
	          <Route path="/administrador/alta/producto" children={<RegistroProducto />} />
	          <Route path="/administrador/editar/producto/:uid" render={(props) => <EditarProducto {...props} />}/>
	
	          <Route path="/administrador/sesiones" children={<TablaSesiones />} />
	          <Route path="/administrador/alta/sesion" children={<RegistroSesion />} />
	
	          <Route path="/administrador/reporte/APA" children={<ReporteAPA />} />
	          <Route path="/administrador/reporte/ingresos" children={<ReporteIngresos />} />
	          <Route path="/administrador/reporte/HPI" children={<ReporteHPI />} />
		      </Switch>
				</div>

			</React.Fragment>
		);
	}

}

export default InicioAdministrador;