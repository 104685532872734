//Importación de componentes------------------------------------------------
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import InicioSesion from './components/InicioSesion';
import InicioAdministrador from './components/InicioAdministrador';
import InicioAlumno from './components/InicioAlumno';
import InicioInstructor from './components/instructor/InicioInstructor';
import InicioResponsable from './components/InicioResponsable';
import InicioTutor from './components/InicioTutor';
import './assets/css/general.css';
//--------------------------------------------------------------------------

function App() {
  return (
    <Router>
	      <Route path="/" exact component={InicioSesion} />
	      <Route path="/administrador" component={InicioAdministrador} />
	      <Route path="/alumno/inicio" component={InicioAlumno} />
	      <Route path="/instructor" component={InicioInstructor} />
	      <Route path="/responsable/inicio" component={InicioResponsable} />
	      <Route path="/tutor/inicio" component={InicioTutor} />
    </Router>
  );
}

export default App;