import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import {auth} from '../firebase-config';
import {auth_aux} from '../firebase-config';
import {db} from '../firebase-config';
import logo_header from '../assets/images/logo_header.png';
import {Link} from 'react-router-dom';
import * as RB from "react-bootstrap";
import swal from 'sweetalert2';
import firebase from 'firebase/app';
import 'firebase/auth'
import advertencia from '../assets/images/advertencia.png';
import print from '../assets/images/print.png';
import moment from 'moment';
import key from '../assets/images/key.png';
import eliminar from '../assets/images/eliminar.png';
import lupa from '../assets/images/lupa.png';
import nuevo from '../assets/images/nuevo.png';
import asociar from '../assets/images/asociar.png';
import mas from '../assets/images/mas.png';
import jsPDF from 'jspdf';
import 'jspdf-autotable'

class ReporteAPA extends React.Component{
	//constructor del componente
	constructor(props){
		super(props)
		//Estado inicial del componente
		this.state = {
			cursos: []
		}
	}
	//Se ejecuta al cargar componente
	componentDidMount() {
		//-----------------------------------------------------------------------------------------------------------------
		db.collection("actividades").orderBy("clave").get().then(querySnapshot => {
	    	const docs = [];
	        querySnapshot.forEach(doc => {
	            //-------------------------------------------------------------------------
	            docs.push({
	            	clave: doc.data().clave,
	            	nombre: doc.data().nombre,
	            	id: doc.id
	            });
	            //-------------------------------------------------------------------------
	        });
	        this.setState({cursos: docs});
	    });
		//-----------------------------------------------------------------------------------------------------------------
	}
	//----------------------------------------------------------------------------------------------------------------------------------
	//----------------------------------------------------------------------------------------------------------------------------------
	//----------------------------------------------------------------------------------------------------------------------------------
	//
	//-----------------------------------------------------------------------------------------------------------------------------------
	//-----------------------------------------------------------------------------------------------------------------------------------
	//-----------------------------------------------------------------------------------------------------------------------------------
	render(){
		//**************Variables para impresión********************
		var impresionDisponible = false;
		var pdf;
		//**********************************************************
		//****************************************************************************************************************
		//****************************************************************************************************************
		//****************************************************************************************************************
		//BÚSQUEDA DE ACTIVIDAD (CONSULTA FIRESTORE)***********************************************************
	    const buscarActividad = (e) => {

	      e.preventDefault();//Evita la recarga de la página
	      var textoBusqueda = document.getElementById("busqueda-actividad").value.toUpperCase();//Texto a buscar
	      var existeResultado = false;//Se inicia sin resultados
	      document.getElementById("body-tabla-actividades-busqueda").innerHTML = "";//Se borran todos los resultados previos
	      //Sí hay texto que buscar
	      if(textoBusqueda !== ""){
	        //-------------------------------------------------------------------------------------------------------------
	        //Se busca entre todos los cursos coincidencias
		    this.state.cursos.forEach(curso => {
		      if(curso.clave.search(textoBusqueda) >= 0 || curso.nombre.search(textoBusqueda) >= 0){
		        existeResultado = true;//Existe al menos un resultado
		        //-------------------------------------------------------------------------------------------------------
		        document.getElementById("body-tabla-actividades-busqueda").insertAdjacentHTML("beforeend", 
                "<tr class='filaTabla filaTabla-hover'>"+
                  "<td>"+curso.clave+"</td>"+
                  "<td>"+curso.nombre+"</td>"+
                  "<td>"+
                    "<div class='toggle-botonTabla' id='"+curso.id+"busqueda-VI"+"' title='Seleccionar' style='margin-right:15px'>"+
                      "<img class='botonTabla' src="+nuevo+" width='17px' />"+
                    "</div>"+
                  "</td>"+
                "</tr>");
                //Evento de la fila (onclick en asociar)----------------------------------------
                document.getElementById(curso.id+"busqueda-VI").addEventListener('click', (e) => {
                  asociarActividad(curso.id, curso.clave, curso.nombre);
                });
                //------------------------------------------------------------------------------
		        //-------------------------------------------------------------------------------------------------------
		      }
		    });
		    //-------------------------------------------------------------------------------------------------------------
		    //Sí no hubo resultados----------------------------------------------------------------------------------------
		    if(!existeResultado){
		    	document.getElementById("body-tabla-actividades-busqueda").innerHTML = 
	            "<tr>"+
	              "<td colSpan='3'>"+
	                "<center>"+
	                  "<p id='avisoSinResultadosBusquedaActividad' style='color:#c8c8c8'>No hay resultados a mostrar</p>"+
	                "</center>"+
	              "</td>"+
	            "</tr>";
		    }
		    //-------------------------------------------------------------------------------------------------------------
	      } else {
	      	//No hay texto a buscar
	      	document.getElementById("body-tabla-actividades-busqueda").innerHTML = 
            "<tr>"+
              "<td colSpan='3'>"+
                "<center>"+
                  "<p id='avisoSinResultadosBusquedaActividad' style='color:#c8c8c8'>No hay resultados a mostrar</p>"+
                "</center>"+
              "</td>"+
            "</tr>";
	      }

	    }
	    //*****************************************************************************************************
	    //***************************************** ASOCIAR ACTIVIDAD ***********************************************
	    var curso = [];
	    const asociarActividad = (uid, clave, nombre) => {
	      //Sí la tabla está vacía, se elimina el mensaje de "Ninguna selección"-------
	      if(curso.length == 0){
	        document.getElementById("body-tabla-actividades-asociados").innerHTML = "";
	      }
	      //---------------------------------------------------------------------------
	      //Se guarda en array "actividades" la referencia--------------------------------------------------------------------
	      //Sí ya existe el valor que se intenta guardar, se omite
	      var aux = db.collection('actividades').doc(uid);
	      if(!(curso.find(element => element.id == aux.id) !== undefined)){
	        curso.push(aux);//Guardado en el array "actividades"
	        //*********************************************************************
	        //***************** SE INICIA CONSUTAS PARA REPORTE *******************
	        //*********************************************************************
	        if(!errorEnPeriodo){
	        	generarReporte();
	        }
	        //*********************************************************************
	        //*********************************************************************
	        //*********************************************************************
	        //Mostrar en Front (Tabla de asociación)----------------------------------------------------------------------------
	        //*****************************************************************************************
	        document.getElementById("body-tabla-actividades-asociados").insertAdjacentHTML("beforeend", 
	        "<tr class='filaTabla filaTabla-hover' id='"+uid+"asociado-fila-VI'>"+
	          "<td>"+clave+"</td>"+
	          "<td>"+nombre+"</td>"+
	          "<td>"+
	            "<div class='toggle-botonTabla' id='"+uid+"asociado-VI"+"' title='Eliminar selección' style='margin-right:15px'>"+
	              "<img class='botonTabla' src="+eliminar+" width='17px' />"+
	            "</div>"+
	          "</td>"+
	        "</tr>");
	        //Evento de la fila (onclick en desasociar)----------------------------------------
	        document.getElementById(uid+"asociado-VI").addEventListener('click', (e) => {
	          //--------------------------------------------------------------------------------
	          //----------------------------- Desasociar actividad -----------------------------
	          //--------------------------------------------------------------------------------
	          //Se elimina de la tabla (Front)
	          document.getElementById("body-tabla-actividades-asociados").removeChild(document.getElementById(uid+"asociado-fila-VI"));
	          //Se elimina del array "actividades"
	          curso.splice(curso.indexOf(curso.find(element => element.id == aux.id)), 1);
	          //*********************************************************************
	          //***************** SE INICIA CONSUTAS PARA REPORTE *******************
	          //*********************************************************************
	          if(!errorEnPeriodo && curso.length !== 0){
	        	  generarReporte();
	          }
	          //*********************************************************************
	          //*********************************************************************
	          //*********************************************************************
	          //Sí la tabla se quedó sin elementos
	          if(curso.length == 0) {
	          	document.getElementById("imprimir").style.opacity = "0.2";
			    impresionDisponible = false;
	            document.getElementById("total").innerHTML = "0";
	            document.getElementById("total").style.color = "#C3C3C3";
	            document.getElementById("body-tabla-alumnos").innerHTML = 
			    "<tr>"+
		          "<td colSpan='5'>"+
		            "<center>"+
		              "<p id='avisoSinAlumnos' style='color:#c8c8c8'>No hay resultados a mostrar</p>"+
		            "</center>"+
		          "</td>"+
		        "</tr>";
	            document.getElementById("body-tabla-actividades-asociados").innerHTML = 
	            "<tr>"+
	              "<td colSpan='3'>"+
	                "<center>"+
	                  "<p id='avisoSinAsociacionesActividad' style='color:#c8c8c8'>Ningún curso seleccionado</p>"+
	                "</center>"+
	              "</td>"+
	            "</tr>";
	          }
	          //--------------------------------------------------------------------------------
	          //--------------------------------------------------------------------------------
	          //--------------------------------------------------------------------------------
	        });
	        //------------------------------------------------------------------------------
	        //*****************************************************************************************
	        //-----------------------------------------------------------------------------------------------------------------
	      }
	      //------------------------------------------------------------------------------------------------------------------
	    }
	    //***********************************************************************************************************
	    //***************************************SELECCIONAR TODOS LOS CURSOS**********************************************
	    const selectAllCursos = () => {
	    	//Se eliminan todos los elementos de la tabla asociados
	    	document.getElementById("body-tabla-actividades-asociados").innerHTML = "";
	    	//Se resetea el arreglo curso
	    	curso = [];
	    	//Se recorre el estado que contiene todos los cursos y se van asociando
	    	this.state.cursos.forEach(curso => {
					asociarActividad(curso.id, curso.clave, curso.nombre);
				});
	    }
	    //*****************************************************************************************************************
		//----------------------------------------------------------------------------------------------------
		//VALIDACIÓN DE LAS FECHAS----------------------------------------------------------------------------
		//----------------------------------------------------------------------------------------------------
		var errorEnPeriodo = true;
		const validarFechas = (e) => {
			switch(e.target.name){
				case "fechaInicio": {
					//-----------------------------------------------------------------------------------------------------------
					if(document.getElementById("fechaInicioRA").value>=document.getElementById("fechaFinRA").value){
						document.getElementById("fechaInicioRA").classList.remove('input_correcto');
						document.getElementById("fechaInicioRA").classList.add('input_incorrecto');
						if(document.getElementById("fechaInicioRA").value==document.getElementById("fechaFinRA").value){
							document.getElementById("error-"+"fechaInicioRA").innerHTML = 'Inicio igual que fin';
							document.getElementById("error-"+"fechaFinRA").innerHTML = '';
						} else {
							document.getElementById("error-"+"fechaInicioRA").innerHTML = 'Inicio mayor que fin';
							document.getElementById("error-"+"fechaFinRA").innerHTML = '';
						}
						errorEnPeriodo = true;
						document.getElementById("imprimir").style.opacity = "0.2";
						impresionDisponible = false;
						document.getElementById("total").innerHTML = "0";
						document.getElementById("total").style.color = "#C3C3C3";
						document.getElementById("body-tabla-alumnos").innerHTML = 
						"<tr>"+
			              "<td colSpan='5'>"+
			                "<center>"+
			                  "<p id='avisoSinAlumnos' style='color:#c8c8c8'>No hay resultados a mostrar</p>"+
			                "</center>"+
			              "</td>"+
			            "</tr>";
					} else {
						document.getElementById("fechaInicioRA").classList.remove('input_incorrecto');
						document.getElementById("fechaInicioRA").classList.add('input_correcto');
						document.getElementById("error-"+"fechaInicioRA").innerHTML = '';
						document.getElementById("fechaFinRA").classList.remove('input_incorrecto');
						document.getElementById("fechaFinRA").classList.add('input_correcto');
						document.getElementById("error-"+"fechaFinRA").innerHTML = '';
						errorEnPeriodo = false;
					}
					//-----------------------------------------------------------------------------------------------------------
				}break;
				case "fechaFin": {
					//-----------------------------------------------------------------------------------------------------------
					if(document.getElementById("fechaInicioRA").value>=document.getElementById("fechaFinRA").value){
						document.getElementById("fechaFinRA").classList.remove('input_correcto');
						document.getElementById("fechaFinRA").classList.add('input_incorrecto');
						if(document.getElementById("fechaInicioRA").value==document.getElementById("fechaFinRA").value){
							document.getElementById("error-"+"fechaFinRA").innerHTML = 'Fin igual que inicio';
							document.getElementById("error-"+"fechaInicioRA").innerHTML = '';
						} else {
							document.getElementById("error-"+"fechaFinRA").innerHTML = 'Fin menor que inicio';
							document.getElementById("error-"+"fechaInicioRA").innerHTML = '';
						}
						errorEnPeriodo = true;
						document.getElementById("imprimir").style.opacity = "0.2";
						impresionDisponible = false;
						document.getElementById("total").innerHTML = "0";
						document.getElementById("total").style.color = "#C3C3C3";
						document.getElementById("body-tabla-alumnos").innerHTML = 
						"<tr>"+
			              "<td colSpan='5'>"+
			                "<center>"+
			                  "<p id='avisoSinAlumnos' style='color:#c8c8c8'>No hay resultados a mostrar</p>"+
			                "</center>"+
			              "</td>"+
			            "</tr>";
					} else {
						document.getElementById("fechaFinRA").classList.remove('input_incorrecto');
						document.getElementById("fechaFinRA").classList.add('input_correcto');
						document.getElementById("error-"+"fechaFinRA").innerHTML = '';
						document.getElementById("fechaInicioRA").classList.remove('input_incorrecto');
						document.getElementById("fechaInicioRA").classList.add('input_correcto');
						document.getElementById("error-"+"fechaInicioRA").innerHTML = '';
						if(document.getElementById("fechaInicioRA").value == ""){
							errorEnPeriodo = true;
							document.getElementById("imprimir").style.opacity = "0.2";
							impresionDisponible = false;
							document.getElementById("total").innerHTML = "0";
							document.getElementById("total").style.color = "#C3C3C3";
							document.getElementById("body-tabla-alumnos").innerHTML = 
							"<tr>"+
				              "<td colSpan='5'>"+
				                "<center>"+
				                  "<p id='avisoSinAlumnos' style='color:#c8c8c8'>No hay resultados a mostrar</p>"+
				                "</center>"+
				              "</td>"+
				            "</tr>";
						} else {
							errorEnPeriodo = false;
						}
					}
					//-----------------------------------------------------------------------------------------------------------
				}break;
			}
			//*********************************************************************
	        //***************** SE INICIA CONSUTAS PARA REPORTE *******************
	        //*********************************************************************
	        if(!errorEnPeriodo && curso.length > 0){
	        	if(e.target.value !== "" && e.target.valueAsDate.getFullYear() > 1980 && e.target.valueAsDate.getFullYear() < 2100){
		        	//Fecha correcta
		        	generarReporte();
		        } else {
		        	document.getElementById("imprimir").style.opacity = "0.2";
					impresionDisponible = false;
					document.getElementById("total").innerHTML = "0";
					document.getElementById("total").style.color = "#C3C3C3";
					document.getElementById("body-tabla-alumnos").innerHTML = 
					"<tr>"+
		              "<td colSpan='5'>"+
		                "<center>"+
		                  "<p id='avisoSinAlumnos' style='color:#c8c8c8'>No hay resultados a mostrar</p>"+
		                "</center>"+
		              "</td>"+
		            "</tr>";
		            e.target.classList.remove('input_correcto');
					e.target.classList.add('input_incorrecto');
		        }
	        }
	        //*********************************************************************
	        //*********************************************************************
	        //*********************************************************************
		}
		//----------------------------------------------------------------------------------------------------
		//----------------------------------------------------------------------------------------------------
		//----------------------------------------------------------------------------------------------------
		const iniciarGenerarReporte = () => {
			//*********************************************************************
      //***************** SE INICIA CONSUTAS PARA REPORTE *******************
      //*********************************************************************
      if(!errorEnPeriodo && curso.length !== 0){
    	  generarReporte();
      }
      //*********************************************************************
      //*********************************************************************
      //*********************************************************************
		}
		//-------------------------------------------------------------------------------------------------------------
		//---------------------------------------------- GENERAR REPORTE ----------------------------------------------
		//-------------------------------------------------------------------------------------------------------------
		const generarReporte = () => {

			//IMPRESIÓN************************************************************
			var pdfY = 0;
			var pdfX = 25;
			pdf = null;
			pdf = new jsPDF('p', 'pt', 'a4');
			//ENCABEZADO--------------------------------------------------
			pdfY+=15;
			pdf.addImage(logo_header, 'PNG', pdfX-5, pdfY, 130, 37);
			pdfY+=55;
			textoCentradoPDF("REPORTE DE INSCRIPCIONES", pdfY, 12);
			pdf.setFontSize(10);
			pdfY+=25;
    		pdf.text(pdfX, pdfY, "Fecha de consulta: "+(new Date().toLocaleDateString()));
    		pdfY+=15;
    		pdf.text(pdfX, pdfY, "Período consultado: "+new Date(document.getElementById("fechaInicioRA").value.replace(/-/g, '\/')).toLocaleDateString()+" 00:00 a "+new Date(document.getElementById("fechaFinRA").value.replace(/-/g, '\/')).toLocaleDateString()+" 00:00");
    		pdfY+=45;
    		textoCentradoPDF("INSCRIPCIONES", pdfY, 10);
    		pdfY+=20;
    		var arrayTabla = [];
    		//------------------------------------------------------------
			//*********************************************************************
			document.getElementById("body-tabla-alumnos").innerHTML = "";//Se limpia tabla de alumnos
			var aux_total = 0;//Total de alumnos inscritos al curso durante el período
			//*********************************************************************************************************************************************
			//*********************************************************************************************************************************************
			//*********************************************************************************************************************************************
			//*********************************************************************************************************************************************
			var sinInscripciones = true;
			var indexCursoSeleccionado = 0;
			curso.forEach(cursoSeleccionado => {
				db.collection("actividades").doc(cursoSeleccionado.id).get().then(cursoActual => {
					db.collection("inscripciones")
					.where("actividad", "==", cursoSeleccionado)
					.where("fechaInscripcion", ">=", firebase.firestore.Timestamp.fromDate(new Date(document.getElementById("fechaInicioRA").value.replace(/-/g, '\/'))))
					.where("fechaInscripcion", "<=", firebase.firestore.Timestamp.fromDate(new Date(document.getElementById("fechaFinRA").value.replace(/-/g, '\/'))))
					.orderBy("fechaInscripcion", "desc")
					.get().then(inscripciones => {

						var index = 0;
						inscripciones.forEach(doc => {
							if(doc.data().fechaBaja != null && document.getElementById("mostrarInactivos").checked || doc.data().fechaBaja == null) {
						    aux_total++;
						    //Se llena tabla "Alumnos que fueron inscritos al curso durante el período seleccionado"------------
						    db.collection("alumnos").doc(doc.data().alumno.id).get().then(alumno => {
							    if (alumno.exists) {
							    	//Sí alumno existe
							    	var nombre_curso_aux = cursoActual.data().nombre;
							    	var nombre_aux = alumno.data().nombre;
							    	var apellidos_aux = alumno.data().apellidos;
							    	var fechaInscripcion_aux = moment(doc.data().fechaInscripcion.toDate()).format('DD-MM-YYYY');
							    	var fechaBaja_aux = printFechaBaja(doc.data().fechaBaja);
							    	var fechaNacimiento_aux = moment(alumno.data().fechaNacimiento.toDate()).format('DD-MM-YYYY');
							    	var edad_aux = parseInt(((new Date()) - (new Date(moment(alumno.data().fechaNacimiento.toDate()).format('YYYY-MM-DD')))) / (1000*60*60*24*365));
							    	if(document.getElementById(doc.id) == undefined){
								        document.getElementById("body-tabla-alumnos").insertAdjacentHTML("beforeend", 
								        "<tr class='filaTabla filaTabla-hover' id='"+doc.id+"'>"+
								          "<td>"+nombre_curso_aux+"</td>"+
								          "<td>"+nombre_aux+"</td>"+
								          "<td>"+apellidos_aux+"</td>"+
								          "<td>"+fechaInscripcion_aux+"</td>"+
								          "<td>"+fechaBaja_aux+"</td>"+
								          "<td>"+fechaNacimiento_aux+"</td>"+
								          "<td>"+edad_aux+"</td>"+
								        "</tr>");
							    	}
							    	//IMPRESIÓN*************************************************************
							    	arrayTabla.push([nombre_curso_aux, nombre_aux, apellidos_aux, fechaInscripcion_aux, fechaBaja_aux, fechaNacimiento_aux, edad_aux]);
								    if(index == (inscripciones.size-1) && indexCursoSeleccionado == (curso.length-1)){
								    	pdf.text(pdfX, 125, "Total de inscripciones: "+aux_total);
										pdf.autoTable({
										  head: [['Curso', 'Nombre', 'Apellidos', 'Fecha de inscripción', 'Fecha de baja', 'Fecha de nacimiento', 'Edad']],
										  startY: pdfY,
										  body: arrayTabla
										});
										document.getElementById("total").innerHTML = aux_total;
										document.getElementById("total").style.color = "#282828";
								    }
								    if(index == (inscripciones.size-1)){indexCursoSeleccionado++;}
									//**********************************************************************
									index++;
							    } else {
							    	//Sí alumno no existe
							    	var nombre_curso_aux = cursoActual.data().nombre;
							    	var fechaInscripcion_aux = moment(doc.data().fechaInscripcion.toDate()).format('DD-MM-YYYY');
							    	var fechaBaja_aux = printFechaBaja(doc.data().fechaBaja);
							    	if(document.getElementById(doc.id) == undefined){
								        document.getElementById("body-tabla-alumnos").insertAdjacentHTML("beforeend", 
								        "<tr class='filaTabla filaTabla-hover' id='"+doc.id+"'>"+
								          "<td>"+nombre_curso_aux+"</td>"+
								          "<td><i>Eliminado</i></td>"+
								          "<td><i>Eliminado</i></td>"+
								          "<td>"+fechaInscripcion_aux+"</td>"+
								          "<td>"+fechaBaja_aux+"</td>"+
								          "<td><i>Eliminado</i></td>"+
								          "<td><i>-</i></td>"+
								        "</tr>");
							    	}
							    	//IMPRESIÓN*************************************************************
							    	arrayTabla.push([nombre_curso_aux, "Eliminado", "Eliminado", fechaInscripcion_aux, fechaBaja_aux, "Eliminado", "-"]);
								    if(index == (inscripciones.size-1) && indexCursoSeleccionado == (curso.length-1)){
								    	pdf.text(pdfX, 125, "Total de inscripciones: "+aux_total);
										pdf.autoTable({
										  head: [['Curso', 'Nombre', 'Apellidos', 'Fecha de inscripción', 'Fecha de baja', 'Fecha de nacimiento', 'Edad']],
										  startY: pdfY,
										  body: arrayTabla
										});
										document.getElementById("total").innerHTML = aux_total;
										document.getElementById("total").style.color = "#282828";
								    }
								    if(index == (inscripciones.size-1)){indexCursoSeleccionado++;}
									//**********************************************************************
									index++;
							    }
							});
						  //--------------------------------------------------------------------------------------------------
						  } else {
						  			if(index == (inscripciones.size-1) && indexCursoSeleccionado == (curso.length-1)){
								    	pdf.text(pdfX, 125, "Total de inscripciones: "+aux_total);
										pdf.autoTable({
										  head: [['Curso', 'Nombre', 'Apellidos', 'Fecha de inscripción', 'Fecha de baja', 'Fecha de nacimiento', 'Edad']],
										  startY: pdfY,
										  body: arrayTabla
										});
										document.getElementById("total").innerHTML = aux_total;
										document.getElementById("total").style.color = "#282828";
								    }
								    if(index == (inscripciones.size-1)){indexCursoSeleccionado++;}
								    index++;
						  }
						});

						if(inscripciones.empty){
							if(indexCursoSeleccionado == (curso.length-1) && sinInscripciones){
								document.getElementById("imprimir").style.opacity = "0.2";
								impresionDisponible = false;
								document.getElementById("body-tabla-alumnos").innerHTML = 
								"<tr>"+
					              "<td colSpan='7'>"+
					                "<center>"+
					                  "<p id='avisoSinAlumnos' style='color:#c8c8c8'>No hay resultados a mostrar</p>"+
					                "</center>"+
					              "</td>"+
					            "</tr>";
							}
							indexCursoSeleccionado++;
			        	} else {
			        		sinInscripciones = false;
							document.getElementById("imprimir").style.opacity = "0.9";
							impresionDisponible = true;
						}

					}).catch(error => {console.log(error);});
				});
			});
			//*********************************************************************************************************************************************
			//*********************************************************************************************************************************************
			//*********************************************************************************************************************************************
			//*********************************************************************************************************************************************

		}
		function printFechaBaja(fecha){
			if(fecha == null){
				return("Activo");
			} else {
				return(moment(fecha.toDate()).format('DD-MM-YYYY'));
			}
		}
		//-------------------------------------------------------------------------------------------------------------
		//-------------------------------------------------------------------------------------------------------------
		//-------------------------------------------------------------------------------------------------------------
		//----------------------------------------------------------------------------------------------------
		//------------------------------------- IMPRESIÓN DE REPORTE -----------------------------------------
		//----------------------------------------------------------------------------------------------------
		const imprimirReporte = () => {
			//-----------------------------------------------------------------------------------
			if(impresionDisponible){
				window.open(pdf.output('bloburl'),'_blank');
			}
			//-----------------------------------------------------------------------------------
		}
		function textoCentradoPDF(text, y, fSize){
			var textWidth = pdf.getStringUnitWidth(text) * fSize / pdf.internal.scaleFactor;
    		var textOffset = (pdf.internal.pageSize.width - textWidth) / 2;
    		pdf.setFontSize(fSize);
    		pdf.text(textOffset, y, text);
		}
		//----------------------------------------------------------------------------------------------------
		//----------------------------------------------------------------------------------------------------
		//----------------------------------------------------------------------------------------------------
		//****************************************************************************************************************
		//****************************************************************************************************************
		//****************************************************************************************************************
		return(

			<div className="global-container">
				<div className="card login-form tablas-adm" style={{width:'82%'}}>
					<div className="card-body card-tablas-adm" style={{padding:'10px'}}>

						<div className="row">
							<div className="col-sm-12 col-md-9 col-lg-8">
								<h3 className="card-title" style={{fontSize:'16pt',fontWeight:'500'}}>Reporte de inscripciones</h3>
							</div>
							<div className="col-sm-12 col-md-3 col-lg-4">
								<div id="imprimir" style={{cursor:'pointer',opacity:'0.2'}} onClick={imprimirReporte}>
									<p style={{float:'right',display:'inline',fontSize:'9pt'}}>Imprimir reporte</p>
									<img src={print} style={{float:'right',display:'inline',marginRight:'6px'}} width="20px"/>
								</div>
							</div>
						</div>

						<div className="row">
							<div className="col-sm-12 col-md-7 col-lg-5">
								<form autoComplete="off" style={{marginTop:'0px',margin:'auto'}}>
					                <div style={{borderRadius:'8px',border:'1px solid #D2D5D8',width:"100%",margin:'auto',marginBottom:'30px'}}>
					                  <div className="wrap" style={{width:'100%'}}>
					                     <div className="search">
					                        <input type="search" onSearch={buscarActividad} onChange={buscarActividad} className="searchTerm" id="busqueda-actividad" placeholder="Buscar curso por clave o nombre"/>
					                        <button className="searchButton" onClick={buscarActividad} id="boton-busqueda-actividad">
					                          <img src={lupa} style={{marginTop:'-6px',marginLeft:'-2px',opacity:'0.6'}} width="18px" />
					                        </button>
					                     </div>
					                  </div>
					                  <div style={{borderRadius:'0px 0px 7px 7px',overflow:'hidden',width: '100%',margin:'auto'}}>
					                    <div id="tabla-actividades" style={{
					                        width: '100%',
					                        height: '130px', 
					                        overflowY: 'auto',
					                        overflowX: 'auto',
					                        backgroundColor: '#fff',
					                        margin: 'auto'
					                    }}>
					                      <RB.Table>
					                        <thead>
					                           <tr style={{backgroundColor:'#fafafa'}}>
					                              <th>Clave</th>
					                              <th>Nombre</th>
					                              <th width="50px"></th>
					                           </tr>
					                        </thead>
					                        <tbody id="body-tabla-actividades-busqueda">
					                          <tr>
					                            <td colSpan="3">
					                              <center>
					                                <p id="avisoSinResultadosBusquedaActividad" style={{color: '#c8c8c8'}}>No hay resultados a mostrar</p>
					                              </center>
					                            </td>
					                          </tr>
					                        </tbody>
					                      </RB.Table>
					                    </div>
					                  </div>
					                </div>
				                </form>
							</div>

							<div className="col-sm-12 col-md-5 col-lg-4">
								<div className="form-group input-field" style={{margin:'auto',marginTop:'20px'}}>
									<input className="input_correcto input" type="date" id="fechaInicioRA" name="fechaInicio" onChange={validarFechas} required/>
									<label className="label" for="fechaInicioRA">Inicio de período</label>
									<p id="error-fechaInicioRA"></p>
								</div>
								<div className="form-group input-field" style={{margin:'auto',marginTop:'20px'}}>
									<input className="input_correcto input" type="date" id="fechaFinRA" name="fechaFin" onChange={validarFechas} required/>
									<label className="label" for="fechaFinRA">Fin de período</label>
									<p id="error-fechaFinRA"></p>
								</div>
							</div>

							<div className="col-sm-12 col-md-12 col-lg-3">
								<center style={{marginTop:'40px'}}>
									<h3 className="card-title" style={{fontSize:'13pt',fontWeight:'500'}}>Total de alumnos inscritos durante el período</h3>
									<h3 className="card-title" id="total" style={{fontSize:'30pt',fontWeight:'500',color:'#C3C3C3'}}>0</h3>
								</center>
							</div>
						</div>

		                <div className="row" style={{paddingTop:'0px',paddingBottom:'25px'}}>
		                	<div className="col-sm-12 col-md-7 col-lg-5" style={{marginBottom:'20px'}}>
			                    <div style={{margin:'auto',backgroundColor:'#fafafa',border:'1px solid #D2D5D8',borderRadius:'9px',padding:'1px',width: '100%'}}>
			                        <p style={{fontSize:'10pt',fontWeight:'500',marginBottom:'11px',marginLeft:'10px',marginTop:'8px',float:'left'}}>Cursos seleccionados</p>
			                        <RB.Button variant="info" style={{fontSize: '10pt',float: 'right',borderRadius:'8px'}} className="boton-presionado" onClick={() => selectAllCursos()}>Seleccionar todos los cursos</RB.Button>
			                        <div style={{borderRadius:'0px 0px 8px 8px',overflow:'hidden',width: '100%',margin:'auto'}}>
			                          <div id="tabla-actividades" style={{
			                              width: '100%',
			                              height: '300px', 
			                              overflowY: 'auto',
			                              overflowX: 'auto',
			                              backgroundColor: '#fff',
			                              margin: 'auto'
			                          }}>
			                            <RB.Table>
			                              <thead>
			                                 <tr style={{backgroundColor:'#fafafa'}}>
			                                    <th>Clave</th>
			                                    <th>Nombre</th>
			                                    <th width="50px"></th>
			                                 </tr>
			                              </thead>
			                              <tbody id="body-tabla-actividades-asociados">
			                                <tr>
			                                  <td colSpan="3">
			                                    <center>
			                                      <p id="avisoSinAsociacionesActividad" style={{color: '#c8c8c8'}}>Ningún curso seleccionado</p>
			                                    </center>
			                                  </td>
			                                </tr>
			                              </tbody>
			                            </RB.Table>
			                          </div>
			                        </div>
			                    </div>
			                </div>

			                <div className="col-sm-12 col-md-5 col-lg-7">
			                    <div style={{margin:'auto',backgroundColor:'#fafafa',border:'1px solid #D2D5D8',borderRadius:'9px',padding:'1px',width: '100%'}}>
			                        <p style={{fontSize:'10pt',fontWeight:'500',marginBottom:'11px',marginLeft:'10px',marginTop:'8px',float:'left'}}>Alumnos que fueron inscritos durante el período seleccionado</p>
			                        <div style={{float:'right',marginRight:'10px',marginTop:'5px'}}><input type="checkbox" id="mostrarInactivos" onChange={() => iniciarGenerarReporte()} /><label for="mostrarInactivos" style={{marginLeft:'5px',fontSize:'9pt'}}>Incluir inactivos</label></div>
			                        <div style={{borderRadius:'0px 0px 8px 8px',overflow:'hidden',width: '100%',margin:'auto'}}>
			                          <div id="tabla-actividades" style={{
			                              width: '100%',
			                              height: '300px', 
			                              overflowY: 'auto',
			                              overflowX: 'auto',
			                              backgroundColor: '#fff',
			                              margin: 'auto'
			                          }}>
			                            <RB.Table>
			                              <thead>
			                                 <tr style={{backgroundColor:'#fafafa'}}>
			                                 	  <th>Curso</th>
			                                    <th>Nombre</th>
			                                    <th>Apellidos</th>
			                                    <th>Fecha de inscripción</th>
			                                    <th>Fecha de baja</th>
			                                    <th>Fecha de nacimiento</th>
			                                    <th>Edad</th>
			                                 </tr>
			                              </thead>
			                              <tbody id="body-tabla-alumnos">
			                                <tr>
			                                  <td colSpan="7">
			                                    <center>
			                                      <p id="avisoSinAlumnos" style={{color: '#c8c8c8'}}>No hay resultados a mostrar</p>
			                                    </center>
			                                  </td>
			                                </tr>
			                              </tbody>
			                            </RB.Table>
			                          </div>
			                        </div>
			                    </div>
			                </div>
		                </div>

					</div>
				</div>
			</div>

		);
	}
}

export default ReporteAPA;