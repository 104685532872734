/*CONFIGUARCIÓN PARA USAR FIREBASE EN TODO EL PROYECTO*/

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

var firebaseConfig = {
	apiKey: "AIzaSyDOafd3wkkjh-8nt0kb98ieVx3kkgyyOYs",
    authDomain: "sistema-escuela-69a3d.firebaseapp.com",
    databaseURL: "https://sistema-escuela-69a3d.firebaseio.com",
    projectId: "sistema-escuela-69a3d",
    storageBucket: "sistema-escuela-69a3d.appspot.com",
    messagingSenderId: "845451210481",
    appId: "1:845451210481:web:5084beec118cff12fc0350",
    measurementId: "G-QQ7MTG7S9C"
};

const fb = firebase.initializeApp(firebaseConfig);

const fb_aux = firebase.initializeApp(firebaseConfig, "Secondary");

export const auth = fb.auth();
export const db = fb.firestore();

export const auth_aux = fb_aux.auth();