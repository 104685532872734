import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import {auth} from '../firebase-config';
import {auth_aux} from '../firebase-config';
import {db} from '../firebase-config';
import logo_header from '../assets/images/logo_header.png';
import {Link} from 'react-router-dom';
import * as RB from "react-bootstrap";
import swal from 'sweetalert2';
import firebase from 'firebase/app';
import 'firebase/auth'
import advertencia from '../assets/images/advertencia.png';
import moment from 'moment';
import key from '../assets/images/key.png';
import eliminar from '../assets/images/eliminar.png';
import lupa from '../assets/images/lupa.png';
import nuevo from '../assets/images/nuevo.png';
import asociar from '../assets/images/asociar.png';
import mas from '../assets/images/mas.png';
import info from '../assets/images/info.png';

class InfoTutor extends React.Component{
	//constructor del componente
	constructor(props){
		super(props)
		//Estado inicial del componente
		this.state = {
			uid: "",
			datos: []
		}
	}
	//Se ejecuta al cargar componente
	componentDidMount() {
		//Se verifican las props
		if(typeof this.props.match.params !== 'undefined') {
			//Se guarda el uid del usuarios, recibido por props
			const {uid} = this.props.match.params;
			this.setState({uid: uid});
			//----------------------------------------------------------------------------------
			//---------Se actualizan los valores iniciales con los datos del usuario------------
			//----------------------------------------------------------------------------------
			db.collection("tutores").doc(uid).get().then(doc => {
				const docs = [];
			    if (doc.exists) {
			        //Se guardan los datos -----------------------------
			        const data = doc.data();
		            docs.push({
		            	...data
		            });
			        //--------------------------------------------------
			    } else {
			    	//Sí documento no existe, se regresa a página anterior
			        document.getElementById("boton_RA").click();
			    }
			    this.setState({datos: docs});
			}).catch(error => {
			    //Error al leer documento
			    document.getElementById("boton_RA").click();
			});
			//----------------------------------------------------------------------------------
			//----------------------------------------------------------------------------------
			//----------------------------------------------------------------------------------
		} else {
			//Sí no se pueden leer las props se regresa a página anterior
			document.getElementById("boton_RA").click();
		}
	}
	consultaAlumnos = (collection,id) => {
		db.collection(collection).doc(id).get().then(doc => {
		    if (doc.exists) {
		        //--------------------------------------------------
		        if(document.getElementById("alumno_"+doc.data().id_usuario)==null) {
			        document.getElementById("tablaAlumnosAsociados").insertAdjacentHTML("beforeend",
			        "<tr class='filaTabla filaTabla-hover' id='alumno_"+doc.data().id_usuario+"'>"+
			        "<td>"+doc.data().nombre+"</td>"+
			        "<td>"+doc.data().apellidos+"</td>"+
			        "<td>"+doc.data().correoElectronico+"</td>"+
			        "<td>"+
					`<div class='toggle-botonTabla' title='Información' style='margin-right:15px;' onClick="window.open('/administrador/informacion/alumno/${id}','_blank')">`+
						"<img class='botonTabla' src='"+info+"' width='23px' />"+
					"</div>"+
			        "</td>"+
			        "</tr>");
		    	}
		        //--------------------------------------------------
		    }
		});
	}
	render(){
		//*********************************************************************************************************************
		const controlSeleccion = (e) => {
			var opciones = document.getElementById("menuLateral").childNodes;
			for (var i = 0; i < opciones.length; i++) {
				document.getElementById(opciones[i].id).classList.remove('cardMenuInfo-selected');
				document.getElementById(opciones[i].id).classList.add('cardMenuInfo');
				document.getElementById("seccion"+opciones[i].id.slice(5)).style.display = "none";
			}
			document.getElementById("boton"+e.target.id).classList.remove('cardMenuInfo');
			document.getElementById("boton"+e.target.id).classList.add('cardMenuInfo-selected');
			document.getElementById("seccion"+e.target.id).style.display = "flex";
		}
		//*********************************************************************************************************************
		return(
			<React.Fragment>

			<Link to="/administrador/tutores" id="boton_RA" style={{display:'none'}}></Link>

			<div id="menuLateral" className="global-container" style={{paddingBottom:'0px',paddingTop:'0px',borderBottom:'1px solid #dadce0',marginTop:'5px'}}>
				<div className="cardMenuInfo-selected" id="botonIG">
					<div><p id="IG" onClick={controlSeleccion}>Información general</p></div>
				</div>
				<div className="cardMenuInfo" id="botonDM">
					<div><p id="DM" onClick={controlSeleccion}>Datos fiscales</p></div>
				</div>
				<div className="cardMenuInfo" id="botonTR">
					<div><p id="TR" onClick={controlSeleccion}>Alumnos asociados</p></div>
				</div>
			</div>

			<div className="global-container" id="seccionIG">
				<div className="card login-form" style={{ width: '1000px', paddingTop:'0px' }}>
					<div className="card-body">
						<div className="row" style={{marginBottom:'0px'}}>
							<div className="col-sm-12 col-md-6 col-lg-6">
								<h4 className="card-title" style={{fontSize:'18pt',fontWeight:'500'}}>Información del tutor</h4>
							</div>
							<div className="col-sm-12 col-md-6 col-lg-6">
								<Link to={`/administrador/editar/tutor/${this.state.uid}`} id={`to_Editar_${this.state.uid}`} style={{display:'none'}}></Link>
								<RB.Dropdown drop="left">
									<RB.Dropdown.Toggle as="p" bsPrefix="none" className="toggle-botonTabla" title="Ver opciones">
										<center><img src={mas} width="17px" className="botonTabla"/></center>
									</RB.Dropdown.Toggle>
									<RB.Dropdown.Menu>
										<RB.Dropdown.Item style={{fontSize:'14px'}} className="itemDropdown" onClick={() => document.getElementById('to_Editar_'+this.state.uid).click()}>
											Editar información del tutor
										</RB.Dropdown.Item>
									</RB.Dropdown.Menu>
								</RB.Dropdown>
							</div>
						</div>
						<div className="card-text" style={{marginTop:'30px'}}>
							{this.state.datos.map(datos => {return(
								<React.Fragment>
								<div className="row borderTop borderBottom" style={{marginBottom:'30px'}}>
									<div className="col-sm-12 col-md-3 col-lg-3">
										<p className="textoNormal">Nombre completo</p>
									</div>
									<div className="col-sm-12 col-md-6 col-lg-6">
										<p className="textoBold">{datos.nombre} {datos.apellidos}</p>
									</div>
								</div>

								<div className="row">
									<div className="col-sm-12 col-md-8 col-lg-8">
										<p className="textoBoldSub">Información básica</p>
									</div>
								</div>

								<div className="row borderTop borderBottom" style={{marginBottom:'0px'}}>
									<div className="col-sm-12 col-md-3 col-lg-3">
										<p className="textoNormal">Teléfono</p>
									</div>
									<div className="col-sm-12 col-md-7 col-lg-7">
										<p className="textoBold">{datos.telefono}</p>
									</div>
								</div>

								<div className="row">
									<div className="col-sm-12 col-md-7 col-lg-7">
										<p className="textoBoldSub">Información de cuenta</p>
									</div>
								</div>

								<div className="row borderTop borderBottom" style={{marginBottom:'0px'}}>
									<div className="col-sm-12 col-md-3 col-lg-3">
										<p className="textoNormal">Dirección de correo electrónico</p>
									</div>
									<div className="col-sm-12 col-md-7 col-lg-7">
										<p className="textoBold">{datos.correoElectronico}</p>
									</div>
								</div>

								<div className="row borderBottom" style={{marginBottom:'0px'}}>
									<div className="col-sm-12 col-md-3 col-lg-3">
										<p className="textoNormal">ID de la cuenta</p>
									</div>
									<div className="col-sm-12 col-md-7 col-lg-7">
										<p className="textoNormal" style={{color:'#BCBDBD'}}>{datos.id_usuario}</p>
									</div>
								</div>
								</React.Fragment>
							);})}
						</div>
					</div>
				</div>
			</div>

			<div className="global-container" id="seccionDM" style={{display:'none'}}>
				<div className="card login-form" style={{ width: '1000px', paddingTop:'0px' }}>
					<div className="card-body">
						<div className="card-text" style={{marginTop:'0px'}}>
							{this.state.datos.map(datos => {if(datos.datosFiscalesReg){return(
								<React.Fragment>
								<div className="row" style={{marginBottom:'15px'}}>
									<div className="col-sm-12 col-md-6 col-lg-6">
										<h4 className="card-title" style={{fontSize:'16pt',fontWeight:'500'}}>Datos fiscales</h4>
									</div>
								</div>

								<div className="row borderTop borderBottom" style={{marginBottom:'0px'}}>
									<div className="col-sm-12 col-md-3 col-lg-3">
										<p className="textoNormal">RFC</p>
									</div>
									<div className="col-sm-12 col-md-7 col-lg-7">
										<p className="textoBold">{datos.datosFiscales.RFC}</p>
									</div>
								</div>

								<div className="row borderBottom" style={{marginBottom:'0px'}}>
									<div className="col-sm-12 col-md-3 col-lg-3">
										<p className="textoNormal">Razón social</p>
									</div>
									<div className="col-sm-12 col-md-7 col-lg-7">
										<p className="textoBold">{datos.datosFiscales.razonSocial}</p>
									</div>
								</div>

								<div className="row borderBottom" style={{marginBottom:'0px'}}>
									<div className="col-sm-12 col-md-3 col-lg-3">
										<p className="textoNormal">Correo electrónico</p>
									</div>
									<div className="col-sm-12 col-md-7 col-lg-7">
										<p className="textoBold">{datos.datosFiscales.correoElectronicoDF}</p>
									</div>
								</div>

								<div className="row" style={{marginTop:'25px'}}>
									<div className="col-sm-12 col-md-8 col-lg-8">
										<p className="textoBoldSub">Dirección</p>
									</div>
								</div>

								<div className="row borderBottom borderTop" style={{marginBottom:'0px'}}>
									<div className="col-sm-12 col-md-3 col-lg-3">
										<p className="textoNormal">Estado</p>
									</div>
									<div className="col-sm-12 col-md-7 col-lg-7">
										<p className="textoBold">{datos.datosFiscales.direccion.estado}</p>
									</div>
								</div>

								<div className="row borderBottom" style={{marginBottom:'0px'}}>
									<div className="col-sm-12 col-md-3 col-lg-3">
										<p className="textoNormal">Municipio</p>
									</div>
									<div className="col-sm-12 col-md-7 col-lg-7">
										<p className="textoBold">{datos.datosFiscales.direccion.municipio}</p>
									</div>
								</div>

								<div className="row borderBottom" style={{marginBottom:'0px'}}>
									<div className="col-sm-12 col-md-3 col-lg-3">
										<p className="textoNormal">Código postal</p>
									</div>
									<div className="col-sm-12 col-md-7 col-lg-7">
										<p className="textoBold">{datos.datosFiscales.direccion.codigoPostal}</p>
									</div>
								</div>

								<div className="row borderBottom" style={{marginBottom:'0px'}}>
									<div className="col-sm-12 col-md-3 col-lg-3">
										<p className="textoNormal">Colonia</p>
									</div>
									<div className="col-sm-12 col-md-7 col-lg-7">
										<p className="textoBold">{datos.datosFiscales.direccion.colonia}</p>
									</div>
								</div>

								<div className="row borderBottom" style={{marginBottom:'0px'}}>
									<div className="col-sm-12 col-md-3 col-lg-3">
										<p className="textoNormal">Calle</p>
									</div>
									<div className="col-sm-12 col-md-7 col-lg-7">
										<p className="textoBold">{datos.datosFiscales.direccion.calle}</p>
									</div>
								</div>

								<div className="row borderBottom" style={{marginBottom:'0px'}}>
									<div className="col-sm-12 col-md-3 col-lg-3">
										<p className="textoNormal">Número exterior</p>
									</div>
									<div className="col-sm-12 col-md-7 col-lg-7">
										<p className="textoBold">{datos.datosFiscales.direccion.numeroExterior}</p>
									</div>
								</div>

								<div className="row borderBottom" style={{marginBottom:'0px'}}>
									<div className="col-sm-12 col-md-3 col-lg-3">
										<p className="textoNormal">Número interior</p>
									</div>
									<div className="col-sm-12 col-md-7 col-lg-7">
										<p className="textoBold">{datos.datosFiscales.direccion.numeroInterior}</p>
									</div>
								</div>
								</React.Fragment>
							);}else{return(
								<React.Fragment>
									<center>
										<p style={{marginTop:'40px',marginBottom:'30px',fontSize:'14px',color:'#5f6368'}}>
											Tutor sin registro de datos fiscales
										</p>
										<RB.Button id="botonMostrarMas" className="boton-presionado" variant="outline-info" type="button" style={{fontSize: '9pt',marginBottom:'70px'}} onClick={() => document.getElementById('to_Editar_'+this.state.uid).click()}>
											Actualizar datos del tutor
										</RB.Button>
									</center>
								</React.Fragment>
							);}})}
						</div>
					</div>
				</div>
			</div>

			<div className="global-container" id="seccionTR" style={{display:'none'}}>
				<div className="card login-form" style={{width:'1000px',paddingTop:'0px',padding:'0px',paddingTop:'15px',overflow:'hidden'}}>
					<div className="card-body" style={{padding:'0px',overflow:'hidden'}}>
						<div className="card-text" style={{margin:'0px',backgroundColor:'#fafafa',overflow:'hidden'}}>
							{this.state.datos.map(datos => {return(
								<React.Fragment>
								<div className="row" style={{marginBottom:'0px',padding:'20px',paddingBottom:'0px'}}>
									<div className="col-sm-12 col-md-6 col-lg-6">
										<h4 className="card-title" style={{fontSize:'16pt',fontWeight:'500'}}>Alumnos asociados</h4>
									</div>
								</div>

								<div style={{marginTop:'10px',overflow:'hidden'}}>
									<div id="tabla" style={{
										width: '100%',
									    height: '350px', 
									    overflowY: 'auto',
									    overflowX: 'auto',
									    backgroundColor: '#fff'
									}}>
										<RB.Table>
											<thead>
											   <tr style={{backgroundColor:'#fafafa'}}>
											      <th>Nombre</th>
											      <th>Apellidos</th>
											      <th>Correo</th>
											      <th width="50px"></th>
											   </tr>
											</thead>
											{datos.alumnos.map(a => {this.consultaAlumnos(a.parent.id,a.id);})}
											<tbody id="tablaAlumnosAsociados">
											</tbody>
										</RB.Table>
									</div>
								</div>
								</React.Fragment>
							);})}
						</div>
					</div>
				</div>
			</div>

			</React.Fragment>
		);
	}
}

export default InfoTutor;