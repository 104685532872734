import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import {auth} from '../firebase-config';
import {auth_aux} from '../firebase-config';
import {db} from '../firebase-config';
import logo_header from '../assets/images/logo_header.png';
import {Link} from 'react-router-dom';
import * as RB from "react-bootstrap";
import swal from 'sweetalert2';
import firebase from 'firebase/app';
import 'firebase/auth'
import advertencia from '../assets/images/advertencia.png';

class EditarAdministrador extends React.Component{
	//constructor del componente
	constructor(props){
		super(props)
		//Estado inicial del componente
		this.state = {
			nombre: "",
			apellidos: "",
			telefono: "",
			correo: ""
		}
	}
	//Datos iniciales del usuario
	datosIniciales = {
		nombre: "",
		apellidos: "",
		telefono: "",
		correo: "",
		uid: ""
	}
	//Auxiliares para conocer si se ha cambia el estado inicial de los datos
	registroCambios = {
		cambio_nombre: false,
		cambio_apellidos: false,
		cambio_telefono: false,
		cambio_correo: false
	}
	//Se ejecuta al cargar componente
	componentDidMount() {
		//Se verifican las props
		if(typeof this.props.match.params !== 'undefined') {
			//Se guarda el uid del usuarios, recibido por props
			const {uid} = this.props.match.params;
			this.datosIniciales.uid = uid;
			//----------------------------------------------------------------------------------
			//---------Se actualizan los valores iniciales con los datos del usuario------------
			//----------------------------------------------------------------------------------
			db.collection("administradores").doc(uid).get().then(doc => {
			    if (doc.exists) {
			        //Se guardan los datos iniciales--------------------
			        this.setState({nombre: doc.data().nombre});
			        this.setState({apellidos: doc.data().apellidos});
			        this.setState({telefono: doc.data().telefono});
			        this.setState({correo: doc.data().correoElectronico});
			        this.datosIniciales.nombre = doc.data().nombre;
			        this.datosIniciales.apellidos = doc.data().apellidos;
			        this.datosIniciales.telefono = doc.data().telefono;
			        this.datosIniciales.correo = doc.data().correoElectronico;
			        //--------------------------------------------------
			    } else {
			    	//Sí documento no existe, se regresa a página anterior
			        document.getElementById("boton_RA").click();
			    }
			}).catch(error => {
			    //Error al leer documento
			});
			//----------------------------------------------------------------------------------
			//----------------------------------------------------------------------------------
			//----------------------------------------------------------------------------------
		} else {
			//Sí no se pueden leer las props se regresa a página anterior
			document.getElementById("boton_RA").click();
		}
	}
	//--------------------------------------------------------------------------------------------------------
	//----------------------Actualiza la contraseña, llamando a cloud function--------------------------------
	//--------------------------------------------------------------------------------------------------------
	actualizarContraseña = () => {
		swal.fire({
		  title:"<p style='font-size: 15pt;font-weight:600;color: #282828;'>Restablecer contraseña</p>",
		  html: "<p style='font-size: 10pt;color: #0000008A;'>Cuenta de usuario</p>"+
		  		"<p style='font-size: 11pt;color: #646464;'>"+this.datosIniciales.correo+"</p>"+
		  		"<div class='col-sm-12' style='margin-top:30px;'>"+
				"<div class='form-group input-field' style='margin:auto;'>"+
				"<input class='input_correcto input' type='password' id='contraseñaRA' name='contraseña' required />"+
				"<label class='label' for='contraseñaRA'>Contraseña nueva</label>"+
				"<p id='error-contraseñaRA'></p>"+
				"</div>"+
				"</div>"+
				"<div class='col-sm-12'>"+
				"<div class='form-group input-field' style='margin:auto;'>"+
				"<input class='input_correcto input' type='password' id='confirmacionContraseñaRA' name='confirmacionContraseña' required />"+
				"<label class='label' for='confirmacionContraseñaRA'>Confirmar contraseña</label>"+
				"<p id='error-confirmacionContraseñaRA'></p>"+
				"</div>"+
				"</div>",
		  footer: "<img width='20px' height='20px' style='margin-right:5px' src='"+advertencia+
		  		"'/><b style='font-size: 14;color: #D45454;'>Esta acción no se puede deshacer.</b>",
		  showCancelButton: true,
		  confirmButtonColor: "#17a2b8",
		  confirmButtonText: "<div style='font-size: 10pt;font-weight: 500;'>Actualizar</div>",
		  cancelButtonText: "<div style='font-size: 10pt;font-weight: 600;'>Cancelar</div>",
		  customClass: {
		    cancelButton: 'cancelButton',
		    confirmButton: 'confirmButton',
		    actions: 'alert-buttons',
		    title: 'titulo-alert-update',
		    content: 'container-alert'
		  },
		  didOpen: () => {
		  	swal.getConfirmButton().disabled = true;//Se deshabilita el botón "Actualizar"
		  	//Se selecciona el primer campo de la contraseña, al abrir el alert
		    document.getElementById("contraseñaRA").focus();
		    //Se añaden funciones onChange a los dos inputs de contraseña----------------------------------
		    document.getElementById("contraseñaRA").addEventListener('keyup', (e) => {
		    	//----------------------------------------------------------------------------
		    	//Validación de la primer contraseña
		    	if(/^.{6,40}$/.test(e.target.value)){
					document.getElementById("contraseñaRA").classList.remove('input_incorrecto');
					document.getElementById("contraseñaRA").classList.add('input_correcto');
					document.getElementById("error-contraseñaRA").innerHTML = '';
				} else {
					document.getElementById("contraseñaRA").classList.remove('input_correcto');
					document.getElementById("contraseñaRA").classList.add('input_incorrecto');
					document.getElementById("error-contraseñaRA").innerHTML = "La contraseña debe contener al menos 6 caracteres";
				}
				//Validación de la confirmación de la contraseña
				if(document.getElementById("contraseñaRA").value!==document.getElementById("confirmacionContraseñaRA").value){
					document.getElementById("confirmacionContraseñaRA").classList.remove('input_correcto');
					document.getElementById("confirmacionContraseñaRA").classList.add('input_incorrecto');
					document.getElementById("error-confirmacionContraseñaRA").innerHTML = 'Ambas contraseñas deben coincidir';
				} else {
					document.getElementById("confirmacionContraseñaRA").classList.remove('input_incorrecto');
					document.getElementById("confirmacionContraseñaRA").classList.add('input_correcto');
					document.getElementById("error-confirmacionContraseñaRA").innerHTML = '';
				}
				//Condición para continuar
				if(!(/^.{6,40}$/.test(document.getElementById("contraseñaRA").value)) || document.getElementById("contraseñaRA").value!==document.getElementById("confirmacionContraseñaRA").value){
					//Error en la contraseñas
					swal.getConfirmButton().disabled = true;//Se deshabilita el botón "Actualizar"
				} else {
					//Contraseñas correctas
					swal.getConfirmButton().disabled = false;//Se habilita el botón "Actualizar"
				}
		    	//----------------------------------------------------------------------------
		    });
		    document.getElementById("confirmacionContraseñaRA").addEventListener('keyup', (e) => {
		    	//----------------------------------------------------------------------------
		    	//Validación de la confirmación de la contraseña
		    	if(document.getElementById("contraseñaRA").value!==document.getElementById("confirmacionContraseñaRA").value){
					document.getElementById("confirmacionContraseñaRA").classList.remove('input_correcto');
					document.getElementById("confirmacionContraseñaRA").classList.add('input_incorrecto');
					document.getElementById("error-confirmacionContraseñaRA").innerHTML = 'Ambas contraseñas deben coincidir';
					swal.getConfirmButton().disabled = true;//Se deshabilita el botón "Actualizar"
				} else {
					document.getElementById("confirmacionContraseñaRA").classList.remove('input_incorrecto');
					document.getElementById("confirmacionContraseñaRA").classList.add('input_correcto');
					document.getElementById("error-confirmacionContraseñaRA").innerHTML = '';
					swal.getConfirmButton().disabled = false;//Se habilita el botón "Actualizar"
				}
				//Condición para continuar
				if(!(/^.{6,40}$/.test(document.getElementById("contraseñaRA").value)) || document.getElementById("contraseñaRA").value!==document.getElementById("confirmacionContraseñaRA").value){
					//Error en la contraseñas
					swal.getConfirmButton().disabled = true;//Se deshabilita el botón "Actualizar"
				} else {
					//Contraseñas correctas
					swal.getConfirmButton().disabled = false;//Se habilita el botón "Actualizar"
				}
				//----------------------------------------------------------------------------
		    });
		    //---------------------------------------------------------------------------------------------
		  }
		}).then((result) => {
		  if (result.isConfirmed) {
		  	//*******************************************************************************************************************************
		  	//************************Administrador confirmó la actualización, se procede a cambiar la contraseña****************************
		  	//*******************************************************************************************************************************
		  	var password_temp = document.getElementById("contraseñaRA").value;//Se guarda la contraseña para no perderla al cerrar alert
		  	//Alert "loading"----------------------
			const alert_cargando = swal.fire({
			  width: '300px',
			  allowOutsideClick: false,
			  showConfirmButton: false,
			  didOpen: () => {
			    swal.showLoading()
			  },
			  customClass: {
				   title: 'titulo-alert-loading',
			     actions: 'alert-loader'
				}
			})
			//-------------------------------------
		  	//Se actualiza la contraseña de la cuenta del usuario seleccionado (llamado a cloud function)********
		  	fetch("https://us-central1-sistema-escuela-69a3d.cloudfunctions.net/actualizarPassword?uid="+this.datosIniciales.uid+"&pass="+password_temp)
		  	.then(response => response.json())
		  	.then(data => {
		  		//Sí se completó correctamente la actualización de la contraseña (auth)
		  		if(data.completado) {
		  			//-------------------------------------------------------------------------------------------------------
		  			alert_cargando.close();
		  			//Mensaje de éxito
					swal.fire({
					  title:"<p style='font-size:13pt;font-weight:600;color: #282828;'>Cambio realizado</p>",
					  icon: 'success',
					  iconColor: '#17a2b8',
					  width: '300px',
					  showConfirmButton: false,
					  timer: 2500
					})
		  			//-------------------------------------------------------------------------------------------------------
		  		} else {
		  			//Sí no se actualizó correctamente la contraseña (auth)
		  			alert_cargando.close();
		  			//Mensaje de error
		  			swal.fire({
				      title:"<p style='font-size:13pt;font-weight:600;color: #282828;'>Error al actualizar contraseña</p>",
				      icon: 'error',
				      width: '400px',
				      showConfirmButton: false,
				      timer: 2500
				    })
		  		}
		  	})
		  	.catch(error => {
		    	//Error al ejecutar función (cloud function)
		    	alert_cargando.close();
		    	//Mensaje de error
		    	swal.fire({
				  title:"<p style='font-size:13pt;font-weight:600;color: #282828;'>Error al actualizar contraseña</p>",
				  icon: 'error',
				  width: '400px',
				  showConfirmButton: false,
				  timer: 2500
				})
		    });
		  	//***************************************************************************************************
		  	//*******************************************************************************************************************************
		  	//*******************************************************************************************************************************
		  	//*******************************************************************************************************************************
		  }
		});
	}
	//--------------------------------------------------------------------------------------------------------
	//--------------------------------------------------------------------------------------------------------
	//--------------------------------------------------------------------------------------------------------
	render(){
		//-------------------------------------------------------------
		//-------------------------------------------------------------------------------------------------------------------------
		//--------------------------------------------------INICIAR EDICIÓN--------------------------------------------------------
		//-------------------------------------------------------------------------------------------------------------------------
		const handleSubmit = e =>{
			e.preventDefault();//Evita la recarga de la página

			//***************************************************************************************
			//************************** VALIDACIÓN DE CAMPOS ***************************************
			//***************************************************************************************
			document.getElementById("alerta-RA").innerHTML = '';//Borra alertas pasadas
			var formulario_incorrecto = false;
			const aux_inputs = e.target.querySelectorAll('input');
			aux_inputs.forEach((input) => {
				switch(input.name){
					case "nombre":
						if(!expresiones.nombre.test(input.value)){
							//Error en el nombre
							formulario_incorrecto = true;
						}
					break;
					case "apellidos":
						if(!expresiones.apellidos.test(input.value)){
							//Error en los apellidos
							formulario_incorrecto = true;
						}
					break;
					case "telefono":
						if(!expresiones.telefono.test(input.value)){
							//Error en el teléfono
							formulario_incorrecto = true;
						}
					break;
					case "correo":
						if(!expresiones.correo.test(input.value)){
							//Error en el correo
							formulario_incorrecto = true;
						}
					break;
				}
				if(formulario_incorrecto){
					document.getElementById("alerta-RA").innerHTML = '<p>Complete los campos correctamente</p>';
				}
			});
			//***************************************************************************************
			//***************************************************************************************
			//***************************************************************************************
			//-------------------------------------------------------------------------------------------------------------------------------------
			//-----------------------------------Sí hubo cambios en los campos y se aceptó la edición----------------------------------------------
			//-------------------------------------------------------------------------------------------------------------------------------------
			if(!formulario_incorrecto){
				//Sí el correo es un campo a actualizar
				if(this.registroCambios.cambio_correo){
					//Alert "loading"----------------------
					const alert_cargando = swal.fire({
					  width: '300px',
					  allowOutsideClick: false,
					  showConfirmButton: false,
					  didOpen: () => {
					    swal.showLoading()
					  },
					  customClass: {
						   title: 'titulo-alert-loading',
					     actions: 'alert-loader'
						}
					})
					//-------------------------------------
					//------------------------------------------------------------------------------------------------------------
					//Se actualiza el correo de la cuenta mediante cloud function (admin)-----------------------------------------
					//------------------------------------------------------------------------------------------------------------
				  	fetch("https://us-central1-sistema-escuela-69a3d.cloudfunctions.net/actualizarCorreo?uid="+this.datosIniciales.uid+"&email="+document.getElementById("correoRA").value)
				  	.then(response => response.json())
				  	.then(data => {
				  		//Sí se completó correctamente la actualización del correo (auth)
				  		if(data.completado) {
				  			//--------------------------------------------------------------------------------------------------------------------------
							//Sí hay más campos a actualizar que solo el correo
							if(this.registroCambios.cambio_nombre||this.registroCambios.cambio_apellidos||this.registroCambios.cambio_telefono){
								//**************************************************************************************************
								//************************Se actualizan los datos en la DB firestore********************************
								//**************************************************************************************************
								db.collection("administradores").doc(this.datosIniciales.uid).update({
								    nombre: document.getElementById("nombreRA").value.trim().toUpperCase(),
								    apellidos: document.getElementById("apellidosRA").value.trim().toUpperCase(),
								    telefono: document.getElementById("telefonoRA").value,
								    correoElectronico: document.getElementById("correoRA").value
								}).then(function() {
								    //Los datos se actualizaron correctamente en firestore
								    alert_cargando.close();
								    //Mensaje de éxito
									swal.fire({
									  title:"<p style='font-size:13pt;font-weight:600;color: #282828;'>Cambios guardados</p>",
									  icon: 'success',
									  iconColor: '#17a2b8',
									  width: '300px',
									  showConfirmButton: false,
									  timer: 2000
									}).then((result) => {
									  	//Se regresa a inicio
								    	document.getElementById("boton_RA").click();
									});
								}).catch(function(error) {
								    //Error al actualizar datos en firestore
								    alert_cargando.close();
								    //Mensaje de error
								    swal.fire({
									  title:"<p style='font-size:13pt;font-weight:600;color: #282828;'>Error al actualizar datos</p>",
									  icon: 'error',
									  width: '400px',
									  showConfirmButton: false,
									  timer: 2000
									})
								});
								//**************************************************************************************************
								//**************************************************************************************************
								//**************************************************************************************************
							} else {
								//--------------------------------SÍ SOLO SE ACTUALIZÓ EL CORREO------------------------------------
								//**************************************************************************************************
								//************************Se actualizan los datos en la DB firestore********************************
								//**************************************************************************************************
								db.collection("administradores").doc(this.datosIniciales.uid).update({
								    correoElectronico: document.getElementById("correoRA").value
								}).then(function() {
								    //Los datos se actualizaron correctamente en firestore
								    alert_cargando.close();
								    //Mensaje de éxito
									swal.fire({
									  title:"<p style='font-size:13pt;font-weight:600;color: #282828;'>Cambios guardados</p>",
									  icon: 'success',
									  iconColor: '#17a2b8',
									  width: '300px',
									  showConfirmButton: false,
									  timer: 2000
									}).then((result) => {
									  	//Se regresa a inicio
								    	document.getElementById("boton_RA").click();
									});
								}).catch(function(error) {
								    //Error al actualizar datos en firestore
								    alert_cargando.close();
								    //Mensaje de error
								    swal.fire({
									  title:"<p style='font-size:13pt;font-weight:600;color: #282828;'>Error al actualizar datos</p>",
									  icon: 'error',
									  width: '400px',
									  showConfirmButton: false,
									  timer: 2000
									})
								});
								//**************************************************************************************************
								//**************************************************************************************************
								//**************************************************************************************************
							}
							//--------------------------------------------------------------------------------------------------------------------------
				  		} else {
				  			//Sí no se actualizó correctamente el correo (auth)
				  			alert_cargando.close();
				  			//Mensaje de error
				  			swal.fire({
						      title:"<p style='font-size:13pt;font-weight:600;color: #282828;'>Error al actualizar datos</p>",
						      icon: 'error',
						      width: '400px',
						      showConfirmButton: false,
						      timer: 2000
						    })
				  		}
				  	})
				  	.catch(error => {
				    	//Error al ejecutar función (cloud function)
				    	alert_cargando.close();
				    	//Mensaje de error
				    	swal.fire({
						  title:"<p style='font-size:13pt;font-weight:600;color: #282828;'>Error al actualizar datos</p>",
						  icon: 'error',
						  width: '400px',
						  showConfirmButton: false,
						  timer: 2000
						})
				    });
					//------------------------------------------------------------------------------------------------------------
					//------------------------------------------------------------------------------------------------------------
					//------------------------------------------------------------------------------------------------------------
				} else {//Sí el correo no es un campo a actualizar
					//Alert "loading"----------------------
					const alert_cargando = swal.fire({
					  width: '300px',
					  allowOutsideClick: false,
					  showConfirmButton: false,
					  didOpen: () => {
					    swal.showLoading()
					  },
					  customClass: {
						   title: 'titulo-alert-loading',
					     actions: 'alert-loader'
						}
					})
					//-------------------------------------
					//**************************************************************************************************
					//************************Se actualizan los datos en la DB firestore********************************
					//**************************************************************************************************
					db.collection("administradores").doc(this.datosIniciales.uid).update({
					    nombre: document.getElementById("nombreRA").value.trim().toUpperCase(),
					    apellidos: document.getElementById("apellidosRA").value.trim().toUpperCase(),
					    telefono: document.getElementById("telefonoRA").value
					}).then(function() {
					    //Los datos se actualizaron correctamente en firestore
					    alert_cargando.close();
					    //Mensaje de éxito
						swal.fire({
						  title:"<p style='font-size:13pt;font-weight:600;color: #282828;'>Cambios guardados</p>",
						  icon: 'success',
						  iconColor: '#17a2b8',
						  width: '300px',
						  showConfirmButton: false,
						  timer: 2000
						}).then((result) => {
						  	//Se regresa a inicio
					    	document.getElementById("boton_RA").click();
						});
					}).catch(function(error) {
					    //Error al actualizar datos en firestore
					    alert_cargando.close();
					    //Mensaje de error
					    swal.fire({
						  title:"<p style='font-size:13pt;font-weight:600;color: #282828;'>Error al actualizar datos</p>",
						  icon: 'error',
						  width: '400px',
						  showConfirmButton: false,
						  timer: 2000
						})
					});
					//**************************************************************************************************
					//**************************************************************************************************
					//**************************************************************************************************
				}
			}
			//-------------------------------------------------------------------------------------------------------------------------------------
			//-------------------------------------------------------------------------------------------------------------------------------------
			//-------------------------------------------------------------------------------------------------------------------------------------
		}
		//-------------------------------------------------------------------------------------------------------------------------
		//-------------------------------------------------------------------------------------------------------------------------
		//-------------------------------------------------------------------------------------------------------------------------
		//*************************************************************************************************************************************
		//***************************************************VALIDACIÓN DE LOS DATOS***********************************************************
		//*************************************************************************************************************************************
		const expresiones = {
			nombre: /^[a-zA-ZÀ-ÿ\s]{1,40}$/, // Letras y espacios, pueden llevar acentos
			apellidos: /^[a-zA-ZÀ-ÿ\s]{1,40}$/, // Letras y espacios, pueden llevar acentos
			correo: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
			telefono: /^\d{10,13}$/, // 10 numeros
			contraseña: /^.{6,40}$/ //cualquier caracter
		}
		
		const validarFormulario = (e) => {
			switch(e.target.name){
				case "nombre":
					this.setState({nombre: e.target.value});
					validarCampo(expresiones.nombre, e.target, e.target.name, "Campo nombre solo acepta letras y espacios");
					if(e.target.value !== this.datosIniciales.nombre){
						this.registroCambios.cambio_nombre = true;
					} else {
						this.registroCambios.cambio_nombre = false;
					}
				break;
				case "apellidos":
					this.setState({apellidos: e.target.value});
					validarCampo(expresiones.apellidos, e.target, e.target.name, "Campo apellidos solo acepta letras y espacios");
					if(e.target.value !== this.datosIniciales.apellidos){
						this.registroCambios.cambio_apellidos = true;
					} else {
						this.registroCambios.cambio_apellidos = false;
					}
				break;
				case "telefono":
					this.setState({telefono: e.target.value});
					validarCampo(expresiones.telefono, e.target, e.target.name, "Ingrese número telefónico válido");
					if(e.target.value !== this.datosIniciales.telefono){
						this.registroCambios.cambio_telefono = true;
					} else {
						this.registroCambios.cambio_telefono = false;
					}
				break;
				case "correo":
					this.setState({correo: e.target.value});
					validarCampo(expresiones.correo, e.target, e.target.name, "Ingrese un correo válido");
					if(e.target.value !== this.datosIniciales.correo){
						this.registroCambios.cambio_correo = true;
					} else {
						this.registroCambios.cambio_correo = false;
					}
				break;
			}
			//Sí los datos cambiaron, en relación con los originales; se activa el botón "actualizar datos"
			if(this.registroCambios.cambio_nombre || this.registroCambios.cambio_apellidos || this.registroCambios.cambio_telefono || this.registroCambios.cambio_correo){
				document.getElementById("boton1_RA").disabled = false;
			}
			//Sí los datos no han cambiado, se desactiva el botón "actualizar datos"
			if(!this.registroCambios.cambio_nombre && !this.registroCambios.cambio_apellidos && !this.registroCambios.cambio_telefono && !this.registroCambios.cambio_correo){
				document.getElementById("boton1_RA").disabled = true;
			}
		}
		const validarCampo = (expresion, input, campo, mensaje_error) => {
			if(expresion.test(input.value)){
				document.getElementById(`${campo}RA`).classList.remove('input_incorrecto');
				document.getElementById(`${campo}RA`).classList.add('input_correcto');
				document.getElementById(`error-${campo}RA`).innerHTML = '';
			} else {
				document.getElementById(`${campo}RA`).classList.remove('input_correcto');
				document.getElementById(`${campo}RA`).classList.add('input_incorrecto');
				document.getElementById(`error-${campo}RA`).innerHTML = mensaje_error;
			}
		}
		//Solo permite ingresar numeros
		const validarNumerico = (e) => {
     		if(isNaN(e.target.value+String.fromCharCode(e.charCode))){
        		e.preventDefault();
     		}
  		}
		//*************************************************************************************************************************************
		//*************************************************************************************************************************************
		//*************************************************************************************************************************************
		return(
			<React.Fragment>

			<div className="global-container">
				<div className="card login-form" id="cardFRA" style={{ width: '800px', paddingTop:'10px' }}>
					<div className="card-body" id="tarjeta">
						<h4 className="card-title" style={{fontSize:'18pt',fontWeight:'500'}}>Editar cuenta de administrador</h4>
						<div className="card-text" style={{marginTop:'40px'}}>
							<form autoComplete="off" onSubmit={handleSubmit} style={{margin:'0px',overflow:'hidden'}}>

								<div className="row" style={{marginBottom:'30px'}}>
									<div className="col-sm-12 col-md-6 col-lg-6">
										<div className="form-group input-field" style={{margin: 'auto'}}>
											<input className="input_correcto input" type="text" id="nombreRA" name="nombre" onChange={validarFormulario} value={this.state.nombre} required />
											<label className="label" for="nombreRA">Nombre</label>
											<p id="error-nombreRA"></p>
										</div>
									</div>
									
									<div className="col-sm-12 col-md-6 col-lg-6">
										<div className="form-group input-field" style={{margin: 'auto'}}>
											<input className="input_correcto input" type="text" id="apellidosRA" name="apellidos" onChange={validarFormulario} value={this.state.apellidos} required />
											<label className="label" for="apellidosRA">Apellidos</label>
											<p id="error-apellidosRA"></p>
										</div>
									</div>

									<div className="col-sm-12 col-md-6 col-lg-6">
										<div className="form-group input-field" style={{margin: 'auto'}}>
											<input className="input_correcto input" type="text" id="telefonoRA" name="telefono" onChange={validarFormulario} onKeyPress={validarNumerico} value={this.state.telefono} required />
											<label className="label" for="telefonoRA">Teléfono</label>
											<p id="error-telefonoRA"></p>
										</div>
									</div>

									<div className="col-sm-12 col-md-6 col-lg-6">
										<div className="form-group input-field" style={{margin: 'auto'}}>
											<input className="input_correcto input" type="text" id="correoRA" name="correo" onChange={validarFormulario} value={this.state.correo} required />
											<label className="label" for="correoRA">Correo electrónico</label>
											<p id="error-correoRA"></p>
										</div>
									</div>
								</div>

								<div id="alerta-RA" style={{fontSize:'11pt',color:'#D45454',marginBottom:"10px",marginTop:"10px"}}></div>

								<p style={{cursor:'pointer',color:'#17a2b8',fontSize:'14px',float:'left'}} onClick={() => this.actualizarContraseña()}>
									Restablecer contraseña
								</p>

								<RB.Button disabled variant="info" id="boton1_RA" type="submit" style={{float:'right',marginRight:'10px',width:'120px',fontSize:'10pt'}} className="button-disabled boton-presionado">Actualizar datos</RB.Button>

								<Link to="/administrador/administradores" id="boton_RA" style={{float:'right',marginRight:'35px',lineHeight:'2.4',color:'#17a2b8',fontSize:'10pt',textDecoration:'none'}}>Cancelar</Link>
							</form>
						</div>
					</div>
				</div>
			</div>

			</React.Fragment>
		);
	}
}

export default EditarAdministrador;