import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import {auth} from '../firebase-config';
import {auth_aux} from '../firebase-config';
import {db} from '../firebase-config';
import logo_header from '../assets/images/logo_header.png';
import {Link} from 'react-router-dom';
import * as RB from "react-bootstrap";
import swal from 'sweetalert2';
import firebase from 'firebase/app';
import 'firebase/auth'
import advertencia from '../assets/images/advertencia.png';
import print from '../assets/images/print.png';
import moment from 'moment';
import key from '../assets/images/key.png';
import eliminar from '../assets/images/eliminar.png';
import lupa from '../assets/images/lupa.png';
import nuevo from '../assets/images/nuevo.png';
import asociar from '../assets/images/asociar.png';
import mas from '../assets/images/mas.png';
import jsPDF from 'jspdf';
import 'jspdf-autotable'

class ReporteHPI extends React.Component{
	//Se ejecuta al cargar componente
	componentDidMount() {
		//-----------------------------------------------------------------------------------------------------------------
		//
		//-----------------------------------------------------------------------------------------------------------------
	}
	//----------------------------------------------------------------------------------------------------------------------------------
	//----------------------------------------------------------------------------------------------------------------------------------
	//----------------------------------------------------------------------------------------------------------------------------------
	//
	//-----------------------------------------------------------------------------------------------------------------------------------
	//-----------------------------------------------------------------------------------------------------------------------------------
	//-----------------------------------------------------------------------------------------------------------------------------------
	render(){
		//**************Variables para impresión********************
		var impresionDisponible = false;
		var pdf;
		//**********************************************************
		//****************************************************************************************************************
		//****************************************************************************************************************
		//****************************************************************************************************************
		//BÚSQUEDA DE INSTRUCTOR (CONSULTA FIRESTORE)**********************************************************
	    const buscarInstructor = (e) => {
	      e.preventDefault();//Evita la recarga de la página
	      var textoBusqueda = document.getElementById("busqueda-instructor").value;
	      if(textoBusqueda !== ""){
	        //------------------------------------------------------------------------------------------------------------
	        //------------------------------------------------------------------------------------------------------------
	        //------------------------------------------------------------------------------------------------------------
	        db.collection("instructores").where("correoElectronico", "==", textoBusqueda.trim()).get().then(function(querySnapshot) {
	            var contador = 0;
	            document.getElementById("body-tabla-instructores-busqueda").innerHTML = "";
	            querySnapshot.forEach(function(doc) {
	                contador++;
	                //*****************************************************************************************
	                document.getElementById("body-tabla-instructores-busqueda").insertAdjacentHTML("beforeend", 
	                "<tr class='filaTabla filaTabla-hover'>"+
	                  "<td>"+doc.data().nombre+"</td>"+
	                  "<td>"+doc.data().apellidos+"</td>"+
	                  "<td>"+
	                    "<div class='toggle-botonTabla' id='"+doc.id+"busqueda-VI"+"' title='Seleccionar' style='margin-right:15px'>"+
	                      "<img class='botonTabla' src="+nuevo+" width='17px' />"+
	                    "</div>"+
	                  "</td>"+
	                "</tr>");
	                //Evento de la fila (onclick en asociar)----------------------------------------
	                document.getElementById(doc.id+"busqueda-VI").addEventListener('click', (e) => {
	                  asociarInstructor(doc.id, doc.data().nombre, doc.data().apellidos);
	                });
	                //------------------------------------------------------------------------------
	                //*****************************************************************************************
	            });
	            if(contador == 0){
	              //No hubo resultados para correo electrónico ------------------------------------------------------------------
	              //-------------------------------------------------------------------------------------------------------------
	              //-------------------------------------------------------------------------------------------------------------
	              db.collection("instructores").where("telefono", "==", textoBusqueda.trim()).get().then(function(querySnapshot) {
	                  document.getElementById("body-tabla-instructores-busqueda").innerHTML = "";
	                  querySnapshot.forEach(function(doc) {
	                      contador++;
	                      //*****************************************************************************************
	                      document.getElementById("body-tabla-instructores-busqueda").insertAdjacentHTML("beforeend", 
	                      "<tr class='filaTabla filaTabla-hover'>"+
	                        "<td>"+doc.data().nombre+"</td>"+
	                        "<td>"+doc.data().apellidos+"</td>"+
	                        "<td>"+
	                          "<div class='toggle-botonTabla' id='"+doc.id+"busqueda-VI"+"' title='Seleccionar' style='margin-right:15px'>"+
	                            "<img class='botonTabla' src="+nuevo+" width='17px' />"+
	                          "</div>"+
	                        "</td>"+
	                      "</tr>");
	                      //Evento de la fila (onclick en asociar)----------------------------------------
	                      document.getElementById(doc.id+"busqueda-VI").addEventListener('click', (e) => {
	                        asociarInstructor(doc.id, doc.data().nombre, doc.data().apellidos);
	                      });
	                      //------------------------------------------------------------------------------
	                      //*****************************************************************************************
	                  });
	                  if(contador == 0){
	                    //No hubo resultados para teléfono (NINGÚN RESULTADO PARA LA BÚSQUEDA)
	                    document.getElementById("body-tabla-instructores-busqueda").innerHTML = 
	                    "<tr>"+
	                      "<td colSpan='3'>"+
	                        "<center>"+
	                          "<p id='avisoSinResultadosBusquedaActividad' style='color:#c8c8c8'>No hay resultados a mostrar</p>"+
	                        "</center>"+
	                      "</td>"+
	                    "</tr>";
	                  }
	              }).catch(function(error) {
	                  //Error al realizar consulta
	              });
	              //-------------------------------------------------------------------------------------------------------------
	              //-------------------------------------------------------------------------------------------------------------
	              //-------------------------------------------------------------------------------------------------------------
	            }
	        }).catch(function(error) {
	            //Error al realizar consulta
	        });
	        //------------------------------------------------------------------------------------------------------------
	        //------------------------------------------------------------------------------------------------------------
	        //------------------------------------------------------------------------------------------------------------
	      }
	    }
	    //*****************************************************************************************************
	    //**************************************** ASOCIAR INSTRUCTOR ***********************************************
	    var instructor = [];
	    const asociarInstructor = (uid, nombre, apellidos) => {
	      //Sí la tabla está vacía, se elimina el mensaje de "Ninguna selección"-------
	      if(instructor.length == 0){
	        document.getElementById("body-tabla-instructores-asociados").innerHTML = "";
	      }
	      //---------------------------------------------------------------------------
	      //Solo se selecciona nuevo instructor cuando no hay uno ya seleccionado
	      if(instructor.length == 0) {
	      //Se guarda en array "instructor" la referencia--------------------------------------------------------------------
	      //Sí ya existe el valor que se intenta guardar, se omite
	      var aux = db.collection('instructores').doc(uid);
	      if(!(instructor.find(element => element.id == aux.id) !== undefined)){
	        instructor.push(aux);//Guardado en el array "instructor"
	        //*********************************************************************
	        //***************** SE INICIA CONSUTAS PARA REPORTE *******************
	        //*********************************************************************
	        if(!errorEnPeriodo){
	        	generarReporte();
	        }
	        //*********************************************************************
	        //*********************************************************************
	        //*********************************************************************
	        //Mostrar en Front (Tabla de asociación)----------------------------------------------------------------------------
	        //*****************************************************************************************
	        document.getElementById("body-tabla-instructores-asociados").insertAdjacentHTML("beforeend", 
	        "<tr class='filaTabla filaTabla-hover' id='"+uid+"asociado-fila-VI'>"+
	          "<td>"+nombre+"</td>"+
	          "<td>"+apellidos+"</td>"+
	          "<td>"+
	            "<div class='toggle-botonTabla' id='"+uid+"asociado-VI"+"' title='Eliminar selección' style='margin-right:15px'>"+
	              "<img class='botonTabla' src="+eliminar+" width='17px' />"+
	            "</div>"+
	          "</td>"+
	        "</tr>");
	        //Evento de la fila (onclick en desasociar)----------------------------------------
	        document.getElementById(uid+"asociado-VI").addEventListener('click', (e) => {
	          //--------------------------------------------------------------------------------
	          //---------------------------- Desasociar instructor -----------------------------
	          //--------------------------------------------------------------------------------
	          document.getElementById("imprimir").style.opacity = "0.2";
	          impresionDisponible = false;
	          document.getElementById("total").innerHTML = "0";
	          document.getElementById("total").style.color = "#C3C3C3";
	          document.getElementById("total_pago").innerHTML = "$0";
			  document.getElementById("total_pago").style.color = "#C3C3C3";
	          document.getElementById("body-tabla-sesiones").innerHTML = 
			  "<tr>"+
		        "<td colSpan='4'>"+
		          "<center>"+
		            "<p style='color:#c8c8c8'>No hay resultados a mostrar</p>"+
		          "</center>"+
		        "</td>"+
		      "</tr>";
	          //Se elimina de la tabla (Front)
	          document.getElementById("body-tabla-instructores-asociados").removeChild(document.getElementById(uid+"asociado-fila-VI"));
	          //Se elimina del array "actividades"
	          instructor.splice(instructor.indexOf(instructor.find(element => element.id == aux.id)), 1);
	          //Sí la tabla se quedó sin elementos
	          if(instructor.length == 0) {
	            document.getElementById("body-tabla-instructores-asociados").innerHTML = 
	            "<tr>"+
	              "<td colSpan='3'>"+
	                "<center>"+
	                  "<p style='color:#c8c8c8'>Ningún instructor seleccionado</p>"+
	                "</center>"+
	              "</td>"+
	            "</tr>";
	          }
	          //--------------------------------------------------------------------------------
	          //--------------------------------------------------------------------------------
	          //--------------------------------------------------------------------------------
	        });
	        //------------------------------------------------------------------------------
	        //*****************************************************************************************
	        //-----------------------------------------------------------------------------------------------------------------
	      }
	      //------------------------------------------------------------------------------------------------------------------
	  	  }
	    }
	    //***********************************************************************************************************
		//----------------------------------------------------------------------------------------------------
		//VALIDACIÓN DE LAS FECHAS----------------------------------------------------------------------------
		//----------------------------------------------------------------------------------------------------
		var errorEnPeriodo = true;
		const validarFechas = (e) => {
			switch(e.target.name){
				case "fechaInicio": {
					//-----------------------------------------------------------------------------------------------------------
					if(document.getElementById("fechaInicioRA").value>=document.getElementById("fechaFinRA").value){
						document.getElementById("fechaInicioRA").classList.remove('input_correcto');
						document.getElementById("fechaInicioRA").classList.add('input_incorrecto');
						if(document.getElementById("fechaInicioRA").value==document.getElementById("fechaFinRA").value){
							document.getElementById("error-"+"fechaInicioRA").innerHTML = 'Inicio igual que fin';
							document.getElementById("error-"+"fechaFinRA").innerHTML = '';
						} else {
							document.getElementById("error-"+"fechaInicioRA").innerHTML = 'Inicio mayor que fin';
							document.getElementById("error-"+"fechaFinRA").innerHTML = '';
						}
						errorEnPeriodo = true;
						document.getElementById("imprimir").style.opacity = "0.2";
						impresionDisponible = false;
						document.getElementById("total").innerHTML = "0";
						document.getElementById("total").style.color = "#C3C3C3";
						document.getElementById("total_pago").innerHTML = "$0";
						document.getElementById("total_pago").style.color = "#C3C3C3";
						document.getElementById("body-tabla-sesiones").innerHTML = 
						"<tr>"+
			              "<td colSpan='4'>"+
			                "<center>"+
			                  "<p id='avisoSinAlumnos' style='color:#c8c8c8'>No hay resultados a mostrar</p>"+
			                "</center>"+
			              "</td>"+
			            "</tr>";
					} else {
						document.getElementById("fechaInicioRA").classList.remove('input_incorrecto');
						document.getElementById("fechaInicioRA").classList.add('input_correcto');
						document.getElementById("error-"+"fechaInicioRA").innerHTML = '';
						document.getElementById("fechaFinRA").classList.remove('input_incorrecto');
						document.getElementById("fechaFinRA").classList.add('input_correcto');
						document.getElementById("error-"+"fechaFinRA").innerHTML = '';
						errorEnPeriodo = false;
					}
					//-----------------------------------------------------------------------------------------------------------
				}break;
				case "fechaFin": {
					//-----------------------------------------------------------------------------------------------------------
					if(document.getElementById("fechaInicioRA").value>=document.getElementById("fechaFinRA").value){
						document.getElementById("fechaFinRA").classList.remove('input_correcto');
						document.getElementById("fechaFinRA").classList.add('input_incorrecto');
						if(document.getElementById("fechaInicioRA").value==document.getElementById("fechaFinRA").value){
							document.getElementById("error-"+"fechaFinRA").innerHTML = 'Fin igual que inicio';
							document.getElementById("error-"+"fechaInicioRA").innerHTML = '';
						} else {
							document.getElementById("error-"+"fechaFinRA").innerHTML = 'Fin menor que inicio';
							document.getElementById("error-"+"fechaInicioRA").innerHTML = '';
						}
						errorEnPeriodo = true;
						document.getElementById("imprimir").style.opacity = "0.2";
						impresionDisponible = false;
						document.getElementById("total").innerHTML = "0";
						document.getElementById("total").style.color = "#C3C3C3";
						document.getElementById("total_pago").innerHTML = "$0";
						document.getElementById("total_pago").style.color = "#C3C3C3";
						document.getElementById("body-tabla-sesiones").innerHTML = 
						"<tr>"+
			              "<td colSpan='4'>"+
			                "<center>"+
			                  "<p id='avisoSinAlumnos' style='color:#c8c8c8'>No hay resultados a mostrar</p>"+
			                "</center>"+
			              "</td>"+
			            "</tr>";
					} else {
						document.getElementById("fechaFinRA").classList.remove('input_incorrecto');
						document.getElementById("fechaFinRA").classList.add('input_correcto');
						document.getElementById("error-"+"fechaFinRA").innerHTML = '';
						document.getElementById("fechaInicioRA").classList.remove('input_incorrecto');
						document.getElementById("fechaInicioRA").classList.add('input_correcto');
						document.getElementById("error-"+"fechaInicioRA").innerHTML = '';
						if(document.getElementById("fechaInicioRA").value == ""){
							errorEnPeriodo = true;
							document.getElementById("imprimir").style.opacity = "0.2";
							impresionDisponible = false;
							document.getElementById("total").innerHTML = "0";
							document.getElementById("total").style.color = "#C3C3C3";
							document.getElementById("total_pago").innerHTML = "$0";
							document.getElementById("total_pago").style.color = "#C3C3C3";
							document.getElementById("body-tabla-sesiones").innerHTML = 
							"<tr>"+
				              "<td colSpan='4'>"+
				                "<center>"+
				                  "<p id='avisoSinAlumnos' style='color:#c8c8c8'>No hay resultados a mostrar</p>"+
				                "</center>"+
				              "</td>"+
				            "</tr>";
						} else {
							errorEnPeriodo = false;
						}
					}
					//-----------------------------------------------------------------------------------------------------------
				}break;
			}
			//*********************************************************************
	        //***************** SE INICIA CONSUTAS PARA REPORTE *******************
	        //*********************************************************************
	        if(!errorEnPeriodo && instructor.length > 0){
	        	if(e.target.value !== "" && e.target.valueAsDate.getFullYear() > 1980 && e.target.valueAsDate.getFullYear() < 2100){
		        	//Fecha correcta
		        	generarReporte();
		        } else {
		        	document.getElementById("imprimir").style.opacity = "0.2";
					impresionDisponible = false;
					document.getElementById("total").innerHTML = "0";
					document.getElementById("total").style.color = "#C3C3C3";
					document.getElementById("total_pago").innerHTML = "$0";
					document.getElementById("total_pago").style.color = "#C3C3C3";
					document.getElementById("body-tabla-sesiones").innerHTML = 
					"<tr>"+
		              "<td colSpan='4'>"+
		                "<center>"+
		                  "<p id='avisoSinAlumnos' style='color:#c8c8c8'>No hay resultados a mostrar</p>"+
		                "</center>"+
		              "</td>"+
		            "</tr>";
		            e.target.classList.remove('input_correcto');
					e.target.classList.add('input_incorrecto');
		        }
	        }
	        //*********************************************************************
	        //*********************************************************************
	        //*********************************************************************
		}
		//----------------------------------------------------------------------------------------------------
		//----------------------------------------------------------------------------------------------------
		//----------------------------------------------------------------------------------------------------
		//-------------------------------------------------------------------------------------------------------------
		//---------------------------------------------- GENERAR REPORTE ----------------------------------------------
		//-------------------------------------------------------------------------------------------------------------
		const generarReporte = () => {

			//----------------------------------------------------------------------------------
			//----------------------------------------------------------------------------------
			//IMPRESIÓN************************************************************
			var pdfY = 0;
			var pdfX = 25;
			pdf = null;
			pdf = new jsPDF('p', 'pt', 'a4');
			//ENCABEZADO--------------------------------------------------
			pdfY+=15;
			pdf.addImage(logo_header, 'PNG', pdfX-5, pdfY, 130, 37);
			pdfY+=55;
			textoCentradoPDF("REPORTE DE SESIONES POR INSTRUCTOR", pdfY, 12);
			pdf.setFontSize(10);
			pdfY+=25;
    		pdf.text(pdfX, pdfY, "Fecha de consulta: "+(new Date().toLocaleDateString()));
    		pdfY+=15;
    		db.collection("instructores").doc(instructor[0].id).get().then(doc => {
			    if (doc.exists) {
			    	pdf.text(pdfX, 110, "Nombre del instructor: "+doc.data().nombre+" "+doc.data().apellidos);
			    }
			});
    		pdfY+=15;
    		pdf.text(pdfX, pdfY, "Período consultado: "+new Date(document.getElementById("fechaInicioRA").value.replace(/-/g, '\/')).toLocaleDateString()+" 00:00 a "+new Date(document.getElementById("fechaFinRA").value.replace(/-/g, '\/')).toLocaleDateString()+" 00:00");
    		pdfY+=45;
    		textoCentradoPDF("SESIONES REGISTRADAS", pdfY, 10);
    		pdfY+=20;
    		var arrayTabla = [];
    		//------------------------------------------------------------
			//*********************************************************************
			document.getElementById("body-tabla-sesiones").innerHTML = "";//Se limpia tabla de sesiones
			var aux_total = 0;//Total de horas en el período
			var aux_total_pago = 0;//Pago total calculado
			//************************************************************************************************
			db.collection("sesiones")
			.where("instructor", "==", instructor[0])
			.where("fechaRealizacion", ">=", firebase.firestore.Timestamp.fromDate(new Date(document.getElementById("fechaInicioRA").value.replace(/-/g, '\/'))))
			.where("fechaRealizacion", "<=", firebase.firestore.Timestamp.fromDate(new Date(document.getElementById("fechaFinRA").value.replace(/-/g, '\/'))))
			.orderBy("fechaRealizacion", "desc")
			.get().then(sesiones => {

				var index = 0;
				sesiones.forEach(doc => {
				    //Se llena tabla "Sesiones asociadas al instructor"-------------------------------------------------
				    db.collection("actividades").doc(doc.data().actividad.id).get().then(curso => {
					    if (curso.exists) {
					    	//Sí curso existe
					    	var curso_aux = curso.data().clave+" "+curso.data().nombre;
					    	var fecha_aux = moment(doc.data().fechaRealizacion.toDate()).format('DD-MM-YYYY');
					    	var horas_aux = doc.data().horas.toString();
					    	var pago_aux = "$"+(doc.data().horas*parseFloat(curso.data().pagoHora)).toString();
					    	if(document.getElementById(doc.id) == undefined){
						        document.getElementById("body-tabla-sesiones").insertAdjacentHTML("beforeend", 
						        "<tr class='filaTabla filaTabla-hover' id='"+doc.id+"'>"+
						          "<td>"+curso_aux+"</td>"+
						          "<td>"+fecha_aux+"</td>"+
						          "<td>"+horas_aux+"</td>"+
						          "<td>"+pago_aux+"</td>"+
						        "</tr>");
					    	}
					    	aux_total+=parseFloat(doc.data().horas);
					    	aux_total_pago+=(doc.data().horas*parseFloat(curso.data().pagoHora));
					    	//IMPRESIÓN*************************************************************
					    	arrayTabla.push([curso_aux, fecha_aux, horas_aux, pago_aux]);
					    	//**********************************************************************
					    } else {
					    	//Sí curso no existe
					    	var fecha_aux = moment(doc.data().fechaRealizacion.toDate()).format('DD-MM-YYYY');
					    	var horas_aux = doc.data().horas.toString();
					    	if(document.getElementById(doc.id) == undefined){
						        document.getElementById("body-tabla-sesiones").insertAdjacentHTML("beforeend", 
						        "<tr class='filaTabla filaTabla-hover' id='"+doc.id+"'>"+
						          "<td><i>Eliminado</i></td>"+
						          "<td>"+fecha_aux+"</td>"+
						          "<td>"+horas_aux+"</td>"+
						          "<td>-</td>"+
						        "</tr>");
					    	}
					    	aux_total+=parseFloat(doc.data().horas);
					    	//IMPRESIÓN*************************************************************
					    	arrayTabla.push(["CURSO ELIMINADO", fecha_aux, horas_aux, "-"]);
					    	//**********************************************************************
					    }
					    document.getElementById("total").innerHTML = aux_total;
					    document.getElementById("total_pago").innerHTML = "$"+aux_total_pago;
					    //IMPRESIÓN*************************************************************
					    if(index == (sesiones.size-1)){
					    	pdf.text(pdfX, 140, "Horas totales registradas: "+aux_total);
							pdf.text(pdfX, 155, "Pago total calculado: $"+aux_total_pago);
							pdf.autoTable({
							  head: [['Curso', 'Fecha', 'Horas', 'Pago']],
							  startY: pdfY,
							  body: arrayTabla
							});
					    }
						//**********************************************************************
						index++;
					});
				    //--------------------------------------------------------------------------------------------------
				});

				if(sesiones.empty){
					document.getElementById("imprimir").style.opacity = "0.2";
					impresionDisponible = false;
					document.getElementById("total").innerHTML = aux_total;
					document.getElementById("total_pago").innerHTML = "$"+aux_total_pago;
					document.getElementById("body-tabla-sesiones").innerHTML = 
					"<tr>"+
		              "<td colSpan='4'>"+
		                "<center>"+
		                  "<p id='avisoSinAlumnos' style='color:#c8c8c8'>No hay resultados a mostrar</p>"+
		                "</center>"+
		              "</td>"+
		            "</tr>";
				} else {
					document.getElementById("imprimir").style.opacity = "0.9";
					impresionDisponible = true;
				}

				document.getElementById("total").style.color = "#282828";
				document.getElementById("total_pago").style.color = "#282828";

			}).catch(error => {console.log(error);});
			//************************************************************************************************

		}
		//-------------------------------------------------------------------------------------------------------------
		//-------------------------------------------------------------------------------------------------------------
		//-------------------------------------------------------------------------------------------------------------
		//----------------------------------------------------------------------------------------------------
		//------------------------------------- IMPRESIÓN DE REPORTE -----------------------------------------
		//----------------------------------------------------------------------------------------------------
		const imprimirReporte = () => {
			//-----------------------------------------------------------------------------------
			if(impresionDisponible){
				window.open(pdf.output('bloburl'),'_blank');
			}
			//-----------------------------------------------------------------------------------
		}
		function textoCentradoPDF(text, y, fSize){
			var textWidth = pdf.getStringUnitWidth(text) * fSize / pdf.internal.scaleFactor;
    		var textOffset = (pdf.internal.pageSize.width - textWidth) / 2;
    		pdf.setFontSize(fSize);
    		pdf.text(textOffset, y, text);
		}
		//----------------------------------------------------------------------------------------------------
		//----------------------------------------------------------------------------------------------------
		//----------------------------------------------------------------------------------------------------
		//****************************************************************************************************************
		//****************************************************************************************************************
		//****************************************************************************************************************
		return(

			<div className="global-container">
				<div className="card login-form tablas-adm" style={{width:'82%'}}>
					<div className="card-body card-tablas-adm" style={{padding:'10px'}}>

						<div className="row">
							<div className="col-sm-12 col-md-9 col-lg-8">
								<h3 className="card-title" style={{fontSize:'16pt',fontWeight:'500'}}>Reporte de sesiones por instructor</h3>
							</div>
							<div className="col-sm-12 col-md-3 col-lg-4">
								<div id="imprimir" style={{cursor:'pointer',opacity:'0.2'}} onClick={imprimirReporte}>
									<p style={{float:'right',display:'inline',fontSize:'9pt'}}>Imprimir reporte</p>
									<img src={print} style={{float:'right',display:'inline',marginRight:'6px'}} width="20px"/>
								</div>
							</div>
						</div>

						<div className="row">
							<div className="col-sm-12 col-md-7 col-lg-5">
								<form autoComplete="off" style={{marginTop:'0px',margin:'auto'}}>
					                <div style={{borderRadius:'8px',border:'1px solid #D2D5D8',width:"100%",margin:'auto',marginBottom:'30px'}}>
					                  <div className="wrap" style={{width:'100%'}}>
					                     <div className="search">
					                        <input type="search" onSearch={buscarInstructor} className="searchTerm" id="busqueda-instructor" placeholder="Buscar instructor por correo o teléfono"/>
					                        <button className="searchButton" onClick={buscarInstructor} id="boton-busqueda-instructor">
					                          <img src={lupa} style={{marginTop:'-6px',marginLeft:'-2px',opacity:'0.6'}} width="18px" />
					                        </button>
					                     </div>
					                  </div>
					                  <div style={{borderRadius:'0px 0px 7px 7px',overflow:'hidden',width: '100%',margin:'auto'}}>
					                    <div style={{
					                        width: '100%',
					                        height: '130px', 
					                        overflowY: 'auto',
					                        overflowX: 'auto',
					                        backgroundColor: '#fff',
					                        margin: 'auto'
					                    }}>
					                      <RB.Table>
					                        <thead>
					                           <tr style={{backgroundColor:'#fafafa'}}>
					                              <th>Nombre</th>
					                              <th>Apellidos</th>
					                              <th width="50px"></th>
					                           </tr>
					                        </thead>
					                        <tbody id="body-tabla-instructores-busqueda">
					                          <tr>
					                            <td colSpan="3">
					                              <center>
					                                <p style={{color: '#c8c8c8'}}>No hay resultados a mostrar</p>
					                              </center>
					                            </td>
					                          </tr>
					                        </tbody>
					                      </RB.Table>
					                    </div>
					                  </div>
					                </div>
				                </form>
							</div>

							<div className="col-sm-12 col-md-5 col-lg-4">
								<div className="form-group input-field" style={{margin:'auto',marginTop:'20px'}}>
									<input className="input_correcto input" type="date" id="fechaInicioRA" name="fechaInicio" onChange={validarFechas} required/>
									<label className="label" for="fechaInicioRA">Inicio de período</label>
									<p id="error-fechaInicioRA"></p>
								</div>
								<div className="form-group input-field" style={{margin:'auto',marginTop:'20px'}}>
									<input className="input_correcto input" type="date" id="fechaFinRA" name="fechaFin" onChange={validarFechas} required/>
									<label className="label" for="fechaFinRA">Fin de período</label>
									<p id="error-fechaFinRA"></p>
								</div>
							</div>

							<div className="col-sm-12 col-md-12 col-lg-3">
								<center style={{marginTop:'15px'}}>
									<h3 className="card-title" style={{fontSize:'13pt',fontWeight:'500'}}>Total de horas en el período</h3>
									<h3 className="card-title" id="total" style={{fontSize:'30pt',fontWeight:'500',color:'#C3C3C3'}}>0</h3>

									<h3 className="card-title" style={{fontSize:'13pt',fontWeight:'500'}}>Pago total calculado</h3>
									<h3 className="card-title" id="total_pago" style={{fontSize:'22pt',fontWeight:'500',color:'#C3C3C3'}}>$0</h3>
								</center>
							</div>
						</div>

		                <div className="row" style={{paddingTop:'0px',paddingBottom:'25px'}}>
		                	<div className="col-sm-12 col-md-12 col-lg-5" style={{marginBottom:'20px'}}>
			                    <div style={{margin:'auto',backgroundColor:'#fafafa',border:'1px solid #D2D5D8',borderRadius:'9px',padding:'1px',width: '100%'}}>
			                        <p style={{fontSize:'10pt',fontWeight:'500',marginBottom:'11px',marginLeft:'10px',marginTop:'8px',float:'left'}}>Instructor seleccionado</p>
			                        <div style={{borderRadius:'0px 0px 8px 8px',overflow:'hidden',width: '100%',margin:'auto'}}>
			                          <div style={{
			                              width: '100%',
			                              height: '95px', 
			                              overflowY: 'hidden',
			                              overflowX: 'auto',
			                              backgroundColor: '#fff',
			                              margin: 'auto'
			                          }}>
			                            <RB.Table>
			                              <thead>
			                                 <tr style={{backgroundColor:'#fafafa'}}>
			                                    <th>Nombre</th>
			                                    <th>Apellidos</th>
			                                    <th width="50px"></th>
			                                 </tr>
			                              </thead>
			                              <tbody id="body-tabla-instructores-asociados">
			                                <tr>
			                                  <td colSpan="3">
			                                    <center>
			                                      <p style={{color: '#c8c8c8'}}>Ningún instructor seleccionado</p>
			                                    </center>
			                                  </td>
			                                </tr>
			                              </tbody>
			                            </RB.Table>
			                          </div>
			                        </div>
			                    </div>
			                </div>

			                <div className="col-sm-12 col-md-12 col-lg-7">
			                    <div style={{margin:'auto',backgroundColor:'#fafafa',border:'1px solid #D2D5D8',borderRadius:'9px',padding:'1px',width: '100%'}}>
			                        <p style={{fontSize:'10pt',fontWeight:'500',marginBottom:'11px',marginLeft:'10px',marginTop:'8px',float:'left'}}>Sesiones asociadas al instructor</p>
			                        <div style={{borderRadius:'0px 0px 8px 8px',overflow:'hidden',width: '100%',margin:'auto'}}>
			                          <div style={{
			                              width: '100%',
			                              height: '300px',
			                              overflowY: 'auto',
			                              overflowX: 'auto',
			                              backgroundColor: '#fff',
			                              margin: 'auto'
			                          }}>
			                            <RB.Table>
			                              <thead>
			                                 <tr style={{backgroundColor:'#fafafa'}}>
			                                    <th>Curso</th>
			                                    <th>Fecha</th>
			                                    <th>Horas</th>
			                                    <th>Pago</th>
			                                 </tr>
			                              </thead>
			                              <tbody id="body-tabla-sesiones">
			                                <tr>
			                                  <td colSpan="4">
			                                    <center>
			                                      <p style={{color: '#c8c8c8'}}>No hay resultados a mostrar</p>
			                                    </center>
			                                  </td>
			                                </tr>
			                              </tbody>
			                            </RB.Table>
			                          </div>
			                        </div>
			                    </div>
			                </div>
		                </div>

					</div>
				</div>
			</div>

		);
	}
}

export default ReporteHPI;