import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import {auth} from '../firebase-config';
import {auth_aux} from '../firebase-config';
import {db} from '../firebase-config';
import logo_header from '../assets/images/logo_header.png';
import {Link} from 'react-router-dom';
import * as RB from "react-bootstrap";
import swal from 'sweetalert2';
import firebase from 'firebase/app';
import 'firebase/auth'
import advertencia from '../assets/images/advertencia.png';
import print from '../assets/images/print.png';
import moment from 'moment';
import key from '../assets/images/key.png';
import eliminar from '../assets/images/eliminar.png';
import lupa from '../assets/images/lupa.png';
import nuevo from '../assets/images/nuevo.png';
import asociar from '../assets/images/asociar.png';
import mas from '../assets/images/mas.png';
import jsPDF from 'jspdf';
import 'jspdf-autotable'

class ReporteIngresos extends React.Component{
	//constructor del componente
	constructor(props){
		super(props)
		//Estado inicial del componente
		this.state = {
			productos: []
		}
	}
	//Se ejecuta al cargar componente
	componentDidMount() {
		//-----------------------------------------------------------------------------------------------------------------
		db.collection("productos").orderBy("clave").get().then(querySnapshot => {
	    	const docs = [];
	        querySnapshot.forEach(doc => {
	            //-------------------------------------------------------------------------
	            docs.push({
	            	clave: doc.data().clave,
	            	nombre: doc.data().nombre,
	            	tipo: doc.data().tipo,
	            	costo: doc.data().costo,
	            	id: doc.id
	            });
	            //-------------------------------------------------------------------------
	        });
	        this.setState({productos: docs});
	    });
		//-----------------------------------------------------------------------------------------------------------------
	}
	//----------------------------------------------------------------------------------------------------------------------------------
	//----------------------------------------------------------------------------------------------------------------------------------
	//----------------------------------------------------------------------------------------------------------------------------------
	//
	//-----------------------------------------------------------------------------------------------------------------------------------
	//-----------------------------------------------------------------------------------------------------------------------------------
	//-----------------------------------------------------------------------------------------------------------------------------------
	render(){
		//**************Variables para impresión********************
		var impresionDisponible = false;
		var pdf;
		//**********************************************************
		//****************************************************************************************************************
		//****************************************************************************************************************
		//****************************************************************************************************************
		//BÚSQUEDA DE PRODUCTO (CONSULTA FIRESTORE)************************************************************
	    const buscarProducto = (e) => {
	      
	      e.preventDefault();//Evita la recarga de la página
	      var textoBusqueda = document.getElementById("busqueda-actividad").value.toUpperCase();//Texto a buscar
	      var existeResultado = false;//Se inicia sin resultados
	      document.getElementById("body-tabla-actividades-busqueda").innerHTML = "";//Se borran todos los resultados previos
	      //Sí hay texto que buscar
	      if(textoBusqueda !== ""){
	        //-------------------------------------------------------------------------------------------------------------
	        //Se busca entre todos los productos coincidencias
		    this.state.productos.forEach(producto => {
		      if(producto.clave.search(textoBusqueda) >= 0 || producto.nombre.search(textoBusqueda) >= 0){
		        existeResultado = true;//Existe al menos un resultado
		        //-------------------------------------------------------------------------------------------------------
		        document.getElementById("body-tabla-actividades-busqueda").insertAdjacentHTML("beforeend", 
                "<tr class='filaTabla filaTabla-hover'>"+
                  "<td>"+producto.clave+"</td>"+
                  "<td>"+producto.nombre+"</td>"+
                  "<td>"+
                    "<div class='toggle-botonTabla' id='"+producto.id+"busqueda-VI"+"' title='Seleccionar' style='margin-right:15px'>"+
                      "<img class='botonTabla' src="+nuevo+" width='17px' />"+
                    "</div>"+
                  "</td>"+
                "</tr>");
                //Evento de la fila (onclick en asociar)----------------------------------------
                document.getElementById(producto.id+"busqueda-VI").addEventListener('click', (e) => {
                  seleccionarProducto(producto.id, producto.clave, producto.nombre, producto.tipo, "$"+producto.costo);
                });
                //------------------------------------------------------------------------------
		        //-------------------------------------------------------------------------------------------------------
		      }
		    });
		    //-------------------------------------------------------------------------------------------------------------
		    //Sí no hubo resultados----------------------------------------------------------------------------------------
		    if(!existeResultado){
		    	document.getElementById("body-tabla-actividades-busqueda").innerHTML = 
	            "<tr>"+
	              "<td colSpan='3'>"+
	                "<center>"+
	                  "<p id='avisoSinResultadosBusquedaActividad' style='color:#c8c8c8'>No hay resultados a mostrar</p>"+
	                "</center>"+
	              "</td>"+
	            "</tr>";
		    }
		    //-------------------------------------------------------------------------------------------------------------
	      } else {
	      	//No hay texto a buscar
	      	document.getElementById("body-tabla-actividades-busqueda").innerHTML = 
            "<tr>"+
              "<td colSpan='3'>"+
                "<center>"+
                  "<p id='avisoSinResultadosBusquedaActividad' style='color:#c8c8c8'>No hay resultados a mostrar</p>"+
                "</center>"+
              "</td>"+
            "</tr>";
	      }

	    }
	    //*****************************************************************************************************
	    //**************************************** SELECCIONAR PRODUCTO *********************************************
	    var productos = [];
	    const seleccionarProducto = (uid, clave, nombre, tipo, costo) => {
	      //Sí la tabla está vacía, se elimina el mensaje de "Ninguna selección"-------
	      if(productos.length == 0){
	        document.getElementById("body-tabla-actividades-asociados").innerHTML = "";
	      }
	      //---------------------------------------------------------------------------
	      //Se guarda en array "productos" la referencia--------------------------------------------------------------------
	      //Sí ya existe el valor que se intenta guardar, se omite
	      var aux = db.collection('productos').doc(uid);
	      if(!(productos.find(element => element.producto.id == aux.id) !== undefined)){
	      	var objAux = {
	      		producto: aux,
	      		clave: clave,
	      		nombre: nombre,
	      		tipo: tipo,
	      		costo: costo
	      	};
	        productos.push(objAux);//Guardado en el array "actividades"
	        //*********************************************************************
	        //***************** SE INICIA CONSUTAS PARA REPORTE *******************
	        //*********************************************************************
	        if(!errorEnPeriodo){
	        	generarReporte();
	        }
	        //*********************************************************************
	        //*********************************************************************
	        //*********************************************************************
	        //Mostrar en Front (Tabla de asociación)----------------------------------------------------------------------------
	        //*****************************************************************************************
	        document.getElementById("body-tabla-actividades-asociados").insertAdjacentHTML("beforeend", 
	        "<tr class='filaTabla filaTabla-hover' id='"+uid+"asociado-fila-VI'>"+
	          "<td>"+clave+"</td>"+
	          "<td>"+nombre+"</td>"+
	          "<td>"+tipo+"</td>"+
	          "<td>"+costo+"</td>"+
	          "<td id='"+uid+"-cantidad'></td>"+
	          "<td id='"+uid+"-ingreso'></td>"+
	          "<td>"+
	            "<div class='toggle-botonTabla' id='"+uid+"asociado-VI"+"' title='Eliminar selección' style='margin-right:15px'>"+
	              "<img class='botonTabla' src="+eliminar+" width='17px' />"+
	            "</div>"+
	          "</td>"+
	        "</tr>");
	        //Evento de la fila (onclick en desasociar)----------------------------------------
	        document.getElementById(uid+"asociado-VI").addEventListener('click', (e) => {
	          //--------------------------------------------------------------------------------
	          //----------------------------- Desasociar actividad -----------------------------
	          //--------------------------------------------------------------------------------
	          //Se elimina de la tabla (Front)
	          document.getElementById("body-tabla-actividades-asociados").removeChild(document.getElementById(uid+"asociado-fila-VI"));
	          //Se elimina del array "actividades"
	          productos.splice(productos.indexOf(productos.find(element => element.producto.id == aux.id)), 1);
	          //Sí la tabla se quedó sin elementos
	          if(productos.length == 0) {
	          	document.getElementById("imprimir").style.opacity = "0.2";
				impresionDisponible = false;
	          	document.getElementById("total").innerHTML = "$0";
	          	document.getElementById("total").style.color = "#C3C3C3";
	            document.getElementById("body-tabla-actividades-asociados").innerHTML = 
	            "<tr>"+
	              "<td colSpan='7'>"+
	                "<center>"+
	                  "<p id='avisoSinAsociacionesActividad' style='color:#c8c8c8'>Ningún producto seleccionado</p>"+
	                "</center>"+
	              "</td>"+
	            "</tr>";
	          } else{
	          	//*********************************************************************
		        //***************** SE INICIA CONSUTAS PARA REPORTE *******************
		        //*********************************************************************
		        if(!errorEnPeriodo){
		        	generarReporte();
		        }
		        //*********************************************************************
		        //*********************************************************************
		        //*********************************************************************
	          }
	          //--------------------------------------------------------------------------------
	          //--------------------------------------------------------------------------------
	          //--------------------------------------------------------------------------------
	        });
	        //------------------------------------------------------------------------------
	        //*****************************************************************************************
	        //-----------------------------------------------------------------------------------------------------------------
	      }
	      //------------------------------------------------------------------------------------------------------------------
	    }
	    //***********************************************************************************************************
		//----------------------------------------------------------------------------------------------------
		//VALIDACIÓN DE LAS FECHAS----------------------------------------------------------------------------
		//----------------------------------------------------------------------------------------------------
		var errorEnPeriodo = true;
		const validarFechas = (e) => {
			switch(e.target.name){
				case "fechaInicio": {
					//-----------------------------------------------------------------------------------------------------------
					if(document.getElementById("fechaInicioRA").value>=document.getElementById("fechaFinRA").value){
						document.getElementById("fechaInicioRA").classList.remove('input_correcto');
						document.getElementById("fechaInicioRA").classList.add('input_incorrecto');
						if(document.getElementById("fechaInicioRA").value==document.getElementById("fechaFinRA").value){
							document.getElementById("error-"+"fechaInicioRA").innerHTML = 'Inicio igual que fin';
							document.getElementById("error-"+"fechaFinRA").innerHTML = '';
						} else {
							document.getElementById("error-"+"fechaInicioRA").innerHTML = 'Inicio mayor que fin';
							document.getElementById("error-"+"fechaFinRA").innerHTML = '';
						}
						errorEnPeriodo = true;
						document.getElementById("imprimir").style.opacity = "0.2";
						impresionDisponible = false;
						document.getElementById("total").innerHTML = "$0";
						document.getElementById("total").style.color = "#C3C3C3";
					} else {
						document.getElementById("fechaInicioRA").classList.remove('input_incorrecto');
						document.getElementById("fechaInicioRA").classList.add('input_correcto');
						document.getElementById("error-"+"fechaInicioRA").innerHTML = '';
						document.getElementById("fechaFinRA").classList.remove('input_incorrecto');
						document.getElementById("fechaFinRA").classList.add('input_correcto');
						document.getElementById("error-"+"fechaFinRA").innerHTML = '';
						errorEnPeriodo = false;
					}
					//-----------------------------------------------------------------------------------------------------------
				}break;
				case "fechaFin": {
					//-----------------------------------------------------------------------------------------------------------
					if(document.getElementById("fechaInicioRA").value>=document.getElementById("fechaFinRA").value){
						document.getElementById("fechaFinRA").classList.remove('input_correcto');
						document.getElementById("fechaFinRA").classList.add('input_incorrecto');
						if(document.getElementById("fechaInicioRA").value==document.getElementById("fechaFinRA").value){
							document.getElementById("error-"+"fechaFinRA").innerHTML = 'Fin igual que inicio';
							document.getElementById("error-"+"fechaInicioRA").innerHTML = '';
						} else {
							document.getElementById("error-"+"fechaFinRA").innerHTML = 'Fin menor que inicio';
							document.getElementById("error-"+"fechaInicioRA").innerHTML = '';
						}
						errorEnPeriodo = true;
						document.getElementById("imprimir").style.opacity = "0.2";
						impresionDisponible = false;
						document.getElementById("total").innerHTML = "$0";
						document.getElementById("total").style.color = "#C3C3C3";
					} else {
						document.getElementById("fechaFinRA").classList.remove('input_incorrecto');
						document.getElementById("fechaFinRA").classList.add('input_correcto');
						document.getElementById("error-"+"fechaFinRA").innerHTML = '';
						document.getElementById("fechaInicioRA").classList.remove('input_incorrecto');
						document.getElementById("fechaInicioRA").classList.add('input_correcto');
						document.getElementById("error-"+"fechaInicioRA").innerHTML = '';
						if(document.getElementById("fechaInicioRA").value == ""){
							errorEnPeriodo = true;
							document.getElementById("imprimir").style.opacity = "0.2";
							impresionDisponible = false;
							document.getElementById("total").innerHTML = "$0";
							document.getElementById("total").style.color = "#C3C3C3";
						} else {
							errorEnPeriodo = false;
						}
					}
					//-----------------------------------------------------------------------------------------------------------
				}break;
			}
			//*********************************************************************
	        //***************** SE INICIA CONSUTAS PARA REPORTE *******************
	        //*********************************************************************
	        if(!errorEnPeriodo && productos.length > 0){
	        	if(e.target.value !== "" && e.target.valueAsDate.getFullYear() > 1980 && e.target.valueAsDate.getFullYear() < 2100){
		        	//Fecha correcta
		        	generarReporte();
		        } else {
		        	e.target.classList.remove('input_correcto');
					e.target.classList.add('input_incorrecto');
		        }
	        }
	        //*********************************************************************
	        //*********************************************************************
	        //*********************************************************************
		}
		//----------------------------------------------------------------------------------------------------
		//----------------------------------------------------------------------------------------------------
		//----------------------------------------------------------------------------------------------------
		//-------------------------------------------------------------------------------------------------------------
		//---------------------------------------------- GENERAR REPORTE ----------------------------------------------
		//-------------------------------------------------------------------------------------------------------------
		const generarReporte = () => {
			document.getElementById("imprimir").style.opacity = "0.9";
			impresionDisponible = true;
			//IMPRESIÓN************************************************************
			var pdfY = 0;
			var pdfX = 25;
			pdf = null;
			pdf = new jsPDF('p', 'pt', 'a4');
			//ENCABEZADO--------------------------------------------------
			pdfY+=15;
			pdf.addImage(logo_header, 'PNG', pdfX-5, pdfY, 130, 37);
			pdfY+=55;
			textoCentradoPDF("REPORTE DE INGRESOS", pdfY, 12);
			pdf.setFontSize(10);
			pdfY+=25;
    		pdf.text(pdfX, pdfY, "Fecha de consulta: "+(new Date().toLocaleDateString()));
    		pdfY+=30;
    		pdf.text(pdfX, pdfY, "Período consultado: "+new Date(document.getElementById("fechaInicioRA").value.replace(/-/g, '\/')).toLocaleDateString()+" 00:00 a "+new Date(document.getElementById("fechaFinRA").value.replace(/-/g, '\/')).toLocaleDateString()+" 00:00");
    		pdfY+=45;
    		textoCentradoPDF("PRODUCTOS SELECCIONADOS", pdfY, 10);
    		pdfY+=20;
    		var arrayTabla = [];
    		//------------------------------------------------------------
			//*********************************************************************
			var aux_total = 0;//Total del ingreso calculado en el período
			var index = 0;
			productos.forEach(producto => {
				//************************************************************************************************
				db.collection("compras")
				.where("producto", "==", producto.producto)
				.where("fechaCompra", ">=", firebase.firestore.Timestamp.fromDate(new Date(document.getElementById("fechaInicioRA").value.replace(/-/g, '\/'))))
				.where("fechaCompra", "<=", firebase.firestore.Timestamp.fromDate(new Date(document.getElementById("fechaFinRA").value.replace(/-/g, '\/'))))
				.get().then(compras => {

					var cantidad = 0;
					var aux_ingreso = 0;
				    compras.forEach(doc => {
				        cantidad+=doc.data().cantidad;
				        aux_ingreso+=(parseFloat(doc.data().costo)*doc.data().cantidad);
				    });
				    document.getElementById(producto.producto.id+"-cantidad").innerHTML = cantidad;
				    document.getElementById(producto.producto.id+"-ingreso").innerHTML = "$"+aux_ingreso;
				    aux_total+=aux_ingreso;
				    document.getElementById("total").innerHTML = "$"+aux_total;
					document.getElementById("total").style.color = "#282828";
					//IMPRESIÓN*************************************************************
					arrayTabla.push([producto.clave, producto.nombre, producto.tipo, producto.costo, cantidad.toString(), "$"+aux_ingreso]);
				    if(index == (productos.length-1)){
				    	pdf.text(pdfX, 110, "Ingreso calculado en el período: $"+aux_total);
						pdf.autoTable({
						  head: [['Clave', 'Nombre', 'Tipo', 'Costo actual', 'Cantidad', 'Ingreso']],
						  startY: pdfY,
						  body: arrayTabla
						});
				    }
					//**********************************************************************
					index++;

				}).catch(error => {console.log(error);});
				//************************************************************************************************
			});
		}
		//-------------------------------------------------------------------------------------------------------------
		//-------------------------------------------------------------------------------------------------------------
		//-------------------------------------------------------------------------------------------------------------
		//----------------------------------------------------------------------------------------------------
		//------------------------------------- IMPRESIÓN DE REPORTE -----------------------------------------
		//----------------------------------------------------------------------------------------------------
		const imprimirReporte = () => {
			//-----------------------------------------------------------------------------------
			if(impresionDisponible){
				window.open(pdf.output('bloburl'),'_blank');
			}
			//-----------------------------------------------------------------------------------
		}
		function textoCentradoPDF(text, y, fSize){
			var textWidth = pdf.getStringUnitWidth(text) * fSize / pdf.internal.scaleFactor;
    		var textOffset = (pdf.internal.pageSize.width - textWidth) / 2;
    		pdf.setFontSize(fSize);
    		pdf.text(textOffset, y, text);
		}
		//----------------------------------------------------------------------------------------------------
		//----------------------------------------------------------------------------------------------------
		//----------------------------------------------------------------------------------------------------
		//****************************************************************************************************************
		//****************************************************************************************************************
		//****************************************************************************************************************
		return(

			<div className="global-container">
				<div className="card login-form tablas-adm" style={{width:'82%'}}>
					<div className="card-body card-tablas-adm" style={{padding:'10px'}}>

						<div className="row">
							<div className="col-sm-12 col-md-9 col-lg-8">
								<h3 className="card-title" style={{fontSize:'16pt',fontWeight:'500'}}>Reporte de ingresos</h3>
							</div>
							<div className="col-sm-12 col-md-3 col-lg-4">
								<div id="imprimir" style={{cursor:'pointer',opacity:'0.2'}} onClick={imprimirReporte}>
									<p style={{float:'right',display:'inline',fontSize:'9pt'}}>Imprimir reporte</p>
									<img src={print} style={{float:'right',display:'inline',marginRight:'6px'}} width="20px"/>
								</div>
							</div>
						</div>

						<div className="row">
							<div className="col-sm-12 col-md-7 col-lg-5">
								<form autoComplete="off" style={{marginTop:'0px',margin:'auto'}}>
					                <div style={{borderRadius:'8px',border:'1px solid #D2D5D8',width:"100%",margin:'auto',marginBottom:'30px'}}>
					                  <div className="wrap" style={{width:'100%'}}>
					                     <div className="search">
					                        <input type="search" onChange={buscarProducto} onSearch={buscarProducto} className="searchTerm" id="busqueda-actividad" placeholder="Buscar producto por clave o nombre"/>
					                        <button className="searchButton" onClick={buscarProducto} id="boton-busqueda-actividad">
					                          <img src={lupa} style={{marginTop:'-6px',marginLeft:'-2px',opacity:'0.6'}} width="18px" />
					                        </button>
					                     </div>
					                  </div>
					                  <div style={{borderRadius:'0px 0px 7px 7px',overflow:'hidden',width: '100%',margin:'auto'}}>
					                    <div id="tabla-actividades" style={{
					                        width: '100%',
					                        height: '130px', 
					                        overflowY: 'auto',
					                        overflowX: 'auto',
					                        backgroundColor: '#fff',
					                        margin: 'auto'
					                    }}>
					                      <RB.Table>
					                        <thead>
					                           <tr style={{backgroundColor:'#fafafa'}}>
					                              <th>Clave</th>
					                              <th>Nombre</th>
					                              <th width="50px"></th>
					                           </tr>
					                        </thead>
					                        <tbody id="body-tabla-actividades-busqueda">
					                          <tr>
					                            <td colSpan="3">
					                              <center>
					                                <p id="avisoSinResultadosBusquedaActividad" style={{color: '#c8c8c8'}}>No hay resultados a mostrar</p>
					                              </center>
					                            </td>
					                          </tr>
					                        </tbody>
					                      </RB.Table>
					                    </div>
					                  </div>
					                </div>
				                </form>
							</div>

							<div className="col-sm-12 col-md-5 col-lg-4">
								<div className="form-group input-field" style={{margin:'auto',marginTop:'20px'}}>
									<input className="input_correcto input" type="date" id="fechaInicioRA" name="fechaInicio" onChange={validarFechas} required/>
									<label className="label" for="fechaInicioRA">Inicio de período</label>
									<p id="error-fechaInicioRA"></p>
								</div>
								<div className="form-group input-field" style={{margin:'auto',marginTop:'20px'}}>
									<input className="input_correcto input" type="date" id="fechaFinRA" name="fechaFin" onChange={validarFechas} required/>
									<label className="label" for="fechaFinRA">Fin de período</label>
									<p id="error-fechaFinRA"></p>
								</div>
							</div>

							<div className="col-sm-12 col-md-12 col-lg-3">
								<center style={{marginTop:'40px'}}>
									<h3 className="card-title" style={{fontSize:'13pt',fontWeight:'500'}}>Ingreso calculado en el período</h3>
									<h3 className="card-title" id="total" style={{fontSize:'30pt',fontWeight:'500',color:'#C3C3C3'}}>$0</h3>
								</center>
							</div>
						</div>

		                <div className="row" style={{paddingTop:'0px',paddingBottom:'25px'}}>
		                	<div className="col-sm-12 col-md-12 col-lg-12">
			                    <div style={{margin:'auto',backgroundColor:'#fafafa',border:'1px solid #D2D5D8',borderRadius:'9px',padding:'1px',width: '100%'}}>
			                        <p style={{fontSize:'12pt',fontWeight:'500',marginBottom:'11px',marginLeft:'10px',marginTop:'8px',float:'left'}}>Productos seleccionados</p>

			                        <div style={{borderRadius:'0px 0px 8px 8px',overflow:'hidden',width: '100%',margin:'auto'}}>
			                          <div id="tabla-actividades" style={{
			                              width: '100%',
			                              height: '350px', 
			                              overflowY: 'auto',
			                              overflowX: 'auto',
			                              backgroundColor: '#fff',
			                              margin: 'auto'
			                          }}>
			                            <RB.Table>
			                              <thead>
			                                 <tr style={{backgroundColor:'#fafafa'}}>
			                                    <th>Clave</th>
			                                    <th>Nombre</th>
			                                    <th>Tipo</th>
			                                    <th>Costo actual</th>
			                                    <th>Cantidad</th>
			                                    <th>Ingreso</th>
			                                    <th width="50px"></th>
			                                 </tr>
			                              </thead>
			                              <tbody id="body-tabla-actividades-asociados">
			                                <tr>
			                                  <td colSpan="7">
			                                    <center>
			                                      <p id="avisoSinAsociacionesActividad" style={{color: '#c8c8c8'}}>Ningún producto seleccionado</p>
			                                    </center>
			                                  </td>
			                                </tr>
			                              </tbody>
			                            </RB.Table>
			                          </div>
			                        </div>
			                    </div>
			                </div>
		                </div>

					</div>
				</div>
			</div>

		);
	}
}

export default ReporteIngresos;