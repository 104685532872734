import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import {auth} from '../firebase-config';
import {db} from '../firebase-config';
import logo_header from '../assets/images/logo_header.png';
import {Link} from 'react-router-dom';

class InicioTutor extends React.Component{
	//Se ejecuta al cargar componente
	componentDidMount() {
		//
	}
	render(){
		//-------------------------------------------------------------
		function cerrar_sesion() {
			//---------------------------------
			    auth.signOut().then(function() {
				  // Se cerró sesión
				  document.getElementById("to_IniciarSesion").click();
				}).catch(function(error) {
				  // Error al cerrar sesión
				  alert("Error al cerrar sesión");
				});
			//---------------------------------
		}
		//Cambiar título de la página
		window.onload = cambiar_titulo_tut();
		function cambiar_titulo_tut() {
			document.title ="Chiquifit - Tutor";
			verificar_sesion_tut();
		}
		function verificar_sesion_tut(){
			//---------------------------------
			auth.onAuthStateChanged(function(user) {
			  if (user) {
			    // Existe una sesión activa
			    //------------------------------------------------------
			    //Verifica sí es de administrador-----------------------
			    //------------------------------------------------------
			    	var tipo_cuenta = "";
			    	var user = auth.currentUser;
					var uid = user.uid;
					db.collection("usuarios").where("id_usuario", "==", uid)
				    .get()
				    .then(function(querySnapshot) {
				        querySnapshot.forEach(function(doc) {
				            tipo_cuenta = doc.data().tipo_usuario;
				            //-------------------------------------------------------------------------
							switch(tipo_cuenta){
								case "alumno": {document.getElementById("to_Alumno").click();}break;
								case "administrador": {document.getElementById("to_Administrador").click();}break;
								case "instructor": {document.getElementById("to_Instructor").click();}break;
								case "responsable": {document.getElementById("to_Responsable").click();}break;
								case "tutor": {/*SE MANTIENE EN LA PÁGINA*/}break;
								default: {cerrar_sesion();}break;
							}
				            //-------------------------------------------------------------------------
				        });
				    })
				    .catch(function(error) {
				        //Error al leer tipo de sesión
				    });
			    //------------------------------------------------------
			    //------------------------------------------------------
			    //------------------------------------------------------
			  } else {
			  	//No hay una sesión activa
			    document.getElementById("to_IniciarSesion").click();
			  }

			});
			//---------------------------------
		}
		//-------------------------------------------------------------
		return(
			<React.Fragment>

			<header id="headerIA">
				<Link id="to_IniciarSesion" to="/"></Link>
				<Link id="to_Administrador" to="/administrador/inicio"></Link>
				<Link id="to_Alumno" to="/alumno/inicio"></Link>
				<Link id="to_Instructor" to="/instructor/inicio"></Link>
				<Link id="to_Responsable" to="/responsable/inicio"></Link>
				<Link id="to_Tutor" to="/tutor/inicio"><img src={logo_header} width="230"></img></Link>
		        <button className="btn btn-primary btn-block" id="botoncss1" onClick={cerrar_sesion}>Cerrar sesión</button>
		    </header>

			<div className="global-container">
				<div className="card login-form" id="cardFRA">
					<div className="card-body" id="tarjeta">
						<center><h3 className="card-title">Inicio</h3></center>
						<br></br>
						<div className="card-text">
							<center><p>PÁGINA PRINCIPAL TUTOR</p></center>
						</div>
					</div>
				</div>
			</div>

			<footer>
		        <center><p>Chiquifit© 2020</p></center>
		    </footer>

			</React.Fragment>
		);
	}
}

export default InicioTutor;