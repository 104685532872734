import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import * as RB from "react-bootstrap";
import {auth} from '../../firebase-config';
import {db} from '../../firebase-config';
import logo_header from '../../assets/images/logo_header.png';
import logout from '../../assets/images/logout.png';
import usuario from '../../assets/images/usuario.png';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useLocation,
  useParams
} from "react-router-dom";

import SeccionAlumnos from '../instructor/SeccionAlumnos';

class InicioInstructor extends React.Component{

	//constructor del componente
	constructor(props){
		super(props)
		//Estado inicial del componente
		this.state = {
			nombreUsuarioActual: ""
		}
	}

	//Se ejecuta al cargar componente
	componentDidMount() {
		//---------------------------------------------------------------------------------------------------------
			document.title ="Chiquifit - Instructor";//Se cambia el título de la página
			//************************************************************************************************
			//**********************************Se verifica el tipo de sesión*********************************
			//************************************************************************************************
			auth.onAuthStateChanged(user => {
			  if (user) {
			    // Existe una sesión activa
			    var tipo_cuenta = "";
			    var user = auth.currentUser;
					var uid = user.uid;
					db.collection("usuarios").where("id_usuario", "==", uid).get().then(querySnapshot => {
				    querySnapshot.forEach(doc => {
				    	tipo_cuenta = doc.data().tipo_usuario;
							switch(tipo_cuenta){
								case "alumno": {document.getElementById("to_Alumno").click();}break;
								case "administrador": {document.getElementById("to_Administrador").click();}break;
								case "instructor": {
									//SESIÓN DE INSTRUCTOR VÁLIDA
									db.collection("instructores").doc(uid).get().then(instructor => {
										var nombreInstructor = instructor.data().nombre+" "+instructor.data().apellidos;
										this.setState({nombreUsuarioActual: nombreInstructor});
									});
								}break;
								case "responsable": {document.getElementById("to_Responsable").click();}break;
								case "tutor": {document.getElementById("to_Tutor").click();}break;
								default: {
									//---------------------------------
									//Se cierra sesión
									auth.signOut().then(function() {
										document.getElementById("to_IniciarSesion").click();
									}).catch(function(error) {alert("Error al cerrar sesión");});
									//---------------------------------
								}break;
							}
				    });
				  }).catch(function(error) {/*Error al leer tipo de sesión*/});
			  } else {
			  	//No hay una sesión activa
			    document.getElementById("to_IniciarSesion").click();
			  }
			});
			//************************************************************************************************
			//************************************************************************************************
			//************************************************************************************************
		//---------------------------------------------------------------------------------------------------------
	}

	render(){
		//-------------------------------------------------------------
		function cerrar_sesion() {
			auth.signOut().then(function() {
				document.getElementById("to_IniciarSesion").click();//Se cerró sesión
			}).catch(function(error) {/*Error al cerrar sesión*/});
		}
		//-------------------------------------------------------------
		return(
			<React.Fragment>

				<Link id="to_IniciarSesion" to="/"></Link>
				<Link id="to_Alumno" to="/alumno/inicio"></Link>
				<Link id="to_Administrador" to="/administrador"></Link>
				<Link id="to_Responsable" to="/responsable/inicio"></Link>
				<Link id="to_Tutor" to="/tutor/inicio"></Link>

				<Link id="to_Alumnos" to="/instructor/alumnos"></Link>

		    <RB.Navbar collapseOnSelect expand="lg" variant="light" bg="white" style={{fontSize:'11pt',fontWeight:'500'}}>
			  <RB.Navbar.Brand className="mr-sm-5"><Link id="to_InicioInstructor" to="/instructor"><img src={logo_header} width="170" /></Link></RB.Navbar.Brand>
			  <RB.Navbar.Toggle aria-controls="responsive-navbar-nav" />
			  <RB.Navbar.Collapse id="responsive-navbar-nav">
			    <RB.Nav variant="tabs" defaultActiveKey="alumnos" style={{width:'100%'}}>
			    	<RB.Nav.Item>
			      	<RB.Nav.Link eventKey="misCursos" disabled style={{paddingLeft:'25px',paddingRight:'25px'}}><center>Mis cursos</center></RB.Nav.Link>
			      </RB.Nav.Item>
			      <RB.Nav.Item>
			      	<RB.Nav.Link eventKey="alumnos" onClick={() => document.getElementById('to_Alumnos').click()} style={{paddingLeft:'25px',paddingRight:'25px'}}><center>Alumnos</center></RB.Nav.Link>
			    	</RB.Nav.Item>
			    	<RB.Nav.Item>
			      	<RB.Nav.Link eventKey="reportes" disabled style={{paddingLeft:'25px',paddingRight:'25px'}}><center>Reportes</center></RB.Nav.Link>
			    	</RB.Nav.Item>
			    </RB.Nav>

			    <RB.Nav fill>
			    	<RB.NavDropdown alignRight title={<img src={usuario} width="27" style={{marginRight:'4px'}}/>} style={{padding:'4px'}}>
			    		<center><img src={usuario} width="60" style={{marginTop:'8px',marginBottom:'8px'}}/></center>
			    		<center><p style={{fontSize:'11pt',fontWeight:'700'}}>{this.state.nombreUsuarioActual}</p></center>
			    		<center><p style={{fontSize:'10pt',fontWeight:'400',color:'#b2b2b2',lineHeight:'0px',marginTop:'-8px'}}>Cuenta de instructor</p></center>

			    		<RB.NavDropdown.Divider />

			    		<p style={{fontSize:'12pt',fontWeight:'500',marginLeft:'12px',marginBottom:'0px'}}>Notificaciones</p>
			    		<RB.Table style={{marginBottom:'0px'}}>
			    			<tbody>
			    				<tr className="filaTabla filaTabla-hover">
			    					<td>Sin notificaciones recientes</td>
			    				</tr>
			    			</tbody>
			    		</RB.Table>
			    		<Link to="#" style={{float:'right',marginRight:'12px',fontSize:'10pt',marginBottom:'5px',color:'#17a2b8'}}>ver todo</Link>

			    		<RB.NavDropdown.Divider style={{clear:'both'}} />

			    		<p style={{fontSize:'12pt',fontWeight:'500',marginLeft:'12px',marginBottom:'0px'}}>Cuenta</p>
			    		<RB.NavDropdown.Item className="itemDropdown logout" style={{width:'350px'}}>Información</RB.NavDropdown.Item>
			    		<RB.NavDropdown.Item className="itemDropdown logout" style={{width:'350px'}}>Configuración</RB.NavDropdown.Item>
			    		
			    		<RB.NavDropdown.Divider />

			    		<RB.NavDropdown.Item className="itemDropdown logout" onClick={cerrar_sesion} style={{width:'350px'}}>
			    			<img src={logout} width="15" style={{marginRight:'5px',opacity:'0.5',marginTop:'-4px'}}/>Cerrar sesión
			    		</RB.NavDropdown.Item>
			      </RB.NavDropdown>
			    </RB.Nav>
			  </RB.Navbar.Collapse>
				</RB.Navbar>

				<div>
					<Switch>
					  <Route path="/instructor" exact children={<SeccionAlumnos />} />

					  <Route path="/instructor/alumnos" children={<SeccionAlumnos />} />
		      </Switch>
				</div>

			</React.Fragment>
		);
	}

}

export default InicioInstructor;