import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import {auth} from '../firebase-config';
import {db} from '../firebase-config';
import logo_header from '../assets/images/logo_header.png';
import {Link} from 'react-router-dom';
import * as RB from "react-bootstrap";
import swal from 'sweetalert2';
import firebase from 'firebase/app';
import 'firebase/firestore'

class RegistroProducto extends React.Component{
	//Se ejecuta al cargar componente
	componentDidMount() {
		//Al cargar sección se posiciona en el primer input y se selecciona un radio input
		document.getElementById("productoRA").click();
		document.getElementById("claveRA").focus();
	}
	render(){
		//-------------------------------------------------------------
		//-------------------------------------------------------------------------------------------------------------------------
		//--------------------------------------------------INICIAR REGISTRO-------------------------------------------------------
		//-------------------------------------------------------------------------------------------------------------------------
		const handleSubmit = e =>{
			e.preventDefault();//Evita la recarga de la página

			//***************************************************************************************
			//************************** VALIDACIÓN DE CAMPOS ***************************************
			//***************************************************************************************
			document.getElementById("alerta-RA").innerHTML = '';//Borra alertas pasadas
			var formulario_incorrecto = false;
			const aux_inputs = e.target.querySelectorAll('input');
			aux_inputs.forEach((input) => {
				/*switch(input.name){
					case "fechaVigencia":
					break;
				}*/
				if(formulario_incorrecto){
					document.getElementById("alerta-RA").innerHTML = '<p>Complete los campos correctamente</p>';
				}
			});
			//***************************************************************************************
			//***************************************************************************************
			//***************************************************************************************
			if(!formulario_incorrecto){
				//Alert "loading"----------------------
				const alert_cargando = swal.fire({
				  width: '300px',
				  allowOutsideClick: false,
				  showConfirmButton: false,
				  didOpen: () => {
				    swal.showLoading()
				  },
				  customClass: {
					   title: 'titulo-alert-loading',
				     actions: 'alert-loader'
					}
				})
				//-------------------------------------
				//-------------------------------------------------------------------------------------------------
				//Ahora se crea su documento en la colección "productos"-----------------------------------------
				//-------------------------------------------------------------------------------------------------
				var tipoRA = "";if(document.getElementById("productoRA").checked){tipoRA = "producto";}else{tipoRA = "servicio";}
				var fechaVigenciaRA;if(document.getElementById("servicioRA").checked){
					fechaVigenciaRA = firebase.firestore.Timestamp.fromDate(new Date(document.getElementById("fechaVigenciaRA").value.replace(/-/g, '\/')));
				}else{fechaVigenciaRA = null;}

				db.collection("productos").doc().set({
					clave: document.getElementById("claveRA").value.trim().toUpperCase(),
					costo: document.getElementById("costoRA").value,
					descripcion: document.getElementById("descripcionRA").value.trim().toUpperCase(),
					fechaVigencia: fechaVigenciaRA,
				    nombre: document.getElementById("nombreRA").value.trim().toUpperCase(),
				    tipo: tipoRA
				})
				.then(function() {
					//EL REGISTRO SE COMPLETÓ POR COMPLETO CON ÉXITO-------------
					  alert_cargando.close();
					  swal.fire({
					      title:"<p style='font-size: 13pt;font-weight:600;color: #282828;'>Producto registrado</p>",
					      icon: 'success',
					      iconColor: '#17a2b8',
					      width: '350px',
					      showConfirmButton: false,
					      timer: 2500
					    }).then((result) => {
						  	//Se regresa a inicio
					    	document.getElementById("boton_RA").click();
						});
				    //-----------------------------------------------------------
				})
				.catch(function(error) {
					alert_cargando.close();
				    //alerta por error
					document.getElementById("alerta-RA").innerHTML = 
					'<p">'+
						'Error al registrar: '+
						error+
					'</p>';
				});
				//-------------------------------------------------------------------------------------------------
				//-------------------------------------------------------------------------------------------------
				//-------------------------------------------------------------------------------------------------
			}
		}
		//-------------------------------------------------------------------------------------------------------------------------
		//-------------------------------------------------------------------------------------------------------------------------
		//-------------------------------------------------------------------------------------------------------------------------
		//*************************************************************************************************************************************
		//***************************************************VALIDACIÓN DE LOS DATOS***********************************************************
		//*************************************************************************************************************************************
		//Solo permite ingresar números
		const validarNumerico = (e) => {
     		if(isNaN(e.target.value+String.fromCharCode(e.charCode))){
        		e.preventDefault();
     		}
  		}
		//*************************************************************************************************************************************
		//*************************************************************************************************************************************
		//*************************************************************************************************************************************
		//CONTROL SELECCIÓN SERVICIO----------------------------------------------------------------------------------
		function changeCheckServicio(){
			if(document.getElementById("servicioRA").checked){
				document.getElementById("div-input-fechaVigencia").style.display = "block";
				document.getElementById("fechaVigenciaRA").required = true;
			} else {
				document.getElementById("div-input-fechaVigencia").style.display = "none";
				document.getElementById("fechaVigenciaRA").required = false;
			}
		}
		//------------------------------------------------------------------------------------------------------------
		return(
			<React.Fragment>

			<div className="global-container">
				<div className="card login-form" id="cardFRA" style={{ width: '800px', paddingTop:'10px' }}>
					<div className="card-body" id="tarjeta">
						<h4 className="card-title" style={{fontSize:'18pt',fontWeight:'500'}}>Crear registro de producto</h4>
						<div className="card-text" style={{marginTop:'40px'}}>
							<form autoComplete="off" onSubmit={handleSubmit} style={{margin:'0px',overflow:'hidden'}}>

								<div className="row">
									<div className="col-sm-12 col-md-6 col-lg-6">
										<div className="form-group input-field" style={{margin: 'auto'}}>
											<input className="input_correcto input" type="text" id="claveRA" name="clave" required />
											<label className="label" for="claveRA">Clave</label>
											<p id="error-claveRA"></p>
										</div>
									</div>

									<div className="col-sm-12 col-md-6 col-lg-6">
										<div className="form-group input-field" style={{margin: 'auto'}}>
											<input className="input_correcto input" type="text" id="nombreRA" name="nombre" required />
											<label className="label" for="nombreRA">Nombre</label>
											<p id="error-nombreRA"></p>
										</div>
									</div>

									<div className="col-sm-12 col-md-6 col-lg-6">
										<div className="form-group input-field" style={{margin: 'auto'}}>
											<input className="input_correcto input" type="text" id="descripcionRA" name="descripcion" required />
											<label className="label" for="descripcionRA">Descripción</label>
											<p id="error-descripcionRA"></p>
										</div>
									</div>

									<div className="col-sm-12 col-md-6 col-lg-6">
										<div className="form-group input-field" style={{margin: 'auto'}}>
											<input className="input_correcto input" type="text" id="costoRA" name="costo" onKeyPress={validarNumerico} required />
											<label className="label" for="costoRA">Costo</label>
											<p id="error-costoRA"></p>
										</div>
									</div>

									<div className="col-sm-12 col-md-6 col-lg-6">
										<div className="form-group input-field" style={{margin:'auto'}}>
											<div className="cont-grupo-radio">
											    <center>
											    	<input type="radio" name="tipo" id="productoRA" onClick={(e) => {changeCheckServicio();}} />
											    	<label for="productoRA" style={{marginLeft:'5px'}}>Producto</label>
											    	<input type="radio" name="tipo" id="servicioRA" style={{marginLeft:'20px'}} onClick={(e) => {changeCheckServicio();}} />
											    	<label for="servicioRA" style={{marginLeft:'5px'}}>Servicio</label>
											    </center>
											</div>
											<label className="label-grupo-radio">Tipo</label>
											<p id="error-tipoRA"></p>
										</div>
									</div>

									<div className="col-sm-12 col-md-6 col-lg-6">
										<div className="form-group input-field" id="div-input-fechaVigencia" style={{margin: 'auto',display: 'none'}}>
											<input className="input_correcto input" type="date" id="fechaVigenciaRA" name="fechaVigencia" />
											<label className="label" for="fechaVigenciaRA">Fecha de vigencia</label>
											<p id="error-fechaVigenciaRA"></p>
										</div>
									</div>
								</div>

								<div id="alerta-RA" style={{fontSize:'11pt',color:'#D45454',marginBottom:"10px",marginTop:"10px"}}></div>

								<RB.Button variant="info" id="boton1_RA" className="boton-presionado" type="submit" style={{float:'right',marginRight:'10px',width:'140px',fontSize:'10pt'}}>Registrar producto</RB.Button>

								<Link to="/administrador/productos" id="boton_RA" style={{float:'right',marginRight:'35px',lineHeight:'2.4',color:'#17a2b8',fontSize:'10pt',textDecoration:'none'}}>Cancelar</Link>
							</form>
						</div>
					</div>
				</div>
			</div>

			</React.Fragment>
		);
	}
}

export default RegistroProducto;