import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import {auth} from '../firebase-config';
import {auth_aux} from '../firebase-config';
import {db} from '../firebase-config';
import eliminar from '../assets/images/eliminar.png';
import lupa from '../assets/images/lupa.png';
import nuevo from '../assets/images/nuevo.png';
import asociar from '../assets/images/asociar.png';
import {Link} from 'react-router-dom';
import * as RB from "react-bootstrap";
import swal from 'sweetalert2';
import firebase from 'firebase/app';

class RegistroAlumno extends React.Component{
  //----------------------------------------------------------------------------------------------------------------------------------
  //constructor del componente
  constructor(props){
    super(props)
    //Estado inicial del componente
    this.state = {
      texto_direccion: "",
    }
  }
  //Se ejecuta al cargar componente
  componentDidMount() {
    //Manejo de dirección (address)
    this.handleScriptLoad();
    //Al cargar sección se posiciona en el primer input
    document.getElementById("nombreRA").focus();
  }
  //***********************************************************************************************************************************
  //************************************************ Objeto de información del registro ***********************************************
  //***********************************************************************************************************************************
  datosRegistro = {
    nombre: "",
    apellidos: "",
    genero: "",
    fechaNacimiento: "",
    fechaInscripcion: "",
    correoElectronico: "",
    constraseña: "",
    datosMedicos: {
      alergias: "",
      enfermedades: "",
      grupoSanguineo: "",
      limitacionesFisicas: "",
      medicamentos: "",
      tratamientoMedico: ""
    },
    tutores: [],
    contactosEmergencia: [],
    personasAutorizadas: [],
    actividades: [],
    productos: []
  }
  //***********************************************************************************************************************************
  //***********************************************************************************************************************************
  //***********************************************************************************************************************************
  //--------------------------------------------------------------------------------------------------------
  //--------------------------Objeto de información de ubicación (dirección)--------------------------------
  //--------------------------------------------------------------------------------------------------------
  locationInfo = {
    estado: "",
    municipio: "",
    colonia: "",
    codigoPostal: "",
    calle: "",
    numeroExterior: "",
    numeroInterior: "S/N",
    cadenaDireccion: "",
    lat: "",
    lng: "",
    direccion_correcta: false,
    reset: function() {
      this.estado = "";
      this.municipio = "";
      this.colonia = "";
      this.codigoPostal = "";
      this.calle = "";
      this.numeroExterior = "";
      this.numeroInterior = "S/N";
      this.cadenaDireccion = "";
      this.lat = "";
      this.lng = "";
    }
  }
  //--------------------------------------------------------------------------------------------------------
  //--------------------------------------------------------------------------------------------------------
  //--------------------------------------------------------------------------------------------------------
  //********************************************************************************
  //********************************************************************************
  //********************************************************************************
  //CONTROL DE MAPA (AUTOCOMPLETADO - DIRECCIÓN)
  handleScriptLoad = () => {
    let autoComplete = new window.google.maps.places.Autocomplete(
      document.getElementById("address"),
      { componentRestrictions: { country: "mx" } }
    );
    autoComplete.setFields(["address_components", "formatted_address", "geometry"]);
    autoComplete.addListener("place_changed", () => {
        //Segmentación de los resultados
        const place = autoComplete.getPlace();
        const aux_dir_texto = place.formatted_address;
        this.setState({texto_direccion: aux_dir_texto});
        var address = place.address_components;
        var lat = place.geometry.location.lat();
        var lng = place.geometry.location.lng();
        //Se reinicia el objeto de información de ubicación
        this.locationInfo.reset();
        //Se guardan de forma individual los componentes de la dirección
        for (var i = 0; i < address.length; i++) {
          var component = address[i].types[0];
          switch (component) {
            case "administrative_area_level_1":
              this.locationInfo.estado = address[i]["long_name"];
              break;
            case "locality":
              this.locationInfo.municipio = address[i]["short_name"];
              break;
            case "sublocality_level_1":
              this.locationInfo.colonia = address[i]["long_name"];
              break;
            case "postal_code":
              this.locationInfo.codigoPostal = address[i]["long_name"];
              break;
            case "route":
              this.locationInfo.calle = address[i]["long_name"];
              break;
            case "street_number":
              this.locationInfo.numeroExterior = address[i]["long_name"];
              break;
            default:
              break;
          }
        }
        this.locationInfo.lat = lat;
        this.locationInfo.lng = lng;
        this.locationInfo.cadenaDireccion = document.getElementById("address").value;
        //Vista previa del mapa
        var src =
            "https://maps.googleapis.com/maps/api/staticmap?center=" +
            lat +
            "," +
            lng +
            "&zoom=17&size=388x101&maptype=roadmap&markers=color:red%7C"+lat+","+lng+"&key=AIzaSyDOafd3wkkjh-8nt0kb98ieVx3kkgyyOYs",
        img = document.createElement("img");
        img.src = src;
        document.getElementById("js-preview-map").replaceChild(img, document.getElementById("js-preview-map").firstElementChild);
        this.locationInfo.direccion_correcta = true;
        document.getElementById("principalDirecciones").style.marginBottom = "77px";
        document.getElementById("address").classList.remove('input_incorrecto-address');
        document.getElementById("address").classList.add('input_correcto-address');
        document.getElementById("error-direccion").innerHTML = '';
        document.getElementById("boton_agregar_ni").style.display = "block";
        document.getElementById("numeroInteriorRA").value = "S/N";
    });
  }
  //********************************************************************************
  //********************************************************************************
  //********************************************************************************
  //----------------------------------------------------------------------------------------------------------------------------------
	render(){
    //-----------------------------------------------------------------------------------------------------------------------
    //------------------------------------------------ Registro Parte I -----------------------------------------------------
    //-----------------------------------------------------------------------------------------------------------------------
    const siguienteParteI = e =>{

      e.preventDefault();//Evita la recarga de la página

      //***************************************************************************************
      //************************** VALIDACIÓN DE CAMPOS ***************************************
      //***************************************************************************************
      document.getElementById("alerta-RA-parteI").innerHTML = '';//Borra alertas pasadas
      var formulario_incorrecto = false;
      if(!this.locationInfo.direccion_correcta){
        //Error en dirección
        formulario_incorrecto = true;
      }
      const aux_inputs = e.target.querySelectorAll('input');
      aux_inputs.forEach((input) => {
        switch(input.name){
          case "nombre":
            if(!expresiones.nombre.test(input.value)){
              //Error en el nombre
              formulario_incorrecto = true;
            }
          break;
          case "apellidos":
            if(!expresiones.apellidos.test(input.value)){
              //Error en los apellidos
              formulario_incorrecto = true;
            }
          break;
          case "correo":
            if(!expresiones.correo.test(input.value)){
              //Error en el correo
              formulario_incorrecto = true;
            }
          break;
          case "contraseña":
            if(!expresiones.contraseña.test(input.value)){
              //Error en la contraseña
              formulario_incorrecto = true;
            }
          break;
          case "confirmacionContraseña":
            if(document.getElementById("contraseñaRA").value!==document.getElementById("confirmacionContraseñaRA").value){
              //Error en la confirmación de contraseña
              formulario_incorrecto = true;
            }
          break;
          case "fechaNacimiento":
            const añoFechaIngresada = new Date(document.getElementById("fechaNacimientoRA").value).getFullYear();
            if(añoFechaIngresada<1930 || añoFechaIngresada>((new Date().getFullYear())-1)){
              //Error en la fecha de nacimiento
              formulario_incorrecto = true;
            }
          break;
        }
        if(formulario_incorrecto){
          document.getElementById("alerta-RA-parteI").innerHTML = '<p>Complete los campos correctamente</p>';
        }
      });
      //***************************************************************************************
      //***************************************************************************************
      //***************************************************************************************

      if(!formulario_incorrecto){

        //Guardado de los datos de la sección---------------------------------------------------------
        this.datosRegistro.nombre = document.getElementById("nombreRA").value;
        this.datosRegistro.apellidos = document.getElementById("apellidosRA").value;
        this.datosRegistro.genero = document.getElementById("generoRA").value;
        this.datosRegistro.fechaNacimiento = firebase.firestore.Timestamp.fromDate(new Date(document.getElementById("fechaNacimientoRA").value.replace(/-/g, '\/')));
        this.datosRegistro.fechaInscripcion = firebase.firestore.Timestamp.fromDate(new Date(document.getElementById("fechaInscripcionRA").value.replace(/-/g, '\/')));
        this.datosRegistro.correoElectronico = document.getElementById("correoRA").value;
        this.datosRegistro.constraseña = document.getElementById("contraseñaRA").value;
        //--------------------------------------------------------------------------------------------

        document.getElementById("subtitulo").innerHTML = "Datos médicos";
        document.getElementById("parteI").style.display = "none";
        document.getElementById("parteII").style.display = "block";

        //Alert "loading"----------------------
        const alert_cargando = swal.fire({
          width: '300px',
          allowOutsideClick: false,
          showConfirmButton: false,
          didOpen: () => {
            swal.showLoading()
          },
          customClass: {
             title: 'titulo-alert-loading',
             actions: 'alert-loader'
          },
          timer: 500
        }).then(() => {
          document.getElementById("grupoSanguineoRA").focus();
        })
        //-------------------------------------

      }

    }
    //-----------------------------------------------------------------------------------------------------------------------
    //-----------------------------------------------------------------------------------------------------------------------
    //-----------------------------------------------------------------------------------------------------------------------
    //-----------------------------------------------------------------------------------------------------------------------
    //--------------------------------------------------- REGISTRO PARTE II -------------------------------------------------
    //-----------------------------------------------------------------------------------------------------------------------
    function atrasParteII() {
      document.getElementById("subtitulo").innerHTML = "Datos del alumno";
      document.getElementById("parteI").style.display = "block";
      document.getElementById("parteII").style.display = "none";
    }
    const siguienteParteII = e =>{

      e.preventDefault();//Evita la recarga de la página

        //Guardado de los datos de la sección---------------------------------------------------------
        this.datosRegistro.datosMedicos.grupoSanguineo = document.getElementById("grupoSanguineoRA").value;
        this.datosRegistro.datosMedicos.medicamentos = document.getElementById("medicamentosRA").value;
        this.datosRegistro.datosMedicos.alergias = document.getElementById("alergiasRA").value;
        this.datosRegistro.datosMedicos.enfermedades = document.getElementById("enfermedadesRA").value;
        this.datosRegistro.datosMedicos.limitacionesFisicas = document.getElementById("limitacionesRA").value;
        this.datosRegistro.datosMedicos.tratamientoMedico = document.getElementById("tratamientosMedicosRA").value;
        //--------------------------------------------------------------------------------------------

        document.getElementById("subtitulo").innerHTML = "Padre o tutor";
        document.getElementById("parteI").style.display = "none";
        document.getElementById("parteII").style.display = "none";
        document.getElementById("parteIII").style.display = "block";

        //Alert "loading"----------------------
        const alert_cargando = swal.fire({
          width: '300px',
          allowOutsideClick: false,
          showConfirmButton: false,
          didOpen: () => {
            swal.showLoading()
          },
          customClass: {
             title: 'titulo-alert-loading',
             actions: 'alert-loader'
          },
          timer: 500
        }).then(() => {
          //
        })
        //-------------------------------------

    }
    //-----------------------------------------------------------------------------------------------------------------------
    //-----------------------------------------------------------------------------------------------------------------------
    //-----------------------------------------------------------------------------------------------------------------------
    //-----------------------------------------------------------------------------------------------------------------------
    //-------------------------------------------------- REGISTRO PARTE III -------------------------------------------------
    //-----------------------------------------------------------------------------------------------------------------------
    function atrasParteIII() {
      document.getElementById("subtitulo").innerHTML = "Datos médicos";
      document.getElementById("parteII").style.display = "block";
      document.getElementById("parteIII").style.display = "none";
    }
    const siguienteParteIII = e =>{

      e.preventDefault();//Evita la recarga de la página

      document.getElementById("alerta-RA-parteIII").innerHTML = '';//Borra alertas pasadas
      //Se verifica que exista al menos un tutor asociado
      if(this.datosRegistro.tutores.length == 0){
        //Aviso "Alumno debe tener al menos un tutor asociado"
        document.getElementById("alerta-RA-parteIII").innerHTML = '<p>Alumno debe tener al menos un tutor asociado</p>';
      } else {
        //*****************************************************************
        document.getElementById("subtitulo").innerHTML = "Contactos de emergencia";
        document.getElementById("parteII").style.display = "none";
        document.getElementById("parteIII").style.display = "none";
        document.getElementById("parteIV").style.display = "block";

        //Alert "loading"----------------------
        const alert_cargando = swal.fire({
          width: '300px',
          allowOutsideClick: false,
          showConfirmButton: false,
          didOpen: () => {
            swal.showLoading()
          },
          customClass: {
             title: 'titulo-alert-loading',
             actions: 'alert-loader'
          },
          timer: 500
        }).then(() => {
          //
        })
        //-------------------------------------
        //*****************************************************************
      }

    }
    //BÚSQUEDA DE TUTOR (CONSULTA FIRESTORE)***************************************************************
    const buscarTutor = (e) => {
      e.preventDefault();//Evita la recarga de la página
      var textoBusqueda = document.getElementById("busqueda-tutor").value;
      if(textoBusqueda !== ""){
        //------------------------------------------------------------------------------------------------------------
        //------------------------------------------------------------------------------------------------------------
        //------------------------------------------------------------------------------------------------------------
        db.collection("tutores").where("correoElectronico", "==", textoBusqueda.trim()).get().then(function(querySnapshot) {
            var contador = 0;
            document.getElementById("body-tabla-tutores-busqueda").innerHTML = "";
            querySnapshot.forEach(function(doc) {
                contador++;
                //*****************************************************************************************
                document.getElementById("body-tabla-tutores-busqueda").insertAdjacentHTML("beforeend", 
                "<tr class='filaTabla filaTabla-hover'>"+
                  "<td>"+doc.data().nombre+"</td>"+
                  "<td>"+doc.data().apellidos+"</td>"+
                  "<td>"+
                    "<div class='toggle-botonTabla' id='"+doc.id+"busqueda-III"+"' title='Asociar' style='margin-right:15px'>"+
                      "<img class='botonTabla' src="+asociar+" width='17px' />"+
                    "</div>"+
                  "</td>"+
                "</tr>");
                //Evento de la fila (onclick en asociar)----------------------------------------
                document.getElementById(doc.id+"busqueda-III").addEventListener('click', (e) => {
                  asociarTutor(doc.id, doc.data().nombre, doc.data().apellidos);
                });
                //------------------------------------------------------------------------------
                //*****************************************************************************************
            });
            if(contador == 0){
              //No hubo resultados para correoElectronico
              //------------------------------------------------------------------------------------------------------------
              //------------------------------------------------------------------------------------------------------------
              //------------------------------------------------------------------------------------------------------------
              db.collection("tutores").where("telefono", "==", textoBusqueda.trim()).get().then(function(querySnapshot) {
                  document.getElementById("body-tabla-tutores-busqueda").innerHTML = "";
                  querySnapshot.forEach(function(doc) {
                      contador++;
                      //*****************************************************************************************
                      document.getElementById("body-tabla-tutores-busqueda").insertAdjacentHTML("beforeend", 
                      "<tr class='filaTabla filaTabla-hover'>"+
                        "<td>"+doc.data().nombre+"</td>"+
                        "<td>"+doc.data().apellidos+"</td>"+
                        "<td>"+
                          "<div class='toggle-botonTabla' id='"+doc.id+"busqueda-III"+"' title='Asociar' style='margin-right:15px'>"+
                            "<img class='botonTabla' src="+asociar+" width='17px' />"+
                          "</div>"+
                        "</td>"+
                      "</tr>");
                      //Evento de la fila (onclick en asociar)----------------------------------------
                      document.getElementById(doc.id+"busqueda-III").addEventListener('click', (e) => {
                        asociarTutor(doc.id, doc.data().nombre, doc.data().apellidos);
                      });
                      //------------------------------------------------------------------------------
                      //*****************************************************************************************
                  });
                  if(contador == 0){
                    //No hubo resultados para telefono
                    //------------------------------------------------------------------------------------------------------------
                    //------------------------------------------------------------------------------------------------------------
                    //------------------------------------------------------------------------------------------------------------
                    db.collection("tutores").where("apellidos", "==", textoBusqueda.trim().toUpperCase()).get().then(function(querySnapshot) {
                        document.getElementById("body-tabla-tutores-busqueda").innerHTML = "";
                        querySnapshot.forEach(function(doc) {
                            contador++;
                            //*****************************************************************************************
                            document.getElementById("body-tabla-tutores-busqueda").insertAdjacentHTML("beforeend", 
                            "<tr class='filaTabla filaTabla-hover'>"+
                              "<td>"+doc.data().nombre+"</td>"+
                              "<td>"+doc.data().apellidos+"</td>"+
                              "<td>"+
                                "<div class='toggle-botonTabla' id='"+doc.id+"busqueda-III"+"' title='Asociar' style='margin-right:15px'>"+
                                  "<img class='botonTabla' src="+asociar+" width='17px' />"+
                                "</div>"+
                              "</td>"+
                            "</tr>");
                            //Evento de la fila (onclick en asociar)----------------------------------------
                            document.getElementById(doc.id+"busqueda-III").addEventListener('click', (e) => {
                              asociarTutor(doc.id, doc.data().nombre, doc.data().apellidos);
                            });
                            //------------------------------------------------------------------------------
                            //*****************************************************************************************
                        });
                        if(contador == 0){
                          //No hubo resultados para apellidos
                          //------------------------------------------------------------------------------------------------------------
                          //------------------------------------------------------------------------------------------------------------
                          //------------------------------------------------------------------------------------------------------------
                          db.collection("tutores").where("nombre", "==", textoBusqueda.trim().toUpperCase()).get().then(function(querySnapshot) {
                              document.getElementById("body-tabla-tutores-busqueda").innerHTML = "";
                              querySnapshot.forEach(function(doc) {
                                  contador++;
                                  //*****************************************************************************************
                                  document.getElementById("body-tabla-tutores-busqueda").insertAdjacentHTML("beforeend", 
                                  "<tr class='filaTabla filaTabla-hover'>"+
                                    "<td>"+doc.data().nombre+"</td>"+
                                    "<td>"+doc.data().apellidos+"</td>"+
                                    "<td>"+
                                      "<div class='toggle-botonTabla' id='"+doc.id+"busqueda-III"+"' title='Asociar' style='margin-right:15px'>"+
                                        "<img class='botonTabla' src="+asociar+" width='17px' />"+
                                      "</div>"+
                                    "</td>"+
                                  "</tr>");
                                  //Evento de la fila (onclick en asociar)----------------------------------------
                                  document.getElementById(doc.id+"busqueda-III").addEventListener('click', (e) => {
                                    asociarTutor(doc.id, doc.data().nombre, doc.data().apellidos);
                                  });
                                  //------------------------------------------------------------------------------
                                  //*****************************************************************************************
                              });
                              if(contador == 0){
                                //No hubo resultados para nombre (NINGÚN RESULTADO PARA LA BÚSQUEDA)
                                document.getElementById("body-tabla-tutores-busqueda").innerHTML = 
                                "<tr>"+
                                  "<td colSpan='3'>"+
                                    "<center>"+
                                      "<p id='avisoSinResultadosBusquedaTutor' style='color:#c8c8c8'>No hay resultados a mostrar</p>"+
                                    "</center>"+
                                  "</td>"+
                                "</tr>";
                              }
                          }).catch(function(error) {
                              //Error al realizar consulta
                          });
                          //------------------------------------------------------------------------------------------------------------
                          //------------------------------------------------------------------------------------------------------------
                          //------------------------------------------------------------------------------------------------------------
                        }
                    }).catch(function(error) {
                        //Error al realizar consulta
                    });
                    //------------------------------------------------------------------------------------------------------------
                    //------------------------------------------------------------------------------------------------------------
                    //------------------------------------------------------------------------------------------------------------
                  }
              }).catch(function(error) {
                  //Error al realizar consulta
              });
              //------------------------------------------------------------------------------------------------------------
              //------------------------------------------------------------------------------------------------------------
              //------------------------------------------------------------------------------------------------------------
            }
        }).catch(function(error) {
            //Error al realizar consulta
        });
        //------------------------------------------------------------------------------------------------------------
        //------------------------------------------------------------------------------------------------------------
        //------------------------------------------------------------------------------------------------------------
      }
    }
    //*****************************************************************************************************
    //******************************************* ASOCIAR TUTOR *************************************************
    const asociarTutor = (uid, nombre, apellidos) => {
      //Sí la tabla está vacía, se elimina el mensaje de "Ningún tutor asociado"---
      if(this.datosRegistro.tutores.length == 0){
        document.getElementById("body-tabla-tutores-asociados").innerHTML = "";
      }
      //---------------------------------------------------------------------------

      //Se guarda en array "tutores" la referencia------------------------------------------------------------------------
      //Sí ya existe el valor que se intenta guardar, se omite
      var aux = db.collection('tutores').doc(uid);
      if(!(this.datosRegistro.tutores.find(element => element.id == aux.id) !== undefined)){
        this.datosRegistro.tutores.push(aux);//Guardado en el array "tutores"
        this.datosRegistro.contactosEmergencia.push(aux);//Guardado en el array "contactosEmergencia"
        this.datosRegistro.personasAutorizadas.push(aux);//Guardado en el array "personasAutorizadas"
        //Mostrar en Front (Tabla de asociación)----------------------------------------------------------------------------
        //*****************************************************************************************
        document.getElementById("body-tabla-tutores-asociados").insertAdjacentHTML("beforeend", 
        "<tr class='filaTabla filaTabla-hover' id='"+uid+"asociado-fila-III'>"+
          "<td>"+nombre+"</td>"+
          "<td>"+apellidos+"</td>"+
          "<td>"+
            "<div class='toggle-botonTabla' id='"+uid+"asociado-III"+"' title='Desasociar' style='margin-right:15px'>"+
              "<img class='botonTabla' src="+eliminar+" width='17px' />"+
            "</div>"+
          "</td>"+
        "</tr>");
        document.getElementById("body-tabla-contactos-asociados").insertAdjacentHTML("beforeend", 
        "<tr class='filaTabla filaTabla-hover' id='"+uid+"asociado-fila-IV'>"+
          "<td>"+nombre+"</td>"+
          "<td>"+apellidos+"</td>"+
          "<td>(tutor)</td>"+
        "</tr>");
        document.getElementById("body-tabla-autorizadas-asociados").insertAdjacentHTML("beforeend", 
        "<tr class='filaTabla filaTabla-hover' id='"+uid+"asociado-fila-V'>"+
          "<td>"+nombre+"</td>"+
          "<td>"+apellidos+"</td>"+
          "<td>(tutor)</td>"+
        "</tr>");
        //Evento de la fila (onclick en desasociar)----------------------------------------
        document.getElementById(uid+"asociado-III").addEventListener('click', (e) => {
          //--------------------------------------------------------------------------------
          //------------------------------- Desasociar tutor -------------------------------
          //--------------------------------------------------------------------------------
          //Se elimina de la tabla (Front)
          document.getElementById("body-tabla-tutores-asociados").removeChild(document.getElementById(uid+"asociado-fila-III"));
          document.getElementById("body-tabla-contactos-asociados").removeChild(document.getElementById(uid+"asociado-fila-IV"));
          document.getElementById("body-tabla-autorizadas-asociados").removeChild(document.getElementById(uid+"asociado-fila-V"));
          //Se elimina del array "tutores", "contactosEmergencia" y "personasAutorizadas"
          this.datosRegistro.tutores.splice(this.datosRegistro.tutores.indexOf(this.datosRegistro.tutores.find(element => element.id == aux.id)), 1);
          this.datosRegistro.contactosEmergencia.splice(this.datosRegistro.contactosEmergencia.indexOf(this.datosRegistro.contactosEmergencia.find(element => element.id == aux.id)), 1);
          this.datosRegistro.personasAutorizadas.splice(this.datosRegistro.personasAutorizadas.indexOf(this.datosRegistro.personasAutorizadas.find(element => element.id == aux.id)), 1);
          //Sí la tabla se quedó sin elementos
          if(this.datosRegistro.tutores.length == 0) {
            document.getElementById("body-tabla-tutores-asociados").innerHTML = 
            "<tr>"+
              "<td colSpan='3'>"+
                "<center>"+
                  "<p id='avisoSinAsociacionesTutor' style='color:#c8c8c8'>Ningún tutor asociado</p>"+
                "</center>"+
              "</td>"+
            "</tr>";
          }
          //--------------------------------------------------------------------------------
          //--------------------------------------------------------------------------------
          //--------------------------------------------------------------------------------
        });
        //------------------------------------------------------------------------------
        //*****************************************************************************************
        //-----------------------------------------------------------------------------------------------------------------
      }
      //------------------------------------------------------------------------------------------------------------------
    }
    //***********************************************************************************************************
    //-----------------------------------------------------------------------------------------------------------------------
    //-----------------------------------------------------------------------------------------------------------------------
    //-----------------------------------------------------------------------------------------------------------------------
    //-----------------------------------------------------------------------------------------------------------------------
    //--------------------------------------------------- REGISTRO PARTE IV -------------------------------------------------
    //-----------------------------------------------------------------------------------------------------------------------
    function atrasParteIV() {
      document.getElementById("subtitulo").innerHTML = "Padre o tutor";
      document.getElementById("parteIII").style.display = "block";
      document.getElementById("parteIV").style.display = "none";
    }
    const siguienteParteIV = e =>{

      e.preventDefault();//Evita la recarga de la página

      document.getElementById("alerta-RA-parteIV").innerHTML = '';//Borra alertas pasadas
      //Se verifica que exista al menos un contacto de emergencia asociado
      if(this.datosRegistro.contactosEmergencia.length == 0){
        //Aviso "Alumno debe tener al menos un contacto de emergencia asociado"
        document.getElementById("alerta-RA-parteIV").innerHTML = '<p>Alumno debe tener al menos un contacto de emergencia asociado</p>';
      } else {
        //*****************************************************************
        document.getElementById("subtitulo").innerHTML = "Personas autorizadas a recoger al alumno";
        document.getElementById("parteII").style.display = "none";
        document.getElementById("parteIII").style.display = "none";
        document.getElementById("parteIV").style.display = "none";
        document.getElementById("parteV").style.display = "block";

        //Alert "loading"----------------------
        const alert_cargando = swal.fire({
          width: '300px',
          allowOutsideClick: false,
          showConfirmButton: false,
          didOpen: () => {
            swal.showLoading()
          },
          customClass: {
             title: 'titulo-alert-loading',
             actions: 'alert-loader'
          },
          timer: 500
        }).then(() => {
          //
        })
        //-------------------------------------
        //*****************************************************************
      }

    }
    //BÚSQUEDA DE RESPONSABLES [CONTACTO] (CONSULTA FIRESTORE)***********************************************
    const buscarContacto = (e) => {
      e.preventDefault();//Evita la recarga de la página
      var textoBusqueda = document.getElementById("busqueda-contacto").value;
      if(textoBusqueda !== ""){
        //------------------------------------------------------------------------------------------------------------
        //------------------------------------------------------------------------------------------------------------
        //------------------------------------------------------------------------------------------------------------
        db.collection("responsables").where("correoElectronico", "==", textoBusqueda.trim()).get().then(function(querySnapshot) {
            var contador = 0;
            document.getElementById("body-tabla-contactos-busqueda").innerHTML = "";
            querySnapshot.forEach(function(doc) {
                contador++;
                //*****************************************************************************************
                document.getElementById("body-tabla-contactos-busqueda").insertAdjacentHTML("beforeend", 
                "<tr class='filaTabla filaTabla-hover'>"+
                  "<td>"+doc.data().nombre+"</td>"+
                  "<td>"+doc.data().apellidos+"</td>"+
                  "<td>"+
                    "<div class='toggle-botonTabla' id='"+doc.id+"busqueda-IV"+"' title='Asociar' style='margin-right:15px'>"+
                      "<img class='botonTabla' src="+asociar+" width='17px' />"+
                    "</div>"+
                  "</td>"+
                "</tr>");
                //Evento de la fila (onclick en asociar)----------------------------------------
                document.getElementById(doc.id+"busqueda-IV").addEventListener('click', (e) => {
                  asociarContacto(doc.id, doc.data().nombre, doc.data().apellidos);
                });
                //------------------------------------------------------------------------------
                //*****************************************************************************************
            });
            if(contador == 0){
              //No hubo resultados para correoElectronico
              //------------------------------------------------------------------------------------------------------------
              //------------------------------------------------------------------------------------------------------------
              //------------------------------------------------------------------------------------------------------------
              db.collection("responsables").where("telefono", "==", textoBusqueda.trim()).get().then(function(querySnapshot) {
                  document.getElementById("body-tabla-contactos-busqueda").innerHTML = "";
                  querySnapshot.forEach(function(doc) {
                      contador++;
                      //*****************************************************************************************
                      document.getElementById("body-tabla-contactos-busqueda").insertAdjacentHTML("beforeend", 
                      "<tr class='filaTabla filaTabla-hover'>"+
                        "<td>"+doc.data().nombre+"</td>"+
                        "<td>"+doc.data().apellidos+"</td>"+
                        "<td>"+
                          "<div class='toggle-botonTabla' id='"+doc.id+"busqueda-IV"+"' title='Asociar' style='margin-right:15px'>"+
                            "<img class='botonTabla' src="+asociar+" width='17px' />"+
                          "</div>"+
                        "</td>"+
                      "</tr>");
                      //Evento de la fila (onclick en asociar)----------------------------------------
                      document.getElementById(doc.id+"busqueda-IV").addEventListener('click', (e) => {
                        asociarContacto(doc.id, doc.data().nombre, doc.data().apellidos);
                      });
                      //------------------------------------------------------------------------------
                      //*****************************************************************************************
                  });
                  if(contador == 0){
                    //No hubo resultados para telefono
                    //------------------------------------------------------------------------------------------------------------
                    //------------------------------------------------------------------------------------------------------------
                    //------------------------------------------------------------------------------------------------------------
                    db.collection("responsables").where("apellidos", "==", textoBusqueda.trim().toUpperCase()).get().then(function(querySnapshot) {
                        document.getElementById("body-tabla-contactos-busqueda").innerHTML = "";
                        querySnapshot.forEach(function(doc) {
                            contador++;
                            //*****************************************************************************************
                            document.getElementById("body-tabla-contactos-busqueda").insertAdjacentHTML("beforeend", 
                            "<tr class='filaTabla filaTabla-hover'>"+
                              "<td>"+doc.data().nombre+"</td>"+
                              "<td>"+doc.data().apellidos+"</td>"+
                              "<td>"+
                                "<div class='toggle-botonTabla' id='"+doc.id+"busqueda-IV"+"' title='Asociar' style='margin-right:15px'>"+
                                  "<img class='botonTabla' src="+asociar+" width='17px' />"+
                                "</div>"+
                              "</td>"+
                            "</tr>");
                            //Evento de la fila (onclick en asociar)----------------------------------------
                            document.getElementById(doc.id+"busqueda-IV").addEventListener('click', (e) => {
                              asociarContacto(doc.id, doc.data().nombre, doc.data().apellidos);
                            });
                            //------------------------------------------------------------------------------
                            //*****************************************************************************************
                        });
                        if(contador == 0){
                          //No hubo resultados para apellidos
                          //------------------------------------------------------------------------------------------------------------
                          //------------------------------------------------------------------------------------------------------------
                          //------------------------------------------------------------------------------------------------------------
                          db.collection("responsables").where("nombre", "==", textoBusqueda.trim().toUpperCase()).get().then(function(querySnapshot) {
                              document.getElementById("body-tabla-contactos-busqueda").innerHTML = "";
                              querySnapshot.forEach(function(doc) {
                                  contador++;
                                  //*****************************************************************************************
                                  document.getElementById("body-tabla-contactos-busqueda").insertAdjacentHTML("beforeend", 
                                  "<tr class='filaTabla filaTabla-hover'>"+
                                    "<td>"+doc.data().nombre+"</td>"+
                                    "<td>"+doc.data().apellidos+"</td>"+
                                    "<td>"+
                                      "<div class='toggle-botonTabla' id='"+doc.id+"busqueda-IV"+"' title='Asociar' style='margin-right:15px'>"+
                                        "<img class='botonTabla' src="+asociar+" width='17px' />"+
                                      "</div>"+
                                    "</td>"+
                                  "</tr>");
                                  //Evento de la fila (onclick en asociar)----------------------------------------
                                  document.getElementById(doc.id+"busqueda-IV").addEventListener('click', (e) => {
                                    asociarContacto(doc.id, doc.data().nombre, doc.data().apellidos);
                                  });
                                  //------------------------------------------------------------------------------
                                  //*****************************************************************************************
                              });
                              if(contador == 0){
                                //No hubo resultados para nombre (NINGÚN RESULTADO PARA LA BÚSQUEDA)
                                document.getElementById("body-tabla-contactos-busqueda").innerHTML = 
                                "<tr>"+
                                  "<td colSpan='3'>"+
                                    "<center>"+
                                      "<p id='avisoSinResultadosBusquedaContacto' style='color:#c8c8c8'>No hay resultados a mostrar</p>"+
                                    "</center>"+
                                  "</td>"+
                                "</tr>";
                              }
                          }).catch(function(error) {
                              //Error al realizar consulta
                          });
                          //------------------------------------------------------------------------------------------------------------
                          //------------------------------------------------------------------------------------------------------------
                          //------------------------------------------------------------------------------------------------------------
                        }
                    }).catch(function(error) {
                        //Error al realizar consulta
                    });
                    //------------------------------------------------------------------------------------------------------------
                    //------------------------------------------------------------------------------------------------------------
                    //------------------------------------------------------------------------------------------------------------
                  }
              }).catch(function(error) {
                  //Error al realizar consulta
              });
              //------------------------------------------------------------------------------------------------------------
              //------------------------------------------------------------------------------------------------------------
              //------------------------------------------------------------------------------------------------------------
            }
        }).catch(function(error) {
            //Error al realizar consulta
        });
        //------------------------------------------------------------------------------------------------------------
        //------------------------------------------------------------------------------------------------------------
        //------------------------------------------------------------------------------------------------------------
      }
    }
    //*****************************************************************************************************
    //******************************************* ASOCIAR CONTACTO **********************************************
    const asociarContacto = (uid, nombre, apellidos) => {

      //Se guarda en array "contactosEmergencia" la referencia------------------------------------------------------------
      //Sí ya existe el valor que se intenta guardar, se omite
      var aux = db.collection('responsables').doc(uid);
      if(!(this.datosRegistro.contactosEmergencia.find(element => element.id == aux.id) !== undefined)){
        this.datosRegistro.contactosEmergencia.push(aux);//Guardado en el array "contactosEmergencia"
        //Mostrar en Front (Tabla de asociación)----------------------------------------------------------------------------
        //*****************************************************************************************
        document.getElementById("body-tabla-contactos-asociados").insertAdjacentHTML("beforeend", 
        "<tr class='filaTabla filaTabla-hover' id='"+uid+"asociado-fila-IV'>"+
          "<td>"+nombre+"</td>"+
          "<td>"+apellidos+"</td>"+
          "<td>"+
            "<div class='toggle-botonTabla' id='"+uid+"asociado-IV"+"' title='Desasociar' style='margin-right:15px'>"+
              "<img class='botonTabla' src="+eliminar+" width='17px' />"+
            "</div>"+
          "</td>"+
        "</tr>");
        //Evento de la fila (onclick en desasociar)----------------------------------------
        document.getElementById(uid+"asociado-IV").addEventListener('click', (e) => {
          //--------------------------------------------------------------------------------
          //----------------------------- Desasociar contacto ------------------------------
          //--------------------------------------------------------------------------------
          //Se elimina de la tabla (Front)
          document.getElementById("body-tabla-contactos-asociados").removeChild(document.getElementById(uid+"asociado-fila-IV"));
          //Se elimina del array "contactosEmergencia"
          this.datosRegistro.contactosEmergencia.splice(this.datosRegistro.contactosEmergencia.indexOf(this.datosRegistro.contactosEmergencia.find(element => element.id == aux.id)), 1);
          //--------------------------------------------------------------------------------
          //--------------------------------------------------------------------------------
          //--------------------------------------------------------------------------------
        });
        //------------------------------------------------------------------------------
        //*****************************************************************************************
        //-----------------------------------------------------------------------------------------------------------------
      }
      //------------------------------------------------------------------------------------------------------------------
    }
    //***********************************************************************************************************
    //-----------------------------------------------------------------------------------------------------------------------
    //-----------------------------------------------------------------------------------------------------------------------
    //-----------------------------------------------------------------------------------------------------------------------
    //-----------------------------------------------------------------------------------------------------------------------
    //---------------------------------------------------- REGISTRO PARTE V -------------------------------------------------
    //-----------------------------------------------------------------------------------------------------------------------
    function atrasParteV() {
      document.getElementById("subtitulo").innerHTML = "Contactos de emergencia";
      document.getElementById("parteIV").style.display = "block";
      document.getElementById("parteV").style.display = "none";
    }
    const siguienteParteV = e =>{

      e.preventDefault();//Evita la recarga de la página

      document.getElementById("alerta-RA-parteV").innerHTML = '';//Borra alertas pasadas
      //Se verifica que exista al menos una persona autorizada
      if(this.datosRegistro.personasAutorizadas.length == 0){
        //Aviso "Alumno debe tener al menos una persona autorizada asociada"
        document.getElementById("alerta-RA-parteV").innerHTML = '<p>Alumno debe tener al menos una persona autorizada asociada</p>';
      } else {
        //*****************************************************************
        document.getElementById("subtitulo").innerHTML = "Cursos inscritos";
        document.getElementById("parteIII").style.display = "none";
        document.getElementById("parteIV").style.display = "none";
        document.getElementById("parteV").style.display = "none";
        document.getElementById("parteVI").style.display = "block";

        //Alert "loading"----------------------
        const alert_cargando = swal.fire({
          width: '300px',
          allowOutsideClick: false,
          showConfirmButton: false,
          didOpen: () => {
            swal.showLoading()
          },
          customClass: {
             title: 'titulo-alert-loading',
             actions: 'alert-loader'
          },
          timer: 500
        }).then(() => {
          //
        })
        //-------------------------------------
        //*****************************************************************
      }

    }
    //BÚSQUEDA DE RESPONSABLES [PERSONAS AUTORIZADAS] (CONSULTA FIRESTORE)***********************************
    const buscarAutorizada = (e) => {
      e.preventDefault();//Evita la recarga de la página
      var textoBusqueda = document.getElementById("busqueda-autorizada").value;
      if(textoBusqueda !== ""){
        //------------------------------------------------------------------------------------------------------------
        //------------------------------------------------------------------------------------------------------------
        //------------------------------------------------------------------------------------------------------------
        db.collection("responsables").where("correoElectronico", "==", textoBusqueda.trim()).get().then(function(querySnapshot) {
            var contador = 0;
            document.getElementById("body-tabla-autorizadas-busqueda").innerHTML = "";
            querySnapshot.forEach(function(doc) {
                contador++;
                //*****************************************************************************************
                document.getElementById("body-tabla-autorizadas-busqueda").insertAdjacentHTML("beforeend", 
                "<tr class='filaTabla filaTabla-hover'>"+
                  "<td>"+doc.data().nombre+"</td>"+
                  "<td>"+doc.data().apellidos+"</td>"+
                  "<td>"+
                    "<div class='toggle-botonTabla' id='"+doc.id+"busqueda-V"+"' title='Asociar' style='margin-right:15px'>"+
                      "<img class='botonTabla' src="+asociar+" width='17px' />"+
                    "</div>"+
                  "</td>"+
                "</tr>");
                //Evento de la fila (onclick en asociar)----------------------------------------
                document.getElementById(doc.id+"busqueda-V").addEventListener('click', (e) => {
                  asociarAutorizada(doc.id, doc.data().nombre, doc.data().apellidos);
                });
                //------------------------------------------------------------------------------
                //*****************************************************************************************
            });
            if(contador == 0){
              //No hubo resultados para correoElectronico
              //------------------------------------------------------------------------------------------------------------
              //------------------------------------------------------------------------------------------------------------
              //------------------------------------------------------------------------------------------------------------
              db.collection("responsables").where("telefono", "==", textoBusqueda.trim()).get().then(function(querySnapshot) {
                  document.getElementById("body-tabla-autorizadas-busqueda").innerHTML = "";
                  querySnapshot.forEach(function(doc) {
                      contador++;
                      //*****************************************************************************************
                      document.getElementById("body-tabla-autorizadas-busqueda").insertAdjacentHTML("beforeend", 
                      "<tr class='filaTabla filaTabla-hover'>"+
                        "<td>"+doc.data().nombre+"</td>"+
                        "<td>"+doc.data().apellidos+"</td>"+
                        "<td>"+
                          "<div class='toggle-botonTabla' id='"+doc.id+"busqueda-V"+"' title='Asociar' style='margin-right:15px'>"+
                            "<img class='botonTabla' src="+asociar+" width='17px' />"+
                          "</div>"+
                        "</td>"+
                      "</tr>");
                      //Evento de la fila (onclick en asociar)----------------------------------------
                      document.getElementById(doc.id+"busqueda-V").addEventListener('click', (e) => {
                        asociarAutorizada(doc.id, doc.data().nombre, doc.data().apellidos);
                      });
                      //------------------------------------------------------------------------------
                      //*****************************************************************************************
                  });
                  if(contador == 0){
                    //No hubo resultados para telefono
                    //------------------------------------------------------------------------------------------------------------
                    //------------------------------------------------------------------------------------------------------------
                    //------------------------------------------------------------------------------------------------------------
                    db.collection("responsables").where("apellidos", "==", textoBusqueda.trim().toUpperCase()).get().then(function(querySnapshot) {
                        document.getElementById("body-tabla-autorizadas-busqueda").innerHTML = "";
                        querySnapshot.forEach(function(doc) {
                            contador++;
                            //*****************************************************************************************
                            document.getElementById("body-tabla-autorizadas-busqueda").insertAdjacentHTML("beforeend", 
                            "<tr class='filaTabla filaTabla-hover'>"+
                              "<td>"+doc.data().nombre+"</td>"+
                              "<td>"+doc.data().apellidos+"</td>"+
                              "<td>"+
                                "<div class='toggle-botonTabla' id='"+doc.id+"busqueda-V"+"' title='Asociar' style='margin-right:15px'>"+
                                  "<img class='botonTabla' src="+asociar+" width='17px' />"+
                                "</div>"+
                              "</td>"+
                            "</tr>");
                            //Evento de la fila (onclick en asociar)----------------------------------------
                            document.getElementById(doc.id+"busqueda-V").addEventListener('click', (e) => {
                              asociarAutorizada(doc.id, doc.data().nombre, doc.data().apellidos);
                            });
                            //------------------------------------------------------------------------------
                            //*****************************************************************************************
                        });
                        if(contador == 0){
                          //No hubo resultados para apellidos
                          //------------------------------------------------------------------------------------------------------------
                          //------------------------------------------------------------------------------------------------------------
                          //------------------------------------------------------------------------------------------------------------
                          db.collection("responsables").where("nombre", "==", textoBusqueda.trim().toUpperCase()).get().then(function(querySnapshot) {
                              document.getElementById("body-tabla-autorizadas-busqueda").innerHTML = "";
                              querySnapshot.forEach(function(doc) {
                                  contador++;
                                  //*****************************************************************************************
                                  document.getElementById("body-tabla-autorizadas-busqueda").insertAdjacentHTML("beforeend", 
                                  "<tr class='filaTabla filaTabla-hover'>"+
                                    "<td>"+doc.data().nombre+"</td>"+
                                    "<td>"+doc.data().apellidos+"</td>"+
                                    "<td>"+
                                      "<div class='toggle-botonTabla' id='"+doc.id+"busqueda-V"+"' title='Asociar' style='margin-right:15px'>"+
                                        "<img class='botonTabla' src="+asociar+" width='17px' />"+
                                      "</div>"+
                                    "</td>"+
                                  "</tr>");
                                  //Evento de la fila (onclick en asociar)----------------------------------------
                                  document.getElementById(doc.id+"busqueda-V").addEventListener('click', (e) => {
                                    asociarAutorizada(doc.id, doc.data().nombre, doc.data().apellidos);
                                  });
                                  //------------------------------------------------------------------------------
                                  //*****************************************************************************************
                              });
                              if(contador == 0){
                                //No hubo resultados para nombre (NINGÚN RESULTADO PARA LA BÚSQUEDA)
                                document.getElementById("body-tabla-autorizadas-busqueda").innerHTML = 
                                "<tr>"+
                                  "<td colSpan='3'>"+
                                    "<center>"+
                                      "<p id='avisoSinResultadosBusquedaAutorizada' style='color:#c8c8c8'>No hay resultados a mostrar</p>"+
                                    "</center>"+
                                  "</td>"+
                                "</tr>";
                              }
                          }).catch(function(error) {
                              //Error al realizar consulta
                          });
                          //------------------------------------------------------------------------------------------------------------
                          //------------------------------------------------------------------------------------------------------------
                          //------------------------------------------------------------------------------------------------------------
                        }
                    }).catch(function(error) {
                        //Error al realizar consulta
                    });
                    //------------------------------------------------------------------------------------------------------------
                    //------------------------------------------------------------------------------------------------------------
                    //------------------------------------------------------------------------------------------------------------
                  }
              }).catch(function(error) {
                  //Error al realizar consulta
              });
              //------------------------------------------------------------------------------------------------------------
              //------------------------------------------------------------------------------------------------------------
              //------------------------------------------------------------------------------------------------------------
            }
        }).catch(function(error) {
            //Error al realizar consulta
        });
        //------------------------------------------------------------------------------------------------------------
        //------------------------------------------------------------------------------------------------------------
        //------------------------------------------------------------------------------------------------------------
      }
    }
    //*****************************************************************************************************
    //********************************** ASOCIAR PERSONA AUTORIZADA ***************************************
    const asociarAutorizada = (uid, nombre, apellidos) => {

      //Se guarda en array "personasAutorizadas" la referencia------------------------------------------------------------
      //Sí ya existe el valor que se intenta guardar, se omite
      var aux = db.collection('responsables').doc(uid);
      if(!(this.datosRegistro.personasAutorizadas.find(element => element.id == aux.id) !== undefined)){
        this.datosRegistro.personasAutorizadas.push(aux);//Guardado en el array "personasAutorizadas"
        //Mostrar en Front (Tabla de asociación)----------------------------------------------------------------------------
        //*****************************************************************************************
        document.getElementById("body-tabla-autorizadas-asociados").insertAdjacentHTML("beforeend", 
        "<tr class='filaTabla filaTabla-hover' id='"+uid+"asociado-fila-V'>"+
          "<td>"+nombre+"</td>"+
          "<td>"+apellidos+"</td>"+
          "<td>"+
            "<div class='toggle-botonTabla' id='"+uid+"asociado-V"+"' title='Desasociar' style='margin-right:15px'>"+
              "<img class='botonTabla' src="+eliminar+" width='17px' />"+
            "</div>"+
          "</td>"+
        "</tr>");
        //Evento de la fila (onclick en desasociar)----------------------------------------
        document.getElementById(uid+"asociado-V").addEventListener('click', (e) => {
          //--------------------------------------------------------------------------------
          //------------------------------ Desasociar persona ------------------------------
          //--------------------------------------------------------------------------------
          //Se elimina de la tabla (Front)
          document.getElementById("body-tabla-autorizadas-asociados").removeChild(document.getElementById(uid+"asociado-fila-V"));
          //Se elimina del array "personasAutorizadas"
          this.datosRegistro.personasAutorizadas.splice(this.datosRegistro.personasAutorizadas.indexOf(this.datosRegistro.personasAutorizadas.find(element => element.id == aux.id)), 1);
          //--------------------------------------------------------------------------------
          //--------------------------------------------------------------------------------
          //--------------------------------------------------------------------------------
        });
        //------------------------------------------------------------------------------
        //*****************************************************************************************
        //-----------------------------------------------------------------------------------------------------------------
      }
      //------------------------------------------------------------------------------------------------------------------
    }
    //***********************************************************************************************************
    //-----------------------------------------------------------------------------------------------------------------------
    //-----------------------------------------------------------------------------------------------------------------------
    //-----------------------------------------------------------------------------------------------------------------------
    //-----------------------------------------------------------------------------------------------------------------------
    //-------------------------------------------------- REGISTRO PARTE VI --------------------------------------------------
    //-----------------------------------------------------------------------------------------------------------------------
    function atrasParteVI() {
      document.getElementById("subtitulo").innerHTML = "Personas autorizadas a recoger al alumno";
      document.getElementById("parteV").style.display = "block";
      document.getElementById("parteVI").style.display = "none";
    }
    const siguienteParteVI = e =>{

      e.preventDefault();//Evita la recarga de la página

      //*******************************************************************
      document.getElementById("subtitulo").innerHTML = "Productos";
      document.getElementById("parteVI").style.display = "none";
      document.getElementById("parteVII").style.display = "block";
      //*******************************************************************

      //Alert "loading"----------------------
      const alert_cargando = swal.fire({
        width: '300px',
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: () => {
          swal.showLoading()
        },
        customClass: {
           title: 'titulo-alert-loading',
           actions: 'alert-loader'
        },
        timer: 500
      }).then(() => {
        //
      })
      //-------------------------------------

    }
    //BÚSQUEDA DE ACTIVIDAD (CONSULTA FIRESTORE)***********************************************************
    const buscarActividad = (e) => {
      e.preventDefault();//Evita la recarga de la página
      var textoBusqueda = document.getElementById("busqueda-actividad").value;
      if(textoBusqueda !== ""){
        //------------------------------------------------------------------------------------------------------------
        //------------------------------------------------------------------------------------------------------------
        //------------------------------------------------------------------------------------------------------------
        db.collection("actividades").where("clave", "==", textoBusqueda.trim().toUpperCase()).get().then(function(querySnapshot) {
            var contador = 0;
            document.getElementById("body-tabla-actividades-busqueda").innerHTML = "";
            querySnapshot.forEach(function(doc) {
                contador++;
                //*****************************************************************************************
                document.getElementById("body-tabla-actividades-busqueda").insertAdjacentHTML("beforeend", 
                "<tr class='filaTabla filaTabla-hover'>"+
                  "<td>"+doc.data().clave+"</td>"+
                  "<td>"+doc.data().nombre+"</td>"+
                  "<td>"+
                    "<div class='toggle-botonTabla' id='"+doc.id+"busqueda-VI"+"' title='Asociar' style='margin-right:15px'>"+
                      "<img class='botonTabla' src="+asociar+" width='17px' />"+
                    "</div>"+
                  "</td>"+
                "</tr>");
                //Evento de la fila (onclick en asociar)----------------------------------------
                document.getElementById(doc.id+"busqueda-VI").addEventListener('click', (e) => {
                  asociarActividad(doc.id, doc.data().clave, doc.data().nombre);
                });
                //------------------------------------------------------------------------------
                //*****************************************************************************************
            });
            if(contador == 0){
              //No hubo resultados para clave -------------------------------------------------------------------------------
              //-------------------------------------------------------------------------------------------------------------
              //-------------------------------------------------------------------------------------------------------------
              db.collection("actividades").where("nombre", "==", textoBusqueda.trim().toUpperCase()).get().then(function(querySnapshot) {
                  document.getElementById("body-tabla-actividades-busqueda").innerHTML = "";
                  querySnapshot.forEach(function(doc) {
                      contador++;
                      //*****************************************************************************************
                      document.getElementById("body-tabla-actividades-busqueda").insertAdjacentHTML("beforeend", 
                      "<tr class='filaTabla filaTabla-hover'>"+
                        "<td>"+doc.data().clave+"</td>"+
                        "<td>"+doc.data().nombre+"</td>"+
                        "<td>"+
                          "<div class='toggle-botonTabla' id='"+doc.id+"busqueda-VI"+"' title='Asociar' style='margin-right:15px'>"+
                            "<img class='botonTabla' src="+asociar+" width='17px' />"+
                          "</div>"+
                        "</td>"+
                      "</tr>");
                      //Evento de la fila (onclick en asociar)----------------------------------------
                      document.getElementById(doc.id+"busqueda-VI").addEventListener('click', (e) => {
                        asociarActividad(doc.id, doc.data().clave, doc.data().nombre);
                      });
                      //------------------------------------------------------------------------------
                      //*****************************************************************************************
                  });
                  if(contador == 0){
                    //No hubo resultados para nombre (NINGÚN RESULTADO PARA LA BÚSQUEDA)
                    document.getElementById("body-tabla-actividades-busqueda").innerHTML = 
                    "<tr>"+
                      "<td colSpan='3'>"+
                        "<center>"+
                          "<p id='avisoSinResultadosBusquedaActividad' style='color:#c8c8c8'>No hay resultados a mostrar</p>"+
                        "</center>"+
                      "</td>"+
                    "</tr>";
                  }
              }).catch(function(error) {
                  //Error al realizar consulta
              });
              //-------------------------------------------------------------------------------------------------------------
              //-------------------------------------------------------------------------------------------------------------
              //-------------------------------------------------------------------------------------------------------------
            }
        }).catch(function(error) {
            //Error al realizar consulta
        });
        //------------------------------------------------------------------------------------------------------------
        //------------------------------------------------------------------------------------------------------------
        //------------------------------------------------------------------------------------------------------------
      }
    }
    //*****************************************************************************************************
    //***************************************** ASOCIAR ACTIVIDAD ***********************************************
    const asociarActividad = (uid, clave, nombre) => {
      //Sí la tabla está vacía, se elimina el mensaje de "Ningún tutor asociado"---
      if(this.datosRegistro.actividades.length == 0){
        document.getElementById("body-tabla-actividades-asociados").innerHTML = "";
      }
      //---------------------------------------------------------------------------

      //Se guarda en array "actividades" la referencia--------------------------------------------------------------------
      //Sí ya existe el valor que se intenta guardar, se omite
      var aux = db.collection('actividades').doc(uid);
      if(!(this.datosRegistro.actividades.find(element => element.actividad.id == aux.id) !== undefined)){
        //Se crea objeto a guardar en arreglo **************************************
        var objAux = {
          actividad: aux,
          fechaInscripcion: firebase.firestore.Timestamp.fromDate(new Date())
        }
        //**************************************************************************
        this.datosRegistro.actividades.push(objAux);//Guardado en el array "actividades"
        //Mostrar en Front (Tabla de asociación)----------------------------------------------------------------------------
        //*****************************************************************************************
        document.getElementById("body-tabla-actividades-asociados").insertAdjacentHTML("beforeend", 
        "<tr class='filaTabla filaTabla-hover' id='"+uid+"asociado-fila-VI'>"+
          "<td>"+clave+"</td>"+
          "<td>"+nombre+"</td>"+
          "<td>"+
            "<div class='toggle-botonTabla' id='"+uid+"asociado-VI"+"' title='Desasociar' style='margin-right:15px'>"+
              "<img class='botonTabla' src="+eliminar+" width='17px' />"+
            "</div>"+
          "</td>"+
        "</tr>");
        //Evento de la fila (onclick en desasociar)----------------------------------------
        document.getElementById(uid+"asociado-VI").addEventListener('click', (e) => {
          //--------------------------------------------------------------------------------
          //----------------------------- Desasociar actividad -----------------------------
          //--------------------------------------------------------------------------------
          //Se elimina de la tabla (Front)
          document.getElementById("body-tabla-actividades-asociados").removeChild(document.getElementById(uid+"asociado-fila-VI"));
          //Se elimina del array "actividades"
          this.datosRegistro.actividades.splice(this.datosRegistro.actividades.indexOf(this.datosRegistro.actividades.find(element => element.actividad.id == aux.id)), 1);
          //Sí la tabla se quedó sin elementos
          if(this.datosRegistro.actividades.length == 0) {
            document.getElementById("body-tabla-actividades-asociados").innerHTML = 
            "<tr>"+
              "<td colSpan='3'>"+
                "<center>"+
                  "<p id='avisoSinAsociacionesActividad' style='color:#c8c8c8'>Ningún curso asociado</p>"+
                "</center>"+
              "</td>"+
            "</tr>";
          }
          //--------------------------------------------------------------------------------
          //--------------------------------------------------------------------------------
          //--------------------------------------------------------------------------------
        });
        //------------------------------------------------------------------------------
        //*****************************************************************************************
        //-----------------------------------------------------------------------------------------------------------------
      }
      //------------------------------------------------------------------------------------------------------------------
    }
    //***********************************************************************************************************
    //-----------------------------------------------------------------------------------------------------------------------
    //-----------------------------------------------------------------------------------------------------------------------
    //-----------------------------------------------------------------------------------------------------------------------
    //-----------------------------------------------------------------------------------------------------------------------
    //------------------------------------------------- REGISTRO PARTE VII --------------------------------------------------
    //-----------------------------------------------------------------------------------------------------------------------
    function atrasParteVII() {
      document.getElementById("subtitulo").innerHTML = "Cursos inscritos";
      document.getElementById("parteVI").style.display = "block";
      document.getElementById("parteVII").style.display = "none";
    }
    const siguienteParteVII = e =>{

      e.preventDefault();//Evita la recarga de la página

      //***********************************************************************************************************************
      //***************************************                                      ******************************************
      //***************************************  REGISTRO FINAL DE LA CUENTA ALUMNO  ******************************************
      //***************************************                                      ******************************************
      //***********************************************************************************************************************
      //Alert "loading"----------------------
      const alert_cargando = swal.fire({
        width: '300px',
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: () => {
          swal.showLoading()
        },
        customClass: {
           title: 'titulo-alert-loading',
           actions: 'alert-loader'
        }
      })
      //-------------------------------------
      //-------------------------------------------------------------------------------------------------------------------------------------
      //Se empieza intentando crear la cuenta------------------------------------------------------------------------------------------------
      //La creación de la cuenta se crea con una segunda referencia de firebase para mantener la sesión--------------------------------------
      //-------------------------------------------------------------------------------------------------------------------------------------
      auth_aux.createUserWithEmailAndPassword(document.getElementById("correoRA").value, document.getElementById("contraseñaRA").value)
      .then(() => {
          //-----------------------------------------------------------------------------------------------------------------------------
          //----------------------------------------------SE CREAN LOS NUEVOS DOCUMENTOS-------------------------------------------------
          //-----------------------------------------------------------------------------------------------------------------------------
          //Primero se crea la relación de usuario en colección "usuarios"
          db.collection("usuarios").doc(auth_aux.currentUser.uid).set({
            id_usuario: auth_aux.currentUser.uid,
            tipo_usuario: "alumno"
        })
        .then(() => {
          //-------------------------------------------------------------------------------------------------
          //Ahora se crea su documento en la colección "alumnos"---------------------------------------------
          //-------------------------------------------------------------------------------------------------
          //Se crea objeto auxiliar de dirección----
          var direccionAux = {
            calle: this.locationInfo.calle,
            codigoPostal: this.locationInfo.codigoPostal,
            colonia: this.locationInfo.colonia,
            estado: this.locationInfo.estado,
            municipio: this.locationInfo.municipio,
            numeroExterior: this.locationInfo.numeroExterior,
            numeroInterior: this.locationInfo.numeroInterior,
            cadenaDireccion: this.locationInfo.cadenaDireccion,
            lat: this.locationInfo.lat,
            lng: this.locationInfo.lng
          };
          //----------------------------------------
          db.collection("alumnos").doc(auth_aux.currentUser.uid).set({
            actividades: this.datosRegistro.actividades,
            apellidos: this.datosRegistro.apellidos.trim().toUpperCase(),
            contactosEmergencia: this.datosRegistro.contactosEmergencia,
            correoElectronico: this.datosRegistro.correoElectronico,
            datosMedicos: this.datosRegistro.datosMedicos,
            direccion: direccionAux,
            fechaInscripcion: this.datosRegistro.fechaInscripcion,
            fechaNacimiento: this.datosRegistro.fechaNacimiento,
            genero: this.datosRegistro.genero,
            id_usuario: auth_aux.currentUser.uid,
            logros: [],
            nombre: this.datosRegistro.nombre.trim().toUpperCase(),
            personasAutorizadas: this.datosRegistro.personasAutorizadas,
            productos: this.datosRegistro.productos,
            tutores: this.datosRegistro.tutores,
          })
          .then(() => {
            //EL REGISTRO SE COMPLETÓ POR COMPLETO CON ÉXITO-------------
            //*****************************************************
            //Se crean los documentos de compras
            this.datosRegistro.productos.forEach((producto) => {
              db.collection("compras").doc().set({
                alumno: db.collection('alumnos').doc(auth_aux.currentUser.uid),
                cantidad: producto.cantidad,
                costo: producto.costo,
                fechaCompra: producto.fechaCompra,
                producto: producto.producto
              });
            });
            //Se crean los documentos de inscripciones
            this.datosRegistro.actividades.forEach((curso) => {
              db.collection("inscripciones").doc().set({
                actividad: curso.actividad,
                alumno: db.collection('alumnos').doc(auth_aux.currentUser.uid),
                fechaBaja: null,
                fechaInscripcion: curso.fechaInscripcion
              });
            });
            //*****************************************************
            //*****************************************************************************************************************
            //************************ SE GUARDAN LAS RELACIONES EN DOCUMENTOS DE PERSONAS ASOCIADAS **************************
            //*****************************************************************************************************************
            //TUTORES
            this.datosRegistro.tutores.forEach((tutor) => {
              db.collection("tutores").doc(tutor.id).update({
                  alumnos: firebase.firestore.FieldValue.arrayUnion(db.collection('alumnos').doc(auth_aux.currentUser.uid))
              });
            });
            //CURSOS
            this.datosRegistro.actividades.forEach((actividad) => {
              db.collection("actividades").doc(actividad.actividad.id).update({
                  alumnos: firebase.firestore.FieldValue.arrayUnion(db.collection('alumnos').doc(auth_aux.currentUser.uid))
              });
            });
            //RESPONSABLES
            this.datosRegistro.personasAutorizadas.forEach((responsable) => {
              if(responsable.path.split("/")[0] == "responsables"){
                db.collection("responsables").doc(responsable.id).update({
                    alumnos: firebase.firestore.FieldValue.arrayUnion(db.collection('alumnos').doc(auth_aux.currentUser.uid))
                });
              }
            });
            this.datosRegistro.contactosEmergencia.forEach((responsable) => {
              if(responsable.path.split("/")[0] == "responsables"){
                db.collection("responsables").doc(responsable.id).update({
                    alumnos: firebase.firestore.FieldValue.arrayUnion(db.collection('alumnos').doc(auth_aux.currentUser.uid))
                });
              }
            });
            //*****************************************************************************************************************
            //*****************************************************************************************************************
            //*****************************************************************************************************************
            //Se cierra sesión en la app secundaria
            auth_aux.signOut().then(function() {
              alert_cargando.close();
              // Sign-out successful.
              swal.fire({
                  title:"<p style='font-size: 13pt;font-weight:600;color: #282828;'>Alumno registrado</p>",
                  icon: 'success',
                  iconColor: '#17a2b8',
                  width: '350px',
                  showConfirmButton: false,
                  timer: 2700
                }).then((result) => {
                  //Se regresa a inicio
                  document.getElementById("boton_RA-parteI").click();
              });
            }).catch((error) => {
                // An error happened.
                alert_cargando.close();
                //alerta por error
              document.getElementById("alerta-RA-parteVII").innerHTML = 
              '<p">'+
                'Error al registrar: SIGN OFF '+
                error+
              '</p>';
            });
              //-----------------------------------------------------------
          })
          .catch(function(error) {
            alert_cargando.close();
              //alerta por error
            document.getElementById("alerta-RA-parteVII").innerHTML = 
            '<p">'+
              'Error al registrar: DOC ALUMNO '+
              error+
            '</p>';
          });
          //-------------------------------------------------------------------------------------------------
          //-------------------------------------------------------------------------------------------------
          //-------------------------------------------------------------------------------------------------
        })
        .catch(function(error) {
          alert_cargando.close();
            //alerta por error
          document.getElementById("alerta-RA-parteVII").innerHTML = 
          '<p">'+
            'Error al registrar: DOC USER '+
            error+
          '</p>';
        });
          //-----------------------------------------------------------------------------------------------------------------------------
          //-----------------------------------------------------------------------------------------------------------------------------
          //-----------------------------------------------------------------------------------------------------------------------------
      })
      .catch(function(error) {
        // Errores
        alert_cargando.close();
        var errorMessage = error.message;
        //alerta por error
        document.getElementById("alerta-RA-parteI").innerHTML = 
        '<p">'+
          'Error al registrar: CREATE ACCOUNT '+
          errorMessage+
        '</p>';
        document.getElementById("parteVII").style.display = "none";
        document.getElementById("parteI").style.display = "block";
      });
      //-------------------------------------------------------------------------------------------------------------------------------------
      //-------------------------------------------------------------------------------------------------------------------------------------
      //-------------------------------------------------------------------------------------------------------------------------------------
      //-------------------------------------------------------------------------------------------------------------------------------------
      //***********************************************************************************************************************
      //***********************************************************************************************************************
      //***********************************************************************************************************************
      //***********************************************************************************************************************
      //***********************************************************************************************************************

    }
    //BÚSQUEDA DE PRODUCTO (CONSULTA FIRESTORE)**********************************************************
    const buscarProducto = (e) => {
      e.preventDefault();//Evita la recarga de la página
      var textoBusqueda = document.getElementById("busqueda-producto").value;
      if(textoBusqueda !== ""){
        //------------------------------------------------------------------------------------------------------------
        //------------------------------------------------------------------------------------------------------------
        //------------------------------------------------------------------------------------------------------------
        db.collection("productos").where("clave", "==", textoBusqueda.trim().toUpperCase()).get().then(function(querySnapshot) {
            var contador = 0;
            document.getElementById("body-tabla-productos-busqueda").innerHTML = "";
            querySnapshot.forEach(function(doc) {
                contador++;
                //*****************************************************************************************
                document.getElementById("body-tabla-productos-busqueda").insertAdjacentHTML("beforeend", 
                "<tr class='filaTabla filaTabla-hover'>"+
                  "<td>"+doc.data().clave+"</td>"+
                  "<td>"+doc.data().nombre+"</td>"+
                  "<td>"+
                    "<div class='toggle-botonTabla' id='"+doc.id+"busqueda-VII"+"' title='Asociar' style='margin-right:15px'>"+
                      "<img class='botonTabla' src="+asociar+" width='17px' />"+
                    "</div>"+
                  "</td>"+
                "</tr>");
                //Evento de la fila (onclick en asociar)----------------------------------------
                document.getElementById(doc.id+"busqueda-VII").addEventListener('click', (e) => {
                  asociarProducto(doc.id, doc.data().clave, doc.data().nombre, doc.data().tipo, doc.data().costo, doc.data().fechaVigencia);
                });
                //------------------------------------------------------------------------------
                //*****************************************************************************************
            });
            if(contador == 0){
              //No hubo resultados para clave --------------------------------------------------------------------------------
              //--------------------------------------------------------------------------------------------------------------
              //--------------------------------------------------------------------------------------------------------------
              db.collection("productos").where("nombre", "==", textoBusqueda.trim().toUpperCase()).get().then(function(querySnapshot) {
                  document.getElementById("body-tabla-productos-busqueda").innerHTML = "";
                  querySnapshot.forEach(function(doc) {
                      contador++;
                      //*****************************************************************************************
                      document.getElementById("body-tabla-productos-busqueda").insertAdjacentHTML("beforeend", 
                      "<tr class='filaTabla filaTabla-hover'>"+
                        "<td>"+doc.data().clave+"</td>"+
                        "<td>"+doc.data().nombre+"</td>"+
                        "<td>"+
                          "<div class='toggle-botonTabla' id='"+doc.id+"busqueda-VII"+"' title='Asociar' style='margin-right:15px'>"+
                            "<img class='botonTabla' src="+asociar+" width='17px' />"+
                          "</div>"+
                        "</td>"+
                      "</tr>");
                      //Evento de la fila (onclick en asociar)----------------------------------------
                      document.getElementById(doc.id+"busqueda-VII").addEventListener('click', (e) => {
                        asociarProducto(doc.id, doc.data().clave, doc.data().nombre, doc.data().tipo, doc.data().costo, doc.data().fechaVigencia);
                      });
                      //------------------------------------------------------------------------------
                      //*****************************************************************************************
                  });
                  if(contador == 0){
                    //No hubo resultados para nombre (NINGÚN RESULTADO PARA LA BÚSQUEDA)
                    document.getElementById("body-tabla-productos-busqueda").innerHTML = 
                    "<tr>"+
                      "<td colSpan='3'>"+
                        "<center>"+
                          "<p id='avisoSinResultadosBusquedaProducto' style='color:#c8c8c8'>No hay resultados a mostrar</p>"+
                        "</center>"+
                      "</td>"+
                    "</tr>";
                  }
              }).catch(function(error) {
                  //Error al realizar consulta
              });
              //--------------------------------------------------------------------------------------------------------------
              //--------------------------------------------------------------------------------------------------------------
              //--------------------------------------------------------------------------------------------------------------
            }
        }).catch(function(error) {
            //Error al realizar consulta
        });
        //------------------------------------------------------------------------------------------------------------
        //------------------------------------------------------------------------------------------------------------
        //------------------------------------------------------------------------------------------------------------
      }
    }
    //*****************************************************************************************************
    //***************************************** ASOCIAR PRODUCTO ************************************************
    const asociarProducto = (uid, clave, nombre, tipo, costo, fechaVigencia) => {
      //Sí la tabla está vacía, se elimina el mensaje de "Ningún producto asociado"---
      if(this.datosRegistro.productos.length == 0){
        document.getElementById("body-tabla-productos-asociados").innerHTML = "";
      }
      //------------------------------------------------------------------------------

      //Se guarda en array "productos" la referencia----------------------------------------------------------------------
      //Sí ya existe el valor que se intenta guardar, se omite
      var aux = db.collection('productos').doc(uid);
      if(!(this.datosRegistro.productos.find(element => element.producto.id == aux.id) !== undefined)){
        //Se crea objeto a guardar en arreglo **************************************
        var objAux = {
          producto: aux,
          fechaCompra: firebase.firestore.Timestamp.fromDate(new Date()),
          clave: clave,
          nombre: nombre,
          tipo: tipo,
          cantidad: 1,
          costo: costo,
          fechaVigencia: fechaVigencia
        }
        //**************************************************************************
        this.datosRegistro.productos.push(objAux);//Guardado en el array "productos"
        //Mostrar en Front (Tabla de asociación)----------------------------------------------------------------------------
        //*****************************************************************************************
        document.getElementById("body-tabla-productos-asociados").insertAdjacentHTML("beforeend", 
        "<tr class='filaTabla filaTabla-hover' id='"+uid+"asociado-fila-VII'>"+
          "<td>"+clave+"</td>"+
          "<td>"+nombre+"</td>"+
          "<td><input class='input-cantidad' type='number' id='"+uid+"cantidad-VII"+"' name='"+uid+"' min='1' max='100' value='1'/></td>"+
          "<td>"+
            "<div class='toggle-botonTabla' id='"+uid+"asociado-VII"+"' title='Eliminar' style='margin-right:15px'>"+
              "<img class='botonTabla' src="+eliminar+" width='17px' />"+
            "</div>"+
          "</td>"+
        "</tr>");
        //Evento de la fila (cambio en cantidad)-------------------------------------------
        document.getElementById(uid+"cantidad-VII").addEventListener('change', (e) => {
          //VALIDACIÓN---------------------------------------------------------
          if(e.target.value < 1){e.target.value = 1;}
          if(e.target.value > 100){e.target.value = 100;}
          //-------------------------------------------------------------------
          //Actualiza cantidad en elemento----------------------------------------
          this.datosRegistro.productos.find(element => element.producto.id == e.target.name).cantidad = parseInt(e.target.value, 10);
          //----------------------------------------------------------------------
        });
        //---------------------------------------------------------------------------------
        //Evento de la fila (onclick en desasociar)----------------------------------------
        document.getElementById(uid+"asociado-VII").addEventListener('click', (e) => {
          //--------------------------------------------------------------------------------
          //----------------------------- Desasociar producto ------------------------------
          //--------------------------------------------------------------------------------
          //Se elimina de la tabla (Front)
          document.getElementById("body-tabla-productos-asociados").removeChild(document.getElementById(uid+"asociado-fila-VII"));
          //Se elimina del array "productos"
          this.datosRegistro.productos.splice(this.datosRegistro.productos.indexOf(this.datosRegistro.productos.find(element => element.producto.id == aux.id)), 1);
          //Sí la tabla se quedó sin elementos
          if(this.datosRegistro.productos.length == 0) {
            document.getElementById("body-tabla-productos-asociados").innerHTML = 
            "<tr>"+
              "<td colSpan='4'>"+
                "<center>"+
                  "<p id='avisoSinAsociacionesProducto' style='color:#c8c8c8'>Ningún producto asociado</p>"+
                "</center>"+
              "</td>"+
            "</tr>";
          }
          //--------------------------------------------------------------------------------
          //--------------------------------------------------------------------------------
          //--------------------------------------------------------------------------------
        });
        //------------------------------------------------------------------------------
        //*****************************************************************************************
        //-----------------------------------------------------------------------------------------------------------------
      }
      //------------------------------------------------------------------------------------------------------------------
    }
    //***********************************************************************************************************
    //-----------------------------------------------------------------------------------------------------------------------
    //-----------------------------------------------------------------------------------------------------------------------
    //-----------------------------------------------------------------------------------------------------------------------
    //*****************************************************************************************************************************************************
    //*************************************************************** DIRECCIONES *************************************************************************
    //*****************************************************************************************************************************************************
    //onchange de address
    const onChangeAddress= () => {
      if(this.locationInfo.direccion_correcta){
        direccionIncorrecta();
      } else {
        direccionIncorrecta();
      }
    }
    //onchange de número interior
    const onChangeNumeroInterior = (e) => {
      this.locationInfo.numeroInterior = e.target.value;
    }
    //Función agregar número interior
    const setNumeroInterior = () => {
      document.getElementById("div-input_interior").style.display = "block";
      document.getElementById("boton_agregar_ni").style.display = "none";
      document.getElementById("principalDirecciones").style.marginBottom = "44px";
    }
    //Función dirección incorrecta
    const direccionIncorrecta = () => {
      this.locationInfo.direccion_correcta = false;
      document.getElementById("address").classList.add('input_incorrecto-address');
      document.getElementById("address").classList.remove('input_correcto-address');
      document.getElementById("error-direccion").innerHTML = 'Ingrese una dirección válida';
      document.getElementById("boton_agregar_ni").style.display = "none";
      document.getElementById("div-input_interior").style.display = "none";
      document.getElementById("numeroInteriorRA").value = "S/N";
      document.getElementById("principalDirecciones").style.marginBottom = "25px";
    }
    //*****************************************************************************************************************************************************
    //*****************************************************************************************************************************************************
    //*****************************************************************************************************************************************************
    //*************************************************************************************************************************************
    //***************************************************VALIDACIÓN DE LOS DATOS***********************************************************
    //*************************************************************************************************************************************
    const expresiones = {
      nombre: /^[a-zA-ZÀ-ÿ\s]{1,40}$/, // Letras y espacios, pueden llevar acentos
      apellidos: /^[a-zA-ZÀ-ÿ\s]{1,40}$/, // Letras y espacios, pueden llevar acentos
      correo: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
      telefono: /^\d{10,10}$/, // 10 numeros
      contraseña: /^.{6,40}$/ //cualquier caracter
    }
    const validarFormulario = (e) => {
      switch(e.target.name){
        case "nombre":
          validarCampo(expresiones.nombre, e.target, e.target.name, "Campo nombre solo acepta letras y espacios");
        break;
        case "apellidos":
          validarCampo(expresiones.apellidos, e.target, e.target.name, "Campo apellidos solo acepta letras y espacios");
        break;
        case "telefono":
          validarCampo(expresiones.telefono, e.target, e.target.name, "Ingrese número telefónico a 10 dígitos");
        break;
        case "correo":
          validarCampo(expresiones.correo, e.target, e.target.name, "Ingrese un correo válido");
        break;
        case "contraseña":
          validarCampo(expresiones.contraseña, e.target, e.target.name, "La contraseña debe contener al menos 6 caracteres");
          validarConfirmacionContraseña();
        break;
        case "confirmacionContraseña":
          validarConfirmacionContraseña();
        break;
        case "fechaNacimiento":
          const añoFechaIngresada = new Date(e.target.value).getFullYear();
          if(añoFechaIngresada<1930 || añoFechaIngresada>((new Date().getFullYear())-1)){
            //Fecha incorrecta
            document.getElementById("fechaNacimientoRA").classList.remove('input_correcto');
            document.getElementById("fechaNacimientoRA").classList.add('input_incorrecto');
            document.getElementById("error-fechaNacimientoRA").innerHTML = 'Verifique la fecha ingresada';
          } else {
            //Fecha correcta
            document.getElementById("fechaNacimientoRA").classList.remove('input_incorrecto');
            document.getElementById("fechaNacimientoRA").classList.add('input_correcto');
            document.getElementById("error-fechaNacimientoRA").innerHTML = '';
          }
        break;
      }
    }
    const validarCampo = (expresion, input, campo, mensaje_error) => {
      if(expresion.test(input.value)){
        document.getElementById(`${campo}RA`).classList.remove('input_incorrecto');
        document.getElementById(`${campo}RA`).classList.add('input_correcto');
        document.getElementById(`error-${campo}RA`).innerHTML = '';
      } else {
        document.getElementById(`${campo}RA`).classList.remove('input_correcto');
        document.getElementById(`${campo}RA`).classList.add('input_incorrecto');
        document.getElementById(`error-${campo}RA`).innerHTML = mensaje_error;
      }
    }
    const validarConfirmacionContraseña = () => {
      if(document.getElementById("contraseñaRA").value!==document.getElementById("confirmacionContraseñaRA").value){
        document.getElementById("confirmacionContraseñaRA").classList.remove('input_correcto');
        document.getElementById("confirmacionContraseñaRA").classList.add('input_incorrecto');
        document.getElementById("error-confirmacionContraseñaRA").innerHTML = 'Ambas contraseñas deben coincidir';
      } else {
        document.getElementById("confirmacionContraseñaRA").classList.remove('input_incorrecto');
        document.getElementById("confirmacionContraseñaRA").classList.add('input_correcto');
        document.getElementById("error-confirmacionContraseñaRA").innerHTML = '';
      }
    }
    //*************************************************************************************************************************************
    //*************************************************************************************************************************************
    //*************************************************************************************************************************************
		return(
			<React.Fragment>

			<div className="global-container">
				<div className="card login-form" id="cardFRA" style={{ width: '1000px', paddingTop:'10px' }}>
					<div className="card-body" id="tarjeta">
						<h4 className="card-title" style={{fontSize:'18pt',fontWeight:'500'}}>Crear cuenta de alumno</h4>
						<div className="card-text" style={{marginTop:'40px',marginTop:'5px'}}>

              <h5 id="subtitulo" className="card-title" style={{fontSize:'13pt',fontWeight:'500',marginBottom:'30px',color:'#17a2b8'}}>Datos del alumno</h5>
              
              <form autoComplete="off" onSubmit={siguienteParteI} style={{margin:'0px',overflow:'hidden',display:'block'}} id="parteI">
                <div className="row" style={{width:'99.9%',margin:'auto'}}>
                <div className="col-sm-12 col-md-12 col-lg-4">
                  <div className="row">
                  <div className="col-sm-12 col-md-6 col-lg-12">
                    <div className="form-group input-field" style={{margin: 'auto'}}>
                      <input className="input_correcto input" type="text" id="nombreRA" name="nombre" onChange={validarFormulario} required />
                      <label className="label" for="nombreRA">Nombre</label>
                      <p id="error-nombreRA"></p>
                    </div>
                  </div>
                  
                  <div className="col-sm-12 col-md-6 col-lg-12">
                    <div className="form-group input-field" style={{margin: 'auto'}}>
                      <input className="input_correcto input" type="text" id="apellidosRA" name="apellidos" onChange={validarFormulario} required />
                      <label className="label" for="apellidosRA">Apellidos</label>
                      <p id="error-apellidosRA"></p>
                    </div>
                  </div>

                  <div className="col-sm-12 col-md-6 col-lg-12">
                    <div className="form-group input-field-select" style={{margin: 'auto'}}>
                      <select className="input_correcto input-select" id="generoRA" required>
                        <option value="" style={{display: 'none'}}></option>
                        <option value="F">Femenino</option>
                        <option value="M">Masculino</option>
                      </select>
                      <label className="label-select" for="generoRA">Género</label>
                    </div>
                  </div>

                  <div className="col-sm-12 col-md-6 col-lg-12">
                    <div className="form-group input-field" style={{margin: 'auto'}}>
                      <input className="input_correcto input" type="date" id="fechaNacimientoRA" name="fechaNacimiento" onChange={validarFormulario} required />
                      <label className="label" for="fechaNacimientoRA">Fecha de nacimiento</label>
                      <p id="error-fechaNacimientoRA"></p>
                    </div>
                  </div>

                  <div className="col-sm-12 col-md-6 col-lg-12">
                    <div className="form-group input-field" style={{margin: 'auto'}}>
                      <input className="input_correcto input" type="date" id="fechaInscripcionRA" name="fechaInscripcion" min="1930-01-01" required />
                      <label className="label" for="fechaInscripcionRA">Fecha de inscripción</label>
                      <p id="error-fechaInscripcionRA"></p>
                    </div>
                  </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-8" style={{padding:'0px'}}>
                  <div className="row">
                    <div className="principal-direcciones" id="principalDirecciones">
                      <label className="label-principal-direcciones">Dirección</label>
                      <div style={{height:'101px'}} id="js-preview-map">
                          <img src="https://maps.googleapis.com/maps/api/staticmap?key=AIzaSyDOafd3wkkjh-8nt0kb98ieVx3kkgyyOYs&center=18.9995385,-98.2631484&zoom=12&size=388x101&maptype=roadmap&markers=anchor:center%7Cicon:https%3A%2F%2Fchiquifit.com.mx%2Fwp-content%2Fuploads%2F2020%2F11%2Fcropped-chifquifitMesa-de-trabajo-1-copia-40lt-1-32x32.png%7C18.9995385,-98.2631484" 
                          width="388" height="101"/>
                      </div>
                      <div className="form-group input-field-address">
                          <input className="input_correcto-address input-address" type="text" id="address" name="dirección" placeholder="Ingrese una dirección" onChange={(event) => {this.setState({texto_direccion: event.target.value});onChangeAddress();}} value={this.state.texto_direccion} required />
                          <p id="error-direccion"></p>
                      </div>
                      <p className="boton-agregar-ni" id="boton_agregar_ni" onClick={setNumeroInterior} style={{display:'none'}}>Agregar número interior</p>
                      <div className="form-group input-field" id="div-input_interior" style={{clear:'both',width:'130px',display:'none',height:'56px',marginLeft:'1.5%',marginTop:'5px',marginBottom:'0px'}}>
                          <input className="input_correcto input" type="text" id="numeroInteriorRA" name="numero_interior" onChange={event => onChangeNumeroInterior(event)} required />
                          <label className="label" for="numeroInteriorRA">Interior</label>
                      </div>
                    </div>

                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <div className="form-group input-field" style={{margin: 'auto'}}>
                        <input className="input_correcto input" type="text" id="correoRA" name="correo" onChange={validarFormulario} required />
                        <label className="label" for="correoRA">Correo electrónico</label>
                        <p id="error-correoRA"></p>
                      </div>
                    </div>

                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <div className="form-group input-field" style={{margin: 'auto'}}>
                        <input className="input_correcto input" type="password" id="contraseñaRA" name="contraseña" onChange={validarFormulario} required />
                        <label className="label" for="contraseñaRA">Contraseña</label>
                        <p id="error-contraseñaRA"></p>
                      </div>
                    </div>

                    <div className="col-sm-12 col-md-6 col-lg-6">
                    </div>

                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <div className="form-group input-field" style={{margin: 'auto'}}>
                        <input className="input_correcto input" type="password" id="confirmacionContraseñaRA" name="confirmacionContraseña" onChange={validarFormulario} required />
                        <label className="label" for="confirmacionContraseñaRA">Confirmar contraseña</label>
                        <p id="error-confirmacionContraseñaRA"></p>
                      </div>
                    </div>
                  </div>
                </div>
                </div>

                <div id="alerta-RA-parteI" style={{fontSize:'11pt',color:'#D45454',marginBottom:"10px",marginTop:"10px"}}></div>

                <RB.Button variant="info" id="boton1_RA-parteI" className="boton-presionado" type="submit" style={{float:'right',marginRight:'10px',width:'110px',fontSize:'10pt'}}>Siguiente</RB.Button>

                <Link to="/administrador/alumnos" id="boton_RA-parteI" style={{float:'right',marginRight:'35px',lineHeight:'2.4',color:'#17a2b8',fontSize:'10pt',textDecoration:'none'}}>Cancelar</Link>
              </form>

              <form autoComplete="off" onSubmit={siguienteParteII} style={{margin:'0px',overflow:'hidden',display:'none'}} id="parteII">
                <div className="row">
                  <div className="col-sm-12 col-md-6 col-lg-6">
                      <div className="form-group input-field-select" style={{margin: 'auto'}}>
                        <select className="input_correcto input-select" id="grupoSanguineoRA" required>
                          <option value="" style={{display: 'none'}}></option>
                          <option value="AB+">AB+</option>
                          <option value="AB-">AB-</option>
                          <option value="A+">A+</option>
                          <option value="A-">A-</option>
                          <option value="B+">B+</option>
                          <option value="B-">B-</option>
                          <option value="0+">0+</option>
                          <option value="0-">0-</option>
                        </select>
                        <label className="label-select" for="grupoSanguineoRA">Grupo sanguíneo</label>
                      </div>
                  </div>
                    
                  <div className="col-sm-12 col-md-6 col-lg-6">
                      <div className="form-group input-field" style={{margin: 'auto'}}>
                        <input className="input_correcto input" type="text" id="medicamentosRA" name="medicamentos" required />
                        <label className="label" for="medicamentosRA">Medicamentos</label>
                        <p id="error-medicamentosRA"></p>
                      </div>
                  </div>

                  <div className="col-sm-12 col-md-6 col-lg-6">
                      <div className="form-group input-field" style={{margin: 'auto'}}>
                        <input className="input_correcto input" type="text" id="alergiasRA" name="alergias" required />
                        <label className="label" for="alergiasRA">Alergias</label>
                        <p id="error-alergiasRA"></p>
                      </div>
                  </div>

                  <div className="col-sm-12 col-md-6 col-lg-6">
                      <div className="form-group input-field" style={{margin: 'auto'}}>
                        <input className="input_correcto input" type="text" id="enfermedadesRA" name="enfermedades" required />
                        <label className="label" for="enfermedadesRA">Enfermedades o intervenciones</label>
                        <p id="error-enfermedadesRA"></p>
                      </div>
                  </div>

                  <div className="col-sm-12 col-md-6 col-lg-6">
                      <div className="form-group input-field" style={{margin: 'auto'}}>
                        <input className="input_correcto input" type="text" id="limitacionesRA" name="limitaciones" required />
                        <label className="label" for="limitacionesRA">Limitaciones físicas</label>
                        <p id="error-limitacionesRA"></p>
                      </div>
                  </div>

                  <div className="col-sm-12 col-md-6 col-lg-6">
                      <div className="form-group input-field" style={{margin: 'auto'}}>
                        <input className="input_correcto input" type="text" id="tratamientosMedicosRA" name="tratamientosMedicos" required />
                        <label className="label" for="tratamientosMedicosRA">Tratamientos médicos</label>
                        <p id="error-tratamientosMedicosRA"></p>
                      </div>
                  </div>
                </div>

                <div id="alerta-RA-parteII" style={{fontSize:'11pt',color:'#D45454',marginBottom:"10px",marginTop:"10px"}}></div>

                <RB.Button variant="info" id="boton1_RA-parteII" className="boton-presionado" type="submit" style={{float:'right',marginRight:'10px',width:'110px',fontSize:'10pt'}}>Siguiente</RB.Button>

                <p className="botonP" onClick={atrasParteII}>Atrás</p>

                <Link to="/administrador/alumnos" id="boton_RA-parteII" style={{float:'right',marginRight:'35px',lineHeight:'2.4',color:'#17a2b8',fontSize:'10pt',textDecoration:'none'}}>Cancelar</Link>
              </form>

              <form autoComplete="off" onSubmit={siguienteParteIII} style={{margin:'0px',overflow:'hidden',display:'none'}} id="parteIII">
                <div className="row">

                  <div className="col-sm-12 col-md-12 col-lg-6">
                    <div style={{borderRadius:'8px',backgroundColor:'#e6e6e6',width:"437px",margin:'auto',marginBottom:'30px'}}>
                      <div className="wrap">
                         <div className="search">
                            <input type="search" onSearch={buscarTutor} className="searchTerm" id="busqueda-tutor" placeholder="Buscar por correo electrónico, nombre, apellidos o teléfono"/>
                            <button className="searchButton" onClick={buscarTutor} id="boton-busqueda-tutor">
                              <img src={lupa} style={{marginTop:'-6px',marginLeft:'-2px',opacity:'0.6'}} width="18px" />
                            </button>
                         </div>
                      </div>

                      <div style={{borderRadius:'0px 0px 8px 8px',overflow:'hidden',boxShadow:'0px 1px 2px 1px #BDC3C7',width: '433px',margin:'auto'}}>
                        <div id="tabla-tutores" style={{
                            width: '433px',
                            height: '200px', 
                            overflowY: 'scroll',
                            overflowX: 'scroll',
                            backgroundColor: '#fff',
                            margin: 'auto'
                        }}>
                          <RB.Table>
                            <thead>
                               <tr style={{backgroundColor:'#fafafa'}}>
                                  <th>Nombre</th>
                                  <th>Apellidos</th>
                                  <th width="50px"></th>
                               </tr>
                            </thead>
                            <tbody id="body-tabla-tutores-busqueda">
                              <tr>
                                <td colSpan="3">
                                  <center>
                                    <p id="avisoSinResultadosBusquedaTutor" style={{color: '#c8c8c8'}}>No hay resultados a mostrar</p>
                                  </center>
                                </td>
                              </tr>
                            </tbody>
                          </RB.Table>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-12 col-md-12 col-lg-6">
                    <div style={{margin:'auto',backgroundColor:'#e6e6e6',borderRadius:'9px',padding:'1px',width: '404px'}}>
                        <p style={{fontSize:'11pt',fontWeight:'500',color:'#868686',marginBottom:'11px',marginLeft:'10px',marginTop:'8px',float:'left'}}>Tutores a asociar con alumno</p>
                        <a href="/administrador/alta/tutor" target="_blank">
                          <img className="botonImagen" title="Registrar tutor" style={{float:'right',marginRight:'12px',marginTop:'11px'}} src={nuevo} width="17px" />
                        </a>
                        <div style={{borderRadius:'0px 0px 8px 8px',overflow:'hidden',boxShadow:'0px 1px 2px 1px #BDC3C7',width: '400px',margin:'auto'}}>
                          <div id="tabla-tutores" style={{
                              width: '400px',
                              height: '200px', 
                              overflowY: 'scroll',
                              overflowX: 'scroll',
                              backgroundColor: '#fff',
                              margin: 'auto'
                          }}>
                            <RB.Table>
                              <thead>
                                 <tr style={{backgroundColor:'#fafafa'}}>
                                    <th>Nombre</th>
                                    <th>Apellidos</th>
                                    <th width="50px"></th>
                                 </tr>
                              </thead>
                              <tbody id="body-tabla-tutores-asociados">
                                <tr>
                                  <td colSpan="3">
                                    <center>
                                      <p id="avisoSinAsociacionesTutor" style={{color: '#c8c8c8'}}>Ningún tutor asociado</p>
                                    </center>
                                  </td>
                                </tr>
                              </tbody>
                            </RB.Table>
                          </div>
                        </div>
                    </div>
                  </div>

                </div>

                <div id="alerta-RA-parteIII" style={{fontSize:'11pt',color:'#D45454',marginBottom:"10px",marginTop:"10px"}}></div>

                <RB.Button variant="info" id="boton1_RA-parteIII" className="boton-presionado" type="submit" style={{float:'right',marginRight:'10px',width:'110px',fontSize:'10pt'}}>Siguiente</RB.Button>

                <p className="botonP" onClick={atrasParteIII}>Atrás</p>

                <Link to="/administrador/alumnos" id="boton_RA-parteIII" style={{float:'right',marginRight:'35px',lineHeight:'2.4',color:'#17a2b8',fontSize:'10pt',textDecoration:'none'}}>Cancelar</Link>
              </form>

              <form autoComplete="off" onSubmit={siguienteParteIV} style={{margin:'0px',overflow:'hidden',display:'none'}} id="parteIV">
                <div className="row">

                  <div className="col-sm-12 col-md-12 col-lg-6">
                    <div style={{borderRadius:'8px',backgroundColor:'#e6e6e6',width:"437px",margin:'auto',marginBottom:'30px'}}>
                      <div className="wrap">
                         <div className="search">
                            <input type="search" onSearch={buscarContacto} className="searchTerm" id="busqueda-contacto" placeholder="Buscar por correo electrónico, nombre, apellidos o teléfono"/>
                            <button className="searchButton" onClick={buscarContacto} id="boton-busqueda-contacto">
                              <img src={lupa} style={{marginTop:'-6px',marginLeft:'-2px',opacity:'0.6'}} width="18px" />
                            </button>
                         </div>
                      </div>

                      <div style={{borderRadius:'0px 0px 8px 8px',overflow:'hidden',boxShadow:'0px 1px 2px 1px #BDC3C7',width: '433px',margin:'auto'}}>
                        <div id="tabla-contactos" style={{
                            width: '433px',
                            height: '200px', 
                            overflowY: 'scroll',
                            overflowX: 'scroll',
                            backgroundColor: '#fff',
                            margin: 'auto'
                        }}>
                          <RB.Table>
                            <thead>
                               <tr style={{backgroundColor:'#fafafa'}}>
                                  <th>Nombre</th>
                                  <th>Apellidos</th>
                                  <th width="50px"></th>
                               </tr>
                            </thead>
                            <tbody id="body-tabla-contactos-busqueda">
                              <tr>
                                <td colSpan="3">
                                  <center>
                                    <p id="avisoSinResultadosBusquedaContacto" style={{color: '#c8c8c8'}}>No hay resultados a mostrar</p>
                                  </center>
                                </td>
                              </tr>
                            </tbody>
                          </RB.Table>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-12 col-md-12 col-lg-6">
                    <div style={{margin:'auto',backgroundColor:'#e6e6e6',borderRadius:'9px',padding:'1px',width: '404px'}}>
                        <p style={{fontSize:'11pt',fontWeight:'500',color:'#868686',marginBottom:'11px',marginLeft:'10px',marginTop:'8px',float:'left'}}>Contactos de emergencia a asociar con alumno</p>
                        <a href="/administrador/alta/responsable" target="_blank">
                          <img className="botonImagen" title="Registrar contacto" style={{float:'right',marginRight:'12px',marginTop:'11px'}} src={nuevo} width="17px" />
                        </a>
                        <div style={{borderRadius:'0px 0px 8px 8px',overflow:'hidden',boxShadow:'0px 1px 2px 1px #BDC3C7',width: '400px',margin:'auto'}}>
                          <div id="tabla-contactos" style={{
                              width: '400px',
                              height: '200px', 
                              overflowY: 'scroll',
                              overflowX: 'scroll',
                              backgroundColor: '#fff',
                              margin: 'auto'
                          }}>
                            <RB.Table>
                              <thead>
                                 <tr style={{backgroundColor:'#fafafa'}}>
                                    <th>Nombre</th>
                                    <th>Apellidos</th>
                                    <th width="50px"></th>
                                 </tr>
                              </thead>
                              <tbody id="body-tabla-contactos-asociados">
                              </tbody>
                            </RB.Table>
                          </div>
                        </div>
                    </div>
                  </div>

                </div>

                <div id="alerta-RA-parteIV" style={{fontSize:'11pt',color:'#D45454',marginBottom:"10px",marginTop:"10px"}}></div>

                <RB.Button variant="info" id="boton1_RA-parteIV" className="boton-presionado" type="submit" style={{float:'right',marginRight:'10px',width:'110px',fontSize:'10pt'}}>Siguiente</RB.Button>

                <p className="botonP" onClick={atrasParteIV}>Atrás</p>

                <Link to="/administrador/alumnos" id="boton_RA-parteIV" style={{float:'right',marginRight:'35px',lineHeight:'2.4',color:'#17a2b8',fontSize:'10pt',textDecoration:'none'}}>Cancelar</Link>
              </form>

              <form autoComplete="off" onSubmit={siguienteParteV} style={{margin:'0px',overflow:'hidden',display:'none'}} id="parteV">
                <div className="row">

                  <div className="col-sm-12 col-md-12 col-lg-6">
                    <div style={{borderRadius:'8px',backgroundColor:'#e6e6e6',width:"437px",margin:'auto',marginBottom:'30px'}}>
                      <div className="wrap">
                         <div className="search">
                            <input type="search" onSearch={buscarAutorizada} className="searchTerm" id="busqueda-autorizada" placeholder="Buscar por correo electrónico, nombre, apellidos o teléfono"/>
                            <button className="searchButton" onClick={buscarAutorizada} id="boton-busqueda-autorizada">
                              <img src={lupa} style={{marginTop:'-6px',marginLeft:'-2px',opacity:'0.6'}} width="18px" />
                            </button>
                         </div>
                      </div>

                      <div style={{borderRadius:'0px 0px 8px 8px',overflow:'hidden',boxShadow:'0px 1px 2px 1px #BDC3C7',width: '433px',margin:'auto'}}>
                        <div id="tabla-autorizadas" style={{
                            width: '433px',
                            height: '200px', 
                            overflowY: 'scroll',
                            overflowX: 'scroll',
                            backgroundColor: '#fff',
                            margin: 'auto'
                        }}>
                          <RB.Table>
                            <thead>
                               <tr style={{backgroundColor:'#fafafa'}}>
                                  <th>Nombre</th>
                                  <th>Apellidos</th>
                                  <th width="50px"></th>
                               </tr>
                            </thead>
                            <tbody id="body-tabla-autorizadas-busqueda">
                              <tr>
                                <td colSpan="3">
                                  <center>
                                    <p id="avisoSinResultadosBusquedaAutorizada" style={{color: '#c8c8c8'}}>No hay resultados a mostrar</p>
                                  </center>
                                </td>
                              </tr>
                            </tbody>
                          </RB.Table>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-12 col-md-12 col-lg-6">
                    <div style={{margin:'auto',backgroundColor:'#e6e6e6',borderRadius:'9px',padding:'1px',width: '404px'}}>
                        <p style={{fontSize:'11pt',fontWeight:'500',color:'#868686',marginBottom:'11px',marginLeft:'10px',marginTop:'8px',float:'left'}}>Personas autorizadas a asociar con alumno</p>
                        <a href="/administrador/alta/responsable" target="_blank">
                          <img className="botonImagen" title="Registrar persona autorizada" style={{float:'right',marginRight:'12px',marginTop:'11px'}} src={nuevo} width="17px" />
                        </a>
                        <div style={{borderRadius:'0px 0px 8px 8px',overflow:'hidden',boxShadow:'0px 1px 2px 1px #BDC3C7',width: '400px',margin:'auto'}}>
                          <div id="tabla-autorizadas" style={{
                              width: '400px',
                              height: '200px', 
                              overflowY: 'scroll',
                              overflowX: 'scroll',
                              backgroundColor: '#fff',
                              margin: 'auto'
                          }}>
                            <RB.Table>
                              <thead>
                                 <tr style={{backgroundColor:'#fafafa'}}>
                                    <th>Nombre</th>
                                    <th>Apellidos</th>
                                    <th width="50px"></th>
                                 </tr>
                              </thead>
                              <tbody id="body-tabla-autorizadas-asociados">
                              </tbody>
                            </RB.Table>
                          </div>
                        </div>
                    </div>
                  </div>

                </div>

                <div id="alerta-RA-parteV" style={{fontSize:'11pt',color:'#D45454',marginBottom:"10px",marginTop:"10px"}}></div>

                <RB.Button variant="info" id="boton1_RA-parteV" className="boton-presionado" type="submit" style={{float:'right',marginRight:'10px',width:'110px',fontSize:'10pt'}}>Siguiente</RB.Button>

                <p className="botonP" onClick={atrasParteV}>Atrás</p>

                <Link to="/administrador/alumnos" id="boton_RA-parteV" style={{float:'right',marginRight:'35px',lineHeight:'2.4',color:'#17a2b8',fontSize:'10pt',textDecoration:'none'}}>Cancelar</Link>
              </form>

              <form autoComplete="off" onSubmit={siguienteParteVI} style={{margin:'0px',overflow:'hidden',display:'none'}} id="parteVI">
                <div className="row">

                  <div className="col-sm-12 col-md-12 col-lg-6">
                    <div style={{borderRadius:'8px',backgroundColor:'#e6e6e6',width:"437px",margin:'auto',marginBottom:'30px'}}>
                      <div className="wrap">
                         <div className="search">
                            <input type="search" onSearch={buscarActividad} className="searchTerm" id="busqueda-actividad" placeholder="Buscar por clave o nombre"/>
                            <button className="searchButton" onClick={buscarActividad} id="boton-busqueda-actividad">
                              <img src={lupa} style={{marginTop:'-6px',marginLeft:'-2px',opacity:'0.6'}} width="18px" />
                            </button>
                         </div>
                      </div>

                      <div style={{borderRadius:'0px 0px 8px 8px',overflow:'hidden',boxShadow:'0px 1px 2px 1px #BDC3C7',width: '433px',margin:'auto'}}>
                        <div id="tabla-actividades" style={{
                            width: '433px',
                            height: '200px', 
                            overflowY: 'scroll',
                            overflowX: 'scroll',
                            backgroundColor: '#fff',
                            margin: 'auto'
                        }}>
                          <RB.Table>
                            <thead>
                               <tr style={{backgroundColor:'#fafafa'}}>
                                  <th>Clave</th>
                                  <th>Nombre</th>
                                  <th width="50px"></th>
                               </tr>
                            </thead>
                            <tbody id="body-tabla-actividades-busqueda">
                              <tr>
                                <td colSpan="3">
                                  <center>
                                    <p id="avisoSinResultadosBusquedaActividad" style={{color: '#c8c8c8'}}>No hay resultados a mostrar</p>
                                  </center>
                                </td>
                              </tr>
                            </tbody>
                          </RB.Table>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-12 col-md-12 col-lg-6">
                    <div style={{margin:'auto',backgroundColor:'#e6e6e6',borderRadius:'9px',padding:'1px',width: '404px'}}>
                        <p style={{fontSize:'11pt',fontWeight:'500',color:'#868686',marginBottom:'11px',marginLeft:'10px',marginTop:'8px',float:'left'}}>Cursos a asociar con alumno</p>
                        <a href="/administrador/alta/curso" target="_blank">
                          <img className="botonImagen" title="Registrar curso" style={{float:'right',marginRight:'12px',marginTop:'11px'}} src={nuevo} width="17px" />
                        </a>
                        <div style={{borderRadius:'0px 0px 8px 8px',overflow:'hidden',boxShadow:'0px 1px 2px 1px #BDC3C7',width: '400px',margin:'auto'}}>
                          <div id="tabla-actividades" style={{
                              width: '400px',
                              height: '200px', 
                              overflowY: 'scroll',
                              overflowX: 'scroll',
                              backgroundColor: '#fff',
                              margin: 'auto'
                          }}>
                            <RB.Table>
                              <thead>
                                 <tr style={{backgroundColor:'#fafafa'}}>
                                    <th>Clave</th>
                                    <th>Nombre</th>
                                    <th width="50px"></th>
                                 </tr>
                              </thead>
                              <tbody id="body-tabla-actividades-asociados">
                                <tr>
                                  <td colSpan="3">
                                    <center>
                                      <p id="avisoSinAsociacionesActividad" style={{color: '#c8c8c8'}}>Ningún curso asociado</p>
                                    </center>
                                  </td>
                                </tr>
                              </tbody>
                            </RB.Table>
                          </div>
                        </div>
                    </div>
                  </div>

                </div>

                <div id="alerta-RA-parteVI" style={{fontSize:'11pt',color:'#D45454',marginBottom:"10px",marginTop:"10px"}}></div>

                <RB.Button variant="info" id="boton1_RA-parteVI" className="boton-presionado" type="submit" style={{float:'right',marginRight:'10px',width:'110px',fontSize:'10pt'}}>Siguiente</RB.Button>

                <p className="botonP" onClick={atrasParteVI}>Atrás</p>

                <Link to="/administrador/alumnos" id="boton_RA-parteVI" style={{float:'right',marginRight:'35px',lineHeight:'2.4',color:'#17a2b8',fontSize:'10pt',textDecoration:'none'}}>Cancelar</Link>
              </form>

              <form autoComplete="off" onSubmit={siguienteParteVII} style={{margin:'0px',overflow:'hidden',display:'none'}} id="parteVII">
                <div className="row">

                  <div className="col-sm-12 col-md-12 col-lg-6">
                    <div style={{borderRadius:'8px',backgroundColor:'#e6e6e6',width:"437px",margin:'auto',marginBottom:'30px'}}>
                      <div className="wrap">
                         <div className="search">
                            <input type="search" onSearch={buscarProducto} className="searchTerm" id="busqueda-producto" placeholder="Buscar producto por clave o nombre"/>
                            <button className="searchButton" onClick={buscarProducto} id="boton-busqueda-producto">
                              <img src={lupa} style={{marginTop:'-6px',marginLeft:'-2px',opacity:'0.6'}} width="18px" />
                            </button>
                         </div>
                      </div>

                      <div style={{borderRadius:'0px 0px 8px 8px',overflow:'hidden',boxShadow:'0px 1px 2px 1px #BDC3C7',width: '433px',margin:'auto'}}>
                        <div id="tabla-productos" style={{
                            width: '433px',
                            height: '200px', 
                            overflowY: 'scroll',
                            overflowX: 'scroll',
                            backgroundColor: '#fff',
                            margin: 'auto'
                        }}>
                          <RB.Table>
                            <thead>
                               <tr style={{backgroundColor:'#fafafa'}}>
                                  <th>Clave</th>
                                  <th>Nombre</th>
                                  <th width="50px"></th>
                               </tr>
                            </thead>
                            <tbody id="body-tabla-productos-busqueda">
                              <tr>
                                <td colSpan="3">
                                  <center>
                                    <p id="avisoSinResultadosBusquedaProducto" style={{color: '#c8c8c8'}}>No hay resultados a mostrar</p>
                                  </center>
                                </td>
                              </tr>
                            </tbody>
                          </RB.Table>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-12 col-md-12 col-lg-6">
                    <div style={{margin:'auto',backgroundColor:'#e6e6e6',borderRadius:'9px',padding:'1px',width: '404px'}}>
                        <p style={{fontSize:'11pt',fontWeight:'500',color:'#868686',marginBottom:'11px',marginLeft:'10px',marginTop:'8px',float:'left'}}>Productos a asociar con alumno</p>
                        <a href="/administrador/alta/producto" target="_blank">
                          <img className="botonImagen" title="Registrar producto" style={{float:'right',marginRight:'12px',marginTop:'11px'}} src={nuevo} width="17px" />
                        </a>
                        <div style={{borderRadius:'0px 0px 8px 8px',overflow:'hidden',boxShadow:'0px 1px 2px 1px #BDC3C7',width: '400px',margin:'auto'}}>
                          <div id="tabla-productos" style={{
                              width: '400px',
                              height: '200px', 
                              overflowY: 'scroll',
                              overflowX: 'scroll',
                              backgroundColor: '#fff',
                              margin: 'auto'
                          }}>
                            <RB.Table>
                              <thead>
                                 <tr style={{backgroundColor:'#fafafa'}}>
                                    <th>Clave</th>
                                    <th>Nombre</th>
                                    <th>Cantidad</th>
                                    <th width="50px"></th>
                                 </tr>
                              </thead>
                              <tbody id="body-tabla-productos-asociados">
                                <tr>
                                  <td colSpan="4">
                                    <center>
                                      <p id="avisoSinAsociacionesProducto" style={{color: '#c8c8c8'}}>Ningún producto asociado</p>
                                    </center>
                                  </td>
                                </tr>
                              </tbody>
                            </RB.Table>
                          </div>
                        </div>
                    </div>
                  </div>

                </div>

                <div id="alerta-RA-parteVII" style={{fontSize:'11pt',color:'#D45454',marginBottom:"10px",marginTop:"10px"}}></div>

                <RB.Button variant="info" id="boton1_RA-parteVII" className="boton-presionado" type="submit" style={{float:'right',marginRight:'10px',width:'110px',fontSize:'10pt'}}>Crear cuenta</RB.Button>

                <p className="botonP" onClick={atrasParteVII}>Atrás</p>

                <Link to="/administrador/alumnos" id="boton_RA-parteVII" style={{float:'right',marginRight:'35px',lineHeight:'2.4',color:'#17a2b8',fontSize:'10pt',textDecoration:'none'}}>Cancelar</Link>
              </form>

            </div>
					</div>
				</div>
			</div>

			</React.Fragment>
		);

	}

}

export default RegistroAlumno;