import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import {auth} from '../firebase-config';
import {db} from '../firebase-config';
import logo_header from '../assets/images/logo_header.png';
import {Link} from 'react-router-dom';
import * as RB from "react-bootstrap";
import swal from 'sweetalert2';
import firebase from 'firebase/app';
import moment from 'moment';
import 'firebase/firestore'

class EditarProducto extends React.Component{
	//constructor del componente
	constructor(props){
		super(props)
		//Estado inicial del componente
		this.state = {
			clave: "",
			costo: "",
			descripcion: "",
			fechaVigencia: "",
			nombre: ""
		}
	}
	//Datos iniciales del producto
	datosIniciales = {
		clave: "",
		costo: "",
		descripcion: "",
		fechaVigencia: "",
		nombre: "",
		tipo: "",
		uid: ""
	}
	//Auxiliares para conocer si se ha cambia el estado inicial de los datos
	registroCambios = {
		cambio_clave: false,
		cambio_costo: false,
		cambio_descripcion: false,
		cambio_fechaVigencia: false,
		cambio_nombre: false
	}
	//Se ejecuta al cargar componente
	componentDidMount() {
		//Se verifican las props
		if(typeof this.props.match.params !== 'undefined') {
			//Se guarda el id del documento, recibido por props
			const {uid} = this.props.match.params;
			this.datosIniciales.uid = uid;
			//----------------------------------------------------------------------------------
			//---------Se actualizan los valores iniciales con los datos del usuario------------
			//----------------------------------------------------------------------------------
			db.collection("productos").doc(uid).get().then(doc => {
			    if (doc.exists) {
			    	//************************************************************************
			        //Se guardan los datos iniciales--------------------
			        this.setState({clave: doc.data().clave});
			        this.setState({costo: doc.data().costo});
			        this.setState({descripcion: doc.data().descripcion});
			        this.setState({nombre: doc.data().nombre});
			        this.datosIniciales.clave = doc.data().clave;
			        this.datosIniciales.costo = doc.data().costo;
			        this.datosIniciales.descripcion = doc.data().descripcion;
			        this.datosIniciales.nombre = doc.data().nombre;
			        this.datosIniciales.tipo = doc.data().tipo;
			        if(doc.data().fechaVigencia !== null){
			        	this.setState({fechaVigencia: moment(doc.data().fechaVigencia.toDate()).format('YYYY-MM-DD')});
			        	this.datosIniciales.fechaVigencia = moment(doc.data().fechaVigencia.toDate()).format('YYYY-MM-DD');
			        }
			        //--------------------------------------------------
			        if(doc.data().tipo == "producto"){
			        	document.getElementById("productoRA").click();
			        } else {
			        	document.getElementById("servicioRA").click();
			        }
			        //************************************************************************
			    } else {
			    	//Sí documento no existe, se regresa a página anterior
			        document.getElementById("boton_RA").click();
			    }
			}).catch(error => {
			    //Error al leer documento
			});
			//----------------------------------------------------------------------------------
			//----------------------------------------------------------------------------------
			//----------------------------------------------------------------------------------
		} else {
			//Sí no se pueden leer las props se regresa a página anterior
			document.getElementById("boton_RA").click();
		}
	}
	render(){
		//-------------------------------------------------------------
		//-------------------------------------------------------------------------------------------------------------------------
		//--------------------------------------------------INICIAR REGISTRO-------------------------------------------------------
		//-------------------------------------------------------------------------------------------------------------------------
		const handleSubmit = e =>{
			e.preventDefault();//Evita la recarga de la página

				//Alert "loading"----------------------
				const alert_cargando = swal.fire({
				  width: '300px',
				  allowOutsideClick: false,
				  showConfirmButton: false,
				  didOpen: () => {
				    swal.showLoading()
				  },
				  customClass: {
					   title: 'titulo-alert-loading',
				     actions: 'alert-loader'
					}
				})
				//-------------------------------------
				//-------------------------------------------------------------------------------------------------
				//Ahora se crea su documento en la colección "productos"-----------------------------------------
				//-------------------------------------------------------------------------------------------------
				var tipoRA = "";if(document.getElementById("productoRA").checked){tipoRA = "producto";}else{tipoRA = "servicio";}
				var fechaVigenciaRA;if(document.getElementById("servicioRA").checked){
					fechaVigenciaRA = firebase.firestore.Timestamp.fromDate(new Date(document.getElementById("fechaVigenciaRA").value.replace(/-/g, '\/')));
				}else{fechaVigenciaRA = null;}

				db.collection("productos").doc(this.datosIniciales.uid).update({
					clave: document.getElementById("claveRA").value.trim().toUpperCase(),
					costo: document.getElementById("costoRA").value,
					descripcion: document.getElementById("descripcionRA").value.trim().toUpperCase(),
					fechaVigencia: fechaVigenciaRA,
				    nombre: document.getElementById("nombreRA").value.trim().toUpperCase(),
				    tipo: tipoRA
				})
				.then(function() {
					//EL REGISTRO SE COMPLETÓ POR COMPLETO CON ÉXITO-------------
					  alert_cargando.close();
					  swal.fire({
					      title:"<p style='font-size: 13pt;font-weight:600;color: #282828;'>Cambios guardados</p>",
					      icon: 'success',
					      iconColor: '#17a2b8',
					      width: '350px',
					      showConfirmButton: false,
					      timer: 2500
					    }).then((result) => {
						  	//Se regresa a inicio
					    	document.getElementById("boton_RA").click();
						});
				    //-----------------------------------------------------------
				})
				.catch(function(error) {
					alert_cargando.close();
				    //alerta por error
					document.getElementById("alerta-RA").innerHTML = 
					'<p">'+
						'Error al registrar: '+
						error+
					'</p>';
				});
				//-------------------------------------------------------------------------------------------------
				//-------------------------------------------------------------------------------------------------
				//-------------------------------------------------------------------------------------------------

		}
		//-------------------------------------------------------------------------------------------------------------------------
		//-------------------------------------------------------------------------------------------------------------------------
		//-------------------------------------------------------------------------------------------------------------------------
		//*************************************************************************************************************************************
		//***************************************************VALIDACIÓN DE LOS DATOS***********************************************************
		//*************************************************************************************************************************************
		const validarFormulario = (e) => {
			switch(e.target.name){
				case "clave":
					this.setState({clave: e.target.value});
					if(e.target.value !== this.datosIniciales.clave){
						this.registroCambios.cambio_clave = true;
					} else {
						this.registroCambios.cambio_clave = false;
					}
				break;
				case "costo":
					this.setState({costo: e.target.value});
					if(e.target.value !== this.datosIniciales.costo){
						this.registroCambios.cambio_costo = true;
					} else {
						this.registroCambios.cambio_costo = false;
					}
				break;
				case "descripcion":
					this.setState({descripcion: e.target.value});
					if(e.target.value !== this.datosIniciales.descripcion){
						this.registroCambios.cambio_descripcion = true;
					} else {
						this.registroCambios.cambio_descripcion = false;
					}
				break;
				case "fechaVigencia":
					this.setState({fechaVigencia: e.target.value});
					if(e.target.value !== this.datosIniciales.fechaVigencia){
						this.registroCambios.cambio_fechaVigencia = true;
					} else {
						this.registroCambios.cambio_fechaVigencia = false;
					}
				break;
				case "nombre":
					this.setState({nombre: e.target.value});
					if(e.target.value !== this.datosIniciales.nombre){
						this.registroCambios.cambio_nombre = true;
					} else {
						this.registroCambios.cambio_nombre = false;
					}
				break;
			}
			//Sí los datos cambiaron, en relación con los originales; se activa el botón "actualizar datos"
			if (this.registroCambios.cambio_clave || this.registroCambios.cambio_nombre || 
				this.registroCambios.cambio_descripcion || this.registroCambios.cambio_costo ||
				this.registroCambios.cambio_fechaVigencia){
				document.getElementById("boton1_RA").disabled = false;
			}
			//Sí los datos no han cambiado, se desactiva el botón "actualizar datos"
			if (!this.registroCambios.cambio_clave && !this.registroCambios.cambio_nombre &&
				!this.registroCambios.cambio_descripcion && !this.registroCambios.cambio_costo && 
				!this.registroCambios.cambio_fechaVigencia){
				//--------------------------------------------------------------------------------------
				if(document.getElementById("servicioRA").checked){
					if("servicio" == this.datosIniciales.tipo){
						document.getElementById("boton1_RA").disabled = true;
					}
				} else {
					if("producto" == this.datosIniciales.tipo){
						document.getElementById("boton1_RA").disabled = true;
					}
				}
				//--------------------------------------------------------------------------------------
			}
		}
		//Solo permite ingresar números
		const validarNumerico = (e) => {
     		if(isNaN(e.target.value+String.fromCharCode(e.charCode))){
        		e.preventDefault();
     		}
  		}
		//*************************************************************************************************************************************
		//*************************************************************************************************************************************
		//*************************************************************************************************************************************
		//CONTROL SELECCIÓN SERVICIO----------------------------------------------------------------------------------
		const changeCheckServicio = () => {
			if(document.getElementById("servicioRA").checked){
				document.getElementById("div-input-fechaVigencia").style.display = "block";
				document.getElementById("fechaVigenciaRA").required = true;
				if("servicio" == this.datosIniciales.tipo && !this.registroCambios.cambio_clave &&
					!this.registroCambios.cambio_nombre &&
					!this.registroCambios.cambio_descripcion && !this.registroCambios.cambio_costo && 
					!this.registroCambios.cambio_fechaVigencia){
					document.getElementById("boton1_RA").disabled = true;
				} else {
					document.getElementById("boton1_RA").disabled = false;
				}
			} else {
				document.getElementById("div-input-fechaVigencia").style.display = "none";
				document.getElementById("fechaVigenciaRA").required = false;
				if("producto" == this.datosIniciales.tipo && !this.registroCambios.cambio_clave &&
					!this.registroCambios.cambio_nombre &&
					!this.registroCambios.cambio_descripcion && !this.registroCambios.cambio_costo && 
					!this.registroCambios.cambio_fechaVigencia){
					document.getElementById("boton1_RA").disabled = true;
				} else {
					document.getElementById("boton1_RA").disabled = false;
				}
			}
		}
		//------------------------------------------------------------------------------------------------------------
		return(
			<React.Fragment>

			<div className="global-container">
				<div className="card login-form" id="cardFRA" style={{ width: '800px', paddingTop:'10px' }}>
					<div className="card-body" id="tarjeta">
						<h4 className="card-title" style={{fontSize:'18pt',fontWeight:'500'}}>Editar registro de producto</h4>
						<div className="card-text" style={{marginTop:'40px'}}>
							<form autoComplete="off" onSubmit={handleSubmit} style={{margin:'0px',overflow:'hidden'}}>

								<div className="row">
									<div className="col-sm-12 col-md-6 col-lg-6">
										<div className="form-group input-field" style={{margin: 'auto'}}>
											<input className="input_correcto input" type="text" id="claveRA" name="clave" onChange={validarFormulario} value={this.state.clave} required />
											<label className="label" for="claveRA">Clave</label>
											<p id="error-claveRA"></p>
										</div>
									</div>

									<div className="col-sm-12 col-md-6 col-lg-6">
										<div className="form-group input-field" style={{margin: 'auto'}}>
											<input className="input_correcto input" type="text" id="nombreRA" name="nombre" onChange={validarFormulario} value={this.state.nombre} required />
											<label className="label" for="nombreRA">Nombre</label>
											<p id="error-nombreRA"></p>
										</div>
									</div>

									<div className="col-sm-12 col-md-6 col-lg-6">
										<div className="form-group input-field" style={{margin: 'auto'}}>
											<input className="input_correcto input" type="text" id="descripcionRA" name="descripcion" onChange={validarFormulario} value={this.state.descripcion} required />
											<label className="label" for="descripcionRA">Descripción</label>
											<p id="error-descripcionRA"></p>
										</div>
									</div>

									<div className="col-sm-12 col-md-6 col-lg-6">
										<div className="form-group input-field" style={{margin: 'auto'}}>
											<input className="input_correcto input" type="text" id="costoRA" name="costo" onKeyPress={validarNumerico} onChange={validarFormulario} value={this.state.costo} required />
											<label className="label" for="costoRA">Costo</label>
											<p id="error-costoRA"></p>
										</div>
									</div>

									<div className="col-sm-12 col-md-6 col-lg-6">
										<div className="form-group input-field" style={{margin:'auto'}}>
											<div className="cont-grupo-radio">
											    <center>
											    	<input type="radio" name="tipo" id="productoRA" onClick={(e) => {changeCheckServicio();}} />
											    	<label for="productoRA" style={{marginLeft:'5px'}}>Producto</label>
											    	<input type="radio" name="tipo" id="servicioRA" style={{marginLeft:'20px'}} onClick={(e) => {changeCheckServicio();}} />
											    	<label for="servicioRA" style={{marginLeft:'5px'}}>Servicio</label>
											    </center>
											</div>
											<label className="label-grupo-radio">Tipo</label>
											<p id="error-tipoRA"></p>
										</div>
									</div>

									<div className="col-sm-12 col-md-6 col-lg-6">
										<div className="form-group input-field" id="div-input-fechaVigencia" style={{margin: 'auto',display: 'none'}}>
											<input className="input_correcto input" type="date" id="fechaVigenciaRA" name="fechaVigencia" onChange={validarFormulario} value={this.state.fechaVigencia} />
											<label className="label" for="fechaVigenciaRA">Fecha de vigencia</label>
											<p id="error-fechaVigenciaRA"></p>
										</div>
									</div>
								</div>

								<div id="alerta-RA" style={{fontSize:'11pt',color:'#D45454',marginBottom:"10px",marginTop:"10px"}}></div>

								<RB.Button disabled variant="info" id="boton1_RA" className="button-disabled boton-presionado" type="submit" style={{float:'right',marginRight:'10px',width:'140px',fontSize:'10pt'}}>Actualizar datos</RB.Button>

								<Link to="/administrador/productos" id="boton_RA" style={{float:'right',marginRight:'35px',lineHeight:'2.4',color:'#17a2b8',fontSize:'10pt',textDecoration:'none'}}>Cancelar</Link>
							</form>
						</div>
					</div>
				</div>
			</div>

			</React.Fragment>
		);
	}
}

export default EditarProducto;