import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import {auth} from '../firebase-config';
import {auth_aux} from '../firebase-config';
import {db} from '../firebase-config';
import logo_header from '../assets/images/logo_header.png';
import {Link} from 'react-router-dom';
import * as RB from "react-bootstrap";
import swal from 'sweetalert2';
import firebase from 'firebase/app';
import 'firebase/auth'
import advertencia from '../assets/images/advertencia.png';
import moment from 'moment';
import key from '../assets/images/key.png';
import eliminar from '../assets/images/eliminar.png';
import lupa from '../assets/images/lupa.png';
import nuevo from '../assets/images/nuevo.png';
import asociar from '../assets/images/asociar.png';
import mas from '../assets/images/mas.png';

class RegistroSesion extends React.Component{
	//constructor del componente
	constructor(props){
		super(props)
		//Estado inicial del componente
		this.state = {
			cursos: []
		}
	}
	//Se ejecuta al cargar componente
	componentDidMount() {
		//-----------------------------------------------------------------------------------------------------------------
		db.collection("actividades").orderBy("clave").get().then(querySnapshot => {
	    	const docs = [];
	        querySnapshot.forEach(doc => {
	            //-------------------------------------------------------------------------
	            docs.push({
	            	clave: doc.data().clave,
	            	nombre: doc.data().nombre,
	            	id: doc.id
	            });
	            //-------------------------------------------------------------------------
	        });
	        this.setState({cursos: docs});
	    });
		//-----------------------------------------------------------------------------------------------------------------
	}
	//----------------------------------------------------------------------------------------------------------------------------------
	//----------------------------------------------------------------------------------------------------------------------------------
	//----------------------------------------------------------------------------------------------------------------------------------
	//
	//-----------------------------------------------------------------------------------------------------------------------------------
	//-----------------------------------------------------------------------------------------------------------------------------------
	//-----------------------------------------------------------------------------------------------------------------------------------
	render(){
		//-------------------------------------------------------------------------------------------------------------------------
		//--------------------------------------------------INICIAR REGISTRO-------------------------------------------------------
		//-------------------------------------------------------------------------------------------------------------------------
		const handleSubmit = e => {
			e.preventDefault();//Evita la recarga de la página
			document.getElementById("alerta-RA").innerHTML = '';//Borra alertas pasadas

			//VALIDACIÓN---------------------------------------------------------------------------------------------------
			var formulario_incorrecto = false;
			if(instructores.length == 0){
				//NO HAY INSTRUCTORES SELECCIONADOS
				document.getElementById("alerta-RA").innerHTML = '<p>Seleccione al menos un instructor</p>';
				formulario_incorrecto = true;
			}
			if(curso.length == 0){
				//NO HAY UN CURSO SELECCIONADO
				document.getElementById("alerta-RA").innerHTML = '<p>Seleccione un curso</p>';
				formulario_incorrecto = true;
			}
			//-------------------------------------------------------------------------------------------------------------

			//REGISTRO-----------------------------------------------------------------------------------------------------
			if(!formulario_incorrecto){
				//Alert "loading"----------------------
				const alert_cargando = swal.fire({
				  width: '300px',
				  allowOutsideClick: false,
				  showConfirmButton: false,
				  didOpen: () => {
				    swal.showLoading()
				  },
				  customClass: {
					   title: 'titulo-alert-loading',
					   actions: 'alert-loader'
				  }
				})
				//-------------------------------------
				instructores.map(instructor => {
					db.collection("sesiones").doc().set({
					    actividad: curso[0],
					    fechaRealizacion: firebase.firestore.Timestamp.fromDate(new Date(document.getElementById("fechaRealizacionRA").value.replace(/-/g, '\/'))),
					    horas: document.getElementById("horasRA").value,
					    instructor: instructor
					});
				});
				alert_cargando.close();
			    //Mensaje de éxito
				swal.fire({
				  title:"<p style='font-size:13pt;font-weight:600;color: #282828;'>Sesión registrada</p>",
				  icon: 'success',
				  iconColor: '#17a2b8',
				  width: '300px',
				  showConfirmButton: false,
				  timer: 2500,
				  allowOutsideClick: false
				}).then((result) => {
				  	//se ejecuta después de mensaje de éxito
				  	document.getElementById("boton_RA").click();
				});
			}
			//-------------------------------------------------------------------------------------------------------------
		}
		//-------------------------------------------------------------------------------------------------------------------------
		//-------------------------------------------------------------------------------------------------------------------------
		//-------------------------------------------------------------------------------------------------------------------------
		//****************************************************************************************************************
		//****************************************************************************************************************
		//****************************************************************************************************************
		//BÚSQUEDA DE INSTRUCTOR (CONSULTA FIRESTORE)**********************************************************
	    const buscarInstructor = (e) => {
	      e.preventDefault();//Evita la recarga de la página
	      var textoBusqueda = document.getElementById("busqueda-instructor").value;
	      if(textoBusqueda !== ""){
	        //------------------------------------------------------------------------------------------------------------
	        //------------------------------------------------------------------------------------------------------------
	        //------------------------------------------------------------------------------------------------------------
	        db.collection("instructores").where("correoElectronico", "==", textoBusqueda.trim()).get().then(function(querySnapshot) {
	            var contador = 0;
	            document.getElementById("body-tabla-instructores-busqueda").innerHTML = "";
	            querySnapshot.forEach(function(doc) {
	                contador++;
	                //*****************************************************************************************
	                document.getElementById("body-tabla-instructores-busqueda").insertAdjacentHTML("beforeend", 
	                "<tr class='filaTabla filaTabla-hover'>"+
	                  "<td>"+doc.data().nombre+"</td>"+
	                  "<td>"+doc.data().apellidos+"</td>"+
	                  "<td>"+
	                    "<div class='toggle-botonTabla' id='"+doc.id+"busqueda-inst"+"' title='Seleccionar' style='margin-right:15px'>"+
	                      "<img class='botonTabla' src="+nuevo+" width='17px' />"+
	                    "</div>"+
	                  "</td>"+
	                "</tr>");
	                //Evento de la fila (onclick en asociar)----------------------------------------
	                document.getElementById(doc.id+"busqueda-inst").addEventListener('click', (e) => {
	                  seleccionarInstructor(doc.id, doc.data().nombre, doc.data().apellidos);
	                });
	                //------------------------------------------------------------------------------
	                //*****************************************************************************************
	            });
	            if(contador == 0){
	              //No hubo resultados para correo electrónico ------------------------------------------------------------------
	              //-------------------------------------------------------------------------------------------------------------
	              //-------------------------------------------------------------------------------------------------------------
	              db.collection("instructores").where("telefono", "==", textoBusqueda.trim()).get().then(function(querySnapshot) {
	                  document.getElementById("body-tabla-instructores-busqueda").innerHTML = "";
	                  querySnapshot.forEach(function(doc) {
	                      contador++;
	                      //*****************************************************************************************
	                      document.getElementById("body-tabla-instructores-busqueda").insertAdjacentHTML("beforeend", 
	                      "<tr class='filaTabla filaTabla-hover'>"+
	                        "<td>"+doc.data().nombre+"</td>"+
	                        "<td>"+doc.data().apellidos+"</td>"+
	                        "<td>"+
	                          "<div class='toggle-botonTabla' id='"+doc.id+"busqueda-inst"+"' title='Seleccionar' style='margin-right:15px'>"+
	                            "<img class='botonTabla' src="+nuevo+" width='17px' />"+
	                          "</div>"+
	                        "</td>"+
	                      "</tr>");
	                      //Evento de la fila (onclick en asociar)----------------------------------------
	                      document.getElementById(doc.id+"busqueda-inst").addEventListener('click', (e) => {
	                        seleccionarInstructor(doc.id, doc.data().nombre, doc.data().apellidos);
	                      });
	                      //------------------------------------------------------------------------------
	                      //*****************************************************************************************
	                  });
	                  if(contador == 0){
	                    //No hubo resultados para teléfono (NINGÚN RESULTADO PARA LA BÚSQUEDA)
	                    document.getElementById("body-tabla-instructores-busqueda").innerHTML = 
	                    "<tr>"+
	                      "<td colSpan='3'>"+
	                        "<center>"+
	                          "<p style='color:#c8c8c8'>No hay resultados a mostrar</p>"+
	                        "</center>"+
	                      "</td>"+
	                    "</tr>";
	                  }
	              }).catch(function(error) {
	                  //Error al realizar consulta
	              });
	              //-------------------------------------------------------------------------------------------------------------
	              //-------------------------------------------------------------------------------------------------------------
	              //-------------------------------------------------------------------------------------------------------------
	            }
	        }).catch(function(error) {
	            //Error al realizar consulta
	        });
	        //------------------------------------------------------------------------------------------------------------
	        //------------------------------------------------------------------------------------------------------------
	        //------------------------------------------------------------------------------------------------------------
	      }
	    }
	    //*****************************************************************************************************
	    //************************************** SELECCIONAR INSTRUCTOR *********************************************
	    var instructores = [];
	    const seleccionarInstructor = (uid, nombre, apellidos) => {
	      //Sí la tabla está vacía, se elimina el mensaje de "Ninguna selección"-------
	      if(instructores.length == 0){
	        document.getElementById("body-tabla-instructores-asociados").innerHTML = "";
	      }
	      //---------------------------------------------------------------------------
	      //Se guarda en array "instructores" la referencia-----------------------------------------------------------------
	      //Sí ya existe el valor que se intenta guardar, se omite
	      var aux = db.collection('instructores').doc(uid);
	      if(!(instructores.find(element => element.id == aux.id) !== undefined)){
	        instructores.push(aux);//Guardado en el array "instructores"
	        //Mostrar en Front (Tabla de asociación)----------------------------------------------------------------------------
	        //*****************************************************************************************
	        document.getElementById("body-tabla-instructores-asociados").insertAdjacentHTML("beforeend", 
	        "<tr class='filaTabla filaTabla-hover' id='"+uid+"asociado-fila-inst'>"+
	          "<td>"+nombre+"</td>"+
	          "<td>"+apellidos+"</td>"+
	          "<td>"+
	            "<div class='toggle-botonTabla' id='"+uid+"asociado-inst"+"' title='Eliminar selección' style='margin-right:15px'>"+
	              "<img class='botonTabla' src="+eliminar+" width='17px' />"+
	            "</div>"+
	          "</td>"+
	        "</tr>");
	        //Evento de la fila (onclick en desasociar)----------------------------------------
	        document.getElementById(uid+"asociado-inst").addEventListener('click', (e) => {
	          //--------------------------------------------------------------------------------
	          //---------------------------- Desasociar instructor -----------------------------
	          //--------------------------------------------------------------------------------
	          //Se elimina de la tabla (Front)
	          document.getElementById("body-tabla-instructores-asociados").removeChild(document.getElementById(uid+"asociado-fila-inst"));
	          //Se elimina del array "actividades"
	          instructores.splice(instructores.indexOf(instructores.find(element => element.id == aux.id)), 1);
	          //Sí la tabla se quedó sin elementos
	          if(instructores.length == 0) {
	            document.getElementById("body-tabla-instructores-asociados").innerHTML = 
	            "<tr>"+
	              "<td colSpan='3'>"+
	                "<center>"+
	                  "<p style='color:#c8c8c8'>Ningún instructor seleccionado</p>"+
	                "</center>"+
	              "</td>"+
	            "</tr>";
	          }
	          //--------------------------------------------------------------------------------
	          //--------------------------------------------------------------------------------
	          //--------------------------------------------------------------------------------
	        });
	        //------------------------------------------------------------------------------
	        //*****************************************************************************************
	        //-----------------------------------------------------------------------------------------------------------------
	      }
	      //------------------------------------------------------------------------------------------------------------------
	    }
	    //***********************************************************************************************************
		//****************************************************************************************************************
		//****************************************************************************************************************
		//****************************************************************************************************************

		//****************************************************************************************************************
		//****************************************************************************************************************
		//****************************************************************************************************************
		//BÚSQUEDA DE ACTIVIDAD (CONSULTA FIRESTORE)***********************************************************
	    const buscarActividad = (e) => {

	      e.preventDefault();//Evita la recarga de la página
	      var textoBusqueda = document.getElementById("busqueda-actividad").value.toUpperCase();//Texto a buscar
	      var existeResultado = false;//Se inicia sin resultados
	      document.getElementById("body-tabla-actividades-busqueda").innerHTML = "";//Se borran todos los resultados previos
	      //Sí hay texto que buscar
	      if(textoBusqueda !== ""){
	        //-------------------------------------------------------------------------------------------------------------
	        //Se busca entre todos los cursos coincidencias
		    this.state.cursos.forEach(curso => {
		      if(curso.clave.search(textoBusqueda) >= 0 || curso.nombre.search(textoBusqueda) >= 0){
		        existeResultado = true;//Existe al menos un resultado
		        //-------------------------------------------------------------------------------------------------------
		        document.getElementById("body-tabla-actividades-busqueda").insertAdjacentHTML("beforeend", 
                "<tr class='filaTabla filaTabla-hover'>"+
                  "<td>"+curso.clave+"</td>"+
                  "<td>"+curso.nombre+"</td>"+
                  "<td>"+
                    "<div class='toggle-botonTabla' id='"+curso.id+"busqueda-VI"+"' title='Seleccionar' style='margin-right:15px'>"+
                      "<img class='botonTabla' src="+nuevo+" width='17px' />"+
                    "</div>"+
                  "</td>"+
                "</tr>");
                //Evento de la fila (onclick en asociar)----------------------------------------
                document.getElementById(curso.id+"busqueda-VI").addEventListener('click', (e) => {
                  asociarActividad(curso.id, curso.clave, curso.nombre);
                });
                //------------------------------------------------------------------------------
		        //-------------------------------------------------------------------------------------------------------
		      }
		    });
		    //-------------------------------------------------------------------------------------------------------------
		    //Sí no hubo resultados----------------------------------------------------------------------------------------
		    if(!existeResultado){
		    	document.getElementById("body-tabla-actividades-busqueda").innerHTML = 
	            "<tr>"+
	              "<td colSpan='3'>"+
	                "<center>"+
	                  "<p id='avisoSinResultadosBusquedaActividad' style='color:#c8c8c8'>No hay resultados a mostrar</p>"+
	                "</center>"+
	              "</td>"+
	            "</tr>";
		    }
		    //-------------------------------------------------------------------------------------------------------------
	      } else {
	      	//No hay texto a buscar
	      	document.getElementById("body-tabla-actividades-busqueda").innerHTML = 
            "<tr>"+
              "<td colSpan='3'>"+
                "<center>"+
                  "<p id='avisoSinResultadosBusquedaActividad' style='color:#c8c8c8'>No hay resultados a mostrar</p>"+
                "</center>"+
              "</td>"+
            "</tr>";
	      }

	    }
	    //*****************************************************************************************************
	    //***************************************** ASOCIAR ACTIVIDAD ***********************************************
	    var curso = [];
	    const asociarActividad = (uid, clave, nombre) => {
	      //Sí la tabla está vacía, se elimina el mensaje de "Ninguna selección"-------
	      if(curso.length == 0){
	        document.getElementById("body-tabla-actividades-asociados").innerHTML = "";
	      }
	      //---------------------------------------------------------------------------
	      //Solo se selecciona nuevo curso cuando no hay uno ya seleccionado
	      if(curso.length == 0) {
	      //Se guarda en array "actividades" la referencia--------------------------------------------------------------------
	      //Sí ya existe el valor que se intenta guardar, se omite
	      var aux = db.collection('actividades').doc(uid);
	      if(!(curso.find(element => element.id == aux.id) !== undefined)){
	        curso.push(aux);//Guardado en el array "actividades"
	        //Mostrar en Front (Tabla de asociación)----------------------------------------------------------------------------
	        //*****************************************************************************************
	        document.getElementById("body-tabla-actividades-asociados").insertAdjacentHTML("beforeend", 
	        "<tr class='filaTabla filaTabla-hover' id='"+uid+"asociado-fila-VI'>"+
	          "<td>"+clave+"</td>"+
	          "<td>"+nombre+"</td>"+
	          "<td>"+
	            "<div class='toggle-botonTabla' id='"+uid+"asociado-VI"+"' title='Eliminar selección' style='margin-right:15px'>"+
	              "<img class='botonTabla' src="+eliminar+" width='17px' />"+
	            "</div>"+
	          "</td>"+
	        "</tr>");
	        //Evento de la fila (onclick en desasociar)----------------------------------------
	        document.getElementById(uid+"asociado-VI").addEventListener('click', (e) => {
	          //--------------------------------------------------------------------------------
	          //----------------------------- Desasociar actividad -----------------------------
	          //--------------------------------------------------------------------------------
	          //Se elimina de la tabla (Front)
	          document.getElementById("body-tabla-actividades-asociados").removeChild(document.getElementById(uid+"asociado-fila-VI"));
	          //Se elimina del array "actividades"
	          curso.splice(curso.indexOf(curso.find(element => element.id == aux.id)), 1);
	          //Sí la tabla se quedó sin elementos
	          if(curso.length == 0) {
	            document.getElementById("body-tabla-actividades-asociados").innerHTML = 
	            "<tr>"+
	              "<td colSpan='3'>"+
	                "<center>"+
	                  "<p style='color:#c8c8c8'>Ningún curso seleccionado</p>"+
	                "</center>"+
	              "</td>"+
	            "</tr>";
	          }
	          //--------------------------------------------------------------------------------
	          //--------------------------------------------------------------------------------
	          //--------------------------------------------------------------------------------
	        });
	        //------------------------------------------------------------------------------
	        //*****************************************************************************************
	        //-----------------------------------------------------------------------------------------------------------------
	      }
	      //------------------------------------------------------------------------------------------------------------------
	  	  }
	    }
	    //***********************************************************************************************************
		//****************************************************************************************************************
		//****************************************************************************************************************
		//****************************************************************************************************************
		return(

			<div className="global-container">
				<div className="card login-form tablas-adm" style={{width:'900px'}}>
					<div className="card-body card-tablas-adm" style={{padding:'10px'}}>
						<form autoComplete="off" onSubmit={handleSubmit} style={{margin:'0px',overflow:'hidden'}}>

							<div className="row">
								<div className="col-sm-12 col-md-12 col-lg-12">
									<h3 className="card-title" style={{fontSize:'16pt',fontWeight:'500'}}>Crear registro de sesión</h3>
								</div>
							</div>

							<div className="row">
								<div className="col-sm-12 col-md-6 col-lg-6">
									<div className="form-group input-field" style={{margin:'auto',marginTop:'10px'}}>
										<input className="input_correcto input" type="date" id="fechaRealizacionRA" name="fechaRealizacion" required/>
										<label className="label" for="fechaRealizacionRA">Fecha de realización</label>
									</div>
								</div>
								<div className="col-sm-12 col-md-6 col-lg-6">
									<div className="form-group input-field" style={{margin:'auto',marginTop:'10px'}}>
										<input className="input_correcto input" type="number" id="horasRA" name="horas" onChange={(e) => {if(e.target.value < 1){e.target.value = 1;}}} required/>
										<label className="label" for="horasRA">Horas</label>
									</div>
								</div>
							</div>

							<div className="row" style={{paddingTop:'6px'}}>
				                <div className="col-sm-12 col-md-6 col-lg-6" style={{paddingBottom:'15px'}}>
					                <div style={{borderRadius:'8px',border:'1px solid #D2D5D8',width:"100%",margin:'auto'}}>
					                  <div className="wrap" style={{width:'100%'}}>
					                  	<form autoComplete="off" style={{margin:'0px',padding:'0px'}}>
						                    <div className="search">
						                       <input type="search" onChange={buscarActividad} onSearch={buscarActividad} className="searchTerm" id="busqueda-actividad" placeholder="Buscar curso por clave o nombre"/>
						                       <button className="searchButton" onClick={buscarActividad} id="boton-busqueda-actividad">
						                         <img src={lupa} style={{marginTop:'-6px',marginLeft:'-2px',opacity:'0.6'}} width="18px" />
						                       </button>
						                    </div>
					                    </form>
					                  </div>
					                  <div style={{borderRadius:'0px 0px 7px 7px',overflow:'hidden',width: '100%',margin:'auto'}}>
					                    <div id="tabla-actividades" style={{
					                        width: '100%',
					                        height: '130px', 
					                        overflowY: 'auto',
					                        overflowX: 'auto',
					                        backgroundColor: '#fff',
					                        margin: 'auto'
					                    }}>
					                      <RB.Table>
					                        <thead>
					                           <tr style={{backgroundColor:'#fafafa'}}>
					                              <th>Clave</th>
					                              <th>Nombre</th>
					                              <th width="50px"></th>
					                           </tr>
					                        </thead>
					                        <tbody id="body-tabla-actividades-busqueda">
					                          <tr>
					                            <td colSpan="3">
					                              <center>
					                                <p style={{color: '#c8c8c8'}}>No hay resultados a mostrar</p>
					                              </center>
					                            </td>
					                          </tr>
					                        </tbody>
					                      </RB.Table>
					                    </div>
					                  </div>
					                </div>
				                </div>

				                <div className="col-sm-12 col-md-6 col-lg-6">
				                	<div style={{margin:'auto',backgroundColor:'#fafafa',border:'1px solid #D2D5D8',borderRadius:'9px',padding:'1px',width: '100%'}}>
				                        <p style={{fontSize:'10pt',fontWeight:'500',marginBottom:'11px',marginLeft:'10px',marginTop:'8px',float:'left',color:'#17a2b8'}}>Curso seleccionado</p>
				                        <div style={{borderRadius:'0px 0px 8px 8px',overflow:'hidden',width: '100%',margin:'auto'}}>
				                          <div id="tabla-actividades" style={{
				                              width: '100%',
				                              height: '130px', 
				                              overflowY: 'hidden',
				                              overflowX: 'auto',
				                              backgroundColor: '#fff',
				                              margin: 'auto'
				                          }}>
				                            <RB.Table>
				                              <thead>
				                                 <tr style={{backgroundColor:'#fafafa'}}>
				                                    <th>Clave</th>
				                                    <th>Nombre</th>
				                                    <th width="50px"></th>
				                                 </tr>
				                              </thead>
				                              <tbody id="body-tabla-actividades-asociados">
				                                <tr>
				                                  <td colSpan="3">
				                                    <center>
				                                      <p style={{color: '#c8c8c8'}}>Ningún curso seleccionado</p>
				                                    </center>
				                                  </td>
				                                </tr>
				                              </tbody>
				                            </RB.Table>
				                          </div>
				                        </div>
				                    </div>
				                </div>
			                </div>

							<div className="row">
								<div className="col-sm-12 col-md-6 col-lg-6" style={{paddingBottom:'15px'}}>
					                <div style={{borderRadius:'8px',border:'1px solid #D2D5D8',width:"100%",margin:'auto'}}>
					                  <div className="wrap" style={{width:'100%'}}>
					                     <div className="search">
					                        <input type="search" onSearch={buscarInstructor} className="searchTerm" id="busqueda-instructor" placeholder="Buscar instructor por correo o teléfono"/>
					                        <button className="searchButton" onClick={buscarInstructor} id="boton-busqueda-instructor">
					                          <img src={lupa} style={{marginTop:'-6px',marginLeft:'-2px',opacity:'0.6'}} width="18px" />
					                        </button>
					                     </div>
					                  </div>
					                  <div style={{borderRadius:'0px 0px 7px 7px',overflow:'hidden',width: '100%',margin:'auto'}}>
					                    <div style={{
					                        width: '100%',
					                        height: '160px', 
					                        overflowY: 'auto',
					                        overflowX: 'auto',
					                        backgroundColor: '#fff',
					                        margin: 'auto'
					                    }}>
					                      <RB.Table>
					                        <thead>
					                           <tr style={{backgroundColor:'#fafafa'}}>
					                              <th>Nombre</th>
					                              <th>Apellidos</th>
					                              <th width="50px"></th>
					                           </tr>
					                        </thead>
					                        <tbody id="body-tabla-instructores-busqueda">
					                          <tr>
					                            <td colSpan="3">
					                              <center>
					                                <p style={{color: '#c8c8c8'}}>No hay resultados a mostrar</p>
					                              </center>
					                            </td>
					                          </tr>
					                        </tbody>
					                      </RB.Table>
					                    </div>
					                  </div>
					                </div>
								</div>

								<div className="col-sm-12 col-md-6 col-lg-6">
									<div style={{margin:'auto',backgroundColor:'#fafafa',border:'1px solid #D2D5D8',borderRadius:'9px',padding:'1px',width: '100%'}}>
				                        <p style={{fontSize:'10pt',fontWeight:'500',marginBottom:'11px',marginLeft:'10px',marginTop:'8px',float:'left',color:'#17a2b8'}}>Instructores participantes</p>

				                        <div style={{borderRadius:'0px 0px 8px 8px',overflow:'hidden',width: '100%',margin:'auto'}}>
				                          <div style={{
				                              width: '100%',
				                              height: '160px', 
				                              overflowY: 'auto',
				                              overflowX: 'auto',
				                              backgroundColor: '#fff',
				                              margin: 'auto'
				                          }}>
				                            <RB.Table>
				                              <thead>
				                                 <tr style={{backgroundColor:'#fafafa'}}>
				                                    <th>Nombre</th>
				                                    <th>Apellidos</th>
				                                    <th width="50px"></th>
				                                 </tr>
				                              </thead>
				                              <tbody id="body-tabla-instructores-asociados">
				                                <tr>
				                                  <td colSpan="3">
				                                    <center>
				                                      <p style={{color: '#c8c8c8'}}>Ningún instructor seleccionado</p>
				                                    </center>
				                                  </td>
				                                </tr>
				                              </tbody>
				                            </RB.Table>
				                          </div>
				                        </div>
				                    </div>
								</div>
							</div>

			                <div className="row" style={{paddingTop:'5px',paddingBottom:'0px'}}>
				                <div className="col-sm-12 col-md-12 col-lg-12">
				                	<div id="alerta-RA" style={{fontSize:'11pt',color:'#D45454',marginBottom:"10px",marginTop:"10px"}}></div>

				                	<RB.Button variant="info" id="boton1_RA" className="boton-presionado" type="submit" style={{float:'right',marginRight:'10px',width:'130px',fontSize:'10pt'}}>Registrar sesión</RB.Button>

				                	<Link to="/administrador/sesiones" id="boton_RA" style={{float:'right',marginRight:'35px',lineHeight:'2.4',color:'#17a2b8',fontSize:'10pt',textDecoration:'none'}}>Cancelar</Link>
				                </div>
			                </div>

			                <div className="row" style={{paddingTop:'25px',paddingBottom:'20px'}}>
				                <div className="col-sm-12 col-md-12 col-lg-12">
				                	<center>
				                		<img width="20px" height="20px" style={{marginRight:'5px'}} src={advertencia}/>
				                		<b style={{fontSize:'14',color:'#D45454'}}>Una vez registrada, la información de la sesión no se puede editar ni eliminar.</b>
				                	</center>
				                </div>
			                </div>

		                </form>
					</div>
				</div>
			</div>

		);
	}
}

export default RegistroSesion;