import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import {auth} from '../firebase-config';
import {auth_aux} from '../firebase-config';
import {db} from '../firebase-config';
import logo_header from '../assets/images/logo_header.png';
import {Link} from 'react-router-dom';
import * as RB from "react-bootstrap";
import swal from 'sweetalert2';
import firebase from 'firebase/app';
import advertencia from '../assets/images/advertencia.png';

class RegistroTutor extends React.Component{
	//constructor del componente
	constructor(props){
		super(props)
		//Estado inicial del componente
		this.state = {
			nombre: "",
			apellidos: "",
			telefono: "",
			correo: "",
			datosFiscalesReg: false,
			RFC: "",
			correoElectronicoDF: "",
			razonSocial: "",
			calle: "",
			codigoPostal: "",
			colonia: "",
			estado: "",
			municipio: "",
			numeroExterior: "",
			numeroInterior: ""
		}
	}
	//Datos iniciales del usuario
	datosIniciales = {
		nombre: "",
		apellidos: "",
		telefono: "",
		correo: "",
		datosFiscalesReg: false,
		RFC: "",
		correoElectronicoDF: "",
		razonSocial: "",
		calle: "",
		codigoPostal: "",
		colonia: "",
		estado: "",
		municipio: "",
		numeroExterior: "",
		numeroInterior: "",
		uid: ""
	}
	//Auxiliares para conocer si se ha cambia el estado inicial de los datos
	registroCambios = {
		cambio_nombre: false,
		cambio_apellidos: false,
		cambio_telefono: false,
		cambio_correo: false,
		cambio_datosFiscalesReg: false,
		cambio_RFC: false,
		cambio_correoElectronicoDF: false,
		cambio_razonSocial: false,
		cambio_calle: false,
		cambio_codigoPostal: false,
		cambio_colonia: false,
		cambio_estado: false,
		cambio_municipio: false,
		cambio_numeroExterior: false,
		cambio_numeroInterior: false
	}
	//Se ejecuta al cargar componente
	componentDidMount() {
		//Se verifican las props
		if(typeof this.props.match.params !== 'undefined') {
			//Se guarda el uid del usuarios, recibido por props
			const {uid} = this.props.match.params;
			this.datosIniciales.uid = uid;
			//----------------------------------------------------------------------------------
			//---------Se actualizan los valores iniciales con los datos del usuario------------
			//----------------------------------------------------------------------------------
			db.collection("tutores").doc(uid).get().then(doc => {
			    if (doc.exists) {
			        //Se guardan los datos iniciales--------------------
			        this.setState({nombre: doc.data().nombre});
			        this.setState({apellidos: doc.data().apellidos});
			        this.setState({telefono: doc.data().telefono});
			        this.setState({correo: doc.data().correoElectronico});
			        this.setState({datosFiscalesReg: doc.data().datosFiscalesReg});
			        this.setState({RFC: doc.data().datosFiscales.RFC});
			        this.setState({correoElectronicoDF: doc.data().datosFiscales.correoElectronicoDF});
			        this.setState({razonSocial: doc.data().datosFiscales.razonSocial});
			        this.setState({calle: doc.data().datosFiscales.direccion.calle});
			        this.setState({codigoPostal: doc.data().datosFiscales.direccion.codigoPostal});
			        this.setState({colonia: doc.data().datosFiscales.direccion.colonia});
			        this.setState({estado: doc.data().datosFiscales.direccion.estado});
			        this.setState({municipio: doc.data().datosFiscales.direccion.municipio});
			        this.setState({numeroExterior: doc.data().datosFiscales.direccion.numeroExterior});
			        this.setState({numeroInterior: doc.data().datosFiscales.direccion.numeroInterior});
			        this.datosIniciales.nombre = doc.data().nombre;
			        this.datosIniciales.apellidos = doc.data().apellidos;
			        this.datosIniciales.telefono = doc.data().telefono;
			        this.datosIniciales.correo = doc.data().correoElectronico;
			        this.datosIniciales.datosFiscalesReg = doc.data().datosFiscalesReg;
			        this.datosIniciales.RFC = doc.data().datosFiscales.RFC;
			        this.datosIniciales.correoElectronicoDF = doc.data().datosFiscales.correoElectronicoDF;
			        this.datosIniciales.razonSocial = doc.data().datosFiscales.razonSocial;
			        this.datosIniciales.calle = doc.data().datosFiscales.direccion.calle;
			        this.datosIniciales.codigoPostal = doc.data().datosFiscales.direccion.codigoPostal;
			        this.datosIniciales.colonia = doc.data().datosFiscales.direccion.colonia;
			        this.datosIniciales.estado = doc.data().datosFiscales.direccion.estado;
			        this.datosIniciales.municipio = doc.data().datosFiscales.direccion.municipio;
			        this.datosIniciales.numeroExterior = doc.data().datosFiscales.direccion.numeroExterior;
			        this.datosIniciales.numeroInterior = doc.data().datosFiscales.direccion.numeroInterior;
			        //--------------------------------------------------
			        if(this.datosIniciales.datosFiscalesReg == true){
			        	document.getElementById("checkFactura").checked = true;
			        	//--------------------------------------------------------------------------------------
			        	//Se muestra formulario de datos fiscales
					  	document.getElementById("formFactura").style.display = "block";
					 	//Se establecen como "required" los campos del formulario de datos fiscales
					 	document.getElementById("rfcRA").required = true;
						document.getElementById("razonSocialRA").required = true;
						document.getElementById("correoDFRA").required = true;
						document.getElementById("codigoPostalRA").required = true;
						document.getElementById("calleRA").required = true;
						document.getElementById("coloniaRA").required = true;
						document.getElementById("numeroExteriorRA").required = true;
						document.getElementById("numeroInteriorRA").required = true;
						document.getElementById("estadoRA").required = true;
						document.getElementById("municipioRA").required = true;
			        	//--------------------------------------------------------------------------------------
			        }
			    } else {
			    	//Sí documento no existe, se regresa a página anterior
			        document.getElementById("boton_RA").click();
			    }
			}).catch(error => {
			    //Error al leer documento
			});
			//----------------------------------------------------------------------------------
			//----------------------------------------------------------------------------------
			//----------------------------------------------------------------------------------
		} else {
			//Sí no se pueden leer las props se regresa a página anterior
			document.getElementById("boton_RA").click();
		}
	}
	//--------------------------------------------------------------------------------------------------------
	//----------------------Actualiza la contraseña, llamando a cloud function--------------------------------
	//--------------------------------------------------------------------------------------------------------
	actualizarContraseña = () => {
		swal.fire({
		  title:"<p style='font-size: 15pt;font-weight:600;color: #282828;'>Restablecer contraseña</p>",
		  html: "<p style='font-size: 10pt;color: #0000008A;'>Cuenta de usuario</p>"+
		  		"<p style='font-size: 11pt;color: #646464;'>"+this.datosIniciales.correo+"</p>"+
		  		"<div class='col-sm-12' style='margin-top:30px;'>"+
				"<div class='form-group input-field' style='margin:auto;'>"+
				"<input class='input_correcto input' type='password' id='contraseñaRA' name='contraseña' required />"+
				"<label class='label' for='contraseñaRA'>Contraseña nueva</label>"+
				"<p id='error-contraseñaRA'></p>"+
				"</div>"+
				"</div>"+
				"<div class='col-sm-12'>"+
				"<div class='form-group input-field' style='margin:auto;'>"+
				"<input class='input_correcto input' type='password' id='confirmacionContraseñaRA' name='confirmacionContraseña' required />"+
				"<label class='label' for='confirmacionContraseñaRA'>Confirmar contraseña</label>"+
				"<p id='error-confirmacionContraseñaRA'></p>"+
				"</div>"+
				"</div>",
		  footer: "<img width='20px' height='20px' style='margin-right:5px' src='"+advertencia+
		  		"'/><b style='font-size: 14;color: #D45454;'>Esta acción no se puede deshacer.</b>",
		  showCancelButton: true,
		  confirmButtonColor: "#17a2b8",
		  confirmButtonText: "<div style='font-size: 10pt;font-weight: 500;'>Actualizar</div>",
		  cancelButtonText: "<div style='font-size: 10pt;font-weight: 600;'>Cancelar</div>",
		  customClass: {
		    cancelButton: 'cancelButton',
		    confirmButton: 'confirmButton',
		    actions: 'alert-buttons',
		    title: 'titulo-alert-update',
		    content: 'container-alert'
		  },
		  didOpen: () => {
		  	swal.getConfirmButton().disabled = true;//Se deshabilita el botón "Actualizar"
		  	//Se selecciona el primer campo de la contraseña, al abrir el alert
		    document.getElementById("contraseñaRA").focus();
		    //Se añaden funciones onChange a los dos inputs de contraseña----------------------------------
		    document.getElementById("contraseñaRA").addEventListener('keyup', (e) => {
		    	//----------------------------------------------------------------------------
		    	//Validación de la primer contraseña
		    	if(/^.{6,40}$/.test(e.target.value)){
					document.getElementById("contraseñaRA").classList.remove('input_incorrecto');
					document.getElementById("contraseñaRA").classList.add('input_correcto');
					document.getElementById("error-contraseñaRA").innerHTML = '';
				} else {
					document.getElementById("contraseñaRA").classList.remove('input_correcto');
					document.getElementById("contraseñaRA").classList.add('input_incorrecto');
					document.getElementById("error-contraseñaRA").innerHTML = "La contraseña debe contener al menos 6 caracteres";
				}
				//Validación de la confirmación de la contraseña
				if(document.getElementById("contraseñaRA").value!==document.getElementById("confirmacionContraseñaRA").value){
					document.getElementById("confirmacionContraseñaRA").classList.remove('input_correcto');
					document.getElementById("confirmacionContraseñaRA").classList.add('input_incorrecto');
					document.getElementById("error-confirmacionContraseñaRA").innerHTML = 'Ambas contraseñas deben coincidir';
				} else {
					document.getElementById("confirmacionContraseñaRA").classList.remove('input_incorrecto');
					document.getElementById("confirmacionContraseñaRA").classList.add('input_correcto');
					document.getElementById("error-confirmacionContraseñaRA").innerHTML = '';
				}
				//Condición para continuar
				if(!(/^.{6,40}$/.test(document.getElementById("contraseñaRA").value)) || document.getElementById("contraseñaRA").value!==document.getElementById("confirmacionContraseñaRA").value){
					//Error en la contraseñas
					swal.getConfirmButton().disabled = true;//Se deshabilita el botón "Actualizar"
				} else {
					//Contraseñas correctas
					swal.getConfirmButton().disabled = false;//Se habilita el botón "Actualizar"
				}
		    	//----------------------------------------------------------------------------
		    });
		    document.getElementById("confirmacionContraseñaRA").addEventListener('keyup', (e) => {
		    	//----------------------------------------------------------------------------
		    	//Validación de la confirmación de la contraseña
		    	if(document.getElementById("contraseñaRA").value!==document.getElementById("confirmacionContraseñaRA").value){
					document.getElementById("confirmacionContraseñaRA").classList.remove('input_correcto');
					document.getElementById("confirmacionContraseñaRA").classList.add('input_incorrecto');
					document.getElementById("error-confirmacionContraseñaRA").innerHTML = 'Ambas contraseñas deben coincidir';
					swal.getConfirmButton().disabled = true;//Se deshabilita el botón "Actualizar"
				} else {
					document.getElementById("confirmacionContraseñaRA").classList.remove('input_incorrecto');
					document.getElementById("confirmacionContraseñaRA").classList.add('input_correcto');
					document.getElementById("error-confirmacionContraseñaRA").innerHTML = '';
					swal.getConfirmButton().disabled = false;//Se habilita el botón "Actualizar"
				}
				//Condición para continuar
				if(!(/^.{6,40}$/.test(document.getElementById("contraseñaRA").value)) || document.getElementById("contraseñaRA").value!==document.getElementById("confirmacionContraseñaRA").value){
					//Error en la contraseñas
					swal.getConfirmButton().disabled = true;//Se deshabilita el botón "Actualizar"
				} else {
					//Contraseñas correctas
					swal.getConfirmButton().disabled = false;//Se habilita el botón "Actualizar"
				}
				//----------------------------------------------------------------------------
		    });
		    //---------------------------------------------------------------------------------------------
		  }
		}).then((result) => {
		  if (result.isConfirmed) {
		  	//*******************************************************************************************************************************
		  	//************************Administrador confirmó la actualización, se procede a cambiar la contraseña****************************
		  	//*******************************************************************************************************************************
		  	var password_temp = document.getElementById("contraseñaRA").value;//Se guarda la contraseña para no perderla al cerrar alert
		  	//Alert "loading"----------------------
			const alert_cargando = swal.fire({
			  width: '300px',
			  allowOutsideClick: false,
			  showConfirmButton: false,
			  didOpen: () => {
			    swal.showLoading()
			  },
			  customClass: {
				   title: 'titulo-alert-loading',
			     actions: 'alert-loader'
				}
			})
			//-------------------------------------
		  	//Se actualiza la contraseña de la cuenta del usuario seleccionado (llamado a cloud function)********
		  	fetch("https://us-central1-sistema-escuela-69a3d.cloudfunctions.net/actualizarPassword?uid="+this.datosIniciales.uid+"&pass="+password_temp)
		  	.then(response => response.json())
		  	.then(data => {
		  		//Sí se completó correctamente la actualización de la contraseña (auth)
		  		if(data.completado) {
		  			//-------------------------------------------------------------------------------------------------------
		  			alert_cargando.close();
		  			//Mensaje de éxito
					swal.fire({
					  title:"<p style='font-size:13pt;font-weight:600;color: #282828;'>Cambio realizado</p>",
					  icon: 'success',
					  iconColor: '#17a2b8',
					  width: '300px',
					  showConfirmButton: false,
					  timer: 2500
					})
		  			//-------------------------------------------------------------------------------------------------------
		  		} else {
		  			//Sí no se actualizó correctamente la contraseña (auth)
		  			alert_cargando.close();
		  			//Mensaje de error
		  			swal.fire({
				      title:"<p style='font-size:13pt;font-weight:600;color: #282828;'>Error al actualizar contraseña</p>",
				      icon: 'error',
				      width: '400px',
				      showConfirmButton: false,
				      timer: 2500
				    })
		  		}
		  	})
		  	.catch(error => {
		    	//Error al ejecutar función (cloud function)
		    	alert_cargando.close();
		    	//Mensaje de error
		    	swal.fire({
				  title:"<p style='font-size:13pt;font-weight:600;color: #282828;'>Error al actualizar contraseña</p>",
				  icon: 'error',
				  width: '400px',
				  showConfirmButton: false,
				  timer: 2500
				})
		    });
		  	//***************************************************************************************************
		  	//*******************************************************************************************************************************
		  	//*******************************************************************************************************************************
		  	//*******************************************************************************************************************************
		  }
		});
	}
	//--------------------------------------------------------------------------------------------------------
	//--------------------------------------------------------------------------------------------------------
	//--------------------------------------------------------------------------------------------------------
	render(){
		//-------------------------------------------------------------
		//-------------------------------------------------------------------------------------------------------------------------
		//--------------------------------------------------INICIAR REGISTRO-------------------------------------------------------
		//-------------------------------------------------------------------------------------------------------------------------
		const handleSubmit = e =>{
			e.preventDefault();//Evita la recarga de la página

			//***************************************************************************************
			//************************** VALIDACIÓN DE CAMPOS ***************************************
			//***************************************************************************************
			document.getElementById("alerta-RA").innerHTML = '';//Borra alertas pasadas
			var formulario_incorrecto = false;
			const aux_inputs = e.target.querySelectorAll('input');
			aux_inputs.forEach((input) => {
				switch(input.name){
					case "nombre":
						if(!expresiones.nombre.test(input.value)){
							//Error en el nombre
							formulario_incorrecto = true;
						}
					break;
					case "apellidos":
						if(!expresiones.apellidos.test(input.value)){
							//Error en los apellidos
							formulario_incorrecto = true;
						}
					break;
					case "telefono":
						if(!expresiones.telefono.test(input.value)){
							//Error en el teléfono
							formulario_incorrecto = true;
						}
					break;
					case "correo":
						if(!expresiones.correo.test(input.value)){
							//Error en el correo
							formulario_incorrecto = true;
						}
					break;
					case "correoDF":
						if(!expresiones.correo.test(input.value) && document.getElementById("checkFactura").checked){
							//Error en el correo de datos fiscales
							formulario_incorrecto = true;
						}
					break;
					case "rfc":
						if(!expresiones.rfc.test(input.value) && document.getElementById("checkFactura").checked){
							//Error en el RFC
							formulario_incorrecto = true;
						}
					break;
				}
				if(formulario_incorrecto){
					document.getElementById("alerta-RA").innerHTML = '<p>Complete los campos correctamente</p>';
				}
			});
			//***************************************************************************************
			//***************************************************************************************
			//***************************************************************************************
			//-----------------------------------------------------------------------------------------------------------------
			//-----------------------------------------------------------------------------------------------------------------
			if(!formulario_incorrecto){
				//*************************************************************************************************************
				//*************************************************************************************************************
				//Sí el correo es un campo a actualizar
				if(this.registroCambios.cambio_correo){
					//Alert "loading"----------------------
					const alert_cargando = swal.fire({
					  width: '300px',
					  allowOutsideClick: false,
					  showConfirmButton: false,
					  didOpen: () => {
					    swal.showLoading()
					  },
					  customClass: {
						   title: 'titulo-alert-loading',
					       actions: 'alert-loader'
					  }
					})
					//-------------------------------------
					//------------------------------------------------------------------------------------------------------------
					//Se actualiza el correo de la cuenta mediante cloud function (admin)-----------------------------------------
					//------------------------------------------------------------------------------------------------------------
				  	fetch("https://us-central1-sistema-escuela-69a3d.cloudfunctions.net/actualizarCorreo?uid="+this.datosIniciales.uid+"&email="+document.getElementById("correoRA").value)
				  	.then(response => response.json())
				  	.then(data => {
				  		//Sí se completó correctamente la actualización del correo (auth)
				  		if(data.completado) {
				  			//--------------------------------------------------------------------------------------------------------------------------
							//Sí hay más campos a actualizar que solo el correo
							if (this.registroCambios.cambio_nombre||this.registroCambios.cambio_apellidos||this.registroCambios.cambio_telefono||
								this.registroCambios.cambio_RFC||this.registroCambios.cambio_razonSocial||this.registroCambios.cambio_correoElectronicoDF||
								this.registroCambios.cambio_codigoPostal||this.registroCambios.cambio_calle||this.registroCambios.cambio_colonia||
								this.registroCambios.cambio_numeroExterior||this.registroCambios.cambio_numeroInterior||this.registroCambios.cambio_estado||
								this.registroCambios.cambio_municipio||this.registroCambios.cambio_datosFiscalesReg){
								//----------------------------------------------------------------------------------------
								//------------------------Se crea objeto con los datos fiscales---------------------------
								//----------------------------------------------------------------------------------------
								const objDatosFiscales = {
									RFC: document.getElementById("rfcRA").value,
									correoElectronicoDF: document.getElementById("correoDFRA").value,
									direccion: {
										calle: document.getElementById("calleRA").value,
										codigoPostal: document.getElementById("codigoPostalRA").value,
										colonia: document.getElementById("coloniaRA").value,
										estado: document.getElementById("estadoRA").value,
										municipio: document.getElementById("municipioRA").value,
										numeroExterior: document.getElementById("numeroExteriorRA").value,
										numeroInterior: document.getElementById("numeroInteriorRA").value
									},
									razonSocial: document.getElementById("razonSocialRA").value
								}
								//----------------------------------------------------------------------------------------
								//----------------------------------------------------------------------------------------
								//----------------------------------------------------------------------------------------
								//**************************************************************************************************
								//************************Se actualizan los datos en la DB firestore********************************
								//**************************************************************************************************
								db.collection("tutores").doc(this.datosIniciales.uid).update({
								    apellidos: document.getElementById("apellidosRA").value.trim().toUpperCase(),
									correoElectronico: document.getElementById("correoRA").value,
									datosFiscales: objDatosFiscales,
									datosFiscalesReg: document.getElementById("checkFactura").checked,
								    nombre: document.getElementById("nombreRA").value.trim().toUpperCase(),
								    telefono: document.getElementById("telefonoRA").value
								}).then(function() {
								    //Los datos se actualizaron correctamente en firestore
								    alert_cargando.close();
								    //Mensaje de éxito
									swal.fire({
									  title:"<p style='font-size:13pt;font-weight:600;color: #282828;'>Cambios guardados</p>",
									  icon: 'success',
									  iconColor: '#17a2b8',
									  width: '300px',
									  showConfirmButton: false,
									  timer: 2000
									}).then((result) => {
									  	//Se regresa a inicio
								    	document.getElementById("boton_RA").click();
									});
								}).catch(function(error) {
								    //Error al actualizar datos en firestore
								    alert_cargando.close();
								    //Mensaje de error
								    swal.fire({
									  title:"<p style='font-size:13pt;font-weight:600;color: #282828;'>Error al actualizar datos</p>",
									  icon: 'error',
									  width: '400px',
									  showConfirmButton: false,
									  timer: 2000
									})
								});
								//**************************************************************************************************
								//**************************************************************************************************
								//**************************************************************************************************
							} else {
								//--------------------------------SÍ SOLO SE ACTUALIZÓ EL CORREO------------------------------------
								//**************************************************************************************************
								//************************Se actualizan los datos en la DB firestore********************************
								//**************************************************************************************************
								db.collection("tutores").doc(this.datosIniciales.uid).update({
								    correoElectronico: document.getElementById("correoRA").value
								}).then(function() {
								    //Los datos se actualizaron correctamente en firestore
								    alert_cargando.close();
								    //Mensaje de éxito
									swal.fire({
									  title:"<p style='font-size:13pt;font-weight:600;color: #282828;'>Cambios guardados</p>",
									  icon: 'success',
									  iconColor: '#17a2b8',
									  width: '300px',
									  showConfirmButton: false,
									  timer: 2000
									}).then((result) => {
									  	//Se regresa a inicio
								    	document.getElementById("boton_RA").click();
									});
								}).catch(function(error) {
								    //Error al actualizar datos en firestore
								    alert_cargando.close();
								    //Mensaje de error
								    swal.fire({
									  title:"<p style='font-size:13pt;font-weight:600;color: #282828;'>Error al actualizar datos</p>",
									  icon: 'error',
									  width: '400px',
									  showConfirmButton: false,
									  timer: 2000
									})
								});
								//**************************************************************************************************
								//**************************************************************************************************
								//**************************************************************************************************
							}
							//--------------------------------------------------------------------------------------------------------------------------
				  		} else {
				  			//Sí no se actualizó correctamente el correo (auth)
				  			alert_cargando.close();
				  			//Mensaje de error
				  			swal.fire({
						      title:"<p style='font-size:13pt;font-weight:600;color: #282828;'>Error al actualizar datos</p>",
						      icon: 'error',
						      width: '400px',
						      showConfirmButton: false,
						      timer: 2000
						    })
				  		}
				  	})
				  	.catch(error => {
				    	//Error al ejecutar función (cloud function)
				    	alert_cargando.close();
				    	//Mensaje de error
				    	swal.fire({
						  title:"<p style='font-size:13pt;font-weight:600;color: #282828;'>Error al actualizar datos</p>",
						  icon: 'error',
						  width: '400px',
						  showConfirmButton: false,
						  timer: 2000
						})
				    });
					//------------------------------------------------------------------------------------------------------------
					//------------------------------------------------------------------------------------------------------------
					//------------------------------------------------------------------------------------------------------------
				} else {//Sí el correo no es un campo a actualizar
					//Alert "loading"----------------------
					const alert_cargando = swal.fire({
					  width: '300px',
					  allowOutsideClick: false,
					  showConfirmButton: false,
					  didOpen: () => {
					    swal.showLoading()
					  },
					  customClass: {
						   title: 'titulo-alert-loading',
					     actions: 'alert-loader'
						}
					})
					//-------------------------------------
					//----------------------------------------------------------------------------------------
					//------------------------Se crea objeto con los datos fiscales---------------------------
					//----------------------------------------------------------------------------------------
					const objDatosFiscales = {
						RFC: document.getElementById("rfcRA").value,
						correoElectronicoDF: document.getElementById("correoDFRA").value,
						direccion: {
							calle: document.getElementById("calleRA").value,
							codigoPostal: document.getElementById("codigoPostalRA").value,
							colonia: document.getElementById("coloniaRA").value,
							estado: document.getElementById("estadoRA").value,
							municipio: document.getElementById("municipioRA").value,
							numeroExterior: document.getElementById("numeroExteriorRA").value,
							numeroInterior: document.getElementById("numeroInteriorRA").value
						},
						razonSocial: document.getElementById("razonSocialRA").value
					}
					//----------------------------------------------------------------------------------------
					//----------------------------------------------------------------------------------------
					//----------------------------------------------------------------------------------------
					//**************************************************************************************************
					//************************Se actualizan los datos en la DB firestore********************************
					//**************************************************************************************************
					db.collection("tutores").doc(this.datosIniciales.uid).update({
					    apellidos: document.getElementById("apellidosRA").value.trim().toUpperCase(),
						datosFiscales: objDatosFiscales,
						datosFiscalesReg: document.getElementById("checkFactura").checked,
					    nombre: document.getElementById("nombreRA").value.trim().toUpperCase(),
					    telefono: document.getElementById("telefonoRA").value
					}).then(function() {
					    //Los datos se actualizaron correctamente en firestore
					    alert_cargando.close();
					    //Mensaje de éxito
						swal.fire({
						  title:"<p style='font-size:13pt;font-weight:600;color: #282828;'>Cambios guardados</p>",
						  icon: 'success',
						  iconColor: '#17a2b8',
						  width: '300px',
						  showConfirmButton: false,
						  timer: 2000
						}).then((result) => {
						  	//Se regresa a inicio
					    	document.getElementById("boton_RA").click();
						});
					}).catch(function(error) {
					    //Error al actualizar datos en firestore
					    alert_cargando.close();
					    //Mensaje de error
					    swal.fire({
						  title:"<p style='font-size:13pt;font-weight:600;color: #282828;'>Error al actualizar datos</p>",
						  icon: 'error',
						  width: '400px',
						  showConfirmButton: false,
						  timer: 2000
						})
					});
					//**************************************************************************************************
					//**************************************************************************************************
					//**************************************************************************************************
				}
				//*************************************************************************************************************
				//*************************************************************************************************************
			}
			//-----------------------------------------------------------------------------------------------------------------
			//-----------------------------------------------------------------------------------------------------------------
		}
		//-------------------------------------------------------------------------------------------------------------------------
		//-------------------------------------------------------------------------------------------------------------------------
		//-------------------------------------------------------------------------------------------------------------------------
		//*************************************************************************************************************************************
		//***************************************************VALIDACIÓN DE LOS DATOS***********************************************************
		//*************************************************************************************************************************************
		const expresiones = {
			nombre: /^[a-zA-ZÀ-ÿ\s]{1,40}$/, // Letras y espacios, pueden llevar acentos
			apellidos: /^[a-zA-ZÀ-ÿ\s]{1,40}$/, // Letras y espacios, pueden llevar acentos
			correo: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
			telefono: /^\d{10,13}$/, // 10 números
			contraseña: /^.{6,40}$/, //cualquier caracter
			rfc: /^[a-zA-Z0-9]+$/ //solo letras y números
		}
		const validarFormulario = (e) => {
			switch(e.target.name){
				case "nombre":
					this.setState({nombre: e.target.value});
					validarCampo(expresiones.nombre, e.target, e.target.name, "Campo nombre solo acepta letras y espacios");
					if(e.target.value !== this.datosIniciales.nombre){
						this.registroCambios.cambio_nombre = true;
					} else {
						this.registroCambios.cambio_nombre = false;
					}
				break;
				case "apellidos":
					this.setState({apellidos: e.target.value});
					validarCampo(expresiones.apellidos, e.target, e.target.name, "Campo apellidos solo acepta letras y espacios");
					if(e.target.value !== this.datosIniciales.apellidos){
						this.registroCambios.cambio_apellidos = true;
					} else {
						this.registroCambios.cambio_apellidos = false;
					}
				break;
				case "telefono":
					this.setState({telefono: e.target.value});
					validarCampo(expresiones.telefono, e.target, e.target.name, "Ingrese número telefónico válido");
					if(e.target.value !== this.datosIniciales.telefono){
						this.registroCambios.cambio_telefono = true;
					} else {
						this.registroCambios.cambio_telefono = false;
					}
				break;
				case "correo":
					this.setState({correo: e.target.value});
					validarCampo(expresiones.correo, e.target, e.target.name, "Ingrese un correo válido");
					if(e.target.value !== this.datosIniciales.correo){
						this.registroCambios.cambio_correo = true;
					} else {
						this.registroCambios.cambio_correo = false;
					}
				break;
				case "rfc":
					this.setState({RFC: e.target.value});
					validarCampo(expresiones.rfc, e.target, e.target.name, "Campo RFC solo acepta letras y números");
					if(e.target.value !== this.datosIniciales.RFC){
						this.registroCambios.cambio_RFC = true;
					} else {
						this.registroCambios.cambio_RFC = false;
					}
				break;
				case "razonSocial":
					this.setState({razonSocial: e.target.value});
					if(e.target.value !== this.datosIniciales.razonSocial){
						this.registroCambios.cambio_razonSocial = true;
					} else {
						this.registroCambios.cambio_razonSocial = false;
					}
				break;
				case "correoDF":
					this.setState({correoElectronicoDF: e.target.value});
					validarCampo(expresiones.correo, e.target, e.target.name, "Ingrese un correo válido");
					if(e.target.value !== this.datosIniciales.correoElectronicoDF){
						this.registroCambios.cambio_correoElectronicoDF = true;
					} else {
						this.registroCambios.cambio_correoElectronicoDF = false;
					}
				break;
				case "codigoPostal":
					this.setState({codigoPostal: e.target.value});
					if(e.target.value !== this.datosIniciales.codigoPostal){
						this.registroCambios.cambio_codigoPostal = true;
					} else {
						this.registroCambios.cambio_codigoPostal = false;
					}
				break;
				case "calle":
					this.setState({calle: e.target.value});
					if(e.target.value !== this.datosIniciales.calle){
						this.registroCambios.cambio_calle = true;
					} else {
						this.registroCambios.cambio_calle = false;
					}
				break;
				case "colonia":
					this.setState({colonia: e.target.value});
					if(e.target.value !== this.datosIniciales.colonia){
						this.registroCambios.cambio_colonia = true;
					} else {
						this.registroCambios.cambio_colonia = false;
					}
				break;
				case "numeroExterior":
					this.setState({numeroExterior: e.target.value});
					if(e.target.value !== this.datosIniciales.numeroExterior){
						this.registroCambios.cambio_numeroExterior = true;
					} else {
						this.registroCambios.cambio_numeroExterior = false;
					}
				break;
				case "numeroInterior":
					this.setState({numeroInterior: e.target.value});
					if(e.target.value !== this.datosIniciales.numeroInterior){
						this.registroCambios.cambio_numeroInterior = true;
					} else {
						this.registroCambios.cambio_numeroInterior = false;
					}
				break;
				case "estado":
					this.setState({estado: e.target.value});
					if(e.target.value !== this.datosIniciales.estado){
						this.registroCambios.cambio_estado = true;
					} else {
						this.registroCambios.cambio_estado = false;
					}
				break;
				case "municipio":
					this.setState({municipio: e.target.value});
					if(e.target.value !== this.datosIniciales.municipio){
						this.registroCambios.cambio_municipio = true;
					} else {
						this.registroCambios.cambio_municipio = false;
					}
				break;
				case "datosFiscalesReg":
					this.setState({datosFiscalesReg: e.target.checked});
					if(e.target.checked !== this.datosIniciales.datosFiscalesReg){
						this.registroCambios.cambio_datosFiscalesReg = true;
					} else {
						this.registroCambios.cambio_datosFiscalesReg = false;
					}
				break;
			}
			//Sí los datos cambiaron, en relación con los originales; se activa el botón "actualizar datos"
			if (this.registroCambios.cambio_nombre || this.registroCambios.cambio_apellidos || 
				this.registroCambios.cambio_telefono || this.registroCambios.cambio_correo ||
				this.registroCambios.cambio_RFC || this.registroCambios.cambio_razonSocial ||
				this.registroCambios.cambio_correoElectronicoDF || this.registroCambios.cambio_codigoPostal ||
				this.registroCambios.cambio_calle || this.registroCambios.cambio_colonia ||
				this.registroCambios.cambio_numeroExterior || this.registroCambios.cambio_numeroInterior ||
				this.registroCambios.cambio_estado || this.registroCambios.cambio_municipio ||
				this.registroCambios.cambio_datosFiscalesReg){
				document.getElementById("boton1_RA").disabled = false;
			}
			//Sí los datos no han cambiado, se desactiva el botón "actualizar datos"
			if (!this.registroCambios.cambio_nombre && !this.registroCambios.cambio_apellidos &&
				!this.registroCambios.cambio_telefono && !this.registroCambios.cambio_correo &&
				!this.registroCambios.cambio_RFC && !this.registroCambios.cambio_razonSocial &&
				!this.registroCambios.cambio_correoElectronicoDF && !this.registroCambios.cambio_codigoPostal &&
				!this.registroCambios.cambio_calle && !this.registroCambios.cambio_colonia &&
				!this.registroCambios.cambio_numeroExterior && !this.registroCambios.cambio_numeroInterior &&
				!this.registroCambios.cambio_estado && !this.registroCambios.cambio_municipio &&
				!this.registroCambios.cambio_datosFiscalesReg){
				document.getElementById("boton1_RA").disabled = true;
			}
		}
		const validarCampo = (expresion, input, campo, mensaje_error) => {
			if(expresion.test(input.value)){
				document.getElementById(`${campo}RA`).classList.remove('input_incorrecto');
				document.getElementById(`${campo}RA`).classList.add('input_correcto');
				document.getElementById(`error-${campo}RA`).innerHTML = '';
			} else {
				document.getElementById(`${campo}RA`).classList.remove('input_correcto');
				document.getElementById(`${campo}RA`).classList.add('input_incorrecto');
				document.getElementById(`error-${campo}RA`).innerHTML = mensaje_error;
			}
		}
		//Solo permite ingresar numeros
		const validarNumerico = (e) => {
     		if(isNaN(e.target.value+String.fromCharCode(e.charCode))){
        		e.preventDefault();
     		}
  		}
		//*************************************************************************************************************************************
		//*************************************************************************************************************************************
		//*************************************************************************************************************************************
		//Control del formulario para datos fiscales--------------------------------
		function controlFactura(e) {
			if(!e.target.checked){
				//Se oculta formulario de datos fiscales
			  	document.getElementById("formFactura").style.display = "none";
			  	//Se elimina el "required" en los campos del formulario de datos fiscales
			  	setStateInputs(false);
			} else {
				//Se muestra formulario de datos fiscales
			  	document.getElementById("formFactura").style.display = "block";
			  	//Se posiciona focus en primer campo del formulario
			 	document.getElementById("rfcRA").focus();
			 	//Se establecen como "required" los campos del formulario de datos fiscales
			  	setStateInputs(true);
			}
		}
		//Cambia el valor "required" en los inputs del formulario de datos fiscales
		function setStateInputs(state) {
			document.getElementById("rfcRA").required = state;
			document.getElementById("razonSocialRA").required = state;
			document.getElementById("correoDFRA").required = state;
			document.getElementById("codigoPostalRA").required = state;
			document.getElementById("calleRA").required = state;
			document.getElementById("coloniaRA").required = state;
			document.getElementById("numeroExteriorRA").required = state;
			document.getElementById("numeroInteriorRA").required = state;
			document.getElementById("estadoRA").required = state;
			document.getElementById("municipioRA").required = state;
		}
		//--------------------------------------------------------------------------
		return(
			<React.Fragment>

			<div className="global-container">
				<div className="card login-form" id="cardFRA" style={{ width: '800px', paddingTop:'10px' }}>
					<div className="card-body" id="tarjeta">
						<h4 className="card-title" style={{fontSize:'18pt',fontWeight:'500'}}>Editar cuenta de tutor</h4>
						<div className="card-text" style={{marginTop:'40px'}}>

							<form autoComplete="off" onSubmit={handleSubmit} style={{margin:'0px',overflow:'hidden'}}>
								<div className="row">
									<div className="col-sm-12 col-md-6 col-lg-6">
										<div className="form-group input-field" style={{margin: 'auto'}}>
											<input className="input_correcto input" type="text" id="nombreRA" name="nombre" onChange={validarFormulario} value={this.state.nombre} required />
											<label className="label" for="nombreRA">Nombre</label>
											<p id="error-nombreRA"></p>
										</div>
									</div>
									
									<div className="col-sm-12 col-md-6 col-lg-6">
										<div className="form-group input-field" style={{margin: 'auto'}}>
											<input className="input_correcto input" type="text" id="apellidosRA" name="apellidos" onChange={validarFormulario} value={this.state.apellidos} required />
											<label className="label" for="apellidosRA">Apellidos</label>
											<p id="error-apellidosRA"></p>
										</div>
									</div>

									<div className="col-sm-12 col-md-6 col-lg-6">
										<div className="form-group input-field" style={{margin: 'auto'}}>
											<input className="input_correcto input" type="text" id="telefonoRA" name="telefono" onChange={validarFormulario} onKeyPress={validarNumerico} value={this.state.telefono} required />
											<label className="label" for="telefonoRA">Teléfono</label>
											<p id="error-telefonoRA"></p>
										</div>
									</div>

									<div className="col-sm-12 col-md-6 col-lg-6">
										<div className="form-group input-field" style={{margin: 'auto'}}>
											<input className="input_correcto input" type="text" id="correoRA" name="correo" onChange={validarFormulario} value={this.state.correo} required />
											<label className="label" for="correoRA">Correo electrónico</label>
											<p id="error-correoRA"></p>
										</div>
									</div>
								</div>

								<div id="formFactura" style={{display:'none'}}>
									<div className="row">
									  	<div className="col-sm-12 col-md-12 col-lg-12">
									  		<h5 id="subtitulo" className="card-title" style={{fontSize:'13pt',fontWeight:'500',marginBottom:'30px',color:'#17a2b8'}}>
									  			Datos fiscales
									  		</h5>
									  	</div>

									  	<div className="col-sm-12 col-md-6 col-lg-6">
											<div className="form-group input-field" style={{margin: 'auto'}}>
												<input className="input_correcto input" type="text" style={{textTransform:'uppercase'}} id="rfcRA" name="rfc" onChange={(e) => {e.target.value=e.target.value.toUpperCase();validarFormulario(e);}} value={this.state.RFC} />
												<label className="label" for="rfcRA">RFC</label>
												<p id="error-rfcRA"></p>
											</div>
										</div>

									  	<div className="col-sm-12 col-md-6 col-lg-6">
											<div className="form-group input-field" style={{margin: 'auto'}}>
												<input className="input_correcto input" type="text" id="razonSocialRA" name="razonSocial" onChange={validarFormulario} value={this.state.razonSocial} />
												<label className="label" for="razonSocialRA">Razón social</label>
												<p id="error-razonSocialRA"></p>
											</div>
										</div>

										<div className="col-sm-12 col-md-6 col-lg-6">
											<div className="form-group input-field" style={{margin: 'auto'}}>
												<input className="input_correcto input" type="text" id="correoDFRA" name="correoDF" onChange={validarFormulario} value={this.state.correoElectronicoDF} />
												<label className="label" for="correoDFRA">Correo electrónico</label>
												<p id="error-correoDFRA"></p>
											</div>
										</div>

										<div className="col-sm-12 col-md-6 col-lg-6">
											<div className="form-group input-field" style={{margin: 'auto'}}>
												<input className="input_correcto input" type="text" id="codigoPostalRA" name="codigoPostal" onKeyPress={validarNumerico} onChange={validarFormulario} value={this.state.codigoPostal} />
												<label className="label" for="codigoPostalRA">Código postal</label>
												<p id="error-codigoPostalRA"></p>
											</div>
										</div>

										<div className="col-sm-12 col-md-6 col-lg-6">
											<div className="form-group input-field" style={{margin: 'auto'}}>
												<input className="input_correcto input" type="text" id="calleRA" name="calle" onChange={validarFormulario} value={this.state.calle} />
												<label className="label" for="calleRA">Calle</label>
												<p id="error-calleRA"></p>
											</div>
										</div>

										<div className="col-sm-12 col-md-6 col-lg-6">
											<div className="form-group input-field" style={{margin: 'auto'}}>
												<input className="input_correcto input" type="text" id="coloniaRA" name="colonia" onChange={validarFormulario} value={this.state.colonia} />
												<label className="label" for="coloniaRA">Colonia</label>
												<p id="error-coloniaRA"></p>
											</div>
										</div>

										<div className="col-sm-12 col-md-6 col-lg-6">
											<div className="form-group input-field" style={{margin: 'auto'}}>
												<input className="input_correcto input" type="text" id="numeroExteriorRA" name="numeroExterior" onChange={validarFormulario} value={this.state.numeroExterior} />
												<label className="label" for="numeroExteriorRA">Número exterior</label>
												<p id="error-numeroExteriorRA"></p>
											</div>
										</div>

										<div className="col-sm-12 col-md-6 col-lg-6">
											<div className="form-group input-field" style={{margin: 'auto'}}>
												<input className="input_correcto input" type="text" id="numeroInteriorRA" name="numeroInterior" onChange={validarFormulario} value={this.state.numeroInterior} />
												<label className="label" for="numeroInteriorRA">Número interior</label>
												<p id="error-numeroInteriorRA"></p>
											</div>
										</div>

										<div className="col-sm-12 col-md-6 col-lg-6">
											<div className="form-group input-field" style={{margin: 'auto'}}>
												<input className="input_correcto input" type="text" id="estadoRA" name="estado" onChange={validarFormulario} value={this.state.estado} />
												<label className="label" for="estadoRA">Estado</label>
												<p id="error-estadoRA"></p>
											</div>
										</div>

										<div className="col-sm-12 col-md-6 col-lg-6">
											<div className="form-group input-field" style={{margin: 'auto'}}>
												<input className="input_correcto input" type="text" id="municipioRA" name="municipio" onChange={validarFormulario} value={this.state.municipio} />
												<label className="label" for="municipioRA">Municipio</label>
												<p id="error-municipioRA"></p>
											</div>
										</div>
									</div>
								</div>

								<div id="alerta-RA" style={{fontSize:'11pt',color:'#D45454',marginBottom:"10px",marginTop:"10px"}}></div>

								<div className="form-group form-check" style={{float:'left',marginTop:'5px'}}>
								  <input type="checkbox" className="form-check-input" id="checkFactura" name="datosFiscalesReg" onChange={(e) => {controlFactura(e);validarFormulario(e);}} />
								  <label className="form-check-label label-check" for="checkFactura" style={{fontSize:'10pt',color:'#696969'}}>Requiere factura</label>
								</div>

								<p style={{cursor:'pointer',color:'#17a2b8',fontSize:'14px',float:'left',marginLeft:'30px',marginTop:'5px'}} onClick={() => this.actualizarContraseña()}>
									Restablecer contraseña
								</p>
								
								<RB.Button disabled variant="info" id="boton1_RA" className="button-disabled boton-presionado" type="submit" style={{float:'right',marginRight:'10px',width:'120px',fontSize:'10pt'}}>Actualizar datos</RB.Button>

								<Link to="/administrador/tutores" id="boton_RA" style={{float:'right',marginRight:'35px',lineHeight:'2.4',color:'#17a2b8',fontSize:'10pt',textDecoration:'none'}}>Cancelar</Link>
							</form>
							
						</div>
					</div>
				</div>
			</div>

			</React.Fragment>
		);
	}
}

export default RegistroTutor;