import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import {auth} from '../firebase-config';
import {db} from '../firebase-config';
import logo_header from '../assets/images/logo_header.png';
import {Link} from 'react-router-dom';
import * as RB from "react-bootstrap";
import swal from 'sweetalert2';
import firebase from 'firebase/app';

class RegistroCurso extends React.Component{
	//Se ejecuta al cargar componente
	componentDidMount() {
		//Al cargar sección se posiciona en el primer input
		document.getElementById("claveRA").focus();
	}
	render(){
		//-------------------------------------------------------------
		//-------------------------------------------------------------------------------------------------------------------------
		//--------------------------------------------------INICIAR REGISTRO-------------------------------------------------------
		//-------------------------------------------------------------------------------------------------------------------------
		const handleSubmit = e =>{
			e.preventDefault();//Evita la recarga de la página

			//***************************************************************************************
			//************************** VALIDACIÓN DE CAMPOS ***************************************
			//***************************************************************************************
			document.getElementById("alerta-RA").innerHTML = '';//Borra alertas pasadas
			var formulario_incorrecto = false;
			const aux_inputs = e.target.querySelectorAll('input');
			aux_inputs.forEach((input) => {
				switch(input.name){
					case "lunes":
						if(input.checked){
							if(errorEnHorario.lunes){
								//Error en horario
								formulario_incorrecto = true;
							}
						}
					break;
					case "martes":
						if(input.checked){
							if(errorEnHorario.martes){
								//Error en horario
								formulario_incorrecto = true;
							}
						}
					break;
					case "miercoles":
						if(input.checked){
							if(errorEnHorario.miercoles){
								//Error en horario
								formulario_incorrecto = true;
							}
						}
					break;
					case "jueves":
						if(input.checked){
							if(errorEnHorario.jueves){
								//Error en horario
								formulario_incorrecto = true;
							}
						}
					break;
					case "viernes":
						if(input.checked){
							if(errorEnHorario.viernes){
								//Error en horario
								formulario_incorrecto = true;
							}
						}
					break;
					case "sabado":
						if(input.checked){
							if(errorEnHorario.sabado){
								//Error en horario
								formulario_incorrecto = true;
							}
						}
					break;
					case "domingo":
						if(input.checked){
							if(errorEnHorario.domingo){
								//Error en horario
								formulario_incorrecto = true;
							}
						}
					break;
				}
				if(formulario_incorrecto){
					document.getElementById("alerta-RA").innerHTML = '<p>Complete los campos correctamente</p>';
				}
			});
			//***************************************************************************************
			//***************************************************************************************
			//***************************************************************************************

			if(!formulario_incorrecto){
				//Alert "loading"----------------------
				const alert_cargando = swal.fire({
				  width: '300px',
				  allowOutsideClick: false,
				  showConfirmButton: false,
				  didOpen: () => {
				    swal.showLoading()
				  },
				  customClass: {
					   title: 'titulo-alert-loading',
				     actions: 'alert-loader'
					}
				})
				//-------------------------------------
				//*******************************************************************************************
				//*************************SE ACTUALIZA EL ARREGLO DE 'HORARIO'******************************
				//*******************************************************************************************
				aux_inputs.forEach((input) => {
					switch(input.name){
						case "lunes": actualizarHorario(input, 0);break;
						case "martes": actualizarHorario(input, 1);break;
						case "miercoles": actualizarHorario(input, 2);break;
						case "jueves": actualizarHorario(input, 3);break;
						case "viernes": actualizarHorario(input, 4);break;
						case "sabado": actualizarHorario(input, 5);break;
						case "domingo": actualizarHorario(input, 6);break;
					}
				});
				//*******************************************************************************************
				//*******************************************************************************************
				//*******************************************************************************************
				//-------------------------------------------------------------------------------------------------
				//Ahora se crea su documento en la colección "actividades"-----------------------------------------
				//-------------------------------------------------------------------------------------------------
				db.collection("actividades").doc().set({
					clave: document.getElementById("claveRA").value.trim().toUpperCase(),
					horario: horarioRA,
					horasSemanales: document.getElementById("horasSemanalesRA").value,
				    nombre: document.getElementById("nombreRA").value.trim().toUpperCase(),
				    pagoHora: document.getElementById("pagoHoraRA").value,
				    alumnos: []
				})
				.then(function() {
					//EL REGISTRO SE COMPLETÓ POR COMPLETO CON ÉXITO-------------
					  alert_cargando.close();
					  swal.fire({
					      title:"<p style='font-size: 13pt;font-weight:600;color: #282828;'>Curso registrado</p>",
					      icon: 'success',
					      iconColor: '#17a2b8',
					      width: '350px',
					      showConfirmButton: false,
					      timer: 2500
					    }).then((result) => {
						  	//Se regresa a inicio
					    	document.getElementById("boton_RA").click();
						});
				    //-----------------------------------------------------------
				})
				.catch(function(error) {
					alert_cargando.close();
				    //alerta por error
					document.getElementById("alerta-RA").innerHTML = 
					'<p">'+
						'Error al registrar: '+
						error+
					'</p>';
				});
				//-------------------------------------------------------------------------------------------------
				//-------------------------------------------------------------------------------------------------
				//-------------------------------------------------------------------------------------------------
			}
		}
		//-------------------------------------------------------------------------------------------------------------------------
		//-------------------------------------------------------------------------------------------------------------------------
		//-------------------------------------------------------------------------------------------------------------------------
		//*************************************************************************************************************************************
		//***************************************************VALIDACIÓN DE LOS DATOS***********************************************************
		//*************************************************************************************************************************************
		const errorEnHorario = {
			lunes: false,
			martes: false,
			miercoles: false,
			jueves: false,
			viernes: false,
			sabado: false,
			domingo: false
		}
		const validarFormulario = (e) => {
			switch(e.target.name){
				case "horaIniciolunes": errorEnHorario.lunes=validarHora(e.target.name,true,"horaFinlunes");break;
				case "horaFinlunes": errorEnHorario.lunes=validarHora(e.target.name,false,"horaIniciolunes");break;
				case "horaIniciomartes": errorEnHorario.martes=validarHora(e.target.name,true,"horaFinmartes");break;
				case "horaFinmartes": errorEnHorario.martes=validarHora(e.target.name,false,"horaIniciomartes");break;
				case "horaIniciomiercoles": errorEnHorario.miercoles=validarHora(e.target.name,true,"horaFinmiercoles");break;
				case "horaFinmiercoles": errorEnHorario.miercoles=validarHora(e.target.name,false,"horaIniciomiercoles");break;
				case "horaIniciojueves": errorEnHorario.jueves=validarHora(e.target.name,true,"horaFinjueves");break;
				case "horaFinjueves": errorEnHorario.jueves=validarHora(e.target.name,false,"horaIniciojueves");break;
				case "horaInicioviernes": errorEnHorario.viernes=validarHora(e.target.name,true,"horaFinviernes");break;
				case "horaFinviernes": errorEnHorario.viernes=validarHora(e.target.name,false,"horaInicioviernes");break;
				case "horaIniciosabado": errorEnHorario.sabado=validarHora(e.target.name,true,"horaFinsabado");break;
				case "horaFinsabado": errorEnHorario.sabado=validarHora(e.target.name,false,"horaIniciosabado");break;
				case "horaIniciodomingo": errorEnHorario.domingo=validarHora(e.target.name,true,"horaFindomingo");break;
				case "horaFindomingo": errorEnHorario.domingo=validarHora(e.target.name,false,"horaIniciodomingo");break;
			}
		}
		function validarHora(selectedHour, isStartTime, complementHour, selectedDay) {
			if(isStartTime){
				if(document.getElementById(selectedHour+"RA").value>=document.getElementById(complementHour+"RA").value){
					document.getElementById(selectedHour+"RA").classList.remove('input_correcto');
					document.getElementById(selectedHour+"RA").classList.add('input_incorrecto');
					if(document.getElementById(selectedHour+"RA").value==document.getElementById(complementHour+"RA").value){
						document.getElementById("error-"+selectedHour+"RA").innerHTML = 'Inicio igual que fin';
					} else {
						document.getElementById("error-"+selectedHour+"RA").innerHTML = 'Inicio mayor que fin';
					}
					return true;
				} else {
					document.getElementById(selectedHour+"RA").classList.remove('input_incorrecto');
					document.getElementById(selectedHour+"RA").classList.add('input_correcto');
					document.getElementById("error-"+selectedHour+"RA").innerHTML = '';
					document.getElementById(complementHour+"RA").classList.remove('input_incorrecto');
					document.getElementById(complementHour+"RA").classList.add('input_correcto');
					document.getElementById("error-"+complementHour+"RA").innerHTML = '';
					return false;
				}
			} else {
				if(document.getElementById(complementHour+"RA").value>=document.getElementById(selectedHour+"RA").value){
					document.getElementById(selectedHour+"RA").classList.remove('input_correcto');
					document.getElementById(selectedHour+"RA").classList.add('input_incorrecto');
					if(document.getElementById(complementHour+"RA").value==document.getElementById(selectedHour+"RA").value){
						document.getElementById("error-"+selectedHour+"RA").innerHTML = 'Fin igual que inicio';
					} else {
						document.getElementById("error-"+selectedHour+"RA").innerHTML = 'Fin menor que inicio';
					}
					return true;
				} else {
					document.getElementById(selectedHour+"RA").classList.remove('input_incorrecto');
					document.getElementById(selectedHour+"RA").classList.add('input_correcto');
					document.getElementById("error-"+selectedHour+"RA").innerHTML = '';
					document.getElementById(complementHour+"RA").classList.remove('input_incorrecto');
					document.getElementById(complementHour+"RA").classList.add('input_correcto');
					document.getElementById("error-"+complementHour+"RA").innerHTML = '';
					return false;
				}
			}
		}
		//Solo permite ingresar numeros
		const validarNumerico = (e) => {
     		if(isNaN(e.target.value+String.fromCharCode(e.charCode))){
        		e.preventDefault();
     		}
  		}
		//*************************************************************************************************************************************
		//*************************************************************************************************************************************
		//*************************************************************************************************************************************
		//CONTROL REGISTRO DE HORARIO-----------------------------------------------------------------------------
		const horarioRA = [null, null, null, null, null, null, null];

		function controlDias(e) {
		  if(e.target.checked){
		    document.getElementById("div-check-"+e.target.name).classList.add('div-check-dias-seleccionado');
		    document.getElementById("div-"+e.target.name).classList.add('div-dia-seleccionado');
		    document.getElementById("horas-"+e.target.name).style.display = "block";
		    document.getElementById("horaInicio"+e.target.name+"RA").required = true;
		    document.getElementById("horaFin"+e.target.name+"RA").required = true;
		    document.getElementById("horaInicio"+e.target.name+"RA").focus();
		  } else {
		    document.getElementById("div-check-"+e.target.name).classList.remove('div-check-dias-seleccionado');
		    document.getElementById("div-"+e.target.name).classList.remove('div-dia-seleccionado');
		    document.getElementById("horas-"+e.target.name).style.display = "none";
		    document.getElementById("horaInicio"+e.target.name+"RA").required = false;
		    document.getElementById("horaFin"+e.target.name+"RA").required = false;
		  }
		}

		function actualizarHorario(input, index_array) {
			if(input.checked){
				horarioRA[index_array] = {
					horaInicio: document.getElementById("horaInicio"+input.name+"RA").value,
					horaFin: document.getElementById("horaFin"+input.name+"RA").value
				};
			} else {
				horarioRA[index_array] = null;
			}
		}
		//--------------------------------------------------------------------------------------------------------
		return(
			<React.Fragment>

			<div className="global-container">
				<div className="card login-form" id="cardFRA" style={{ width: '800px', paddingTop:'10px' }}>
					<div className="card-body" id="tarjeta">
						<h4 className="card-title" style={{fontSize:'18pt',fontWeight:'500'}}>Crear registro de curso</h4>
						<div className="card-text" style={{marginTop:'40px'}}>
							<form autoComplete="off" onSubmit={handleSubmit} style={{margin:'0px',overflow:'hidden'}}>

								<div className="row">
									<div className="col-sm-12 col-md-6 col-lg-6">
										<div className="form-group input-field" style={{margin: 'auto'}}>
											<input className="input_correcto input" type="text" id="claveRA" name="clave" required />
											<label className="label" for="claveRA">Clave</label>
											<p id="error-claveRA"></p>
										</div>
									</div>

									<div className="col-sm-12 col-md-6 col-lg-6">
										<div className="form-group input-field" style={{margin: 'auto'}}>
											<input className="input_correcto input" type="text" id="nombreRA" name="nombre" required />
											<label className="label" for="nombreRA">Nombre</label>
											<p id="error-nombreRA"></p>
										</div>
									</div>

									<div className="col-sm-12 col-md-6 col-lg-6">
										<div className="form-group input-field" style={{margin: 'auto'}}>
											<input className="input_correcto input" type="text" id="horasSemanalesRA" name="horasSemanales" onKeyPress={validarNumerico} required />
											<label className="label" for="horasSemanalesRA">Horas por semana</label>
											<p id="error-horasSemanalesRA"></p>
										</div>
									</div>

									<div className="col-sm-12 col-md-6 col-lg-6">
										<div className="form-group input-field" style={{margin: 'auto'}}>
											<input className="input_correcto input" type="text" id="pagoHoraRA" name="pagoHora" onKeyPress={validarNumerico} required />
											<label className="label" for="pagoHoraRA">Pago por hora</label>
											<p id="error-pagoHoraRA"></p>
										</div>
									</div>
								</div>

								<div className="row">
								  <div className="col-sm-12 col-md-12 col-lg-12">
								    <h5 id="subtitulo" className="card-title" style={{fontSize:'13pt',fontWeight:'500',color:'#17a2b8'}}>
								      Horario
								    </h5>
								    <p style={{color:'#5f6368',fontSize:'10pt'}}>Seleccione los días a guardar en el horario del curso</p>
								  </div>
								  
								  <div className="col-sm-12 col-md-4 col-lg-4">
								    <div id="div-lunes" className="div-dia">
								      <div id="div-check-lunes" className="div-check-dias">
								        <input type="checkbox" id="checklunes" name="lunes" onChange={(e) => {controlDias(e);}} />
								        <label for="checklunes">
								          Lunes
								        </label>
								      </div>

								      <div id="horas-lunes" style={{display:'none'}}>
								        <div className="form-group input-field" style={{margin:'auto',width:'140px'}}>
								          <input className="input_correcto input" type="time" id="horaIniciolunesRA" name="horaIniciolunes" onChange={validarFormulario} />
								          <label className="label" for="horaIniciolunesRA">Hora inicio</label>
								          <p id="error-horaIniciolunesRA"></p>
								        </div>

								        <div className="form-group input-field" style={{margin:'auto',width:'140px'}}>
								          <input className="input_correcto input" type="time" id="horaFinlunesRA" name="horaFinlunes" onChange={validarFormulario} />
								          <label className="label" for="horaFinlunesRA">Hora fin</label>
								          <p id="error-horaFinlunesRA"></p>
								        </div>
								      </div>
								    </div>
								  </div>
								  
								  <div className="col-sm-12 col-md-4 col-lg-4">
								    <div id="div-martes" className="div-dia">
								      <div id="div-check-martes" className="div-check-dias">
								        <input type="checkbox" id="checkmartes" name="martes" onChange={(e) => {controlDias(e);}} />
								        <label for="checkmartes">
								          Martes
								        </label>
								      </div>

								      <div id="horas-martes" style={{display:'none'}}>
								        <div className="form-group input-field" style={{margin:'auto',width:'140px'}}>
								          <input className="input_correcto input" type="time" id="horaIniciomartesRA" name="horaIniciomartes" onChange={validarFormulario} />
								          <label className="label" for="horaIniciomartesRA">Hora inicio</label>
								          <p id="error-horaIniciomartesRA"></p>
								        </div>

								        <div className="form-group input-field" style={{margin:'auto',width:'140px'}}>
								          <input className="input_correcto input" type="time" id="horaFinmartesRA" name="horaFinmartes" onChange={validarFormulario} />
								          <label className="label" for="horaFinmartesRA">Hora fin</label>
								          <p id="error-horaFinmartesRA"></p>
								        </div>
								      </div>
								    </div>
								  </div>
								  
								  <div className="col-sm-12 col-md-4 col-lg-4">
								    <div id="div-miercoles" className="div-dia">
								      <div id="div-check-miercoles" className="div-check-dias">
								        <input type="checkbox" id="checkmiercoles" name="miercoles" onChange={(e) => {controlDias(e);}} />
								        <label for="checkmiercoles">
								          Miércoles
								        </label>
								      </div>

								      <div id="horas-miercoles" style={{display:'none'}}>
								        <div className="form-group input-field" style={{margin:'auto',width:'140px'}}>
								          <input className="input_correcto input" type="time" id="horaIniciomiercolesRA" name="horaIniciomiercoles" onChange={validarFormulario} />
								          <label className="label" for="horaIniciomiercolesRA">Hora inicio</label>
								          <p id="error-horaIniciomiercolesRA"></p>
								        </div>

								        <div className="form-group input-field" style={{margin:'auto',width:'140px'}}>
								          <input className="input_correcto input" type="time" id="horaFinmiercolesRA" name="horaFinmiercoles" onChange={validarFormulario} />
								          <label className="label" for="horaFinmiercolesRA">Hora fin</label>
								          <p id="error-horaFinmiercolesRA"></p>
								        </div>
								      </div>
								    </div>
								  </div>

								  <div className="col-sm-12 col-md-4 col-lg-4">
								    <div id="div-jueves" className="div-dia">
								      <div id="div-check-jueves" className="div-check-dias">
								        <input type="checkbox" id="checkjueves" name="jueves" onChange={(e) => {controlDias(e);}} />
								        <label for="checkjueves">
								          Jueves
								        </label>
								      </div>

								      <div id="horas-jueves" style={{display:'none'}}>
								        <div className="form-group input-field" style={{margin:'auto',width:'140px'}}>
								          <input className="input_correcto input" type="time" id="horaIniciojuevesRA" name="horaIniciojueves" onChange={validarFormulario} />
								          <label className="label" for="horaIniciojuevesRA">Hora inicio</label>
								          <p id="error-horaIniciojuevesRA"></p>
								        </div>

								        <div className="form-group input-field" style={{margin:'auto',width:'140px'}}>
								          <input className="input_correcto input" type="time" id="horaFinjuevesRA" name="horaFinjueves" onChange={validarFormulario} />
								          <label className="label" for="horaFinjuevesRA">Hora fin</label>
								          <p id="error-horaFinjuevesRA"></p>
								        </div>
								      </div>
								    </div>
								  </div>

								  <div className="col-sm-12 col-md-4 col-lg-4">
								    <div id="div-viernes" className="div-dia">
								      <div id="div-check-viernes" className="div-check-dias">
								        <input type="checkbox" id="checkviernes" name="viernes" onChange={(e) => {controlDias(e);}} />
								        <label for="checkviernes">
								          Viernes
								        </label>
								      </div>

								      <div id="horas-viernes" style={{display:'none'}}>
								        <div className="form-group input-field" style={{margin:'auto',width:'140px'}}>
								          <input className="input_correcto input" type="time" id="horaInicioviernesRA" name="horaInicioviernes" onChange={validarFormulario} />
								          <label className="label" for="horaInicioviernesRA">Hora inicio</label>
								          <p id="error-horaInicioviernesRA"></p>
								        </div>

								        <div className="form-group input-field" style={{margin:'auto',width:'140px'}}>
								          <input className="input_correcto input" type="time" id="horaFinviernesRA" name="horaFinviernes" onChange={validarFormulario} />
								          <label className="label" for="horaFinviernesRA">Hora fin</label>
								          <p id="error-horaFinviernesRA"></p>
								        </div>
								      </div>
								    </div>
								  </div>

								  <div className="col-sm-12 col-md-4 col-lg-4">
								    <div id="div-sabado" className="div-dia">
								      <div id="div-check-sabado" className="div-check-dias">
								        <input type="checkbox" id="checksabado" name="sabado" onChange={(e) => {controlDias(e);}} />
								        <label for="checksabado">
								          Sábado
								        </label>
								      </div>

								      <div id="horas-sabado" style={{display:'none'}}>
								        <div className="form-group input-field" style={{margin:'auto',width:'140px'}}>
								          <input className="input_correcto input" type="time" id="horaIniciosabadoRA" name="horaIniciosabado" onChange={validarFormulario} />
								          <label className="label" for="horaIniciosabadoRA">Hora inicio</label>
								          <p id="error-horaIniciosabadoRA"></p>
								        </div>

								        <div className="form-group input-field" style={{margin:'auto',width:'140px'}}>
								          <input className="input_correcto input" type="time" id="horaFinsabadoRA" name="horaFinsabado" onChange={validarFormulario} />
								          <label className="label" for="horaFinsabadoRA">Hora fin</label>
								          <p id="error-horaFinsabadoRA"></p>
								        </div>
								      </div>
								    </div>
								  </div>
								  
								  <div className="col-sm-12 col-md-4 col-lg-4">
								    <div id="div-domingo" className="div-dia">
								      <div id="div-check-domingo" className="div-check-dias">
								        <input type="checkbox" id="checkdomingo" name="domingo" onChange={(e) => {controlDias(e);}} />
								        <label for="checkdomingo">
								          Domingo
								        </label>
								      </div>

								      <div id="horas-domingo" style={{display:'none'}}>
								        <div className="form-group input-field" style={{margin:'auto',width:'140px'}}>
								          <input className="input_correcto input" type="time" id="horaIniciodomingoRA" name="horaIniciodomingo" onChange={validarFormulario} />
								          <label className="label" for="horaIniciodomingoRA">Hora inicio</label>
								          <p id="error-horaIniciodomingoRA"></p>
								        </div>

								        <div className="form-group input-field" style={{margin:'auto',width:'140px'}}>
								          <input className="input_correcto input" type="time" id="horaFindomingoRA" name="horaFindomingo" onChange={validarFormulario} />
								          <label className="label" for="horaFindomingoRA">Hora fin</label>
								          <p id="error-horaFindomingoRA"></p>
								        </div>
								      </div>
								    </div>
								  </div>
								</div>

								<div id="alerta-RA" style={{fontSize:'11pt',color:'#D45454',marginBottom:"10px",marginTop:"10px"}}></div>

								<RB.Button variant="info" id="boton1_RA" className="boton-presionado" type="submit" style={{float:'right',marginRight:'10px',width:'110px',fontSize:'10pt'}}>Crear curso</RB.Button>

								<Link to="/administrador/cursos" id="boton_RA" style={{float:'right',marginRight:'35px',lineHeight:'2.4',color:'#17a2b8',fontSize:'10pt',textDecoration:'none'}}>Cancelar</Link>
							</form>
						</div>
					</div>
				</div>
			</div>

			</React.Fragment>
		);
	}
}

export default RegistroCurso;