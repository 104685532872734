import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import * as RB from "react-bootstrap";
import {db} from '../firebase-config';
import {Link} from 'react-router-dom';
import moment from 'moment';
import lupa from '../assets/images/lupa.png';
import info from '../assets/images/info.png';
import cancel from '../assets/images/cancel.png';
import mas from '../assets/images/mas.png';
import advertencia from '../assets/images/advertencia.png';
import swal from 'sweetalert2';

class TablaCursos extends React.Component{
	//constructor del componente
	constructor(props){
		super(props)
		//Estado inicial del componente
		this.state = {
			datos: [],
			last: null,
			busquedaActiva: false,
			criterioBusqueda: "",
			textoBusqueda: ""
		}
	}
	//Estructura de apoyo para tabla
	semana = {
		lunes: "",
		martes: "",
		miercoles: "",
		jueves: "",
		viernes: "",
		sabado: "",
		domingo: ""
	}
	//Se ejecuta al cargar componente
	componentDidMount() {
		//-----------------------------------------------------------------------------------------------------------------
			db.collection("actividades")
			.limit(10)
			.orderBy("clave")
			.get()
		    .then(querySnapshot => {
		    	const docs = [];
		    	var index = this.state.datos.length;//index del arreglo de datos guardado en el estado
		    	this.setState({last: querySnapshot.docs[querySnapshot.docs.length-1]});//Se guarda en el estado el último objeto consultado
		        querySnapshot.forEach(doc => {
		            //-------------------------------------------------------------------------
		            const data = doc.data();
		            docs.push({
		            	...data,
		            	id_doc: doc.id,
		            	index_state: index,
		            });
		            index++;
		            //-------------------------------------------------------------------------
		        });
		        this.setState({datos: docs});
		        //Sí hay más de 10 datos se muestra botón "mostrar más"
		        if(docs.length == 10){
		        	document.getElementById("botonMostrarMas").style.display = 'block';
		        }
		        //Sí no hay datos a mostrar
		        if(docs.length == 0){
	    			document.getElementById("avisoVacio").style.display = 'block';
		        }
		    });
		//-----------------------------------------------------------------------------------------------------------------
	}
	//--------------------------------------------------------------------------------
	//--------------------------MOSTRAR MÁS RESULTADOS--------------------------------
	//--------------------------------------------------------------------------------
	mostrarMas = () => {
		//Sí hay una búsqueda activa
		if(this.state.busquedaActiva){
			//-----------------------------------------------------------------------------------------------------------------
			db.collection("actividades").where(this.state.criterioBusqueda, "==", this.state.textoBusqueda)
			.limit(10)
			.startAfter(this.state.last)
			.get()
		    .then(querySnapshot => {
		    	const docs = [];
		    	//Sí aún hay datos que mostrar
		    	if(querySnapshot.docs.length != 0){
		    		var index = this.state.datos.length;//index del arreglo de datos guardado en el estado
			    	this.setState({last: querySnapshot.docs[querySnapshot.docs.length-1]});//Se guarda en el estado el último objeto consultado
			        querySnapshot.forEach(doc => {
			            //-------------------------------------------------------------------------
			            const data = doc.data();
			            docs.push({
			            	...data,
			            	id_doc: doc.id,
			            	index_state: index,
			            });
			            index++;
			            //-------------------------------------------------------------------------
			        });
			        this.setState((state) => {
					    return {datos: state.datos.concat(docs)}
					});
		    	} else {
		    		//No hay más datos que mostrar
		    		document.getElementById("avisoFin").style.display = 'block';
		    		document.getElementById("botonMostrarMas").style.display = 'none';
		    	}
		    });
			//-----------------------------------------------------------------------------------------------------------------
		} else {
			//Sí no hay una búsqueda activa
			//-----------------------------------------------------------------------------------------------------------------
			db.collection("actividades")
			.limit(10)
			.orderBy("clave")
			.startAfter(this.state.last)
			.get()
		    .then(querySnapshot => {
		    	const docs = [];
		    	//Sí aún hay datos que mostrar
		    	if(querySnapshot.docs.length != 0){
		    		var index = this.state.datos.length;//index del arreglo de datos guardado en el estado
			    	this.setState({last: querySnapshot.docs[querySnapshot.docs.length-1]});//Se guarda en el estado el último objeto consultado
			        querySnapshot.forEach(doc => {
			            //-------------------------------------------------------------------------
			            const data = doc.data();
			            docs.push({
			            	...data,
			            	id_doc: doc.id,
			            	index_state: index,
			            });
			            index++;
			            //-------------------------------------------------------------------------
			        });
			        this.setState((state) => {
					    return {datos: state.datos.concat(docs)}
					});
		    	} else {
		    		//No hay más datos que mostrar
		    		document.getElementById("avisoFin").style.display = 'block';
		    		document.getElementById("botonMostrarMas").style.display = 'none';
		    	}
		    });
			//-----------------------------------------------------------------------------------------------------------------
		}
	}
	//--------------------------------------------------------------------------------
	//--------------------------------------------------------------------------------
	//--------------------------------------------------------------------------------
	//-------------------------------------------------------------------------------------------------
	//---------------------------------ELIMINAR REGISTRO DE CURSO--------------------------------------
	//-------------------------------------------------------------------------------------------------
	eliminarCurso = (nombre, id, index_array_estado, numAlumnos) => {
		swal.fire({
		  title:"<p style='font-size: 15pt;font-weight:600;color: #282828;'>Borrar curso</p>",
		  html: "<p style='font-size: 10pt;color: #0000008A;'>Nombre del curso</p>"+
		  		"<p style='font-size: 11pt;color: #646464;'>"+nombre+"</p>",
		  footer: "<img width='20px' height='20px' style='margin-right:5px' src='"+advertencia+
		  		"'/><b style='font-size: 14;color: #D45454;'>Esta acción no se puede deshacer.</b>",
		  showCancelButton: true,
		  confirmButtonColor: "#D45454",
		  confirmButtonText: "<div style='font-size: 10pt;font-weight: 500;'>Eliminar</div>",
		  cancelButtonText: "<div style='font-size: 10pt;font-weight: 600;'>Cancelar</div>",
		  customClass: {
		    cancelButton: 'cancelButton',
		    confirmButton: 'confirmButton',
		    actions: 'alert-buttons',
		    title: 'titulo-alert-cursos',
		    content: 'container-alert'
		  },
		  didOpen: () => {
		  	//Sí el curso está asociado a alumnos
		  	if(numAlumnos > 0) {
		  		//NO SE PUEDE BORRAR EL CURSO SELECCIONADO
		  		swal.close();
				//Aviso de error
				swal.fire({
				  title:"<p style='font-size: 15pt;font-weight:600;color: #282828;'>Eliminación cancelada</p>",
				  html: "<p style='font-size:10.5pt;color: #696969;'>El curso seleccionado tiene alumnos inscritos.</p>",
				  icon: 'warning',
				  width: '400px',
				  showCloseButton: true,
				  showConfirmButton: false,
				  customClass: {
				  	title: 'titulo-alert-eliminar',
				    icon: 'icono-error-small',
				    closeButton: 'boton-presionado',
				  }
				});
		  	}
		  }
		}).then((result) => {
		  if (result.isConfirmed) {
		  	//*******************************************************************************************************************************
		  	//****************************Administrador confirmó la eliminación, se procede a borrar curso***********************************
		  	//*******************************************************************************************************************************
		  	//Alert "loading"----------------------
			const alert_cargando = swal.fire({
			  width: '300px',
			  allowOutsideClick: false,
			  showConfirmButton: false,
			  didOpen: () => {
			    swal.showLoading()
			  },
			  customClass: {
				   title: 'titulo-alert-loading',
			     actions: 'alert-loader'
				}
			})
			//-------------------------------------
		  	//-------------------------------------------------------------------------------------------------------
		  	//-------------------Se procede a borrar los datos de la base de datos (firestore)-----------------------
		  	//-------------------------------------------------------------------------------------------------------
		  		//---------------------------------------------------------------------------------------------------
			    //----------------------------Se el documento de datos (firestore)-----------------------------------
			    //---------------------------------------------------------------------------------------------------
			    db.collection("actividades").doc(id).delete().then(() => {
			    	//***********************************************************************************************
				    //***************SE BORRÓ TODO LO RELACIONADO CON EL CURSO SELECCIONADO CON ÉXITO****************
				    //***********************************************************************************************
				    //Eliminación de registro del estado (Eliminación del front)---
				  	var aux = this.state.datos;
				  	aux.splice(index_array_estado, 1);
				  	//Sí se está borrando el último registro de la tabla
				  	if(aux.length == 0) {
				  		//No hay más datos que mostrar
					    document.getElementById("avisoVacio").style.display = 'block';
					    document.getElementById("avisoFin").style.display = 'none';
					    document.getElementById("botonMostrarMas").style.display = 'none';
				  	}
				  	for(var i=0;i<aux.length;i++){
				  		aux[i].index_state = i;
				  	}
				  	this.setState((state) => {
				  		return{datos: aux}
					});
					alert_cargando.close();//Se cierra el alert "loading"
					//Mensaje de éxito
					swal.fire({
				      title:"<p style='font-size:13pt;font-weight:600;color: #282828;'>Curso eliminado</p>",
				      icon: 'success',
				      iconColor: '#17a2b8',
				      width: '300px',
				      showConfirmButton: false,
				      timer: 2500
				    })
				    //***********************************************************************************************
				    //***********************************************************************************************
				    //***********************************************************************************************
				}).catch(function(error) {
				    //Error al eliminar datos
				    alert_cargando.close();
				    swal.fire({
				      title:"<p style='font-size:13pt;font-weight:600;color: #282828;'>Error al eliminar datos</p>",
				      icon: 'error',
				      width: '400px',
				      showConfirmButton: false,
				      timer: 2500
				    })
				});
			    //---------------------------------------------------------------------------------------------------
			    //---------------------------------------------------------------------------------------------------
			    //---------------------------------------------------------------------------------------------------
		  	//-------------------------------------------------------------------------------------------------------
		  	//-------------------------------------------------------------------------------------------------------
		  	//-------------------------------------------------------------------------------------------------------
		  	//*******************************************************************************************************************************
		  	//*******************************************************************************************************************************
		  	//*******************************************************************************************************************************
		  }
		});
	}
	//-------------------------------------------------------------------------------------------------
	//-------------------------------------------------------------------------------------------------
	//-------------------------------------------------------------------------------------------------
	//--------------------------------------------------------------------------------------------------------
	//---------------------------------------Información de curso---------------------------------------------
	//--------------------------------------------------------------------------------------------------------
	informacionCurso = (row) => {
		swal.fire({
		  title:"<p style='font-size: 16pt;font-weight:600;color: #282828;'>Información del curso</p>",
		  width: 900,
		  html: "<div class='row' style='margin-top:10px;'>"+
			  		"<div class='col-sm-12 col-md-6 col-lg-6'>"+
			  			"<p style='font-size: 10pt;color: #0000008A;'>Clave</p>"+
		  				"<p style='font-size: 11pt;color: #646464;'>"+row.clave+"</p>"+
					"</div>"+
					"<div class='col-sm-12 col-md-6 col-lg-6'>"+
						"<p style='font-size: 10pt;color: #0000008A;'>Nombre</p>"+
		  				"<p style='font-size: 11pt;color: #646464;'>"+row.nombre+"</p>"+
					"</div>"+
				"</div>"+
				"<div class='row' style='margin-top:10px;margin-bottom:15px;'>"+
			  		"<div class='col-sm-12 col-md-6 col-lg-6'>"+
			  			"<p style='font-size: 10pt;color: #0000008A;'>Horas semanales</p>"+
		  				"<p style='font-size: 11pt;color: #646464;'>"+row.horasSemanales+"</p>"+
					"</div>"+
					"<div class='col-sm-12 col-md-6 col-lg-6'>"+
						"<p style='font-size: 10pt;color: #0000008A;'>Pago por hora</p>"+
		  				"<p style='font-size: 11pt;color: #646464;'>"+row.pagoHora+"</p>"+
					"</div>"+
				"</div>"+
				"<div style='border:1px solid #dee2e6;border-radius:8px;overflow:hidden;padding-top:6px;background-color:#fafafa;margin-top:15px;margin-bottom:30px;'>"+
					"<div class='row' style='margin:3px;margin-bottom:0px;'>"+
						"<div class='col-sm-12 col-md-6 col-lg-6'>"+
							"<h4 class='card-title' style='font-size:13pt;font-weight:500;float:left;'>Alumnos inscritos</h4>"+
						"</div>"+
					"</div>"+
					"<div style='margin-top:4px;overflow:hidden;border:none;border-top:1px solid #dee2e6;'>"+
						"<div id='tabla' style='width: 100%;height: 250px; overflow-y: auto;overflow-x: auto;background-color: #fff;'>"+
							"<table class='table'>"+
								"<thead>"+
								   "<tr style='background-color:#fafafa;'>"+
								      "<th style='border-top-style:none;'>Nombre</th>"+
								      "<th style='border-top-style:none;'>Apellidos</th>"+
								      "<th style='border-top-style:none;'>Correo</th>"+
								      "<th style='border-top-style:none;'></th>"+
								   "</tr>"+
								"</thead>"+
								"<tbody id='tablaAlumnosAsociados'>"+
								"</tbody>"+
							"</table>"+
						"</div>"+
					"</div>"+
				"</div>",
		  showCancelButton: false,
		  showConfirmButton: false,
		  showCloseButton: true,
		  customClass: {
		  	closeButton: 'boton-presionado',
		    actions: 'alert-buttons',
		    title: 'titulo-alert-update-nombre',
		    content: 'container-alert'
		  },
		  didOpen: () => {
		  	row.alumnos.map(alumno => {
		  		db.collection(alumno.parent.id).doc(alumno.id).get().then(doc => {
				    if (doc.exists) {
				        //--------------------------------------------------
				        if(document.getElementById("alumno_"+doc.data().id_usuario)==null) {
					        document.getElementById("tablaAlumnosAsociados").insertAdjacentHTML("beforeend",
					        "<tr class='filaTabla filaTabla-hover' id='alumno_"+doc.data().id_usuario+"'>"+
					        "<td>"+doc.data().nombre+"</td>"+
					        "<td>"+doc.data().apellidos+"</td>"+
					        "<td>"+doc.data().correoElectronico+"</td>"+
					        "<td>"+
							`<div class='toggle-botonTabla' title='Información' style='margin-right:15px;' onClick="window.open('/administrador/informacion/alumno/${alumno.id}','_blank')">`+
								"<img class='botonTabla' src='"+info+"' width='23px' />"+
							"</div>"+
					        "</td>"+
					        "</tr>");
				    	}
				        //--------------------------------------------------
				    }
				});
		  	});
		  }
		});
	}
	//--------------------------------------------------------------------------------------------------------
	//--------------------------------------------------------------------------------------------------------
	//--------------------------------------------------------------------------------------------------------
	render(){
		//****************************************************************************************************************
		//************************************************** BÚSQUEDA ****************************************************
		//****************************************************************************************************************
		//Control del estado de la búsqueda
		const controlBusqueda = (e) => {
		  //Sí existe una búsqueda activa y se presiona "cerrar búsqueda"
		  if(this.state.busquedaActiva && e.target.type != "search"){
		    cerrarBusqueda();
		  } else {
		    var textoBusqueda = document.getElementById("busqueda-general").value;
		    //Sí existe una cadena de búsqueda
		    if(textoBusqueda !== ""){
		        busqueda(textoBusqueda);//Comienza la búsqueda
		        //Valores del front (indicar que búsqueda está activa)
		        document.getElementById("img-searchBar").src = cancel;
		        document.getElementById("busqueda-general").classList.add('onSearch');
		        document.getElementById("boton-busqueda-general").classList.add('onSearch');
		        document.getElementById("busqueda-general").blur();
		        this.setState({busquedaActiva: true});//Se cambia estado de la búsqueda
		    }
		  }
		}
		//Se realiza la búsqueda seleccionada
		const busqueda = (cadenaBusqueda) => {
			//------------------------------------------------------------------------------------------------------------------
			//----------------------------------------- Se realiza la búsqueda -------------------------------------------------
			//------------------------------------------------------------------------------------------------------------------
			document.getElementById("avisoVacio").style.display = 'none';
			document.getElementById("botonMostrarMas").style.display = 'none';
			document.getElementById("avisoFin").style.display = 'none';
			document.getElementById("avisoBusqueda").style.display = 'none';
			document.getElementById("cadenaAvisoBusqueda").style.display = 'none';
			this.setState({datos: []});//Se limpia la tabla
			//COMIENZA LA BÚSQUEDA**********************************************************************************************
			db.collection("actividades").where("clave", "==", cadenaBusqueda.trim().toUpperCase()).limit(10).get().then(querySnapshot => {
			    var contador = 0;
			    const docs = [];
			    var index = 0;//index del arreglo de datos incial
			    this.setState({last: querySnapshot.docs[querySnapshot.docs.length-1]});//Se guarda en el estado el último objeto consultado
			    querySnapshot.forEach(doc => {
			        contador++;
			        //-------------------------------------------------------------------------
			        const data = doc.data();
			        docs.push({
			          ...data,
			          id_doc: doc.id,
			          index_state: index,
			        });
			        index++;
			        //-------------------------------------------------------------------------
			    });
			    if(contador != 0){
			      this.setState({criterioBusqueda: "clave"});//Se cambia el criterio actual de la búsqueda
			      this.setState({textoBusqueda: cadenaBusqueda});
			      this.setState({datos: docs});
			      //Sí hay más de 10 datos se muestra botón "mostrar más"
			      if(docs.length == 10){
			        document.getElementById("botonMostrarMas").style.display = 'block';
			      }
			    } else {
			      //No hubo resultados para "clave"
			      //------------------------------------------------------------------------------------------------------------
			      //------------------------------------------------------------------------------------------------------------
			      //------------------------------------------------------------------------------------------------------------
			      db.collection("actividades").where("nombre", "==", cadenaBusqueda.trim().toUpperCase()).limit(10).get().then(querySnapshot => {
			          this.setState({last: querySnapshot.docs[querySnapshot.docs.length-1]});//Se guarda en el estado el último objeto consultado
			          querySnapshot.forEach(doc => {
			              contador++;
			              //-------------------------------------------------------------------------
			              const data = doc.data();
			              docs.push({
			                ...data,
			                id_doc: doc.id,
			                index_state: index,
			              });
			              index++;
			              //-------------------------------------------------------------------------
			          });
			          if(contador != 0){
			            this.setState({criterioBusqueda: "nombre"});//Se cambia el criterio actual de la búsqueda
				        this.setState({textoBusqueda: cadenaBusqueda});
			            this.setState({datos: docs});
			            //Sí hay más de 10 datos se muestra botón "mostrar más"
				        if(docs.length == 10){
				          document.getElementById("botonMostrarMas").style.display = 'block';
				        }
			          } else {
			            //------------------------------------------------------------------------------------------------------------
			            //------------------------------------------------------------------------------------------------------------
			            //------------------------------------------------------------------------------------------------------------
			            //NINGÚN RESULTADO PARA LA BÚSQUEDA
						document.getElementById("cadenaAvisoBusqueda").innerHTML = '<b>"'+cadenaBusqueda+'"</b>.';
						document.getElementById("botonMostrarMas").style.display = 'none';
						document.getElementById("avisoBusqueda").style.display = 'inline';
						document.getElementById("cadenaAvisoBusqueda").style.display = 'inline';
			            //------------------------------------------------------------------------------------------------------------
			            //------------------------------------------------------------------------------------------------------------
			            //------------------------------------------------------------------------------------------------------------
			          }
			      }).catch(function(error) {
			          //Error al realizar consulta
			      });
			      //------------------------------------------------------------------------------------------------------------
			      //------------------------------------------------------------------------------------------------------------
			      //------------------------------------------------------------------------------------------------------------
			    }
			}).catch(function(error) {
			    //Error al realizar consulta
			});
			//******************************************************************************************************************
			//------------------------------------------------------------------------------------------------------------------
			//------------------------------------------------------------------------------------------------------------------
			//------------------------------------------------------------------------------------------------------------------
		}
		//Cierra la búsqueda actual
		const cerrarBusqueda = () => {
			//------------------------------------------------------------------------------------------------------------------
			//------------------------------------------- Cerrando búsqueda actual ---------------------------------------------
			//------------------------------------------------------------------------------------------------------------------
			document.getElementById("avisoVacio").style.display = 'none';
			document.getElementById("botonMostrarMas").style.display = 'none';
			document.getElementById("avisoFin").style.display = 'none';
			document.getElementById("avisoBusqueda").style.display = 'none';
			document.getElementById("cadenaAvisoBusqueda").style.display = 'none';
			this.setState({datos: []});//Se limpia la tabla
			//Se muestran todos los registros, como al cargar componente
			db.collection("actividades")
			.limit(10)
			.orderBy("clave")
			.get()
			.then(querySnapshot => {
				const docs = [];
				var index = this.state.datos.length;//index del arreglo de datos guardado en el estado
				this.setState({last: querySnapshot.docs[querySnapshot.docs.length-1]});//Se guarda en el estado el último objeto consultado
				querySnapshot.forEach(doc => {
					//-------------------------------------------------------------------------
					const data = doc.data();
					docs.push({
						...data,
						id_doc: doc.id,
						index_state: index,
					});
					index++;
					//-------------------------------------------------------------------------
				});
				this.setState({datos: docs});
				//Sí hay más de 10 datos se muestra botón "mostrar más"
				if(docs.length == 10){
					document.getElementById("botonMostrarMas").style.display = 'block';
				}
				//Sí no hay datos a mostrar
				if(docs.length == 0){
					document.getElementById("avisoVacio").style.display = 'block';
				}
			});
			//------------------------------------------------------------------------------------------------------------------
			//------------------------------------------------------------------------------------------------------------------
			//------------------------------------------------------------------------------------------------------------------
			//Valores del front (indicar que búsqueda no está activa)
			document.getElementById("img-searchBar").src = lupa;
			document.getElementById("busqueda-general").classList.remove('onSearch');
			document.getElementById("boton-busqueda-general").classList.remove('onSearch');
			document.getElementById("busqueda-general").value= "";
			this.setState({busquedaActiva: false});//Se cambia estado de la búsqueda
		}
		//****************************************************************************************************************
		//****************************************************************************************************************
		//****************************************************************************************************************
		return(

			<div className="global-container">
				<div className="card login-form tablas-adm" style={{ width: '82%', backgroundColor: '#fafafa' }}>
					<div className="card-body card-tablas-adm">

						<div className="row">
							<div className="col-sm-12 col-md-5 col-lg-4">
								<h3 className="card-title" style={{fontSize:'32px',fontWeight:'500'}}>Cursos</h3>
							</div>
							
							<div className="col-sm-12 col-md-7 col-lg-5" style={{overflow:'hidden'}}>
								<div className="wrap-general">
									<div className="search-general">
										<input type="search" onKeyPress={(e) => {if(e.charCode === 13){controlBusqueda(e)}}} className="searchTerm-general" id="busqueda-general" placeholder="Buscar por clave o nombre"/>
										<button type="button" className="searchButton-general" onClick={controlBusqueda} id="boton-busqueda-general">
											<img src={lupa} id="img-searchBar" style={{marginTop:'-6px',marginLeft:'-2px',opacity:'0.6'}} width="20px" />
										</button>
									</div>
								</div>
							</div>

							<div className="col-sm-12 col-md-12 col-lg-3" style={{paddingTop: "8px"}}>
								<Link to="/administrador/alta/curso" style={{float: 'right'}}>
									<RB.Button variant="info" style={{fontSize: '10pt'}} className="boton-presionado">Agregar curso</RB.Button>
								</Link>
							</div>
						</div>

						<div className="card-text" id="subtext">
							<div id="tabla" style={{
								width: '100%',
							    height: '400px', 
							    overflowY: 'scroll',
							    overflowX: 'scroll',
							    marginTop: '25px',
							    backgroundColor: '#fff'
							}}>
								<RB.Table>
									<thead>
									   <tr style={{backgroundColor:'#fafafa'}}>
									      <th>Clave ↑</th>
									      <th>Nombre</th>
									      <th>Lunes</th>
									      <th>Martes</th>
									      <th>Miércoles</th>
									      <th>Jueves</th>
									      <th>Viernes</th>
									      <th>Sábado</th>
									      <th>Domingo</th>
									      <th width="110px"></th>
									   </tr>
									</thead>
									<tbody>
										{
											this.state.datos.map(row => {
												if(row.horario[0] == null){this.semana.lunes = "";}else{this.semana.lunes = row.horario[0].horaInicio+'-'+row.horario[0].horaFin;}
												if(row.horario[1] == null){this.semana.martes = "";}else{this.semana.martes = row.horario[1].horaInicio+'-'+row.horario[1].horaFin;}
												if(row.horario[2] == null){this.semana.miercoles = "";}else{this.semana.miercoles = row.horario[2].horaInicio+'-'+row.horario[2].horaFin;}
												if(row.horario[3] == null){this.semana.jueves = "";}else{this.semana.jueves = row.horario[3].horaInicio+'-'+row.horario[3].horaFin;}
												if(row.horario[4] == null){this.semana.viernes = "";}else{this.semana.viernes = row.horario[4].horaInicio+'-'+row.horario[4].horaFin;}
												if(row.horario[5] == null){this.semana.sabado = "";}else{this.semana.sabado = row.horario[5].horaInicio+'-'+row.horario[5].horaFin;}
												if(row.horario[6] == null){this.semana.domingo = "";}else{this.semana.domingo = row.horario[6].horaInicio+'-'+row.horario[6].horaFin;}
												return(
													<tr className="filaTabla filaTabla-hover">
														<td>{row.clave}</td>
														<td>{row.nombre}</td>
														<td>{this.semana.lunes}</td>
														<td>{this.semana.martes}</td>
														<td>{this.semana.miercoles}</td>
														<td>{this.semana.jueves}</td>
														<td>{this.semana.viernes}</td>
														<td>{this.semana.sabado}</td>
														<td>{this.semana.domingo}</td>
														<td>
															<RB.Dropdown drop="left" onToggle={(e) => {
																//Sí el menú desplegable se abre
																if(e){
																	const array = document.getElementsByClassName('filaTabla');
																	[].slice.call(array).map((element) => {
																		element.classList.remove('filaTabla-hover');
																	});
																} else {//Sí se cerró el menú
																	const array = document.getElementsByClassName('filaTabla');
																	[].slice.call(array).map((element) => {
																		element.classList.add('filaTabla-hover');
																	});
																}
															}}>
															  <RB.Dropdown.Toggle as="p" bsPrefix="none" className="toggle-botonTabla" title="Ver más opciones">
															    <center><img src={mas} width="17px" className="botonTabla"/></center>
															  </RB.Dropdown.Toggle>
															  <RB.Dropdown.Menu>
															    <RB.Dropdown.Item style={{fontSize:'14px'}} className="itemDropdown" onClick={() => document.getElementById('to_Editar_'+row.id_doc).click()}>
															      Editar datos
															    </RB.Dropdown.Item>
															    <Link to={`/administrador/editar/curso/${row.id_doc}`} id={`to_Editar_${row.id_doc}`}></Link>
															    <RB.Dropdown.Item style={{fontSize:'14px'}} className="itemDropdown"
															    onClick={(e) => this.eliminarCurso(row.nombre, row.id_doc, row.index_state, row.alumnos.length)}
															    >
															      Borrar curso
															    </RB.Dropdown.Item>
															  </RB.Dropdown.Menu>
															</RB.Dropdown>

															<div className="toggle-botonTabla" title="Información" style={{marginRight:'15px'}} onClick={() => this.informacionCurso(row)}>
																<img className="botonTabla" src={info} width="23px" />
															</div>
														</td>
													</tr>
												);
											})
										}
										<tr>
											<td colSpan="10">
												<center>
													<RB.Button id="botonMostrarMas" className="boton-presionado" variant="outline-info" type="button" style={{fontSize: '9pt',display:'none'}} onClick={(e) => this.mostrarMas()}>
														Mostrar más resultados
													</RB.Button>
													<p id="avisoFin" style={{color: '#c8c8c8', display: 'none'}}>No hay más resultados a mostrar</p>
													<p id="avisoVacio" style={{color: '#c8c8c8', display: 'none'}}>No hay resultados a mostrar</p>
													<p id="avisoBusqueda" style={{color: '#4d5156', display: 'none',fontSize:'15px'}}>No se han encontrado resultados para</p>
													<p id="cadenaAvisoBusqueda" style={{marginLeft:'5px',display:'none',color:'#4d5156',fontSize:'15px'}}></p>
												</center>
											</td>
										</tr>
									</tbody>
								</RB.Table>
							</div>
						</div>

					</div>
				</div>
			</div>

		);
	}
}

export default TablaCursos;