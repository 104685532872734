import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import * as RB from "react-bootstrap";
import {auth} from '../firebase-config';
import {db} from '../firebase-config';
import logo_header from '../assets/images/logo_header.png';
import {Link} from 'react-router-dom'

class InicioSesion extends React.Component{
	//Se ejecuta al cargar componente
	componentDidMount() {
		//-----------------------------------------------------------------------------------------------
			//se cambia título de la página
			document.title ="Chiquifit - Iniciar sesión";
			//Al cargar sección se posiciona en el primer input
			document.getElementById("correo").focus();
			//---------------------------------
			auth.onAuthStateChanged(function(user) {
			  if (user) {
			    // Existe una sesión activa
			    //------------------------------------------------------
			    //Verifica el tipo de cuenta----------------------------
			    //------------------------------------------------------
			    	var tipo_cuenta = "";
			    	var user = auth.currentUser;
					var uid = user.uid;
					db.collection("usuarios").where("id_usuario", "==", uid)
				    .get()
				    .then(function(querySnapshot) {
				        querySnapshot.forEach(function(doc) {
				            tipo_cuenta = doc.data().tipo_usuario;
				            //-------------------------------------------------------------------------
							switch(tipo_cuenta){
								case "alumno": {document.getElementById("to_Alumno").click();}break;
								case "administrador": {document.getElementById("to_Administrador").click();}break;
								case "instructor": {document.getElementById("to_Instructor").click();}break;
								case "responsable": {document.getElementById("to_Responsable").click();}break;
								case "tutor": {document.getElementById("to_Tutor").click();}break;
								default: {
									auth.signOut().then(function() {
									  // Se cerró sesión
									  window.location.reload();
									}).catch(function(error) {
									  // Error al cerrar sesión
									  alert("Error al cerrar sesión");
									});
								}break;
							}
				            //-------------------------------------------------------------------------
				        });
				    })
				    .catch(function(error) {
				        //Error al leer tipo de sesión
				    });
			    //------------------------------------------------------
			    //------------------------------------------------------
			    //------------------------------------------------------
			  } else {
			  	//No hay una sesión activa
			    //SE MANTIENE EN LA PÁGINA
			  }

			});
			//---------------------------------
		//-----------------------------------------------------------------------------------------------
	}
	render(){
		//-------------------------------------------------------------
		const handleSubmit = e =>{
			e.preventDefault();//Evita la racarga de la página
			//Se reinician advertencias----------------------------------------------------
			document.getElementById("correo").classList.add('input_correcto');
			document.getElementById("correo").classList.remove('input_incorrecto');
			document.getElementById("contraseña").classList.add('input_correcto');
			document.getElementById("contraseña").classList.remove('input_incorrecto');
			document.getElementById("error-correo").innerHTML = "";
			document.getElementById("error-contraseña").innerHTML = "";
			//-----------------------------------------------------------------------------
			//Se intenta iniciar sesión
			auth.signInWithEmailAndPassword(document.getElementById("correo").value, document.getElementById("contraseña").value).catch(function(error) {
			  //no se pudo inciar sesión
			  if(error.code == "auth/user-not-found"){
			  	document.getElementById("correo").classList.add('input_incorrecto');
			  	document.getElementById("correo").classList.remove('input_correcto');
			  	document.getElementById("error-correo").innerHTML = "No pudimos encontrar su cuenta";
			  }
			  if(error.code == "auth/wrong-password"){
			  	document.getElementById("contraseña").classList.add('input_incorrecto');
			  	document.getElementById("contraseña").classList.remove('input_correcto');
			  	document.getElementById("error-contraseña").innerHTML = "La contraseña es incorrecta";
			  }
			  if(error.code == "auth/invalid-email"){
			  	document.getElementById("correo").classList.add('input_incorrecto');
			  	document.getElementById("correo").classList.remove('input_correcto');
			  	document.getElementById("error-correo").innerHTML = "Ingrese un correo válido";
			  }
			});
			auth.onAuthStateChanged(function(user) { 
			    if (user) {
			    	//Se inició sesión
					//--------------------------------------BASE DE DATOS----------------------------------------------
					var user = auth.currentUser;
					var uid = user.uid;
					db.collection("usuarios").where("id_usuario", "==", uid)
				    .get()
				    .then(function(querySnapshot) {
				        querySnapshot.forEach(function(doc) {
				            const tipo_cuenta = doc.data().tipo_usuario;
				            //------------------------------------------------------------------------------------
							switch(tipo_cuenta){
								case "alumno": {document.getElementById("to_Alumno").click();}break;
								case "administrador": {document.getElementById("to_Administrador").click();}break;
								case "instructor": {document.getElementById("to_Instructor").click();}break;
								case "responsable": {document.getElementById("to_Responsable").click();}break;
								case "tutor": {document.getElementById("to_Tutor").click();}break;
								default: break;
							}
				            //-------------------------------------------------------------------------------------
				        });
				    })
				    .catch(function(error) {
				        //ERROR AL LEER BASE DE DATOS
				    });
					//--------------------------------------------------------------------------------------------------
			    }
			});
		}
		//-------------------------------------------------------------
		return(
			<React.Fragment>
		        
		        <Link id="to_Administrador" to="/administrador"></Link>
		        <Link id="to_Alumno" to="/alumno/inicio"></Link>
		        <Link id="to_Instructor" to="/instructor"></Link>
		        <Link id="to_Responsable" to="/responsable/inicio"></Link>
		        <Link id="to_Tutor" to="/tutor/inicio"></Link>

		    	<div className="fondo-login"></div>

				<div className="card card-login">
					<div className="card-body">

						<center><img src={logo_header} width="150" style={{paddingTop: '1%', paddingBottom: '3%'}}></img></center>
						<center><h4 className="card-title">Iniciar sesión</h4></center>

						<div className="card-text">
							<form onSubmit={handleSubmit}>

								<div className="form-group input-field" style={{marginBottom: '0px', width: '100%'}}>
									<input className="input_correcto input" type="text" id="correo" required />
									<label className="label" for="correo">Correo electrónico</label>
									<p id="error-correo"></p>
								</div>

								<div className="form-group input-field" style={{marginBottom: '30px', width: '100%'}}>
									<input className="input_correcto input" type="password" id="contraseña" required />
									<label className="label" for="contraseña">Contraseña</label>
									<p id="error-contraseña"></p>
								</div>

								<RB.Button variant="info" type="submit" style={{width:'100%',display:'block',margin:'auto'}}>Acceder</RB.Button>

							</form>
						</div>

					</div>
					<p className="f-login" style={{fontSize:'10pt',textAlign:'center',display:'none'}}>
						<a href="https://chiquifit.com.mx/aviso-privacidad/" target="_blank" style={{color:'#212529'}}>Aviso de privacidad</a> | Derechos reservados | Chiquifit 2021
					</p>
				</div>
				<p className="fp-login" style={{fontSize:'10pt',position:'absolute',bottom:'0.5vh',right:'4vh',textAlign:'center'}}>
					<a href="https://chiquifit.com.mx/aviso-privacidad/" target="_blank" style={{color:'#212529'}}>Aviso de privacidad</a> | Derechos reservados | Chiquifit 2021
				</p>

			</React.Fragment>
		);
	}
}

export default InicioSesion;