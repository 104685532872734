import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import {auth} from '../firebase-config';
import {auth_aux} from '../firebase-config';
import {db} from '../firebase-config';
import logo_header from '../assets/images/logo_header.png';
import {Link} from 'react-router-dom';
import * as RB from "react-bootstrap";
import swal from 'sweetalert2';
import firebase from 'firebase/app';
import 'firebase/auth'
import advertencia from '../assets/images/advertencia.png';
import moment from 'moment';
import key from '../assets/images/key.png';
import eliminar from '../assets/images/eliminar.png';
import lupa from '../assets/images/lupa.png';
import nuevo from '../assets/images/nuevo.png';
import asociar from '../assets/images/asociar.png';
import mas from '../assets/images/mas.png';
import lapiz from '../assets/images/lapiz.png';

class InfoAlumno extends React.Component{
	//constructor del componente
	constructor(props){
		super(props)
		//Estado inicial del componente
		this.state = {
			uid: "",
			datos: []
		}
	}
	  //********************************************************************************
	  //********************************************************************************
	  //********************************************************************************
	  //CONTROL DE MAPA (AUTOCOMPLETADO - DIRECCIÓN)
	  handleScriptLoad = () => {
	    let autoComplete = new window.google.maps.places.Autocomplete(
	      document.getElementById("address"),
	      { componentRestrictions: { country: "mx" } }
	    );
	    autoComplete.setFields(["address_components", "formatted_address", "geometry"]);
	    autoComplete.addListener("place_changed", () => {
	        //Segmentación de los resultados
	        const place = autoComplete.getPlace();
	        const aux_dir_texto = place.formatted_address;
	        this.setState({texto_direccion: aux_dir_texto});
	        var address = place.address_components;
	        var lat = place.geometry.location.lat();
	        var lng = place.geometry.location.lng();
	        //Se reinicia el objeto de información de ubicación
	        this.locationInfo.reset();
	        //Se guardan de forma individual los componentes de la dirección
	        for (var i = 0; i < address.length; i++) {
	          var component = address[i].types[0];
	          switch (component) {
	            case "administrative_area_level_1":
	              this.locationInfo.estado = address[i]["long_name"];
	              break;
	            case "locality":
	              this.locationInfo.municipio = address[i]["short_name"];
	              break;
	            case "sublocality_level_1":
	              this.locationInfo.colonia = address[i]["long_name"];
	              break;
	            case "postal_code":
	              this.locationInfo.codigoPostal = address[i]["long_name"];
	              break;
	            case "route":
	              this.locationInfo.calle = address[i]["long_name"];
	              break;
	            case "street_number":
	              this.locationInfo.numeroExterior = address[i]["long_name"];
	              break;
	            default:
	              break;
	          }
	        }
	        this.locationInfo.lat = lat;
	        this.locationInfo.lng = lng;
	        this.locationInfo.cadenaDireccion = document.getElementById("address").value;
	        //Vista previa del mapa
	        var src =
	            "https://maps.googleapis.com/maps/api/staticmap?center=" +
	            lat +
	            "," +
	            lng +
	            "&zoom=17&size=388x101&maptype=roadmap&markers=color:red%7C"+lat+","+lng+"&key=AIzaSyDOafd3wkkjh-8nt0kb98ieVx3kkgyyOYs",
	        img = document.createElement("img");
	        img.src = src;
	        document.getElementById("js-preview-map").replaceChild(img, document.getElementById("js-preview-map").firstElementChild);
	        this.locationInfo.direccion_correcta = true;
	        document.getElementById("address").classList.remove('input_incorrecto-address');
	        document.getElementById("address").classList.add('input_correcto-address');
	        document.getElementById("error-direccion").innerHTML = '';
	        document.getElementById("numeroInteriorRA").value = "S/N";
	        //Condición para continuar
			this.condicionContinuarIB();
	    });
	  }
	  //********************************************************************************
	  //********************************************************************************
	  //********************************************************************************
	//Se ejecuta al cargar componente
	componentDidMount() {
		//Se verifican las props
		if(typeof this.props.match.params !== 'undefined') {
			//Se guarda el uid del usuarios, recibido por props
			const {uid} = this.props.match.params;
			this.setState({uid: uid});
			//----------------------------------------------------------------------------------
			//---------Se actualizan los valores iniciales con los datos del usuario------------
			//----------------------------------------------------------------------------------
			db.collection("alumnos").doc(uid).get().then(doc => {
				const docs = [];
			    if (doc.exists) {
			        //Se guardan los datos -----------------------------
			        const data = doc.data();
		            docs.push({
		            	...data
		            });
			        //--------------------------------------------------
			    } else {
			    	//Sí documento no existe, se regresa a página anterior
			        document.getElementById("boton_RA").click();
			    }
			    this.setState({datos: docs});
			}).catch(error => {
			    //Error al leer documento
			    document.getElementById("boton_RA").click();
			});
			//----------------------------------------------------------------------------------
			//----------------------------------------------------------------------------------
			//----------------------------------------------------------------------------------
		} else {
			//Sí no se pueden leer las props se regresa a página anterior
			document.getElementById("boton_RA").click();
		}
	}
	consultaTutores = (collection,id) => {
		db.collection(collection).doc(id).get().then(doc => {
		    if (doc.exists) {
		        //--------------------------------------------------
		        if(document.getElementById("tutor_"+doc.data().id_usuario)==null) {
			        document.getElementById("tablaTutores").insertAdjacentHTML("beforeend",
			        "<tr class='filaTabla filaTabla-hover' id='tutor_"+doc.data().id_usuario+"'>"+
			        "<td>"+doc.data().nombre+"</td>"+
			        "<td>"+doc.data().apellidos+"</td>"+
			        "<td>"+doc.data().correoElectronico+"</td>"+
			        "<td>"+doc.data().telefono+"</td>"+
			        "</tr>");
		    	}
		        //--------------------------------------------------
		    }
		});
	}
	consultarPersonasAutorizadas = (collection,id) => {
		db.collection(collection).doc(id).get().then(doc => {
		    if (doc.exists) {
		        //--------------------------------------------------
		        if(document.getElementById("pA_"+doc.data().id_usuario)==null) {
			        document.getElementById("tablaPersonasAutorizadas").insertAdjacentHTML("beforeend",
			        "<tr class='filaTabla filaTabla-hover' id='pA_"+doc.data().id_usuario+"'>"+
			        "<td>"+doc.data().nombre+"</td>"+
			        "<td>"+doc.data().apellidos+"</td>"+
			        "<td>"+doc.data().correoElectronico+"</td>"+
			        "<td>"+doc.data().telefono+"</td>"+
			        "</tr>");
		    	}
		        //--------------------------------------------------
		    }
		});
	}
	consultaContactosEmergencia = (collection,id) => {
		db.collection(collection).doc(id).get().then(doc => {
		    if (doc.exists) {
		        //--------------------------------------------------
		        if(document.getElementById("cE_"+doc.data().id_usuario)==null) {
			        document.getElementById("tablaContactosEmergencia").insertAdjacentHTML("beforeend",
			        "<tr class='filaTabla filaTabla-hover' id='cE_"+doc.data().id_usuario+"'>"+
			        "<td>"+doc.data().nombre+"</td>"+
			        "<td>"+doc.data().apellidos+"</td>"+
			        "<td>"+doc.data().correoElectronico+"</td>"+
			        "<td>"+doc.data().telefono+"</td>"+
			        "</tr>");
		    	}
		        //--------------------------------------------------
		    }
		});
	}
	consultaActividades = (collection,id) => {
		db.collection(collection).doc(id).get().then(doc => {
		    if (doc.exists) {
		        //--------------------------------------------------
		        if(document.getElementById("act_"+doc.id)==null) {
		        	const semana = {
						lunes: "",
						martes: "",
						miercoles: "",
						jueves: "",
						viernes: "",
						sabado: "",
						domingo: ""
					}
		        	if(doc.data().horario[0] == null){semana.lunes = "";}else{semana.lunes = doc.data().horario[0].horaInicio+'-'+doc.data().horario[0].horaFin;}
					if(doc.data().horario[1] == null){semana.martes = "";}else{semana.martes = doc.data().horario[1].horaInicio+'-'+doc.data().horario[1].horaFin;}
					if(doc.data().horario[2] == null){semana.miercoles = "";}else{semana.miercoles = doc.data().horario[2].horaInicio+'-'+doc.data().horario[2].horaFin;}
					if(doc.data().horario[3] == null){semana.jueves = "";}else{semana.jueves = doc.data().horario[3].horaInicio+'-'+doc.data().horario[3].horaFin;}
					if(doc.data().horario[4] == null){semana.viernes = "";}else{semana.viernes = doc.data().horario[4].horaInicio+'-'+doc.data().horario[4].horaFin;}
					if(doc.data().horario[5] == null){semana.sabado = "";}else{semana.sabado = doc.data().horario[5].horaInicio+'-'+doc.data().horario[5].horaFin;}
					if(doc.data().horario[6] == null){semana.domingo = "";}else{semana.domingo = doc.data().horario[6].horaInicio+'-'+doc.data().horario[6].horaFin;}
			        document.getElementById("tablaCursos").insertAdjacentHTML("beforeend",
			        "<tr class='filaTabla filaTabla-hover' id='act_"+doc.id+"'>"+
			        "<td>"+doc.data().clave+"</td>"+
			        "<td>"+doc.data().nombre+"</td>"+
			        "<td>"+semana.lunes+"</td>"+
			        "<td>"+semana.martes+"</td>"+
			        "<td>"+semana.miercoles+"</td>"+
			        "<td>"+semana.jueves+"</td>"+
			        "<td>"+semana.viernes+"</td>"+
			        "<td>"+semana.sabado+"</td>"+
			        "<td>"+semana.domingo+"</td>"+
			        "</tr>");
		    	}
		        //--------------------------------------------------
		    }
		});
	}
	consultaProductos = (collection,id,fechaCompra,indexArray,clave,nombre,cantidad,tipo,costo) => {
		db.collection(collection).doc(id).get().then(doc => {
			//Sí el producto aún está disponible (hay registro de él)
		    if (doc.exists) {
		    	//Sí hay cambios entre producto en historial con producto actual disponible
		    	if(doc.data().clave!==clave||doc.data().nombre!==nombre||doc.data().tipo!==tipo||doc.data().costo!==costo){
		    		//--------------------------------------------------
					document.getElementById("pro_clave_"+indexArray.toString()).innerHTML = "<p style='color:#E4AA10;margin:0px;padding:0px;'>"+clave+"</p>";
					document.getElementById("pro_nombre_"+indexArray.toString()).innerHTML = "<p style='color:#E4AA10;margin:0px;padding:0px;'>"+nombre+"</p>";
					document.getElementById("pro_cantidad_"+indexArray.toString()).innerHTML = "<p style='color:#E4AA10;margin:0px;padding:0px;'>"+cantidad+"</p>";
					document.getElementById("pro_tipo_"+indexArray.toString()).innerHTML = "<p style='color:#E4AA10;margin:0px;padding:0px;'>"+tipo+"</p>";
					document.getElementById("pro_fechaCompra_"+indexArray.toString()).innerHTML = "<p style='color:#E4AA10;margin:0px;padding:0px;'>"+moment(fechaCompra.toDate()).format('DD/MM/YYYY')+"</p>";
				    //--------------------------------------------------
		    	} else {
		    		//--------------------------------------------------
					document.getElementById("pro_clave_"+indexArray.toString()).innerHTML = clave;
					document.getElementById("pro_nombre_"+indexArray.toString()).innerHTML = nombre;
					document.getElementById("pro_cantidad_"+indexArray.toString()).innerHTML = cantidad;
					document.getElementById("pro_tipo_"+indexArray.toString()).innerHTML = tipo;
					document.getElementById("pro_fechaCompra_"+indexArray.toString()).innerHTML = moment(fechaCompra.toDate()).format('DD/MM/YYYY');
				    //--------------------------------------------------
		    	}
		    } else {
		    	//--------------------------------------------------
				document.getElementById("pro_clave_"+indexArray.toString()).innerHTML = "<p style='color:#B62406;margin:0px;padding:0px;'>"+clave+"</p>";
				document.getElementById("pro_nombre_"+indexArray.toString()).innerHTML = "<p style='color:#B62406;margin:0px;padding:0px;'>"+nombre+"</p>";
				document.getElementById("pro_cantidad_"+indexArray.toString()).innerHTML = "<p style='color:#B62406;margin:0px;padding:0px;'>"+cantidad+"</p>";
				document.getElementById("pro_tipo_"+indexArray.toString()).innerHTML = "<p style='color:#B62406;margin:0px;padding:0px;'>"+tipo+"</p>";
				document.getElementById("pro_fechaCompra_"+indexArray.toString()).innerHTML = "<p style='color:#B62406;margin:0px;padding:0px;'>"+moment(fechaCompra.toDate()).format('DD/MM/YYYY')+"</p>";
			    //--------------------------------------------------
		    }
		});
	}
	//--------------------------------------------------------------------------------------------------------
	//----------------------Actualiza la contraseña, llamando a cloud function--------------------------------
	//--------------------------------------------------------------------------------------------------------
	actualizarContraseña = () => {
		swal.fire({
		  title:"<p style='font-size: 15pt;font-weight:600;color: #282828;'>Restablecer contraseña</p>",
		  html: "<p style='font-size: 10pt;color: #0000008A;'>Cuenta de usuario</p>"+
		  		"<p style='font-size: 11pt;color: #646464;'>"+this.state.datos[0].correoElectronico+"</p>"+
		  		"<div class='col-sm-12' style='margin-top:30px;'>"+
				"<div class='form-group input-field' style='margin:auto;'>"+
				"<input class='input_correcto input' type='password' id='contraseñaRA' name='contraseña' required />"+
				"<label class='label' for='contraseñaRA'>Contraseña nueva</label>"+
				"<p id='error-contraseñaRA'></p>"+
				"</div>"+
				"</div>"+
				"<div class='col-sm-12'>"+
				"<div class='form-group input-field' style='margin:auto;'>"+
				"<input class='input_correcto input' type='password' id='confirmacionContraseñaRA' name='confirmacionContraseña' required />"+
				"<label class='label' for='confirmacionContraseñaRA'>Confirmar contraseña</label>"+
				"<p id='error-confirmacionContraseñaRA'></p>"+
				"</div>"+
				"</div>",
		  footer: "<img width='20px' height='20px' style='margin-right:5px' src='"+advertencia+
		  		"'/><b style='font-size: 14;color: #D45454;'>Esta acción no se puede deshacer.</b>",
		  showCancelButton: true,
		  confirmButtonColor: "#17a2b8",
		  confirmButtonText: "<div style='font-size: 10pt;font-weight: 500;'>Actualizar</div>",
		  cancelButtonText: "<div style='font-size: 10pt;font-weight: 600;'>Cancelar</div>",
		  customClass: {
		    cancelButton: 'cancelButton',
		    confirmButton: 'confirmButton',
		    actions: 'alert-buttons',
		    title: 'titulo-alert-update',
		    content: 'container-alert'
		  },
		  didOpen: () => {
		  	swal.getConfirmButton().disabled = true;//Se deshabilita el botón "Actualizar"
		  	//Se selecciona el primer campo de la contraseña, al abrir el alert
		    document.getElementById("contraseñaRA").focus();
		    //Se añaden funciones onChange a los dos inputs de contraseña----------------------------------
		    document.getElementById("contraseñaRA").addEventListener('keyup', (e) => {
		    	//----------------------------------------------------------------------------
		    	//Validación de la primer contraseña
		    	if(/^.{6,40}$/.test(e.target.value)){
					document.getElementById("contraseñaRA").classList.remove('input_incorrecto');
					document.getElementById("contraseñaRA").classList.add('input_correcto');
					document.getElementById("error-contraseñaRA").innerHTML = '';
				} else {
					document.getElementById("contraseñaRA").classList.remove('input_correcto');
					document.getElementById("contraseñaRA").classList.add('input_incorrecto');
					document.getElementById("error-contraseñaRA").innerHTML = "La contraseña debe contener al menos 6 caracteres";
				}
				//Validación de la confirmación de la contraseña
				if(document.getElementById("contraseñaRA").value!==document.getElementById("confirmacionContraseñaRA").value){
					document.getElementById("confirmacionContraseñaRA").classList.remove('input_correcto');
					document.getElementById("confirmacionContraseñaRA").classList.add('input_incorrecto');
					document.getElementById("error-confirmacionContraseñaRA").innerHTML = 'Ambas contraseñas deben coincidir';
				} else {
					document.getElementById("confirmacionContraseñaRA").classList.remove('input_incorrecto');
					document.getElementById("confirmacionContraseñaRA").classList.add('input_correcto');
					document.getElementById("error-confirmacionContraseñaRA").innerHTML = '';
				}
				//Condición para continuar
				if(!(/^.{6,40}$/.test(document.getElementById("contraseñaRA").value)) || document.getElementById("contraseñaRA").value!==document.getElementById("confirmacionContraseñaRA").value){
					//Error en la contraseñas
					swal.getConfirmButton().disabled = true;//Se deshabilita el botón "Actualizar"
				} else {
					//Contraseñas correctas
					swal.getConfirmButton().disabled = false;//Se habilita el botón "Actualizar"
				}
		    	//----------------------------------------------------------------------------
		    });
		    document.getElementById("confirmacionContraseñaRA").addEventListener('keyup', (e) => {
		    	//----------------------------------------------------------------------------
		    	//Validación de la confirmación de la contraseña
		    	if(document.getElementById("contraseñaRA").value!==document.getElementById("confirmacionContraseñaRA").value){
					document.getElementById("confirmacionContraseñaRA").classList.remove('input_correcto');
					document.getElementById("confirmacionContraseñaRA").classList.add('input_incorrecto');
					document.getElementById("error-confirmacionContraseñaRA").innerHTML = 'Ambas contraseñas deben coincidir';
					swal.getConfirmButton().disabled = true;//Se deshabilita el botón "Actualizar"
				} else {
					document.getElementById("confirmacionContraseñaRA").classList.remove('input_incorrecto');
					document.getElementById("confirmacionContraseñaRA").classList.add('input_correcto');
					document.getElementById("error-confirmacionContraseñaRA").innerHTML = '';
					swal.getConfirmButton().disabled = false;//Se habilita el botón "Actualizar"
				}
				//Condición para continuar
				if(!(/^.{6,40}$/.test(document.getElementById("contraseñaRA").value)) || document.getElementById("contraseñaRA").value!==document.getElementById("confirmacionContraseñaRA").value){
					//Error en la contraseñas
					swal.getConfirmButton().disabled = true;//Se deshabilita el botón "Actualizar"
				} else {
					//Contraseñas correctas
					swal.getConfirmButton().disabled = false;//Se habilita el botón "Actualizar"
				}
				//----------------------------------------------------------------------------
		    });
		    //---------------------------------------------------------------------------------------------
		  }
		}).then((result) => {
		  if (result.isConfirmed) {
		  	//*******************************************************************************************************************************
		  	//************************Administrador confirmó la actualización, se procede a cambiar la contraseña****************************
		  	//*******************************************************************************************************************************
		  	var password_temp = document.getElementById("contraseñaRA").value;//Se guarda la contraseña para no perderla al cerrar alert
		  	//Alert "loading"----------------------
			const alert_cargando = swal.fire({
			  width: '300px',
			  allowOutsideClick: false,
			  showConfirmButton: false,
			  didOpen: () => {
			    swal.showLoading()
			  },
			  customClass: {
				   title: 'titulo-alert-loading',
			     actions: 'alert-loader'
				}
			})
			//-------------------------------------
		  	//Se actualiza la contraseña de la cuenta del usuario seleccionado (llamado a cloud function)********
		  	fetch("https://us-central1-sistema-escuela-69a3d.cloudfunctions.net/actualizarPassword?uid="+this.state.uid+"&pass="+password_temp)
		  	.then(response => response.json())
		  	.then(data => {
		  		//Sí se completó correctamente la actualización de la contraseña (auth)
		  		if(data.completado) {
		  			//-------------------------------------------------------------------------------------------------------
		  			alert_cargando.close();
		  			//Mensaje de éxito
					swal.fire({
					  title:"<p style='font-size:13pt;font-weight:600;color: #282828;'>Cambio realizado</p>",
					  icon: 'success',
					  iconColor: '#17a2b8',
					  width: '300px',
					  showConfirmButton: false,
					  timer: 2500
					})
		  			//-------------------------------------------------------------------------------------------------------
		  		} else {
		  			//Sí no se actualizó correctamente la contraseña (auth)
		  			alert_cargando.close();
		  			//Mensaje de error
		  			swal.fire({
				      title:"<p style='font-size:13pt;font-weight:600;color: #282828;'>Error al actualizar contraseña</p>",
				      icon: 'error',
				      width: '400px',
				      showConfirmButton: false,
				      timer: 2500
				    })
		  		}
		  	})
		  	.catch(error => {
		    	//Error al ejecutar función (cloud function)
		    	alert_cargando.close();
		    	//Mensaje de error
		    	swal.fire({
				  title:"<p style='font-size:13pt;font-weight:600;color: #282828;'>Error al actualizar contraseña</p>",
				  icon: 'error',
				  width: '400px',
				  showConfirmButton: false,
				  timer: 2500
				})
		    });
		  	//***************************************************************************************************
		  	//*******************************************************************************************************************************
		  	//*******************************************************************************************************************************
		  	//*******************************************************************************************************************************
		  }
		});
	}
	//--------------------------------------------------------------------------------------------------------
	//--------------------------------------------------------------------------------------------------------
	//--------------------------------------------------------------------------------------------------------
	//***********************************************************************************************************************************
	//***********************************************************************************************************************************
	//***********************************************************************************************************************************
	//***********************************************************************************************************************************
	//--------------------------------------------------------------------------------------------------------
	//-------------------------------------------Actualiza nombre---------------------------------------------
	//--------------------------------------------------------------------------------------------------------
	actualizarNombre = () => {
		swal.fire({
		  title:"<p style='font-size: 14pt;font-weight:600;color: #282828;'>Edición de nombre del alumno</p>",
		  html: "<div class='col-sm-12' style='margin-top:10px;'>"+
				"<div class='form-group input-field' style='margin:auto;'>"+
				"<input class='input_correcto input' type='text' id='nombreRA' name='nombre' value='"+this.state.datos[0].nombre+"' required />"+
				"<label class='label' for='nombreRA'>Nombre</label>"+
				"<p id='error-nombreRA'></p>"+
				"</div>"+
				"</div>"+
				"<div class='col-sm-12'>"+
				"<div class='form-group input-field' style='margin:auto;'>"+
				"<input class='input_correcto input' type='text' id='apellidosRA' name='apellidos' value='"+this.state.datos[0].apellidos+"' required />"+
				"<label class='label' for='apellidosRA'>Apellidos</label>"+
				"<p id='error-apellidosRA'></p>"+
				"</div>"+
				"</div>",
		  footer: "<img width='20px' height='20px' style='margin-right:5px' src='"+advertencia+
		  		"'/><b style='font-size: 14;color: #D45454;'>Esta acción no se puede deshacer.</b>",
		  showCancelButton: true,
		  confirmButtonColor: "#17a2b8",
		  confirmButtonText: "<div style='font-size: 10pt;font-weight: 500;'>Actualizar</div>",
		  cancelButtonText: "<div style='font-size: 10pt;font-weight: 600;'>Cancelar</div>",
		  customClass: {
		    cancelButton: 'cancelButton',
		    confirmButton: 'confirmButton',
		    actions: 'alert-buttons',
		    title: 'titulo-alert-update-nombre',
		    content: 'container-alert'
		  },
		  didOpen: () => {
		  	swal.getConfirmButton().disabled = true;//Se deshabilita el botón "Actualizar"
		    //Se añaden funciones onChange a los inputs ---------------------------------------------------
		    document.getElementById("nombreRA").addEventListener('keyup', (e) => {
		    	//----------------------------------------------------------------------------
		    	//Validación
		    	if(/^[a-zA-ZÀ-ÿ\s]{1,40}$/.test(e.target.value)){
					document.getElementById("nombreRA").classList.remove('input_incorrecto');
					document.getElementById("nombreRA").classList.add('input_correcto');
					document.getElementById("error-nombreRA").innerHTML = '';
				} else {
					document.getElementById("nombreRA").classList.remove('input_correcto');
					document.getElementById("nombreRA").classList.add('input_incorrecto');
					document.getElementById("error-nombreRA").innerHTML = "Campo nombre solo acepta letras y espacios";
				}
				//Condición para continuar
				if(!(/^[a-zA-ZÀ-ÿ\s]{1,40}$/.test(document.getElementById("nombreRA").value)) || !(/^[a-zA-ZÀ-ÿ\s]{1,40}$/.test(document.getElementById("apellidosRA").value)) ||
					(document.getElementById("nombreRA").value==this.state.datos[0].nombre && document.getElementById("apellidosRA").value==this.state.datos[0].apellidos)){
					//Error
					swal.getConfirmButton().disabled = true;//Se deshabilita el botón "Actualizar"
				} else {
					//Correcto
					swal.getConfirmButton().disabled = false;//Se habilita el botón "Actualizar"
				}
		    	//----------------------------------------------------------------------------
		    });
		    document.getElementById("apellidosRA").addEventListener('keyup', (e) => {
		    	//----------------------------------------------------------------------------
		    	//Validación
		    	if(/^[a-zA-ZÀ-ÿ\s]{1,40}$/.test(e.target.value)){
					document.getElementById("apellidosRA").classList.remove('input_incorrecto');
					document.getElementById("apellidosRA").classList.add('input_correcto');
					document.getElementById("error-apellidosRA").innerHTML = '';
				} else {
					document.getElementById("apellidosRA").classList.remove('input_correcto');
					document.getElementById("apellidosRA").classList.add('input_incorrecto');
					document.getElementById("error-apellidosRA").innerHTML = "Campo apellidos solo acepta letras y espacios";
				}
				//Condición para continuar
				if(!(/^[a-zA-ZÀ-ÿ\s]{1,40}$/.test(document.getElementById("nombreRA").value)) || !(/^[a-zA-ZÀ-ÿ\s]{1,40}$/.test(document.getElementById("apellidosRA").value)) ||
					(document.getElementById("nombreRA").value==this.state.datos[0].nombre && document.getElementById("apellidosRA").value==this.state.datos[0].apellidos)){
					//Error
					swal.getConfirmButton().disabled = true;//Se deshabilita el botón "Actualizar"
				} else {
					//Correcto
					swal.getConfirmButton().disabled = false;//Se habilita el botón "Actualizar"
				}
				//----------------------------------------------------------------------------
		    });
		    //---------------------------------------------------------------------------------------------
		  }
		}).then((result) => {
		  if (result.isConfirmed) {
		  	//*******************************************************************************************************************************
		  	//*******************************************Administrador confirmó la actualización*********************************************
		  	//*******************************************************************************************************************************
		  	var nombre_temp = document.getElementById("nombreRA").value;//Se guarda dato no perderlo al cerrar alert
		  	var apellidos_temp = document.getElementById("apellidosRA").value;//Se guarda dato no perderlo al cerrar alert
		  	//Alert "loading"----------------------
			const alert_cargando = swal.fire({
			  width: '300px',
			  allowOutsideClick: false,
			  showConfirmButton: false,
			  didOpen: () => {
			    swal.showLoading()
			  },
			  customClass: {
				   title: 'titulo-alert-loading',
			     actions: 'alert-loader'
				}
			})
			//-------------------------------------
			//Se actualizan los datos****************************************************************************
			db.collection("alumnos").doc(this.state.uid).update({
			    nombre: nombre_temp.trim().toUpperCase(),
			    apellidos: apellidos_temp.trim().toUpperCase()
			}).then(function() {
			    //Los datos se actualizaron correctamente en firestore
			    alert_cargando.close();
			    //Mensaje de éxito
				swal.fire({
				  title:"<p style='font-size:13pt;font-weight:600;color: #282828;'>Cambios guardados</p>",
				  icon: 'success',
				  iconColor: '#17a2b8',
				  width: '300px',
				  showConfirmButton: false,
				  timer: 2000
				}).then((result) => {
				  	//se ejecuta después de mensaje de éxito
				  	window.location.reload(false);
				});
			}).catch(function(error) {
			    //Error al actualizar datos en firestore
			    alert_cargando.close();
			    //Mensaje de error
		  		swal.fire({
				  title:"<p style='font-size:13pt;font-weight:600;color: #282828;'>Error al actualizar datos</p>",
				  icon: 'error',
				  width: '400px',
				  showConfirmButton: false,
				  timer: 2500
				})
			});
		  	//***************************************************************************************************
		  	//*******************************************************************************************************************************
		  	//*******************************************************************************************************************************
		  	//*******************************************************************************************************************************
		  }
		});
	}
	//--------------------------------------------------------------------------------------------------------
	//--------------------------------------------------------------------------------------------------------
	//--------------------------------------------------------------------------------------------------------
	//--------------------------------------------------------------------------------------------------------
	//----------------------------------Actualiza información básica------------------------------------------
	//--------------------------------------------------------------------------------------------------------
	//Auxiliares para dirección--------------------------------
	locationInfo = {
	    estado: "",
	    municipio: "",
	    colonia: "",
	    codigoPostal: "",
	    calle: "",
	    numeroExterior: "",
	    numeroInterior: "S/N",
	    cadenaDireccion: "",
	    lat: "",
	    lng: "",
	    direccion_correcta: true,
	    reset: function() {
	      this.estado = "";
	      this.municipio = "";
	      this.colonia = "";
	      this.codigoPostal = "";
	      this.calle = "";
	      this.numeroExterior = "";
	      this.numeroInterior = "S/N";
	      this.cadenaDireccion = "";
	      this.lat = "";
	      this.lng = "";
	    }
	}
	onChangeNumeroInterior = (e) => {
      this.locationInfo.numeroInterior = e.target.value;
    }
    onChangeAddress = () => {
    	if(document.getElementById("address").value == this.state.datos[0].direccion.cadenaDireccion){
	    	this.locationInfo.direccion_correcta = true;
	    	document.getElementById("address").classList.add('input_correcto-address');
	    	document.getElementById("address").classList.remove('input_incorrecto-address');
	    	document.getElementById("error-direccion").innerHTML = '';
	    } else {
	    	this.locationInfo.direccion_correcta = false;
	    	document.getElementById("address").classList.add('input_incorrecto-address');
	    	document.getElementById("address").classList.remove('input_correcto-address');
	    	document.getElementById("error-direccion").innerHTML = 'Ingrese una dirección válida';
	    }
    }
    condicionContinuarIB = () => {
    	//Condición para continuar
		const añoFechaIngresada = new Date(document.getElementById("fechaNacimientoRA").value).getFullYear();
		if(añoFechaIngresada<1930 || añoFechaIngresada>((new Date().getFullYear())-1) || this.locationInfo.direccion_correcta==false ||
		  (document.getElementById("fechaNacimientoRA").value == moment(this.state.datos[0].fechaNacimiento.toDate()).format('YYYY-MM-DD') &&
		   document.getElementById("generoRA").value == this.state.datos[0].genero && document.getElementById("address").value == this.state.datos[0].direccion.cadenaDireccion &&
		   document.getElementById("numeroInteriorRA").value == this.state.datos[0].direccion.numeroInterior)) {
			//Incorrecto
			swal.getConfirmButton().disabled = true;//Se deshabilita el botón "Actualizar"
		} else {
			//Correcto
			swal.getConfirmButton().disabled = false;//Se habilita el botón "Actualizar"
		}
    }
	//---------------------------------------------------------
	actualizarIB = () => {
		swal.fire({
		  title:"<p style='font-size: 14pt;font-weight:600;color: #282828;'>Edición de información básica</p>",
		  width: 700,
		  html: "<div class='row' style='margin-top:10px;'>"+
			  		"<div class='col-sm-12 col-md-6 col-lg-6'>"+
						"<div class='form-group input-field' style='margin:auto;'>"+
							"<input class='input_correcto input' type='date' id='fechaNacimientoRA' name='fechaNacimiento' value='"+moment(this.state.datos[0].fechaNacimiento.toDate()).format('YYYY-MM-DD')+"' required />"+
							"<label class='label' for='fechaNacimientoRA'>Fecha de nacimiento</label>"+
							"<p id='error-fechaNacimientoRA'></p>"+
						"</div>"+
					"</div>"+
					"<div class='col-sm-12 col-md-6 col-lg-6'>"+
						"<div class='form-group input-field-select' style='margin:auto;'>"+
							"<select class='input_correcto input-select' id='generoRA' required>"+
		                        "<option value='' style='display:none'></option>"+
		                        "<option value='F'>Femenino</option>"+
		                        "<option value='M'>Masculino</option>"+
		                    "</select>"+
							"<label class='label-select' for='generoRA'>Género</label>"+
						"</div>"+
					"</div>"+
				"</div>"+
				"<div class='principal-direcciones' id='principalDirecciones'>"+
                  "<label class='label-principal-direcciones' style='margin-left:-200px;'>Dirección</label>"+
                  "<div style='height:101px;' id='js-preview-map'>"+
                      "<img src='https://maps.googleapis.com/maps/api/staticmap?key=AIzaSyDOafd3wkkjh-8nt0kb98ieVx3kkgyyOYs&center="+this.state.datos[0].direccion.lat+","+this.state.datos[0].direccion.lng+"&zoom=12&size=388x101&maptype=roadmap&markers=color:red%7C"+this.state.datos[0].direccion.lat+","+this.state.datos[0].direccion.lng+"' width='388' height='101'/>"+
                  "</div>"+
                  "<div class='form-group input-field-address'>"+
                      "<input class='input_correcto-address input-address' type='text' id='address' name='dirección' placeholder='Ingrese una dirección' value='"+this.state.datos[0].direccion.cadenaDireccion+"' required />"+
                      "<p id='error-direccion'></p>"+
                  "</div>"+
                  "<div class='form-group input-field' id='div-input_interior' style='clear:both;width:130px;height:56px;margin-left:1.5%;margin-top:5px;margin-bottom:0px'>"+
                      "<input class='input_correcto input' type='text' id='numeroInteriorRA' name='numero_interior' value='"+this.state.datos[0].direccion.numeroInterior+"' required />"+
                      "<label class='label' for='numeroInteriorRA'>Interior</label>"+
                  "</div>"+
                "</div>",
		  footer: "<img width='20px' height='20px' style='margin-right:5px' src='"+advertencia+
		  		"'/><b style='font-size: 14;color: #D45454;'>Esta acción no se puede deshacer.</b>",
		  showCancelButton: true,
		  confirmButtonColor: "#17a2b8",
		  confirmButtonText: "<div style='font-size: 10pt;font-weight: 500;'>Actualizar</div>",
		  cancelButtonText: "<div style='font-size: 10pt;font-weight: 600;'>Cancelar</div>",
		  customClass: {
		    cancelButton: 'cancelButton',
		    confirmButton: 'confirmButton',
		    actions: 'alert-buttons',
		    title: 'titulo-alert-update-nombre',
		    content: 'container-alert'
		  },
		  didOpen: () => {
		  	//Se reinicia el objeto de información de ubicación
	        this.locationInfo.reset();
	        //Se deshabilita el botón "Actualizar"
		  	swal.getConfirmButton().disabled = true;
		  	//Se selecciona el elemento del select
		  	document.getElementById("generoRA").value = this.state.datos[0].genero;
		  	//Manejo de dirección (address)
    		this.handleScriptLoad();
		    //Se añaden funciones onChange a los inputs ---------------------------------------------------
		    document.getElementById("address").addEventListener('keyup', (e) => {
		    	//Validación
		    	if(e.key!=='Enter'){
		    		this.onChangeAddress();
		    	}
		    	//Condición para continuar
				this.condicionContinuarIB();
		    });
		    document.getElementById("numeroInteriorRA").addEventListener('keyup', (e) => {
		    	//Validación
		    	this.onChangeNumeroInterior(e);
		    	//Condición para continuar
				this.condicionContinuarIB();
		    });
		    document.getElementById("fechaNacimientoRA").addEventListener('change', (e) => {
		    	//----------------------------------------------------------------------------
		    	//Validación
		    	const añoFechaIngresada = new Date(document.getElementById("fechaNacimientoRA").value).getFullYear();
		        if(añoFechaIngresada<1930 || añoFechaIngresada>((new Date().getFullYear())-1)){
		          //Fecha incorrecta
		          document.getElementById("fechaNacimientoRA").classList.remove('input_correcto');
		          document.getElementById("fechaNacimientoRA").classList.add('input_incorrecto');
		          document.getElementById("error-fechaNacimientoRA").innerHTML = 'Verifique la fecha ingresada';
		        } else {
		          //Fecha correcta
		          document.getElementById("fechaNacimientoRA").classList.remove('input_incorrecto');
		          document.getElementById("fechaNacimientoRA").classList.add('input_correcto');
		          document.getElementById("error-fechaNacimientoRA").innerHTML = '';
		        }
				//Condición para continuar
				this.condicionContinuarIB();
		    	//----------------------------------------------------------------------------
		    });
		    document.getElementById("generoRA").addEventListener('change', (e) => {
		    	//----------------------------------------------------------------------------
				//Condición para continuar
				this.condicionContinuarIB();
				//----------------------------------------------------------------------------
		    });
		    //---------------------------------------------------------------------------------------------
		  }
		}).then((result) => {
		  if (result.isConfirmed) {
		  	//*******************************************************************************************************************************
		  	//*******************************************Administrador confirmó la actualización*********************************************
		  	//*******************************************************************************************************************************
		  	//Se guardan datos para no perderlos al cerrar alert
		  	var fechaNacimiento_temp = firebase.firestore.Timestamp.fromDate(new Date(document.getElementById("fechaNacimientoRA").value.replace(/-/g, '\/')));
		  	var genero_temp = document.getElementById("generoRA").value;
		  	var cadenaDireccion_temp = document.getElementById("address").value;
		  	var numeroInterior_temp = document.getElementById("numeroInteriorRA").value;
		  	var direccionAux = null;
		  	//Alert "loading"----------------------
			const alert_cargando = swal.fire({
			  width: '300px',
			  allowOutsideClick: false,
			  showConfirmButton: false,
			  didOpen: () => {
			    swal.showLoading()
			  },
			  customClass: {
				   title: 'titulo-alert-loading',
			     actions: 'alert-loader'
				}
			})
			//-------------------------------------
			//Sí la dirección cambió
			if(cadenaDireccion_temp !== this.state.datos[0].direccion.cadenaDireccion){
				//Se crea objeto auxiliar de dirección----
		        direccionAux = {
		          calle: this.locationInfo.calle,
		          codigoPostal: this.locationInfo.codigoPostal,
		          colonia: this.locationInfo.colonia,
		          estado: this.locationInfo.estado,
		          municipio: this.locationInfo.municipio,
		          numeroExterior: this.locationInfo.numeroExterior,
		          numeroInterior: this.locationInfo.numeroInterior,
		          cadenaDireccion: this.locationInfo.cadenaDireccion,
		          lat: this.locationInfo.lat,
		          lng: this.locationInfo.lng
		        };
		        //----------------------------------------
				//Se actualizan los datos****************************************************************************
				db.collection("alumnos").doc(this.state.uid).update({
				    direccion: direccionAux,
					fechaNacimiento: fechaNacimiento_temp,
            		genero: genero_temp
				}).then(function() {
				    //Los datos se actualizaron correctamente en firestore
				    alert_cargando.close();
				    //Mensaje de éxito
					swal.fire({
					  title:"<p style='font-size:13pt;font-weight:600;color: #282828;'>Cambios guardados</p>",
					  icon: 'success',
					  iconColor: '#17a2b8',
					  width: '300px',
					  showConfirmButton: false,
					  timer: 2000
					}).then((result) => {
					  	//se ejecuta después de mensaje de éxito
					  	window.location.reload(false);
					});
				}).catch(function(error) {
				    //Error al actualizar datos en firestore
				    alert_cargando.close();
				    //Mensaje de error
			  		swal.fire({
					  title:"<p style='font-size:13pt;font-weight:600;color: #282828;'>Error al actualizar datos</p>",
					  icon: 'error',
					  width: '400px',
					  showConfirmButton: false,
					  timer: 2500
					})
				});
			  	//***************************************************************************************************
		  	} else {
		  		//Sí la dirección no cambió, pero si el numeroInterior
		  		if(numeroInterior_temp !== this.state.datos[0].direccion.numeroInterior){
		  			//Se crea objeto auxiliar de dirección----
			        direccionAux = {
			          calle: this.state.datos[0].direccion.calle,
			          codigoPostal: this.state.datos[0].direccion.codigoPostal,
			          colonia: this.state.datos[0].direccion.colonia,
			          estado: this.state.datos[0].direccion.estado,
			          municipio: this.state.datos[0].direccion.municipio,
			          numeroExterior: this.state.datos[0].direccion.numeroExterior,
			          numeroInterior: this.locationInfo.numeroInterior,
			          cadenaDireccion: this.state.datos[0].direccion.cadenaDireccion,
			          lat: this.state.datos[0].direccion.lat,
			          lng: this.state.datos[0].direccion.lng
			        };
			        //----------------------------------------
		  			//Se actualizan los datos****************************************************************************
					db.collection("alumnos").doc(this.state.uid).update({
					    direccion: direccionAux,
						fechaNacimiento: fechaNacimiento_temp,
	            		genero: genero_temp
					}).then(function() {
					    //Los datos se actualizaron correctamente en firestore
					    alert_cargando.close();
					    //Mensaje de éxito
						swal.fire({
						  title:"<p style='font-size:13pt;font-weight:600;color: #282828;'>Cambios guardados</p>",
						  icon: 'success',
						  iconColor: '#17a2b8',
						  width: '300px',
						  showConfirmButton: false,
						  timer: 2000
						}).then((result) => {
						  	//se ejecuta después de mensaje de éxito
						  	window.location.reload(false);
						});
					}).catch(function(error) {
					    //Error al actualizar datos en firestore
					    alert_cargando.close();
					    //Mensaje de error
				  		swal.fire({
						  title:"<p style='font-size:13pt;font-weight:600;color: #282828;'>Error al actualizar datos</p>",
						  icon: 'error',
						  width: '400px',
						  showConfirmButton: false,
						  timer: 2500
						})
					});
				  	//***************************************************************************************************
		  		} else {
		  			//Sí solo se va a actualizar la fecha o el género
		  			//Se actualizan los datos****************************************************************************
					db.collection("alumnos").doc(this.state.uid).update({
						fechaNacimiento: fechaNacimiento_temp,
	            		genero: genero_temp
					}).then(function() {
					    //Los datos se actualizaron correctamente en firestore
					    alert_cargando.close();
					    //Mensaje de éxito
						swal.fire({
						  title:"<p style='font-size:13pt;font-weight:600;color: #282828;'>Cambios guardados</p>",
						  icon: 'success',
						  iconColor: '#17a2b8',
						  width: '300px',
						  showConfirmButton: false,
						  timer: 2000
						}).then((result) => {
						  	//se ejecuta después de mensaje de éxito
						  	window.location.reload(false);
						});
					}).catch(function(error) {
					    //Error al actualizar datos en firestore
					    alert_cargando.close();
					    //Mensaje de error
				  		swal.fire({
						  title:"<p style='font-size:13pt;font-weight:600;color: #282828;'>Error al actualizar datos</p>",
						  icon: 'error',
						  width: '400px',
						  showConfirmButton: false,
						  timer: 2500
						})
					});
				  	//***************************************************************************************************
		  		}
		  	}
		  	//*******************************************************************************************************************************
		  	//*******************************************************************************************************************************
		  	//*******************************************************************************************************************************
		  }
		});
	}
	//--------------------------------------------------------------------------------------------------------
	//--------------------------------------------------------------------------------------------------------
	//--------------------------------------------------------------------------------------------------------
	//--------------------------------------------------------------------------------------------------------
	//---------------------------------Actualiza información de la cuenta-------------------------------------
	//--------------------------------------------------------------------------------------------------------
	actualizarIC = () => {
		swal.fire({
		  title:"<p style='font-size: 14pt;font-weight:600;color: #282828;'>Edición de información de la cuenta</p>",
		  html: "<div class='col-sm-12'>"+
					"<div class='form-group input-field' style='margin:auto;'>"+
						"<input class='input_correcto input' type='date' id='fechaInscripcionRA' name='fechaInscripcion' value='"+moment(this.state.datos[0].fechaInscripcion.toDate()).format('YYYY-MM-DD')+"' required />"+
						"<label class='label' for='fechaInscripcionRA'>Fecha de inscripción</label>"+
						"<p id='error-fechaInscripcionRA'></p>"+
					"</div>"+
				"</div>"+
				"<div class='col-sm-12'>"+
					"<div class='form-group input-field' style='margin:auto;'>"+
						"<input class='input_correcto input' type='text' id='correoRA' name='correo' value='"+this.state.datos[0].correoElectronico+"' required />"+
						"<label class='label' for='correoRA'>Correo electrónico</label>"+
						"<p id='error-correoRA'></p>"+
					"</div>"+
				"</div>",
		  footer: "<img width='20px' height='20px' style='margin-right:5px' src='"+advertencia+
		  		"'/><b style='font-size: 14;color: #D45454;'>Esta acción no se puede deshacer.</b>",
		  showCancelButton: true,
		  confirmButtonColor: "#17a2b8",
		  confirmButtonText: "<div style='font-size: 10pt;font-weight: 500;'>Actualizar</div>",
		  cancelButtonText: "<div style='font-size: 10pt;font-weight: 600;'>Cancelar</div>",
		  customClass: {
		    cancelButton: 'cancelButton',
		    confirmButton: 'confirmButton',
		    actions: 'alert-buttons',
		    title: 'titulo-alert-update-nombre',
		    content: 'container-alert'
		  },
		  didOpen: () => {
		  	swal.getConfirmButton().disabled = true;//Se deshabilita el botón "Actualizar"
		    //Se añaden funciones onChange a los inputs ---------------------------------------------------
		    document.getElementById("correoRA").addEventListener('keyup', (e) => {
		    	//----------------------------------------------------------------------------
		    	//Validación
		    	if(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(e.target.value)){
					document.getElementById("correoRA").classList.remove('input_incorrecto');
					document.getElementById("correoRA").classList.add('input_correcto');
					document.getElementById("error-correoRA").innerHTML = '';
				} else {
					document.getElementById("correoRA").classList.remove('input_correcto');
					document.getElementById("correoRA").classList.add('input_incorrecto');
					document.getElementById("error-correoRA").innerHTML = "Ingrese un correo válido";
				}
				//Condición para continuar
				if(!(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(document.getElementById("correoRA").value)) ||
					(document.getElementById("correoRA").value==this.state.datos[0].correoElectronico && document.getElementById("fechaInscripcionRA").value==moment(this.state.datos[0].fechaInscripcion.toDate()).format('YYYY-MM-DD'))){
					//Error
					swal.getConfirmButton().disabled = true;//Se deshabilita el botón "Actualizar"
				} else {
					//Correcto
					swal.getConfirmButton().disabled = false;//Se habilita el botón "Actualizar"
				}
		    	//----------------------------------------------------------------------------
		    });
		    document.getElementById("fechaInscripcionRA").addEventListener('change', (e) => {
		    	//----------------------------------------------------------------------------
		    	//Condición para continuar
				if(!(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(document.getElementById("correoRA").value)) ||
					(document.getElementById("correoRA").value==this.state.datos[0].correoElectronico && document.getElementById("fechaInscripcionRA").value==moment(this.state.datos[0].fechaInscripcion.toDate()).format('YYYY-MM-DD'))){
					//Error
					swal.getConfirmButton().disabled = true;//Se deshabilita el botón "Actualizar"
				} else {
					//Correcto
					swal.getConfirmButton().disabled = false;//Se habilita el botón "Actualizar"
				}
				//----------------------------------------------------------------------------
		    });
		    //---------------------------------------------------------------------------------------------
		  }
		}).then((result) => {
		  if (result.isConfirmed) {
		  	//*******************************************************************************************************************************
		  	//*******************************************Administrador confirmó la actualización*********************************************
		  	//*******************************************************************************************************************************
		  	//Se guardan datos para no perderlos al cerrar alert
		  	var fechaInscripcion_temp = firebase.firestore.Timestamp.fromDate(new Date(document.getElementById("fechaInscripcionRA").value.replace(/-/g, '\/')));
		  	var correo_temp = document.getElementById("correoRA").value;
		  	//Alert "loading"----------------------
			const alert_cargando = swal.fire({
			  width: '300px',
			  allowOutsideClick: false,
			  showConfirmButton: false,
			  didOpen: () => {
			    swal.showLoading()
			  },
			  customClass: {
				   title: 'titulo-alert-loading',
			     actions: 'alert-loader'
				}
			})
			//-------------------------------------
			fetch("https://us-central1-sistema-escuela-69a3d.cloudfunctions.net/actualizarCorreo?uid="+this.state.uid+"&email="+correo_temp)
			.then(response => response.json())
			.then(data => {
				//Sí se completó correctamente la actualización del correo (auth)
				if(data.completado) {
					//--------------------------------------------------------------------------------------------------------------------------
					//Se actualizan los datos****************************************************************************
					db.collection("alumnos").doc(this.state.uid).update({
					    correoElectronico: correo_temp,
					    fechaInscripcion: fechaInscripcion_temp
					}).then(function() {
					    //Los datos se actualizaron correctamente en firestore
					    alert_cargando.close();
					    //Mensaje de éxito
						swal.fire({
						  title:"<p style='font-size:13pt;font-weight:600;color: #282828;'>Cambios guardados</p>",
						  icon: 'success',
						  iconColor: '#17a2b8',
						  width: '300px',
						  showConfirmButton: false,
						  timer: 2000
						}).then((result) => {
						  	//se ejecuta después de mensaje de éxito
						  	window.location.reload(false);
						});
					}).catch(function(error) {
					    //Error al actualizar datos en firestore
					    alert_cargando.close();
					    //Mensaje de error
				  		swal.fire({
						  title:"<p style='font-size:13pt;font-weight:600;color: #282828;'>Error al actualizar datos</p>",
						  icon: 'error',
						  width: '400px',
						  showConfirmButton: false,
						  timer: 2500
						})
					});
				  	//***************************************************************************************************
					//--------------------------------------------------------------------------------------------------------------------------
				} else {
					//Sí no se actualizó correctamente el correo (auth)
					alert_cargando.close();
					//Mensaje de error
				  	swal.fire({
					  title:"<p style='font-size:13pt;font-weight:600;color: #282828;'>Error al actualizar datos</p>",
					  icon: 'error',
					  width: '400px',
					  showConfirmButton: false,
					  timer: 2500
					})
				}
			})
			.catch(error => {
				//Error al ejecutar función (cloud function)
				alert_cargando.close();
				//Mensaje de error
				swal.fire({
				  title:"<p style='font-size:13pt;font-weight:600;color: #282828;'>Error al actualizar datos</p>",
				  icon: 'error',
				  width: '400px',
				  showConfirmButton: false,
				  timer: 2500
				})
			});
		  	//*******************************************************************************************************************************
		  	//*******************************************************************************************************************************
		  	//*******************************************************************************************************************************
		  }
		});
	}
	//--------------------------------------------------------------------------------------------------------
	//--------------------------------------------------------------------------------------------------------
	//--------------------------------------------------------------------------------------------------------
	//--------------------------------------------------------------------------------------------------------
	//---------------------------------------Actualiza datos médicos------------------------------------------
	//--------------------------------------------------------------------------------------------------------
	condicionContinuarDM = () => {
		//----------------------------------------------------------------------------
		//Condición para continuar
		if((document.getElementById("medicamentosRA").value=="" || document.getElementById("alergiasRA").value=="" ||
			document.getElementById("enfermedadesRA").value=="" || document.getElementById("limitacionesRA").value=="" ||
			document.getElementById("tratamientosMedicosRA").value=="")||
		  (document.getElementById("medicamentosRA").value==this.state.datos[0].datosMedicos.medicamentos &&
		   document.getElementById("alergiasRA").value==this.state.datos[0].datosMedicos.alergias &&
		   document.getElementById("enfermedadesRA").value==this.state.datos[0].datosMedicos.enfermedades &&
		   document.getElementById("limitacionesRA").value==this.state.datos[0].datosMedicos.limitacionesFisicas &&
		   document.getElementById("tratamientosMedicosRA").value==this.state.datos[0].datosMedicos.tratamientoMedico &&
		   document.getElementById("grupoSanguineoRA").value==this.state.datos[0].datosMedicos.grupoSanguineo)) {
			//Error
			swal.getConfirmButton().disabled = true;//Se deshabilita el botón "Actualizar"
		} else {
			//Correcto
			swal.getConfirmButton().disabled = false;//Se habilita el botón "Actualizar"
		}
		//----------------------------------------------------------------------------
	}
	actualizarDM = () => {
		swal.fire({
		  title:"<p style='font-size: 14pt;font-weight:600;color: #282828;'>Actualización de datos médicos</p>",
		  width: 700,
		  html: "<p style='font-size: 10pt;color: #0000008A;'>Alumno</p>"+
		  		"<p style='font-size: 11pt;color: #646464;'>"+this.state.datos[0].nombre+" "+this.state.datos[0].apellidos+"</p>"+
		  		"<div class='row' style='margin-top:30px;'>"+
			  		"<div class='col-sm-12 col-md-6 col-lg-6'>"+
						"<div class='form-group input-field-select' style='margin:auto;'>"+
							"<select class='input_correcto input-select' id='grupoSanguineoRA' required>"+
		                        "<option value='' style='display:none'></option>"+
		                        "<option value='AB+'>AB+</option>"+
								"<option value='AB-'>AB-</option>"+
								"<option value='A+'>A+</option>"+
								"<option value='A-'>A-</option>"+
								"<option value='B+'>B+</option>"+
								"<option value='B-'>B-</option>"+
								"<option value='0+'>0+</option>"+
								"<option value='0-'>0-</option>"+
		                    "</select>"+
							"<label class='label-select' for='grupoSanguineoRA'>Grupo sanguíneo</label>"+
						"</div>"+
					"</div>"+
					"<div class='col-sm-12 col-md-6 col-lg-6'>"+
						"<div class='form-group input-field' style='margin:auto;'>"+
							"<input class='input_correcto input' type='text' id='medicamentosRA' name='medicamentos' value='"+this.state.datos[0].datosMedicos.medicamentos+"' required />"+
							"<label class='label' for='medicamentosRA'>Medicamentos</label>"+
						"</div>"+
					"</div>"+
				"</div>"+
				"<div class='row' style='margin-top:10px;'>"+
					"<div class='col-sm-12 col-md-6 col-lg-6'>"+
						"<div class='form-group input-field' style='margin:auto;'>"+
							"<input class='input_correcto input' type='text' id='alergiasRA' name='alergias' value='"+this.state.datos[0].datosMedicos.alergias+"' required />"+
							"<label class='label' for='alergiasRA'>Alergias</label>"+
						"</div>"+
					"</div>"+
					"<div class='col-sm-12 col-md-6 col-lg-6'>"+
						"<div class='form-group input-field' style='margin:auto;'>"+
							"<input class='input_correcto input' type='text' id='enfermedadesRA' name='enfermedades' value='"+this.state.datos[0].datosMedicos.enfermedades+"' required />"+
							"<label class='label' for='enfermedadesRA'>Enfermedades o intervenciones</label>"+
						"</div>"+
					"</div>"+
				"</div>"+
				"<div class='row' style='margin-top:10px;'>"+
					"<div class='col-sm-12 col-md-6 col-lg-6'>"+
						"<div class='form-group input-field' style='margin:auto;'>"+
							"<input class='input_correcto input' type='text' id='limitacionesRA' name='limitaciones' value='"+this.state.datos[0].datosMedicos.limitacionesFisicas+"' required />"+
							"<label class='label' for='limitacionesRA'>Limitaciones físicas</label>"+
						"</div>"+
					"</div>"+
					"<div class='col-sm-12 col-md-6 col-lg-6'>"+
						"<div class='form-group input-field' style='margin:auto;'>"+
							"<input class='input_correcto input' type='text' id='tratamientosMedicosRA' name='tratamientosMedicos' value='"+this.state.datos[0].datosMedicos.tratamientoMedico+"' required />"+
							"<label class='label' for='tratamientosMedicosRA'>Tratamientos médicos</label>"+
						"</div>"+
					"</div>"+
				"</div>",
		  footer: "<img width='20px' height='20px' style='margin-right:5px' src='"+advertencia+
		  		"'/><b style='font-size: 14;color: #D45454;'>Esta acción no se puede deshacer.</b>",
		  showCancelButton: true,
		  confirmButtonColor: "#17a2b8",
		  confirmButtonText: "<div style='font-size: 10pt;font-weight: 500;'>Actualizar</div>",
		  cancelButtonText: "<div style='font-size: 10pt;font-weight: 600;'>Cancelar</div>",
		  customClass: {
		    cancelButton: 'cancelButton',
		    confirmButton: 'confirmButton',
		    actions: 'alert-buttons',
		    title: 'titulo-alert-update-nombre',
		    content: 'container-alert'
		  },
		  didOpen: () => {
		  	//Se deshabilita el botón "Actualizar"
		  	swal.getConfirmButton().disabled = true;
		  	//Se selecciona el elemento del select
		  	document.getElementById("grupoSanguineoRA").value = this.state.datos[0].datosMedicos.grupoSanguineo;
		    //Se añaden funciones onChange a los inputs ---------------------------------------------------
		    document.getElementById("medicamentosRA").addEventListener('keyup', (e) => {this.condicionContinuarDM();});
		    document.getElementById("alergiasRA").addEventListener('keyup', (e) => {this.condicionContinuarDM();});
		    document.getElementById("enfermedadesRA").addEventListener('keyup', (e) => {this.condicionContinuarDM();});
		    document.getElementById("limitacionesRA").addEventListener('keyup', (e) => {this.condicionContinuarDM();});
		    document.getElementById("tratamientosMedicosRA").addEventListener('keyup', (e) => {this.condicionContinuarDM();});
		    document.getElementById("grupoSanguineoRA").addEventListener('change', (e) => {this.condicionContinuarDM();});
		    //---------------------------------------------------------------------------------------------
		  }
		}).then((result) => {
		  if (result.isConfirmed) {
		  	//*******************************************************************************************************************************
		  	//*******************************************Administrador confirmó la actualización*********************************************
		  	//*******************************************************************************************************************************
		  	//Se guardan datos para no perderlos al cerrar alert (se crea objeto de "datosMedicos")
		  	var datosMedicos_aux = {
				alergias: document.getElementById("alergiasRA").value,
				enfermedades: document.getElementById("enfermedadesRA").value,
				grupoSanguineo: document.getElementById("grupoSanguineoRA").value,
				limitacionesFisicas: document.getElementById("limitacionesRA").value,
				medicamentos: document.getElementById("medicamentosRA").value,
				tratamientoMedico: document.getElementById("tratamientosMedicosRA").value
		  	};
		  	//Alert "loading"----------------------
			const alert_cargando = swal.fire({
			  width: '300px',
			  allowOutsideClick: false,
			  showConfirmButton: false,
			  didOpen: () => {
			    swal.showLoading()
			  },
			  customClass: {
				   title: 'titulo-alert-loading',
			     actions: 'alert-loader'
				}
			})
			//-------------------------------------
			//Se actualizan los datos****************************************************************************
			db.collection("alumnos").doc(this.state.uid).update({
			    datosMedicos: datosMedicos_aux
			}).then(function() {
			    //Los datos se actualizaron correctamente en firestore
			    alert_cargando.close();
			    //Mensaje de éxito
				swal.fire({
				  title:"<p style='font-size:13pt;font-weight:600;color: #282828;'>Cambios guardados</p>",
				  icon: 'success',
				  iconColor: '#17a2b8',
				  width: '300px',
				  showConfirmButton: false,
				  timer: 2000
				}).then((result) => {
				  	//se ejecuta después de mensaje de éxito
				  	window.location.reload(false);
				});
			}).catch(function(error) {
			    //Error al actualizar datos en firestore
			    alert_cargando.close();
			    //Mensaje de error
		  		swal.fire({
				  title:"<p style='font-size:13pt;font-weight:600;color: #282828;'>Error al actualizar datos</p>",
				  icon: 'error',
				  width: '400px',
				  showConfirmButton: false,
				  timer: 2500
				})
			});
		  	//***************************************************************************************************
		  	//*******************************************************************************************************************************
		  	//*******************************************************************************************************************************
		  	//*******************************************************************************************************************************
		  }
		});
	}
	//--------------------------------------------------------------------------------------------------------
	//--------------------------------------------------------------------------------------------------------
	//--------------------------------------------------------------------------------------------------------
	//--------------------------------------------------------------------------------------------------------
	//------------------------------------------Actualiza tutores---------------------------------------------
	//--------------------------------------------------------------------------------------------------------
	//##################################################################################################################################
    //##################################################################################################################################
    //##################################################################################################################################
    datosRegistro = {
	  tutores: [],
	  contactosEmergencia: [],
	  personasAutorizadas: [],
	  actividades: [],
	  productos: [],
	  eliminados: [],
	  reset: function() {
	    this.tutores = [];
	    this.contactosEmergencia = [];
	    this.personasAutorizadas = [];
	    this.actividades = [];
	    this.productos = [];
	    this.eliminados = [];
	  }
	}
    //BÚSQUEDA DE TUTOR (CONSULTA FIRESTORE)***************************************************************
    buscarTutor = (e) => {
      e.preventDefault();//Evita la recarga de la página
      var textoBusqueda = document.getElementById("busqueda-tutor").value;
      if(textoBusqueda !== ""){
        //------------------------------------------------------------------------------------------------------------
        //------------------------------------------------------------------------------------------------------------
        //------------------------------------------------------------------------------------------------------------
        db.collection("tutores").where("correoElectronico", "==", textoBusqueda.trim()).get().then((querySnapshot) => {
            var contador = 0;
            document.getElementById("body-tabla-tutores-busqueda").innerHTML = "";
            querySnapshot.forEach((doc) => {
                contador++;
                //*****************************************************************************************
                document.getElementById("body-tabla-tutores-busqueda").insertAdjacentHTML("beforeend", 
                "<tr class='filaTabla filaTabla-hover'>"+
                  "<td>"+doc.data().nombre+"</td>"+
                  "<td>"+doc.data().apellidos+"</td>"+
                  "<td>"+
                    "<div class='toggle-botonTabla' id='"+doc.id+"busqueda-III"+"' title='Asociar' style='margin-right:15px'>"+
                      "<img class='botonTabla' src="+asociar+" width='17px' />"+
                    "</div>"+
                  "</td>"+
                "</tr>");
                //Evento de la fila (onclick en asociar)----------------------------------------
                document.getElementById(doc.id+"busqueda-III").addEventListener('click', (e) => {
                  this.asociarTutor(doc.id, doc.data().nombre, doc.data().apellidos);
                });
                //------------------------------------------------------------------------------
                //*****************************************************************************************
            });
            if(contador == 0){
              //No hubo resultados para correoElectronico
              //------------------------------------------------------------------------------------------------------------
              //------------------------------------------------------------------------------------------------------------
              //------------------------------------------------------------------------------------------------------------
              db.collection("tutores").where("telefono", "==", textoBusqueda.trim()).get().then((querySnapshot) => {
                  document.getElementById("body-tabla-tutores-busqueda").innerHTML = "";
                  querySnapshot.forEach((doc) => {
                      contador++;
                      //*****************************************************************************************
                      document.getElementById("body-tabla-tutores-busqueda").insertAdjacentHTML("beforeend", 
                      "<tr class='filaTabla filaTabla-hover'>"+
                        "<td>"+doc.data().nombre+"</td>"+
                        "<td>"+doc.data().apellidos+"</td>"+
                        "<td>"+
                          "<div class='toggle-botonTabla' id='"+doc.id+"busqueda-III"+"' title='Asociar' style='margin-right:15px'>"+
                            "<img class='botonTabla' src="+asociar+" width='17px' />"+
                          "</div>"+
                        "</td>"+
                      "</tr>");
                      //Evento de la fila (onclick en asociar)----------------------------------------
                      document.getElementById(doc.id+"busqueda-III").addEventListener('click', (e) => {
                        this.asociarTutor(doc.id, doc.data().nombre, doc.data().apellidos);
                      });
                      //------------------------------------------------------------------------------
                      //*****************************************************************************************
                  });
                  if(contador == 0){
                    //No hubo resultados para telefono
                    //------------------------------------------------------------------------------------------------------------
                    //------------------------------------------------------------------------------------------------------------
                    //------------------------------------------------------------------------------------------------------------
                    db.collection("tutores").where("apellidos", "==", textoBusqueda.trim().toUpperCase()).get().then((querySnapshot) => {
                        document.getElementById("body-tabla-tutores-busqueda").innerHTML = "";
                        querySnapshot.forEach((doc) => {
                            contador++;
                            //*****************************************************************************************
                            document.getElementById("body-tabla-tutores-busqueda").insertAdjacentHTML("beforeend", 
                            "<tr class='filaTabla filaTabla-hover'>"+
                              "<td>"+doc.data().nombre+"</td>"+
                              "<td>"+doc.data().apellidos+"</td>"+
                              "<td>"+
                                "<div class='toggle-botonTabla' id='"+doc.id+"busqueda-III"+"' title='Asociar' style='margin-right:15px'>"+
                                  "<img class='botonTabla' src="+asociar+" width='17px' />"+
                                "</div>"+
                              "</td>"+
                            "</tr>");
                            //Evento de la fila (onclick en asociar)----------------------------------------
                            document.getElementById(doc.id+"busqueda-III").addEventListener('click', (e) => {
                              this.asociarTutor(doc.id, doc.data().nombre, doc.data().apellidos);
                            });
                            //------------------------------------------------------------------------------
                            //*****************************************************************************************
                        });
                        if(contador == 0){
                          //No hubo resultados para apellidos
                          //------------------------------------------------------------------------------------------------------------
                          //------------------------------------------------------------------------------------------------------------
                          //------------------------------------------------------------------------------------------------------------
                          db.collection("tutores").where("nombre", "==", textoBusqueda.trim().toUpperCase()).get().then((querySnapshot) => {
                              document.getElementById("body-tabla-tutores-busqueda").innerHTML = "";
                              querySnapshot.forEach((doc) => {
                                  contador++;
                                  //*****************************************************************************************
                                  document.getElementById("body-tabla-tutores-busqueda").insertAdjacentHTML("beforeend", 
                                  "<tr class='filaTabla filaTabla-hover'>"+
                                    "<td>"+doc.data().nombre+"</td>"+
                                    "<td>"+doc.data().apellidos+"</td>"+
                                    "<td>"+
                                      "<div class='toggle-botonTabla' id='"+doc.id+"busqueda-III"+"' title='Asociar' style='margin-right:15px'>"+
                                        "<img class='botonTabla' src="+asociar+" width='17px' />"+
                                      "</div>"+
                                    "</td>"+
                                  "</tr>");
                                  //Evento de la fila (onclick en asociar)----------------------------------------
                                  document.getElementById(doc.id+"busqueda-III").addEventListener('click', (e) => {
                                    this.asociarTutor(doc.id, doc.data().nombre, doc.data().apellidos);
                                  });
                                  //------------------------------------------------------------------------------
                                  //*****************************************************************************************
                              });
                              if(contador == 0){
                                //No hubo resultados para nombre (NINGÚN RESULTADO PARA LA BÚSQUEDA)
                                document.getElementById("body-tabla-tutores-busqueda").innerHTML = 
                                "<tr>"+
                                  "<td colspan='3'>"+
                                    "<center>"+
                                      "<p id='avisoSinResultadosBusquedaTutor' style='color:#c8c8c8;display:inline;'>No hay resultados a mostrar</p>"+
                                    "</center>"+
                                  "</td>"+
                                "</tr>";
                              }
                          }).catch(function(error) {
                              //Error al realizar consulta
                          });
                          //------------------------------------------------------------------------------------------------------------
                          //------------------------------------------------------------------------------------------------------------
                          //------------------------------------------------------------------------------------------------------------
                        }
                    }).catch(function(error) {
                        //Error al realizar consulta
                    });
                    //------------------------------------------------------------------------------------------------------------
                    //------------------------------------------------------------------------------------------------------------
                    //------------------------------------------------------------------------------------------------------------
                  }
              }).catch(function(error) {
                  //Error al realizar consulta
              });
              //------------------------------------------------------------------------------------------------------------
              //------------------------------------------------------------------------------------------------------------
              //------------------------------------------------------------------------------------------------------------
            }
        }).catch(function(error) {
            //Error al realizar consulta
        });
        //------------------------------------------------------------------------------------------------------------
        //------------------------------------------------------------------------------------------------------------
        //------------------------------------------------------------------------------------------------------------
      }
    }
    //*****************************************************************************************************
    //******************************************* ASOCIAR TUTOR *************************************************
    asociarTutor = (uid, nombre, apellidos) => {
      //Sí la tabla está vacía, se elimina el mensaje de "Ningún tutor asociado"---
      if(this.datosRegistro.tutores.length == 0){
      	swal.getConfirmButton().disabled = false;//Se habilita botón "Actualizar"
        document.getElementById("body-tabla-tutores-asociados").innerHTML = "";
      }
      //---------------------------------------------------------------------------

      //Se guarda en array "tutores" la referencia------------------------------------------------------------------------
      //Sí ya existe el valor que se intenta guardar, se omite
      var aux = db.collection('tutores').doc(uid);
      if(!(this.datosRegistro.tutores.find(element => element.id == aux.id) !== undefined)){
        this.datosRegistro.tutores.push(aux);//Guardado en el array "tutores"
        this.datosRegistro.contactosEmergencia.push(aux);//Guardado en el array "contactosEmergencia"
        this.datosRegistro.personasAutorizadas.push(aux);//Guardado en el array "personasAutorizadas"
        //Mostrar en Front (Tabla de asociación)----------------------------------------------------------------------------
        //*****************************************************************************************
        document.getElementById("body-tabla-tutores-asociados").insertAdjacentHTML("beforeend", 
        "<tr class='filaTabla filaTabla-hover' id='"+uid+"asociado-fila-III'>"+
          "<td>"+nombre+"</td>"+
          "<td>"+apellidos+"</td>"+
          "<td>"+
            "<div class='toggle-botonTabla' id='"+uid+"asociado-III"+"' title='Desasociar' style='margin-right:15px'>"+
              "<img class='botonTabla' src="+eliminar+" width='17px' />"+
            "</div>"+
          "</td>"+
        "</tr>");
        //Evento de la fila (onclick en desasociar)----------------------------------------
        document.getElementById(uid+"asociado-III").addEventListener('click', (e) => {
          //--------------------------------------------------------------------------------
          //------------------------------- Desasociar tutor -------------------------------
          //--------------------------------------------------------------------------------
          //Se elimina de la tabla (Front)
          document.getElementById("body-tabla-tutores-asociados").removeChild(document.getElementById(uid+"asociado-fila-III"));
          //Se agrega al arreglo de eliminados
          if(!(this.datosRegistro.eliminados.find(element => element.id == aux.id) !== undefined)){//Sí ya existe el valor que se intenta guardar, se omite
          	this.datosRegistro.eliminados.push(aux);//Guardado en el array "eliminados"
          }
          //Se elimina del array "tutores", "contactosEmergencia" y "personasAutorizadas"
          this.datosRegistro.tutores.splice(this.datosRegistro.tutores.indexOf(this.datosRegistro.tutores.find(element => element.id == aux.id)), 1);
          this.datosRegistro.contactosEmergencia.splice(this.datosRegistro.contactosEmergencia.indexOf(this.datosRegistro.contactosEmergencia.find(element => element.id == aux.id)), 1);
          this.datosRegistro.personasAutorizadas.splice(this.datosRegistro.personasAutorizadas.indexOf(this.datosRegistro.personasAutorizadas.find(element => element.id == aux.id)), 1);
          //Sí la tabla se quedó sin elementos
          if(this.datosRegistro.tutores.length == 0) {
          	swal.getConfirmButton().disabled = true;//Se deshabilita botón "Actualizar"
            document.getElementById("body-tabla-tutores-asociados").innerHTML = 
            "<tr>"+
              "<td colspan='3'>"+
                "<center>"+
                  "<p id='avisoSinAsociacionesTutor' style='color:#c8c8c8;display:inline;'>Ningún tutor asociado</p>"+
                "</center>"+
              "</td>"+
            "</tr>";
          }
          //--------------------------------------------------------------------------------
          //--------------------------------------------------------------------------------
          //--------------------------------------------------------------------------------
        });
        //------------------------------------------------------------------------------
        //*****************************************************************************************
        //-----------------------------------------------------------------------------------------------------------------
      }
      //------------------------------------------------------------------------------------------------------------------
    }
    //***********************************************************************************************************
    //##################################################################################################################################
    //##################################################################################################################################
    //##################################################################################################################################
	actualizarT = () => {
		swal.fire({
		  title:"<p style='font-size: 14pt;font-weight:600;color: #282828;'>Edición de tutores asociados</p>",
		  width: 1000,
		  html: "<p style='font-size: 10pt;color: #0000008A;'>Alumno</p>"+
		  		"<p style='font-size: 11pt;color: #646464;'>"+this.state.datos[0].nombre+" "+this.state.datos[0].apellidos+"</p>"+
		  		"<form style='margin:0;padding:0;margin-top:20px;'><div class='row'>"+
                  "<div class='col-sm-12 col-md-12 col-lg-6'>"+
                    "<div style='border-radius:8px;background-color:#e6e6e6;width:437px;margin:auto;margin-bottom:30px;'>"+
                      "<div class='wrap'>"+
                         "<div class='search'>"+
                            "<input type='search' class='searchTerm' id='busqueda-tutor' placeholder='Buscar por correo electrónico, nombre, apellidos o teléfono' style='font-size:10pt;'/>"+
                            "<button class='searchButton' id='boton-busqueda-tutor'>"+
                              "<img src='"+lupa+"' style='margin-top:-6px;margin-left:-2px;opacity:0.6;' width='18px' />"+
                            "</button>"+
                         "</div>"+
                      "</div>"+
                      "<div style='border-radius:0px 0px 8px 8px;overflow:hidden;box-shadow:0px 1px 2px 1px #BDC3C7;width: 433px;margin:auto;'>"+
                        "<div id='tabla-tutores' style='width: 433px;height: 200px; overflow-y: scroll;overflow-x: scroll;background-color: #fff;margin: auto;'>"+
                          "<table class='table'>"+
                            "<thead>"+
                               "<tr style='background-color:#fafafa;'>"+
                                  "<th>Nombre</th>"+
                                  "<th>Apellidos</th>"+
                                  "<th width='50px'></th>"+
                               "</tr>"+
                            "</thead>"+
                            "<tbody id='body-tabla-tutores-busqueda'>"+
                              "<tr>"+
                                "<td colspan='3' style='text-align:center;'>"+
                                  "<center>"+
                                    "<p id='avisoSinResultadosBusquedaTutor' style='color:#c8c8c8;display:inline;'>No hay resultados a mostrar</p>"+
                                  "</center>"+
                                "</td>"+
                              "</tr>"+
                            "</tbody>"+
                          "</table>"+
                        "</div>"+
                      "</div>"+
                    "</div>"+
                  "</div>"+
                  "<div class='col-sm-12 col-md-12 col-lg-6'>"+
                    "<div style='margin:auto;background-color:#e6e6e6;border-radius:9px;padding:1px;width: 404px;'>"+
                        "<p style='font-size:11pt;font-weight:500;color:#868686;margin-bottom:11px;margin-left:10px;margin-top:8px;float:left;'>Tutores asociados con alumno</p>"+
                        "<a href='/administrador/alta/tutor' target='_blank'>"+
                          "<img class='botonImagen' title='Registrar tutor' style='float:right;margin-right:12px;margin-top:11px;' src='"+nuevo+"' width='17px' />"+
                        "</a>"+
                        "<div style='border-radius:0px 0px 8px 8px;overflow:hidden;box-shadow:0px 1px 2px 1px #BDC3C7;width: 400px;margin:auto;'>"+
                          "<div id='tabla-tutores-asociados' style='width: 400px;height: 200px; overflow-y: scroll;overflow-x: scroll;background-color: #fff;margin: auto;'>"+
                            "<table class='table'>"+
                              "<thead>"+
                                 "<tr style='background-color:#fafafa;'>"+
                                    "<th>Nombre</th>"+
                                    "<th>Apellidos</th>"+
                                    "<th width='50px'></th>"+
                                 "</tr>"+
                              "</thead>"+
                              "<tbody id='body-tabla-tutores-asociados'>"+
                                "<tr>"+
                                  "<td colspan='3' style='text-align:center;'>"+
                                    "<center>"+
                                      "<p id='avisoSinAsociacionesTutor' style='color:#c8c8c8;display:inline;'>Ningún tutor asociado</p>"+
                                    "</center>"+
                                  "</td>"+
                                "</tr>"+
                              "</tbody>"+
                            "</table>"+
                          "</div>"+
                        "</div>"+
                    "</div>"+
                  "</div>"+
                "</div></form>",
		  footer: "<img width='20px' height='20px' style='margin-right:5px' src='"+advertencia+
		  		"'/><b style='font-size: 14;color: #D45454;'>Esta acción no se puede deshacer.</b>",
		  showCancelButton: true,
		  confirmButtonColor: "#17a2b8",
		  confirmButtonText: "<div style='font-size: 10pt;font-weight: 500;'>Actualizar</div>",
		  cancelButtonText: "<div style='font-size: 10pt;font-weight: 600;'>Cancelar</div>",
		  customClass: {
		    cancelButton: 'cancelButton',
		    confirmButton: 'confirmButton',
		    actions: 'alert-buttons',
		    title: 'titulo-alert-update-nombre',
		    content: 'container-alert'
		  },
		  didOpen: () => {
		  	//Se deshabilita el botón "Actualizar"
		  	swal.getConfirmButton().disabled = true;
		  	//Se reinicia el objeto de arreglos
        	this.datosRegistro.reset();
        	//Se precargan en la tabla de asociados los actuales**********************************************
        	this.state.datos.map(datos => {
				datos.tutores.map(tutores => {
					db.collection(tutores.parent.id).doc(tutores.id).get().then(doc => {
					    if (doc.exists) {
					        this.asociarTutor(doc.data().id_usuario, doc.data().nombre, doc.data().apellidos);
					    }
					});
				});
			});
        	//************************************************************************************************
		    //Se añaden funciones onChange ----------------------------------------------------------------
		    document.getElementById("boton-busqueda-tutor").addEventListener('click', (e) => {
		    	this.buscarTutor(e);
		    });
		    document.getElementById("busqueda-tutor").addEventListener('search', (e) => {
		    	this.buscarTutor(e);
		    });
		    //---------------------------------------------------------------------------------------------
		  }
		}).then((result) => {
		  if (result.isConfirmed) {
		  	//*******************************************************************************************************************************
		  	//*******************************************Administrador confirmó la actualización*********************************************
		  	//*******************************************************************************************************************************
		  	//Alert "loading"----------------------
			const alert_cargando = swal.fire({
			  width: '300px',
			  allowOutsideClick: false,
			  showConfirmButton: false,
			  didOpen: () => {
			    swal.showLoading()
			  },
			  customClass: {
				   title: 'titulo-alert-loading',
			     actions: 'alert-loader'
				}
			})
			//-------------------------------------
			//Se actualizan los datos****************************************************************************
			db.collection("alumnos").doc(this.state.uid).update({
			    tutores: this.datosRegistro.tutores
			}).then(() => {
			    //Los datos se actualizaron correctamente en firestore
			    //*****************************************************************************************************************
	            //************************ SE GUARDAN LAS RELACIONES EN DOCUMENTOS DE PERSONAS ASOCIADAS **************************
	            //*****************************************************************************************************************
	            //TUTORES
	            this.datosRegistro.tutores.forEach((tutor) => {
	              db.collection("tutores").doc(tutor.id).update({
	                  alumnos: firebase.firestore.FieldValue.arrayUnion(db.collection('alumnos').doc(this.state.uid))
	              });
	            });
	            //RESPONSABLES (EN ALUMNO ACTUAL)
	            this.datosRegistro.tutores.forEach((responsable) => {
	                db.collection("alumnos").doc(this.state.uid).update({
	                    personasAutorizadas: firebase.firestore.FieldValue.arrayUnion(db.collection('tutores').doc(responsable.id)),
	                    contactosEmergencia: firebase.firestore.FieldValue.arrayUnion(db.collection('tutores').doc(responsable.id))
	                });
	            });
	            //TUTORES Y RESPONSABLES ELIMINADOS
	            this.datosRegistro.eliminados.forEach((eliminado) => {
	              db.collection("tutores").doc(eliminado.id).update({
	                  alumnos: firebase.firestore.FieldValue.arrayRemove(db.collection('alumnos').doc(this.state.uid))
	              });
	              db.collection("alumnos").doc(this.state.uid).update({
	                  personasAutorizadas: firebase.firestore.FieldValue.arrayRemove(db.collection('tutores').doc(eliminado.id)),
	                  contactosEmergencia: firebase.firestore.FieldValue.arrayRemove(db.collection('tutores').doc(eliminado.id))
	              });
	            });
	            //*****************************************************************************************************************
	            //*****************************************************************************************************************
	            //*****************************************************************************************************************
			    alert_cargando.close();
			    //Mensaje de éxito
				swal.fire({
				  title:"<p style='font-size:13pt;font-weight:600;color: #282828;'>Cambios guardados</p>",
				  icon: 'success',
				  iconColor: '#17a2b8',
				  width: '300px',
				  showConfirmButton: false,
				  timer: 2000
				}).then((result) => {
				  	//se ejecuta después de mensaje de éxito
				  	window.location.reload(false);
				});
			}).catch(function(error) {
			    //Error al actualizar datos en firestore
			    alert_cargando.close();
			    //Mensaje de error
		  		swal.fire({
				  title:"<p style='font-size:13pt;font-weight:600;color: #282828;'>Error al actualizar datos</p>",
				  icon: 'error',
				  width: '400px',
				  showConfirmButton: false,
				  timer: 2500
				})
			});
		  	//***************************************************************************************************
		  	//*******************************************************************************************************************************
		  	//*******************************************************************************************************************************
		  	//*******************************************************************************************************************************
		  }
		});
	}
	//--------------------------------------------------------------------------------------------------------
	//--------------------------------------------------------------------------------------------------------
	//--------------------------------------------------------------------------------------------------------
	//--------------------------------------------------------------------------------------------------------
	//------------------------------------Actualiza personas autorizadas--------------------------------------
	//--------------------------------------------------------------------------------------------------------
	//##################################################################################################################################
    //##################################################################################################################################
    //##################################################################################################################################
    //BÚSQUEDA DE RESPONSABLE (CONSULTA FIRESTORE)*********************************************************
    buscarAutorizada = (e) => {
      e.preventDefault();//Evita la recarga de la página
      var textoBusqueda = document.getElementById("busqueda-autorizada").value;
      if(textoBusqueda !== ""){
        //------------------------------------------------------------------------------------------------------------
        //------------------------------------------------------------------------------------------------------------
        //------------------------------------------------------------------------------------------------------------
        db.collection("responsables").where("correoElectronico", "==", textoBusqueda.trim()).get().then((querySnapshot) => {
            var contador = 0;
            document.getElementById("body-tabla-autorizadas-busqueda").innerHTML = "";
            querySnapshot.forEach((doc) => {
                contador++;
                //*****************************************************************************************
                document.getElementById("body-tabla-autorizadas-busqueda").insertAdjacentHTML("beforeend", 
                "<tr class='filaTabla filaTabla-hover'>"+
                  "<td>"+doc.data().nombre+"</td>"+
                  "<td>"+doc.data().apellidos+"</td>"+
                  "<td>"+
                    "<div class='toggle-botonTabla' id='"+doc.id+"busqueda-V"+"' title='Asociar' style='margin-right:15px'>"+
                      "<img class='botonTabla' src="+asociar+" width='17px' />"+
                    "</div>"+
                  "</td>"+
                "</tr>");
                //Evento de la fila (onclick en asociar)----------------------------------------
                document.getElementById(doc.id+"busqueda-V").addEventListener('click', (e) => {
                  this.asociarAutorizada(doc.id, doc.data().nombre, doc.data().apellidos);
                });
                //------------------------------------------------------------------------------
                //*****************************************************************************************
            });
            if(contador == 0){
              //No hubo resultados para correoElectronico
              //------------------------------------------------------------------------------------------------------------
              //------------------------------------------------------------------------------------------------------------
              //------------------------------------------------------------------------------------------------------------
              db.collection("responsables").where("telefono", "==", textoBusqueda.trim()).get().then((querySnapshot) => {
                  document.getElementById("body-tabla-autorizadas-busqueda").innerHTML = "";
                  querySnapshot.forEach((doc) => {
                      contador++;
                      //*****************************************************************************************
                      document.getElementById("body-tabla-autorizadas-busqueda").insertAdjacentHTML("beforeend", 
                      "<tr class='filaTabla filaTabla-hover'>"+
                        "<td>"+doc.data().nombre+"</td>"+
                        "<td>"+doc.data().apellidos+"</td>"+
                        "<td>"+
                          "<div class='toggle-botonTabla' id='"+doc.id+"busqueda-V"+"' title='Asociar' style='margin-right:15px'>"+
                            "<img class='botonTabla' src="+asociar+" width='17px' />"+
                          "</div>"+
                        "</td>"+
                      "</tr>");
                      //Evento de la fila (onclick en asociar)----------------------------------------
                      document.getElementById(doc.id+"busqueda-V").addEventListener('click', (e) => {
                        this.asociarAutorizada(doc.id, doc.data().nombre, doc.data().apellidos);
                      });
                      //------------------------------------------------------------------------------
                      //*****************************************************************************************
                  });
                  if(contador == 0){
                    //No hubo resultados para telefono
                    //------------------------------------------------------------------------------------------------------------
                    //------------------------------------------------------------------------------------------------------------
                    //------------------------------------------------------------------------------------------------------------
                    db.collection("responsables").where("apellidos", "==", textoBusqueda.trim().toUpperCase()).get().then((querySnapshot) => {
                        document.getElementById("body-tabla-autorizadas-busqueda").innerHTML = "";
                        querySnapshot.forEach((doc) => {
                            contador++;
                            //*****************************************************************************************
                            document.getElementById("body-tabla-autorizadas-busqueda").insertAdjacentHTML("beforeend", 
                            "<tr class='filaTabla filaTabla-hover'>"+
                              "<td>"+doc.data().nombre+"</td>"+
                              "<td>"+doc.data().apellidos+"</td>"+
                              "<td>"+
                                "<div class='toggle-botonTabla' id='"+doc.id+"busqueda-V"+"' title='Asociar' style='margin-right:15px'>"+
                                  "<img class='botonTabla' src="+asociar+" width='17px' />"+
                                "</div>"+
                              "</td>"+
                            "</tr>");
                            //Evento de la fila (onclick en asociar)----------------------------------------
                            document.getElementById(doc.id+"busqueda-V").addEventListener('click', (e) => {
                              this.asociarAutorizada(doc.id, doc.data().nombre, doc.data().apellidos);
                            });
                            //------------------------------------------------------------------------------
                            //*****************************************************************************************
                        });
                        if(contador == 0){
                          //No hubo resultados para apellidos
                          //------------------------------------------------------------------------------------------------------------
                          //------------------------------------------------------------------------------------------------------------
                          //------------------------------------------------------------------------------------------------------------
                          db.collection("responsables").where("nombre", "==", textoBusqueda.trim().toUpperCase()).get().then((querySnapshot) => {
                              document.getElementById("body-tabla-autorizadas-busqueda").innerHTML = "";
                              querySnapshot.forEach((doc) => {
                                  contador++;
                                  //*****************************************************************************************
                                  document.getElementById("body-tabla-autorizadas-busqueda").insertAdjacentHTML("beforeend", 
                                  "<tr class='filaTabla filaTabla-hover'>"+
                                    "<td>"+doc.data().nombre+"</td>"+
                                    "<td>"+doc.data().apellidos+"</td>"+
                                    "<td>"+
                                      "<div class='toggle-botonTabla' id='"+doc.id+"busqueda-V"+"' title='Asociar' style='margin-right:15px'>"+
                                        "<img class='botonTabla' src="+asociar+" width='17px' />"+
                                      "</div>"+
                                    "</td>"+
                                  "</tr>");
                                  //Evento de la fila (onclick en asociar)----------------------------------------
                                  document.getElementById(doc.id+"busqueda-V").addEventListener('click', (e) => {
                                    this.asociarAutorizada(doc.id, doc.data().nombre, doc.data().apellidos);
                                  });
                                  //------------------------------------------------------------------------------
                                  //*****************************************************************************************
                              });
                              if(contador == 0){
                                //No hubo resultados para nombre (NINGÚN RESULTADO PARA LA BÚSQUEDA)
                                document.getElementById("body-tabla-autorizadas-busqueda").innerHTML = 
                                "<tr>"+
                                  "<td colspan='3'>"+
                                    "<center>"+
                                      "<p id='avisoSinResultadosBusquedaAutorizada' style='color:#c8c8c8;display:inline;'>No hay resultados a mostrar</p>"+
                                    "</center>"+
                                  "</td>"+
                                "</tr>";
                              }
                          }).catch(function(error) {
                              //Error al realizar consulta
                          });
                          //------------------------------------------------------------------------------------------------------------
                          //------------------------------------------------------------------------------------------------------------
                          //------------------------------------------------------------------------------------------------------------
                        }
                    }).catch(function(error) {
                        //Error al realizar consulta
                    });
                    //------------------------------------------------------------------------------------------------------------
                    //------------------------------------------------------------------------------------------------------------
                    //------------------------------------------------------------------------------------------------------------
                  }
              }).catch(function(error) {
                  //Error al realizar consulta
              });
              //------------------------------------------------------------------------------------------------------------
              //------------------------------------------------------------------------------------------------------------
              //------------------------------------------------------------------------------------------------------------
            }
        }).catch(function(error) {
            //Error al realizar consulta
        });
        //------------------------------------------------------------------------------------------------------------
        //------------------------------------------------------------------------------------------------------------
        //------------------------------------------------------------------------------------------------------------
      }
    }
    //*****************************************************************************************************
    //********************************************** ASOCIAR  ***************************************************
    asociarAutorizada = (uid, nombre, apellidos) => {
      //Sí la tabla está vacía, se elimina el mensaje de "Ningún asociado"---------
      if(this.datosRegistro.personasAutorizadas.length == 0){
      	swal.getConfirmButton().disabled = false;//Se habilita botón "Actualizar"
        document.getElementById("body-tabla-autorizadas-asociados").innerHTML = "";
      }
      //---------------------------------------------------------------------------

      //Se guarda en array la referencia----------------------------------------------------------------------------------
      //Sí ya existe el valor que se intenta guardar, se omite
      var aux = db.collection('responsables').doc(uid);
      if(!(this.datosRegistro.personasAutorizadas.find(element => element.id == aux.id) !== undefined)){
        this.datosRegistro.personasAutorizadas.push(aux);//Guardado en el array "personasAutorizadas"
        //Mostrar en Front (Tabla de asociación)----------------------------------------------------------------------------
        //*****************************************************************************************
        document.getElementById("body-tabla-autorizadas-asociados").insertAdjacentHTML("beforeend", 
        "<tr class='filaTabla filaTabla-hover' id='"+uid+"asociado-fila-V'>"+
          "<td>"+nombre+"</td>"+
          "<td>"+apellidos+"</td>"+
          "<td>"+
            "<div class='toggle-botonTabla' id='"+uid+"asociado-V"+"' title='Desasociar' style='margin-right:15px'>"+
              "<img class='botonTabla' src="+eliminar+" width='17px' />"+
            "</div>"+
          "</td>"+
        "</tr>");
        //Evento de la fila (onclick en desasociar)----------------------------------------
        document.getElementById(uid+"asociado-V").addEventListener('click', (e) => {
          //--------------------------------------------------------------------------------
          //---------------------------------- Desasociar ----------------------------------
          //--------------------------------------------------------------------------------
          //Se elimina de la tabla (Front)
          document.getElementById("body-tabla-autorizadas-asociados").removeChild(document.getElementById(uid+"asociado-fila-V"));
          //Se agrega al arreglo de eliminados
          if(!(this.datosRegistro.eliminados.find(element => element.id == aux.id) !== undefined)){//Sí ya existe el valor que se intenta guardar, se omite
          	this.datosRegistro.eliminados.push(aux);//Guardado en el array "eliminados"
          }
          //Se elimina del array "personasAutorizadas"
          this.datosRegistro.personasAutorizadas.splice(this.datosRegistro.personasAutorizadas.indexOf(this.datosRegistro.personasAutorizadas.find(element => element.id == aux.id)), 1);
          //--------------------------------------------------------------------------------
          //--------------------------------------------------------------------------------
          //--------------------------------------------------------------------------------
        });
        //------------------------------------------------------------------------------
        //*****************************************************************************************
        //-----------------------------------------------------------------------------------------------------------------
      }
      //------------------------------------------------------------------------------------------------------------------
    }
    //***********************************************************************************************************
    //##################################################################################################################################
    //##################################################################################################################################
    //##################################################################################################################################
	actualizarPA = () => {
		swal.fire({
		  title:"<p style='font-size: 14pt;font-weight:600;color: #282828;'>Edición de personas autorizadas asociadas</p>",
		  width: 1000,
		  html: "<p style='font-size: 10pt;color: #0000008A;'>Alumno</p>"+
		  		"<p style='font-size: 11pt;color: #646464;'>"+this.state.datos[0].nombre+" "+this.state.datos[0].apellidos+"</p>"+
		  		"<form style='margin:0;padding:0;margin-top:20px;'><div class='row'>"+
                  "<div class='col-sm-12 col-md-12 col-lg-6'>"+
                    "<div style='border-radius:8px;background-color:#e6e6e6;width:437px;margin:auto;margin-bottom:30px;'>"+
                      "<div class='wrap'>"+
                         "<div class='search'>"+
                            "<input type='search' class='searchTerm' id='busqueda-autorizada' placeholder='Buscar por correo electrónico, nombre, apellidos o teléfono' style='font-size:10pt;'/>"+
                            "<button class='searchButton' id='boton-busqueda-autorizada'>"+
                              "<img src='"+lupa+"' style='margin-top:-6px;margin-left:-2px;opacity:0.6;' width='18px' />"+
                            "</button>"+
                         "</div>"+
                      "</div>"+
                      "<div style='border-radius:0px 0px 8px 8px;overflow:hidden;box-shadow:0px 1px 2px 1px #BDC3C7;width: 433px;margin:auto;'>"+
                        "<div id='tabla-autorizadas-busqueda' style='width: 433px;height: 200px; overflow-y: scroll;overflow-x: scroll;background-color: #fff;margin: auto;'>"+
                          "<table class='table'>"+
                            "<thead>"+
                               "<tr style='background-color:#fafafa;'>"+
                                  "<th>Nombre</th>"+
                                  "<th>Apellidos</th>"+
                                  "<th width='50px'></th>"+
                               "</tr>"+
                            "</thead>"+
                            "<tbody id='body-tabla-autorizadas-busqueda'>"+
                              "<tr>"+
                                "<td colspan='3' style='text-align:center;'>"+
                                  "<center>"+
                                    "<p id='avisoSinResultadosBusquedaAutorizada' style='color:#c8c8c8;display:inline;'>No hay resultados a mostrar</p>"+
                                  "</center>"+
                                "</td>"+
                              "</tr>"+
                            "</tbody>"+
                          "</table>"+
                        "</div>"+
                      "</div>"+
                    "</div>"+
                  "</div>"+
                  "<div class='col-sm-12 col-md-12 col-lg-6'>"+
                    "<div style='margin:auto;background-color:#e6e6e6;border-radius:9px;padding:1px;width: 404px;'>"+
                        "<p style='font-size:11pt;font-weight:500;color:#868686;margin-bottom:11px;margin-left:10px;margin-top:8px;float:left;'>Personas autorizadas asociadas con alumno</p>"+
                        "<a href='/administrador/alta/responsable' target='_blank'>"+
                          "<img class='botonImagen' title='Registrar responsable' style='float:right;margin-right:12px;margin-top:11px;' src='"+nuevo+"' width='17px' />"+
                        "</a>"+
                        "<div style='border-radius:0px 0px 8px 8px;overflow:hidden;box-shadow:0px 1px 2px 1px #BDC3C7;width: 400px;margin:auto;'>"+
                          "<div id='tabla-autorizadas-asociados' style='width: 400px;height: 200px; overflow-y: scroll;overflow-x: scroll;background-color: #fff;margin: auto;'>"+
                            "<table class='table'>"+
                              "<thead>"+
                                 "<tr style='background-color:#fafafa;'>"+
                                    "<th>Nombre</th>"+
                                    "<th>Apellidos</th>"+
                                    "<th width='50px'></th>"+
                                 "</tr>"+
                              "</thead>"+
                              "<tbody id='body-tabla-autorizadas-asociados'>"+
                                "<tr>"+
                                  "<td colspan='3' style='text-align:center;'>"+
                                    "<center>"+
                                      "<p id='avisoSinAsociacionesAutorizadas' style='color:#c8c8c8;display:inline;'>Sin personas autorizadas asociadas</p>"+
                                    "</center>"+
                                  "</td>"+
                                "</tr>"+
                              "</tbody>"+
                            "</table>"+
                          "</div>"+
                        "</div>"+
                    "</div>"+
                  "</div>"+
                "</div></form>",
		  footer: "<img width='20px' height='20px' style='margin-right:5px' src='"+advertencia+
		  		"'/><b style='font-size: 14;color: #D45454;'>Esta acción no se puede deshacer.</b>",
		  showCancelButton: true,
		  confirmButtonColor: "#17a2b8",
		  confirmButtonText: "<div style='font-size: 10pt;font-weight: 500;'>Actualizar</div>",
		  cancelButtonText: "<div style='font-size: 10pt;font-weight: 600;'>Cancelar</div>",
		  customClass: {
		    cancelButton: 'cancelButton',
		    confirmButton: 'confirmButton',
		    actions: 'alert-buttons',
		    title: 'titulo-alert-update-nombre',
		    content: 'container-alert'
		  },
		  didOpen: () => {
		  	//Se deshabilita el botón "Actualizar"
		  	swal.getConfirmButton().disabled = true;
		  	//Se reinicia el objeto de arreglos
        	this.datosRegistro.reset();
        	//Se precargan en la tabla de asociados los actuales**********************************************
        	this.state.datos.map(datos => {
				datos.personasAutorizadas.map(personas => {
					//Sí la persona autorizada pertenece a la clase de "responsables"
					if(personas.path.split("/")[0] == "responsables") {
						db.collection(personas.parent.id).doc(personas.id).get().then(doc => {
						    if (doc.exists) {
						        this.asociarAutorizada(doc.data().id_usuario, doc.data().nombre, doc.data().apellidos);
						    }
						});
					//Sí la persona autorizada es un "tutor"
					} else {
						db.collection(personas.parent.id).doc(personas.id).get().then(doc => {
						    if (doc.exists) {
						    	//Sí la tabla está vacía, se elimina el mensaje de "Ningún asociado"---------
							    if(this.datosRegistro.personasAutorizadas.length == 0){
							    	swal.getConfirmButton().disabled = false;//Se habilita botón "Actualizar"
							      	document.getElementById("body-tabla-autorizadas-asociados").innerHTML = "";
							    }
							    //---------------------------------------------------------------------------
						    	this.datosRegistro.personasAutorizadas.push(personas);//Guardado en el array "personasAutorizadas"
						        //Se muestra en el front (sin opción a eliminar)
								document.getElementById("body-tabla-autorizadas-asociados").insertAdjacentHTML("beforeend", 
						        "<tr class='filaTabla filaTabla-hover' id='"+doc.data().id_usuario+"asociado-fila-V'>"+
						          "<td>"+doc.data().nombre+"</td>"+
						          "<td>"+doc.data().apellidos+"</td>"+
						          "<td>(tutor)</td>"+
						        "</tr>");
						    }
						});
					}
				});
			});
        	//************************************************************************************************
		    //Se añaden funciones onChange ----------------------------------------------------------------
		    document.getElementById("boton-busqueda-autorizada").addEventListener('click', (e) => {
		    	this.buscarAutorizada(e);
		    });
		    document.getElementById("busqueda-autorizada").addEventListener('search', (e) => {
		    	this.buscarAutorizada(e);
		    });
		    //---------------------------------------------------------------------------------------------
		  }
		}).then((result) => {
		  if (result.isConfirmed) {
		  	//*******************************************************************************************************************************
		  	//*******************************************Administrador confirmó la actualización*********************************************
		  	//*******************************************************************************************************************************
		  	//Alert "loading"----------------------
			const alert_cargando = swal.fire({
			  width: '300px',
			  allowOutsideClick: false,
			  showConfirmButton: false,
			  didOpen: () => {
			    swal.showLoading()
			  },
			  customClass: {
				   title: 'titulo-alert-loading',
			     actions: 'alert-loader'
				}
			})
			//-------------------------------------
			//Se actualizan los datos****************************************************************************
			db.collection("alumnos").doc(this.state.uid).update({
			    personasAutorizadas: this.datosRegistro.personasAutorizadas
			}).then(() => {
			    //Los datos se actualizaron correctamente en firestore
			    //*****************************************************************************************************************
	            //************************ SE GUARDAN LAS RELACIONES EN DOCUMENTOS DE PERSONAS ASOCIADAS **************************
	            //*****************************************************************************************************************
	            //RESPONSABLES
	            this.datosRegistro.personasAutorizadas.forEach((persona) => {
	              db.collection("responsables").doc(persona.id).update({
	                  alumnos: firebase.firestore.FieldValue.arrayUnion(db.collection('alumnos').doc(this.state.uid))
	              });
	            });
	            //ELIMINADOS
	            this.datosRegistro.eliminados.forEach((eliminado) => {
		            //-------------------------------------------------------------------------------------------------------
		            //Sí la persona eliminada también es contacto de emergencia, NO se elimina la relación responsable-alumno
				    if(!(this.state.datos[0].contactosEmergencia.find(element => element.id == eliminado.id) !== undefined)){
				    	db.collection("responsables").doc(eliminado.id).update({
			            	alumnos: firebase.firestore.FieldValue.arrayRemove(db.collection('alumnos').doc(this.state.uid))
			            });
				    }
		            //-------------------------------------------------------------------------------------------------------
	            });
	            //*****************************************************************************************************************
	            //*****************************************************************************************************************
	            //*****************************************************************************************************************
			    alert_cargando.close();
			    //Mensaje de éxito
				swal.fire({
				  title:"<p style='font-size:13pt;font-weight:600;color: #282828;'>Cambios guardados</p>",
				  icon: 'success',
				  iconColor: '#17a2b8',
				  width: '300px',
				  showConfirmButton: false,
				  timer: 2000
				}).then((result) => {
				  	//se ejecuta después de mensaje de éxito
				  	window.location.reload(false);
				});
			}).catch(function(error) {
			    //Error al actualizar datos en firestore
			    alert_cargando.close();
			    //Mensaje de error
		  		swal.fire({
				  title:"<p style='font-size:13pt;font-weight:600;color: #282828;'>Error al actualizar datos</p>",
				  icon: 'error',
				  width: '400px',
				  showConfirmButton: false,
				  timer: 2500
				})
			});
		  	//***************************************************************************************************
		  	//*******************************************************************************************************************************
		  	//*******************************************************************************************************************************
		  	//*******************************************************************************************************************************
		  }
		});
	}
	//--------------------------------------------------------------------------------------------------------
	//--------------------------------------------------------------------------------------------------------
	//--------------------------------------------------------------------------------------------------------
	//--------------------------------------------------------------------------------------------------------
	//--------------------------------- Actualiza contactos de emergencia ------------------------------------
	//--------------------------------------------------------------------------------------------------------
	//##################################################################################################################################
    //##################################################################################################################################
    //##################################################################################################################################
    //BÚSQUEDA DE RESPONSABLE (CONSULTA FIRESTORE)*********************************************************
    buscarContacto = (e) => {
      e.preventDefault();//Evita la recarga de la página
      var textoBusqueda = document.getElementById("busqueda-contacto").value;
      if(textoBusqueda !== ""){
        //------------------------------------------------------------------------------------------------------------
        //------------------------------------------------------------------------------------------------------------
        //------------------------------------------------------------------------------------------------------------
        db.collection("responsables").where("correoElectronico", "==", textoBusqueda.trim()).get().then((querySnapshot) => {
            var contador = 0;
            document.getElementById("body-tabla-contactos-busqueda").innerHTML = "";
            querySnapshot.forEach((doc) => {
                contador++;
                //*****************************************************************************************
                document.getElementById("body-tabla-contactos-busqueda").insertAdjacentHTML("beforeend", 
                "<tr class='filaTabla filaTabla-hover'>"+
                  "<td>"+doc.data().nombre+"</td>"+
                  "<td>"+doc.data().apellidos+"</td>"+
                  "<td>"+
                    "<div class='toggle-botonTabla' id='"+doc.id+"busqueda-IV"+"' title='Asociar' style='margin-right:15px'>"+
                      "<img class='botonTabla' src="+asociar+" width='17px' />"+
                    "</div>"+
                  "</td>"+
                "</tr>");
                //Evento de la fila (onclick en asociar)----------------------------------------
                document.getElementById(doc.id+"busqueda-IV").addEventListener('click', (e) => {
                  this.asociarContacto(doc.id, doc.data().nombre, doc.data().apellidos);
                });
                //------------------------------------------------------------------------------
                //*****************************************************************************************
            });
            if(contador == 0){
              //No hubo resultados para correoElectronico
              //------------------------------------------------------------------------------------------------------------
              //------------------------------------------------------------------------------------------------------------
              //------------------------------------------------------------------------------------------------------------
              db.collection("responsables").where("telefono", "==", textoBusqueda.trim()).get().then((querySnapshot) => {
                  document.getElementById("body-tabla-contactos-busqueda").innerHTML = "";
                  querySnapshot.forEach((doc) => {
                      contador++;
                      //*****************************************************************************************
                      document.getElementById("body-tabla-contactos-busqueda").insertAdjacentHTML("beforeend", 
                      "<tr class='filaTabla filaTabla-hover'>"+
                        "<td>"+doc.data().nombre+"</td>"+
                        "<td>"+doc.data().apellidos+"</td>"+
                        "<td>"+
                          "<div class='toggle-botonTabla' id='"+doc.id+"busqueda-IV"+"' title='Asociar' style='margin-right:15px'>"+
                            "<img class='botonTabla' src="+asociar+" width='17px' />"+
                          "</div>"+
                        "</td>"+
                      "</tr>");
                      //Evento de la fila (onclick en asociar)----------------------------------------
                      document.getElementById(doc.id+"busqueda-IV").addEventListener('click', (e) => {
                        this.asociarContacto(doc.id, doc.data().nombre, doc.data().apellidos);
                      });
                      //------------------------------------------------------------------------------
                      //*****************************************************************************************
                  });
                  if(contador == 0){
                    //No hubo resultados para telefono
                    //------------------------------------------------------------------------------------------------------------
                    //------------------------------------------------------------------------------------------------------------
                    //------------------------------------------------------------------------------------------------------------
                    db.collection("responsables").where("apellidos", "==", textoBusqueda.trim().toUpperCase()).get().then((querySnapshot) => {
                        document.getElementById("body-tabla-contactos-busqueda").innerHTML = "";
                        querySnapshot.forEach((doc) => {
                            contador++;
                            //*****************************************************************************************
                            document.getElementById("body-tabla-contactos-busqueda").insertAdjacentHTML("beforeend", 
                            "<tr class='filaTabla filaTabla-hover'>"+
                              "<td>"+doc.data().nombre+"</td>"+
                              "<td>"+doc.data().apellidos+"</td>"+
                              "<td>"+
                                "<div class='toggle-botonTabla' id='"+doc.id+"busqueda-IV"+"' title='Asociar' style='margin-right:15px'>"+
                                  "<img class='botonTabla' src="+asociar+" width='17px' />"+
                                "</div>"+
                              "</td>"+
                            "</tr>");
                            //Evento de la fila (onclick en asociar)----------------------------------------
                            document.getElementById(doc.id+"busqueda-IV").addEventListener('click', (e) => {
                              this.asociarContacto(doc.id, doc.data().nombre, doc.data().apellidos);
                            });
                            //------------------------------------------------------------------------------
                            //*****************************************************************************************
                        });
                        if(contador == 0){
                          //No hubo resultados para apellidos
                          //------------------------------------------------------------------------------------------------------------
                          //------------------------------------------------------------------------------------------------------------
                          //------------------------------------------------------------------------------------------------------------
                          db.collection("responsables").where("nombre", "==", textoBusqueda.trim().toUpperCase()).get().then((querySnapshot) => {
                              document.getElementById("body-tabla-contactos-busqueda").innerHTML = "";
                              querySnapshot.forEach((doc) => {
                                  contador++;
                                  //*****************************************************************************************
                                  document.getElementById("body-tabla-contactos-busqueda").insertAdjacentHTML("beforeend", 
                                  "<tr class='filaTabla filaTabla-hover'>"+
                                    "<td>"+doc.data().nombre+"</td>"+
                                    "<td>"+doc.data().apellidos+"</td>"+
                                    "<td>"+
                                      "<div class='toggle-botonTabla' id='"+doc.id+"busqueda-IV"+"' title='Asociar' style='margin-right:15px'>"+
                                        "<img class='botonTabla' src="+asociar+" width='17px' />"+
                                      "</div>"+
                                    "</td>"+
                                  "</tr>");
                                  //Evento de la fila (onclick en asociar)----------------------------------------
                                  document.getElementById(doc.id+"busqueda-IV").addEventListener('click', (e) => {
                                    this.asociarContacto(doc.id, doc.data().nombre, doc.data().apellidos);
                                  });
                                  //------------------------------------------------------------------------------
                                  //*****************************************************************************************
                              });
                              if(contador == 0){
                                //No hubo resultados para nombre (NINGÚN RESULTADO PARA LA BÚSQUEDA)
                                document.getElementById("body-tabla-contactos-busqueda").innerHTML = 
                                "<tr>"+
                                  "<td colspan='3'>"+
                                    "<center>"+
                                      "<p id='avisoSinResultadosBusquedaContacto' style='color:#c8c8c8;display:inline;'>No hay resultados a mostrar</p>"+
                                    "</center>"+
                                  "</td>"+
                                "</tr>";
                              }
                          }).catch(function(error) {
                              //Error al realizar consulta
                          });
                          //------------------------------------------------------------------------------------------------------------
                          //------------------------------------------------------------------------------------------------------------
                          //------------------------------------------------------------------------------------------------------------
                        }
                    }).catch(function(error) {
                        //Error al realizar consulta
                    });
                    //------------------------------------------------------------------------------------------------------------
                    //------------------------------------------------------------------------------------------------------------
                    //------------------------------------------------------------------------------------------------------------
                  }
              }).catch(function(error) {
                  //Error al realizar consulta
              });
              //------------------------------------------------------------------------------------------------------------
              //------------------------------------------------------------------------------------------------------------
              //------------------------------------------------------------------------------------------------------------
            }
        }).catch(function(error) {
            //Error al realizar consulta
        });
        //------------------------------------------------------------------------------------------------------------
        //------------------------------------------------------------------------------------------------------------
        //------------------------------------------------------------------------------------------------------------
      }
    }
    //*****************************************************************************************************
    //********************************************** ASOCIAR  ***************************************************
    asociarContacto = (uid, nombre, apellidos) => {
      //Sí la tabla está vacía, se elimina el mensaje de "Ningún asociado"---------
      if(this.datosRegistro.contactosEmergencia.length == 0){
      	swal.getConfirmButton().disabled = false;//Se habilita botón "Actualizar"
        document.getElementById("body-tabla-contactos-asociados").innerHTML = "";
      }
      //---------------------------------------------------------------------------

      //Se guarda en array la referencia----------------------------------------------------------------------------------
      //Sí ya existe el valor que se intenta guardar, se omite
      var aux = db.collection('responsables').doc(uid);
      if(!(this.datosRegistro.contactosEmergencia.find(element => element.id == aux.id) !== undefined)){
        this.datosRegistro.contactosEmergencia.push(aux);//Guardado en el array "contactosEmergencia"
        //Mostrar en Front (Tabla de asociación)----------------------------------------------------------------------------
        //*****************************************************************************************
        document.getElementById("body-tabla-contactos-asociados").insertAdjacentHTML("beforeend", 
        "<tr class='filaTabla filaTabla-hover' id='"+uid+"asociado-fila-IV'>"+
          "<td>"+nombre+"</td>"+
          "<td>"+apellidos+"</td>"+
          "<td>"+
            "<div class='toggle-botonTabla' id='"+uid+"asociado-IV"+"' title='Desasociar' style='margin-right:15px'>"+
              "<img class='botonTabla' src="+eliminar+" width='17px' />"+
            "</div>"+
          "</td>"+
        "</tr>");
        //Evento de la fila (onclick en desasociar)----------------------------------------
        document.getElementById(uid+"asociado-IV").addEventListener('click', (e) => {
          //--------------------------------------------------------------------------------
          //---------------------------------- Desasociar ----------------------------------
          //--------------------------------------------------------------------------------
          //Se elimina de la tabla (Front)
          document.getElementById("body-tabla-contactos-asociados").removeChild(document.getElementById(uid+"asociado-fila-IV"));
          //Se agrega al arreglo de eliminados
          if(!(this.datosRegistro.eliminados.find(element => element.id == aux.id) !== undefined)){//Sí ya existe el valor que se intenta guardar, se omite
          	this.datosRegistro.eliminados.push(aux);//Guardado en el array "eliminados"
          }
          //Se elimina del array "contactosEmergencia"
          this.datosRegistro.contactosEmergencia.splice(this.datosRegistro.contactosEmergencia.indexOf(this.datosRegistro.contactosEmergencia.find(element => element.id == aux.id)), 1);
          //--------------------------------------------------------------------------------
          //--------------------------------------------------------------------------------
          //--------------------------------------------------------------------------------
        });
        //------------------------------------------------------------------------------
        //*****************************************************************************************
        //-----------------------------------------------------------------------------------------------------------------
      }
      //------------------------------------------------------------------------------------------------------------------
    }
    //***********************************************************************************************************
    //##################################################################################################################################
    //##################################################################################################################################
    //##################################################################################################################################
	actualizarCE = () => {
		swal.fire({
		  title:"<p style='font-size: 14pt;font-weight:600;color: #282828;'>Edición de contactos de emergencia asociados</p>",
		  width: 1000,
		  html: "<p style='font-size: 10pt;color: #0000008A;'>Alumno</p>"+
		  		"<p style='font-size: 11pt;color: #646464;'>"+this.state.datos[0].nombre+" "+this.state.datos[0].apellidos+"</p>"+
		  		"<form style='margin:0;padding:0;margin-top:20px;'><div class='row'>"+
                  "<div class='col-sm-12 col-md-12 col-lg-6'>"+
                    "<div style='border-radius:8px;background-color:#e6e6e6;width:437px;margin:auto;margin-bottom:30px;'>"+
                      "<div class='wrap'>"+
                         "<div class='search'>"+
                            "<input type='search' class='searchTerm' id='busqueda-contacto' placeholder='Buscar por correo electrónico, nombre, apellidos o teléfono' style='font-size:10pt;'/>"+
                            "<button class='searchButton' id='boton-busqueda-contacto'>"+
                              "<img src='"+lupa+"' style='margin-top:-6px;margin-left:-2px;opacity:0.6;' width='18px' />"+
                            "</button>"+
                         "</div>"+
                      "</div>"+
                      "<div style='border-radius:0px 0px 8px 8px;overflow:hidden;box-shadow:0px 1px 2px 1px #BDC3C7;width: 433px;margin:auto;'>"+
                        "<div id='tabla-contactos-busqueda' style='width: 433px;height: 200px; overflow-y: scroll;overflow-x: scroll;background-color: #fff;margin: auto;'>"+
                          "<table class='table'>"+
                            "<thead>"+
                               "<tr style='background-color:#fafafa;'>"+
                                  "<th>Nombre</th>"+
                                  "<th>Apellidos</th>"+
                                  "<th width='50px'></th>"+
                               "</tr>"+
                            "</thead>"+
                            "<tbody id='body-tabla-contactos-busqueda'>"+
                              "<tr>"+
                                "<td colspan='3' style='text-align:center;'>"+
                                  "<center>"+
                                    "<p id='avisoSinResultadosBusquedaContacto' style='color:#c8c8c8;display:inline;'>No hay resultados a mostrar</p>"+
                                  "</center>"+
                                "</td>"+
                              "</tr>"+
                            "</tbody>"+
                          "</table>"+
                        "</div>"+
                      "</div>"+
                    "</div>"+
                  "</div>"+
                  "<div class='col-sm-12 col-md-12 col-lg-6'>"+
                    "<div style='margin:auto;background-color:#e6e6e6;border-radius:9px;padding:1px;width: 404px;'>"+
                        "<p style='font-size:11pt;font-weight:500;color:#868686;margin-bottom:11px;margin-left:10px;margin-top:8px;float:left;'>Contactos de emergencia asociados con alumno</p>"+
                        "<a href='/administrador/alta/responsable' target='_blank'>"+
                          "<img class='botonImagen' title='Registrar responsable' style='float:right;margin-right:12px;margin-top:11px;' src='"+nuevo+"' width='17px' />"+
                        "</a>"+
                        "<div style='border-radius:0px 0px 8px 8px;overflow:hidden;box-shadow:0px 1px 2px 1px #BDC3C7;width: 400px;margin:auto;'>"+
                          "<div id='tabla-contactos-asociados' style='width: 400px;height: 200px; overflow-y: scroll;overflow-x: scroll;background-color: #fff;margin: auto;'>"+
                            "<table class='table'>"+
                              "<thead>"+
                                 "<tr style='background-color:#fafafa;'>"+
                                    "<th>Nombre</th>"+
                                    "<th>Apellidos</th>"+
                                    "<th width='50px'></th>"+
                                 "</tr>"+
                              "</thead>"+
                              "<tbody id='body-tabla-contactos-asociados'>"+
                                "<tr>"+
                                  "<td colspan='3' style='text-align:center;'>"+
                                    "<center>"+
                                      "<p id='avisoSinAsociacionesContactos' style='color:#c8c8c8;display:inline;'>Ningún contacto asociado</p>"+
                                    "</center>"+
                                  "</td>"+
                                "</tr>"+
                              "</tbody>"+
                            "</table>"+
                          "</div>"+
                        "</div>"+
                    "</div>"+
                  "</div>"+
                "</div></form>",
		  footer: "<img width='20px' height='20px' style='margin-right:5px' src='"+advertencia+
		  		"'/><b style='font-size: 14;color: #D45454;'>Esta acción no se puede deshacer.</b>",
		  showCancelButton: true,
		  confirmButtonColor: "#17a2b8",
		  confirmButtonText: "<div style='font-size: 10pt;font-weight: 500;'>Actualizar</div>",
		  cancelButtonText: "<div style='font-size: 10pt;font-weight: 600;'>Cancelar</div>",
		  customClass: {
		    cancelButton: 'cancelButton',
		    confirmButton: 'confirmButton',
		    actions: 'alert-buttons',
		    title: 'titulo-alert-update-nombre',
		    content: 'container-alert'
		  },
		  didOpen: () => {
		  	//Se deshabilita el botón "Actualizar"
		  	swal.getConfirmButton().disabled = true;
		  	//Se reinicia el objeto de arreglos
        	this.datosRegistro.reset();
        	//Se precargan en la tabla de asociados los actuales**********************************************
        	this.state.datos.map(datos => {
				datos.contactosEmergencia.map(personas => {
					//Sí el contacto de emergencia pertenece a la clase de "responsables"
					if(personas.path.split("/")[0] == "responsables") {
						db.collection(personas.parent.id).doc(personas.id).get().then(doc => {
						    if (doc.exists) {
						        this.asociarContacto(doc.data().id_usuario, doc.data().nombre, doc.data().apellidos);
						    }
						});
					//Sí la persona autorizada es un "tutor"
					} else {
						db.collection(personas.parent.id).doc(personas.id).get().then(doc => {
						    if (doc.exists) {
						    	//Sí la tabla está vacía, se elimina el mensaje de "Ningún asociado"---------
							    if(this.datosRegistro.contactosEmergencia.length == 0){
							    	swal.getConfirmButton().disabled = false;//Se habilita botón "Actualizar"
							      	document.getElementById("body-tabla-contactos-asociados").innerHTML = "";
							    }
							    //---------------------------------------------------------------------------
						    	this.datosRegistro.contactosEmergencia.push(personas);//Guardado en el array "contactosEmergencia"
						        //Se muestra en el front (sin opción a eliminar)
								document.getElementById("body-tabla-contactos-asociados").insertAdjacentHTML("beforeend", 
						        "<tr class='filaTabla filaTabla-hover' id='"+doc.data().id_usuario+"asociado-fila-IV'>"+
						          "<td>"+doc.data().nombre+"</td>"+
						          "<td>"+doc.data().apellidos+"</td>"+
						          "<td>(tutor)</td>"+
						        "</tr>");
						    }
						});
					}
				});
			});
        	//************************************************************************************************
		    //Se añaden funciones onChange ----------------------------------------------------------------
		    document.getElementById("boton-busqueda-contacto").addEventListener('click', (e) => {
		    	this.buscarContacto(e);
		    });
		    document.getElementById("busqueda-contacto").addEventListener('search', (e) => {
		    	this.buscarContacto(e);
		    });
		    //---------------------------------------------------------------------------------------------
		  }
		}).then((result) => {
		  if (result.isConfirmed) {
		  	//*******************************************************************************************************************************
		  	//*******************************************Administrador confirmó la actualización*********************************************
		  	//*******************************************************************************************************************************
		  	//Alert "loading"----------------------
			const alert_cargando = swal.fire({
			  width: '300px',
			  allowOutsideClick: false,
			  showConfirmButton: false,
			  didOpen: () => {
			    swal.showLoading()
			  },
			  customClass: {
				   title: 'titulo-alert-loading',
			     actions: 'alert-loader'
				}
			})
			//-------------------------------------
			//Se actualizan los datos****************************************************************************
			db.collection("alumnos").doc(this.state.uid).update({
			    contactosEmergencia: this.datosRegistro.contactosEmergencia
			}).then(() => {
			    //Los datos se actualizaron correctamente en firestore
			    //*****************************************************************************************************************
	            //************************ SE GUARDAN LAS RELACIONES EN DOCUMENTOS DE PERSONAS ASOCIADAS **************************
	            //*****************************************************************************************************************
	            //RESPONSABLES
	            this.datosRegistro.contactosEmergencia.forEach((persona) => {
	              db.collection("responsables").doc(persona.id).update({
	                  alumnos: firebase.firestore.FieldValue.arrayUnion(db.collection('alumnos').doc(this.state.uid))
	              });
	            });
	            //ELIMINADOS
	            this.datosRegistro.eliminados.forEach((eliminado) => {
		            //-------------------------------------------------------------------------------------------------------
		            //Sí el contacto eliminado también es una persona autorizada, NO se elimina la relación responsable-alumno
				    if(!(this.state.datos[0].personasAutorizadas.find(element => element.id == eliminado.id) !== undefined)){
				    	db.collection("responsables").doc(eliminado.id).update({
			            	alumnos: firebase.firestore.FieldValue.arrayRemove(db.collection('alumnos').doc(this.state.uid))
			            });
				    }
		            //-------------------------------------------------------------------------------------------------------
	            });
	            //*****************************************************************************************************************
	            //*****************************************************************************************************************
	            //*****************************************************************************************************************
			    alert_cargando.close();
			    //Mensaje de éxito
				swal.fire({
				  title:"<p style='font-size:13pt;font-weight:600;color: #282828;'>Cambios guardados</p>",
				  icon: 'success',
				  iconColor: '#17a2b8',
				  width: '300px',
				  showConfirmButton: false,
				  timer: 2000
				}).then((result) => {
				  	//se ejecuta después de mensaje de éxito
				  	window.location.reload(false);
				});
			}).catch(function(error) {
			    //Error al actualizar datos en firestore
			    alert_cargando.close();
			    //Mensaje de error
		  		swal.fire({
				  title:"<p style='font-size:13pt;font-weight:600;color: #282828;'>Error al actualizar datos</p>",
				  icon: 'error',
				  width: '400px',
				  showConfirmButton: false,
				  timer: 2500
				})
			});
		  	//***************************************************************************************************
		  	//*******************************************************************************************************************************
		  	//*******************************************************************************************************************************
		  	//*******************************************************************************************************************************
		  }
		});
	}
	//--------------------------------------------------------------------------------------------------------
	//--------------------------------------------------------------------------------------------------------
	//--------------------------------------------------------------------------------------------------------
	//--------------------------------------------------------------------------------------------------------
	//---------------------------------------- Actualiza actividades -----------------------------------------
	//--------------------------------------------------------------------------------------------------------
	//##################################################################################################################################
    //##################################################################################################################################
    //##################################################################################################################################
    //BÚSQUEDA DE ACTIVIDAD (CONSULTA FIRESTORE)***********************************************************
    buscarActividad = (e) => {
      e.preventDefault();//Evita la recarga de la página
      var textoBusqueda = document.getElementById("busqueda-actividad").value;
      if(textoBusqueda !== ""){
        //------------------------------------------------------------------------------------------------------------
        //------------------------------------------------------------------------------------------------------------
        //------------------------------------------------------------------------------------------------------------
        db.collection("actividades").where("clave", "==", textoBusqueda.trim().toUpperCase()).get().then((querySnapshot) => {
            var contador = 0;
            document.getElementById("body-tabla-actividades-busqueda").innerHTML = "";
            querySnapshot.forEach((doc) => {
                contador++;
                //*****************************************************************************************
                document.getElementById("body-tabla-actividades-busqueda").insertAdjacentHTML("beforeend", 
                "<tr class='filaTabla filaTabla-hover'>"+
                  "<td>"+doc.data().clave+"</td>"+
                  "<td>"+doc.data().nombre+"</td>"+
                  "<td>"+
                    "<div class='toggle-botonTabla' id='"+doc.id+"busqueda-VI"+"' title='Asociar' style='margin-right:15px'>"+
                      "<img class='botonTabla' src="+asociar+" width='17px' />"+
                    "</div>"+
                  "</td>"+
                "</tr>");
                //Evento de la fila (onclick en asociar)----------------------------------------
                document.getElementById(doc.id+"busqueda-VI").addEventListener('click', (e) => {
                  this.asociarActividad(doc.id, doc.data().clave, doc.data().nombre, null);
                });
                //------------------------------------------------------------------------------
                //*****************************************************************************************
            });
            if(contador == 0){
              //No hubo resultados para clave
              //------------------------------------------------------------------------------------------------------------
              //------------------------------------------------------------------------------------------------------------
              //------------------------------------------------------------------------------------------------------------
              db.collection("actividades").where("nombre", "==", textoBusqueda.trim().toUpperCase()).get().then((querySnapshot) => {
                  document.getElementById("body-tabla-actividades-busqueda").innerHTML = "";
                  querySnapshot.forEach((doc) => {
                      contador++;
                      //*****************************************************************************************
                      document.getElementById("body-tabla-actividades-busqueda").insertAdjacentHTML("beforeend", 
                      "<tr class='filaTabla filaTabla-hover'>"+
                        "<td>"+doc.data().clave+"</td>"+
                        "<td>"+doc.data().nombre+"</td>"+
                        "<td>"+
                          "<div class='toggle-botonTabla' id='"+doc.id+"busqueda-VI"+"' title='Asociar' style='margin-right:15px'>"+
                            "<img class='botonTabla' src="+asociar+" width='17px' />"+
                          "</div>"+
                        "</td>"+
                      "</tr>");
                      //Evento de la fila (onclick en asociar)----------------------------------------
                      document.getElementById(doc.id+"busqueda-VI").addEventListener('click', (e) => {
                        this.asociarActividad(doc.id, doc.data().clave, doc.data().nombre, null);
                      });
                      //------------------------------------------------------------------------------
                      //*****************************************************************************************
                  });
                  if(contador == 0){
	                //No hubo resultados para nombre (NINGÚN RESULTADO PARA LA BÚSQUEDA)
	                document.getElementById("body-tabla-actividades-busqueda").innerHTML = 
	                "<tr>"+
	                  "<td colspan='3'>"+
	                    "<center>"+
	                      "<p id='avisoSinResultadosBusquedaContacto' style='color:#c8c8c8;display:inline;'>No hay resultados a mostrar</p>"+
	                    "</center>"+
	                  "</td>"+
	                "</tr>"; 
                  }
              }).catch(function(error) {
                  //Error al realizar consulta
              });
              //------------------------------------------------------------------------------------------------------------
              //------------------------------------------------------------------------------------------------------------
              //------------------------------------------------------------------------------------------------------------
            }
        }).catch(function(error) {
            //Error al realizar consulta
        });
        //------------------------------------------------------------------------------------------------------------
        //------------------------------------------------------------------------------------------------------------
        //------------------------------------------------------------------------------------------------------------
      }
    }
    //*****************************************************************************************************
    //********************************************** ASOCIAR  ***************************************************
    asociarActividad = (uid, clave, nombre, fechaInscripcion) => {
      //Sí la tabla está vacía, se elimina el mensaje de "Ningún asociado"---------
      if(this.datosRegistro.actividades.length == 0){
      	swal.getConfirmButton().disabled = false;//Se habilita botón "Actualizar"
        document.getElementById("body-tabla-actividades-asociados").innerHTML = "";
      }
      //---------------------------------------------------------------------------

      //Se guarda en array la referencia----------------------------------------------------------------------------------
      //Sí ya existe el valor que se intenta guardar, se omite
      var aux = db.collection('actividades').doc(uid);
      if(!(this.datosRegistro.actividades.find(element => element.actividad.id == aux.id) !== undefined)){
      	//Se precarga fecha de inscripción sí es un curso ya inscrito-----------------------------
      	var objAux;
      	if(fechaInscripcion !== null){
	      	//Se crea objeto a guardar en arreglo **************************************
	        objAux = {
	          actividad: aux,
	          fechaInscripcion: fechaInscripcion
	        }
	        //**************************************************************************
    	} else {
    		//Se crea objeto a guardar en arreglo **************************************
	        objAux = {
	          actividad: aux,
	          fechaInscripcion: firebase.firestore.Timestamp.fromDate(new Date())
	        }
	        //**************************************************************************
    	}
        //----------------------------------------------------------------------------------------
        this.datosRegistro.actividades.push(objAux);//Guardado en el array "actividades"
        //Mostrar en Front (Tabla de asociación)----------------------------------------------------------------------------
        //*****************************************************************************************
        document.getElementById("body-tabla-actividades-asociados").insertAdjacentHTML("beforeend", 
        "<tr class='filaTabla filaTabla-hover' id='"+uid+"asociado-fila-VI'>"+
          "<td>"+clave+"</td>"+
          "<td>"+nombre+"</td>"+
          "<td>"+
            "<div class='toggle-botonTabla' id='"+uid+"asociado-VI"+"' title='Desasociar' style='margin-right:15px'>"+
              "<img class='botonTabla' src="+eliminar+" width='17px' />"+
            "</div>"+
          "</td>"+
        "</tr>");
        //Evento de la fila (onclick en desasociar)----------------------------------------
        document.getElementById(uid+"asociado-VI").addEventListener('click', (e) => {
          //--------------------------------------------------------------------------------
          //---------------------------------- Desasociar ----------------------------------
          //--------------------------------------------------------------------------------
          //Se elimina de la tabla (Front)
          document.getElementById("body-tabla-actividades-asociados").removeChild(document.getElementById(uid+"asociado-fila-VI"));
          //Se agrega al arreglo de eliminados
          if(!(this.datosRegistro.eliminados.find(element => element.actividad.id == aux.id) !== undefined)){//Sí ya existe el valor que se intenta guardar, se omite
          	this.datosRegistro.eliminados.push(objAux);//Guardado en el array "eliminados"
          }
          //Se elimina del array "actividades"
          this.datosRegistro.actividades.splice(this.datosRegistro.actividades.indexOf(this.datosRegistro.actividades.find(element => element.actividad.id == aux.id)), 1);
          //Sí la tabla se quedó sin elementos
          if(this.datosRegistro.actividades.length == 0) {
            document.getElementById("body-tabla-actividades-asociados").innerHTML = 
            "<tr>"+
              "<td colspan='3'>"+
                "<center>"+
                  "<p id='avisoSinAsociacionesActividad' style='color:#c8c8c8;display:inline;'>Ningún curso asociado</p>"+
                "</center>"+
              "</td>"+
            "</tr>";
          }
          //--------------------------------------------------------------------------------
          //--------------------------------------------------------------------------------
          //--------------------------------------------------------------------------------
        });
        //------------------------------------------------------------------------------
        //*****************************************************************************************
        //-----------------------------------------------------------------------------------------------------------------
      }
      //------------------------------------------------------------------------------------------------------------------
    }
    //***********************************************************************************************************
    //##################################################################################################################################
    //##################################################################################################################################
    //##################################################################################################################################
	actualizarA = () => {
		swal.fire({
		  title:"<p style='font-size: 14pt;font-weight:600;color: #282828;'>Edición de cursos asociados</p>",
		  width: 1000,
		  html: "<p style='font-size: 10pt;color: #0000008A;'>Alumno</p>"+
		  		"<p style='font-size: 11pt;color: #646464;'>"+this.state.datos[0].nombre+" "+this.state.datos[0].apellidos+"</p>"+
		  		"<form style='margin:0;padding:0;margin-top:20px;'><div class='row'>"+
                  "<div class='col-sm-12 col-md-12 col-lg-6'>"+
                    "<div style='border-radius:8px;background-color:#e6e6e6;width:437px;margin:auto;margin-bottom:30px;'>"+
                      "<div class='wrap'>"+
                         "<div class='search'>"+
                            "<input type='search' class='searchTerm' id='busqueda-actividad' placeholder='Buscar por clave o nombre' style='font-size:10pt;'/>"+
                            "<button class='searchButton' id='boton-busqueda-actividad'>"+
                              "<img src='"+lupa+"' style='margin-top:-6px;margin-left:-2px;opacity:0.6;' width='18px' />"+
                            "</button>"+
                         "</div>"+
                      "</div>"+
                      "<div style='border-radius:0px 0px 8px 8px;overflow:hidden;box-shadow:0px 1px 2px 1px #BDC3C7;width: 433px;margin:auto;'>"+
                        "<div id='tabla-actividades-busqueda' style='width: 433px;height: 200px; overflow-y: scroll;overflow-x: scroll;background-color: #fff;margin: auto;'>"+
                          "<table class='table'>"+
                            "<thead>"+
                               "<tr style='background-color:#fafafa;'>"+
                                  "<th>Clave</th>"+
                                  "<th>Nombre</th>"+
                                  "<th width='50px'></th>"+
                               "</tr>"+
                            "</thead>"+
                            "<tbody id='body-tabla-actividades-busqueda'>"+
                              "<tr>"+
                                "<td colspan='3' style='text-align:center;'>"+
                                  "<center>"+
                                    "<p id='avisoSinResultadosBusquedaActividad' style='color:#c8c8c8;display:inline;'>No hay resultados a mostrar</p>"+
                                  "</center>"+
                                "</td>"+
                              "</tr>"+
                            "</tbody>"+
                          "</table>"+
                        "</div>"+
                      "</div>"+
                    "</div>"+
                  "</div>"+
                  "<div class='col-sm-12 col-md-12 col-lg-6'>"+
                    "<div style='margin:auto;background-color:#e6e6e6;border-radius:9px;padding:1px;width: 404px;'>"+
                        "<p style='font-size:11pt;font-weight:500;color:#868686;margin-bottom:11px;margin-left:10px;margin-top:8px;float:left;'>Cursos asociados con alumno</p>"+
                        "<a href='/administrador/alta/curso' target='_blank'>"+
                          "<img class='botonImagen' title='Registrar curso' style='float:right;margin-right:12px;margin-top:11px;' src='"+nuevo+"' width='17px' />"+
                        "</a>"+
                        "<div style='border-radius:0px 0px 8px 8px;overflow:hidden;box-shadow:0px 1px 2px 1px #BDC3C7;width: 400px;margin:auto;'>"+
                          "<div id='tabla-actividades-asociados' style='width: 400px;height: 200px; overflow-y: scroll;overflow-x: scroll;background-color: #fff;margin: auto;'>"+
                            "<table class='table'>"+
                              "<thead>"+
                                 "<tr style='background-color:#fafafa;'>"+
                                    "<th>Clave</th>"+
                                    "<th>Nombre</th>"+
                                    "<th width='50px'></th>"+
                                 "</tr>"+
                              "</thead>"+
                              "<tbody id='body-tabla-actividades-asociados'>"+
                                "<tr>"+
                                  "<td colspan='3' style='text-align:center;'>"+
                                    "<center>"+
                                      "<p id='avisoSinAsociacionesActividades' style='color:#c8c8c8;display:inline;'>Ningún curso asociado</p>"+
                                    "</center>"+
                                  "</td>"+
                                "</tr>"+
                              "</tbody>"+
                            "</table>"+
                          "</div>"+
                        "</div>"+
                    "</div>"+
                    "<p style='font-size:7pt;margin-top:5px;text-align:center !important;'>Sí elimina un curso asociado por error, <b>no</b> lo asocie nuevamente por búsqueda, cancele la edición.</p>"+
                  "</div>"+
                "</div></form>",
		  footer: "<img width='20px' height='20px' style='margin-right:5px' src='"+advertencia+
		  		"'/><b style='font-size: 14;color: #D45454;'>Esta acción no se puede deshacer.</b>",
		  showCancelButton: true,
		  confirmButtonColor: "#17a2b8",
		  confirmButtonText: "<div style='font-size: 10pt;font-weight: 500;'>Actualizar</div>",
		  cancelButtonText: "<div style='font-size: 10pt;font-weight: 600;'>Cancelar</div>",
		  customClass: {
		    cancelButton: 'cancelButton',
		    confirmButton: 'confirmButton',
		    actions: 'alert-buttons',
		    title: 'titulo-alert-update-nombre',
		    content: 'container-alert'
		  },
		  didOpen: () => {
		  	//Se deshabilita el botón "Actualizar"
		  	swal.getConfirmButton().disabled = true;
		  	//Se reinicia el objeto de arreglos
        	this.datosRegistro.reset();
        	//Se precargan en la tabla de asociados los actuales**********************************************
        	this.state.datos.map(datos => {
				datos.actividades.map(curso => {
					db.collection(curso.actividad.parent.id).doc(curso.actividad.id).get().then(doc => {
					    if (doc.exists) {
					        this.asociarActividad(doc.id, doc.data().clave, doc.data().nombre, curso.fechaInscripcion);
					    }
					});
				});
			});
        	//************************************************************************************************
		    //Se añaden funciones onChange ----------------------------------------------------------------
		    document.getElementById("boton-busqueda-actividad").addEventListener('click', (e) => {
		    	this.buscarActividad(e);
		    });
		    document.getElementById("busqueda-actividad").addEventListener('search', (e) => {
		    	this.buscarActividad(e);
		    });
		    //---------------------------------------------------------------------------------------------
		  }
		}).then((result) => {
		  if (result.isConfirmed) {
		  	//*******************************************************************************************************************************
		  	//*******************************************Administrador confirmó la actualización*********************************************
		  	//*******************************************************************************************************************************
		  	//Alert "loading"----------------------
			const alert_cargando = swal.fire({
			  width: '300px',
			  allowOutsideClick: false,
			  showConfirmButton: false,
			  didOpen: () => {
			    swal.showLoading()
			  },
			  customClass: {
				   title: 'titulo-alert-loading',
			     actions: 'alert-loader'
				}
			})
			//-------------------------------------
			//Se actualizan los datos****************************************************************************
			db.collection("alumnos").doc(this.state.uid).update({
			    actividades: this.datosRegistro.actividades
			}).then(() => {
			    //Los datos se actualizaron correctamente en firestore
			    //*****************************************************************************************************************
	            //******************************* SE GUARDAN LAS RELACIONES EN DOCUMENTOS ASOCIADOS *******************************
	            //*****************************************************************************************************************
	            //ACTIVIDADES
	            this.datosRegistro.actividades.forEach((curso) => {
	              db.collection("actividades").doc(curso.actividad.id).update({
	                  alumnos: firebase.firestore.FieldValue.arrayUnion(db.collection('alumnos').doc(this.state.uid))
	              });
				  //Se crea el registro en la colección "inscripciones", sí no existe ya-----
				  db.collection("inscripciones")
				  .where("actividad", "==", curso.actividad)
				  .where("alumno", "==", db.collection('alumnos').doc(this.state.uid))
				  .where("fechaInscripcion", "==", curso.fechaInscripcion)
				  .get().then(doc => {
					  if (doc.empty) {
					    //---------------------------------------------------------------
					    db.collection("inscripciones").doc().set({
						  actividad: curso.actividad,
						  alumno: db.collection('alumnos').doc(this.state.uid),
						  fechaBaja: null,
						  fechaInscripcion: curso.fechaInscripcion
						});
					    //---------------------------------------------------------------
					  }
				  });
				  //-------------------------------------------------------------------------
	            });
	            //ELIMINADOS
	            this.datosRegistro.eliminados.forEach((eliminado) => {
		            //-------------------------------------------------------------------------------------------------------
				    db.collection("actividades").doc(eliminado.actividad.id).update({
			        	alumnos: firebase.firestore.FieldValue.arrayRemove(db.collection('alumnos').doc(this.state.uid))
			        });
			        //Se modifica la fecha de baja en el documento de la colección "inscripciones", sí existe-----
				    db.collection("inscripciones")
				    .where("actividad", "==", eliminado.actividad)
				    .where("alumno", "==", db.collection('alumnos').doc(this.state.uid))
				    .where("fechaInscripcion", "==", eliminado.fechaInscripcion)
				    .get().then(doc => {
					    if (!doc.empty) {
					      //---------------------------------------------------------------
					      db.collection("inscripciones").doc(doc.docs[0].id).update({
					      	fechaBaja: firebase.firestore.Timestamp.fromDate(new Date())
					      });
					      //---------------------------------------------------------------
					    }
				    });
				    //--------------------------------------------------------------------------------------------
		            //-------------------------------------------------------------------------------------------------------
	            });
	            //*****************************************************************************************************************
	            //*****************************************************************************************************************
	            //*****************************************************************************************************************
			    alert_cargando.close();
			    //Mensaje de éxito
				swal.fire({
				  title:"<p style='font-size:13pt;font-weight:600;color: #282828;'>Cambios guardados</p>",
				  icon: 'success',
				  iconColor: '#17a2b8',
				  width: '300px',
				  showConfirmButton: false,
				  timer: 2500
				}).then((result) => {
				  	//se ejecuta después de mensaje de éxito
				  	window.location.reload(false);
				});
			}).catch(function(error) {
			    //Error al actualizar datos en firestore
			    alert_cargando.close();
			    //Mensaje de error
		  		swal.fire({
				  title:"<p style='font-size:13pt;font-weight:600;color: #282828;'>Error al actualizar datos</p>",
				  icon: 'error',
				  width: '400px',
				  showConfirmButton: false,
				  timer: 2500
				})
			});
		  	//***************************************************************************************************
		  	//*******************************************************************************************************************************
		  	//*******************************************************************************************************************************
		  	//*******************************************************************************************************************************
		  }
		});
	}
	//--------------------------------------------------------------------------------------------------------
	//--------------------------------------------------------------------------------------------------------
	//--------------------------------------------------------------------------------------------------------
	//--------------------------------------------------------------------------------------------------------
	//----------------------------------------- Actualiza productos ------------------------------------------
	//--------------------------------------------------------------------------------------------------------
	//##################################################################################################################################
    //##################################################################################################################################
    //##################################################################################################################################
    //BÚSQUEDA DE PRODUCTO (CONSULTA FIRESTORE)************************************************************
    buscarProducto = (e) => {
      e.preventDefault();//Evita la recarga de la página
      var textoBusqueda = document.getElementById("busqueda-producto").value;
      if(textoBusqueda !== ""){
        //------------------------------------------------------------------------------------------------------------
        //------------------------------------------------------------------------------------------------------------
        //------------------------------------------------------------------------------------------------------------
        db.collection("productos").where("clave", "==", textoBusqueda.trim().toUpperCase()).get().then((querySnapshot) => {
            var contador = 0;
            document.getElementById("body-tabla-productos-busqueda").innerHTML = "";
            querySnapshot.forEach((doc) => {
                contador++;
                //*****************************************************************************************
                document.getElementById("body-tabla-productos-busqueda").insertAdjacentHTML("beforeend", 
                "<tr class='filaTabla filaTabla-hover'>"+
                  "<td>"+doc.data().clave+"</td>"+
                  "<td>"+doc.data().nombre+"</td>"+
                  "<td>"+
                    "<div class='toggle-botonTabla' id='"+doc.id+"busqueda-VII"+"' title='Asociar' style='margin-right:15px'>"+
                      "<img class='botonTabla' src="+asociar+" width='17px' />"+
                    "</div>"+
                  "</td>"+
                "</tr>");
                //Evento de la fila (onclick en asociar)----------------------------------------
                document.getElementById(doc.id+"busqueda-VII").addEventListener('click', (e) => {
                  this.asociarProducto(doc.id, doc.data().clave, doc.data().nombre, doc.data().tipo, doc.data().costo, doc.data().fechaVigencia);
                });
                //------------------------------------------------------------------------------
                //*****************************************************************************************
            });
            if(contador == 0){
              //No hubo resultados para clave
              //------------------------------------------------------------------------------------------------------------
              //------------------------------------------------------------------------------------------------------------
              //------------------------------------------------------------------------------------------------------------
              db.collection("productos").where("nombre", "==", textoBusqueda.trim().toUpperCase()).get().then((querySnapshot) => {
                  document.getElementById("body-tabla-productos-busqueda").innerHTML = "";
                  querySnapshot.forEach((doc) => {
                      contador++;
                      //*****************************************************************************************
                      document.getElementById("body-tabla-productos-busqueda").insertAdjacentHTML("beforeend", 
                      "<tr class='filaTabla filaTabla-hover'>"+
                        "<td>"+doc.data().clave+"</td>"+
                        "<td>"+doc.data().nombre+"</td>"+
                        "<td>"+
                          "<div class='toggle-botonTabla' id='"+doc.id+"busqueda-VII"+"' title='Asociar' style='margin-right:15px'>"+
                            "<img class='botonTabla' src="+asociar+" width='17px' />"+
                          "</div>"+
                        "</td>"+
                      "</tr>");
                      //Evento de la fila (onclick en asociar)----------------------------------------
                      document.getElementById(doc.id+"busqueda-VII").addEventListener('click', (e) => {
                        this.asociarProducto(doc.id, doc.data().clave, doc.data().nombre, doc.data().tipo, doc.data().costo, doc.data().fechaVigencia);
                      });
                      //------------------------------------------------------------------------------
                      //*****************************************************************************************
                  });
                  if(contador == 0){
	                //No hubo resultados para nombre (NINGÚN RESULTADO PARA LA BÚSQUEDA)
	                document.getElementById("body-tabla-productos-busqueda").innerHTML = 
	                "<tr>"+
	                  "<td colspan='3'>"+
	                    "<center>"+
	                      "<p id='avisoSinResultadosBusquedaContacto' style='color:#c8c8c8;display:inline;'>No hay resultados a mostrar</p>"+
	                    "</center>"+
	                  "</td>"+
	                "</tr>"; 
                  }
              }).catch(function(error) {
                  //Error al realizar consulta
              });
              //------------------------------------------------------------------------------------------------------------
              //------------------------------------------------------------------------------------------------------------
              //------------------------------------------------------------------------------------------------------------
            }
        }).catch(function(error) {
            //Error al realizar consulta
        });
        //------------------------------------------------------------------------------------------------------------
        //------------------------------------------------------------------------------------------------------------
        //------------------------------------------------------------------------------------------------------------
      }
    }
    //*****************************************************************************************************
    //********************************************** ASOCIAR  ***************************************************
    asociarProducto = (uid, clave, nombre, tipo, costo, fechaVigencia) => {
      //Sí la tabla está vacía, se elimina el mensaje de "Ningún asociado"---------
      if(this.datosRegistro.productos.length == 0){
      	swal.getConfirmButton().disabled = false;//Se habilita botón "Actualizar"
        document.getElementById("body-tabla-productos-asociados").innerHTML = "";
      }
      //---------------------------------------------------------------------------

      //Se guarda en array la referencia----------------------------------------------------------------------------------
      //Sí ya existe el valor que se intenta guardar, se omite
      var aux = db.collection('productos').doc(uid);
      if(!(this.datosRegistro.productos.find(element => element.producto.id == aux.id) !== undefined)){
      	//Se crea objeto a guardar en arreglo **************************************
        var objAux = {
          producto: aux,
          fechaCompra: firebase.firestore.Timestamp.fromDate(new Date()),
          clave: clave,
          nombre: nombre,
          tipo: tipo,
          cantidad: 1,
          costo: costo,
          fechaVigencia: fechaVigencia
        }
        //**************************************************************************
        this.datosRegistro.productos.push(objAux);//Guardado en el array "productos"
        //Mostrar en Front (Tabla de asociación)----------------------------------------------------------------------------
        //*****************************************************************************************
        document.getElementById("body-tabla-productos-asociados").insertAdjacentHTML("beforeend", 
        "<tr class='filaTabla filaTabla-hover' id='"+uid+"asociado-fila-VII'>"+
          "<td>"+clave+"</td>"+
          "<td>"+nombre+"</td>"+
          "<td><input class='input-cantidad' type='number' id='"+uid+"cantidad-VII"+"' name='"+uid+"' min='1' max='100' value='1'/></td>"+
          "<td>"+
            "<div class='toggle-botonTabla' id='"+uid+"asociado-VII"+"' title='Eliminar' style='margin-right:15px'>"+
              "<img class='botonTabla' src="+eliminar+" width='17px' />"+
            "</div>"+
          "</td>"+
        "</tr>");
        //Evento de la fila (cambio en cantidad)-------------------------------------------
        document.getElementById(uid+"cantidad-VII").addEventListener('change', (e) => {
        	//VALIDACIÓN---------------------------------------------------------
        	if(e.target.value < 1){e.target.value = 1;}
        	if(e.target.value > 100){e.target.value = 100;}
        	//-------------------------------------------------------------------
        	//Actualiza cantidad en elemento----------------------------------------
        	this.datosRegistro.productos.find(element => element.producto.id == e.target.name).cantidad = parseInt(e.target.value, 10);
        	//----------------------------------------------------------------------
        });
        //---------------------------------------------------------------------------------
        //Evento de la fila (onclick en desasociar)----------------------------------------
        document.getElementById(uid+"asociado-VII").addEventListener('click', (e) => {
          //--------------------------------------------------------------------------------
          //---------------------------------- Desasociar ----------------------------------
          //--------------------------------------------------------------------------------
          //Se elimina de la tabla (Front)
          document.getElementById("body-tabla-productos-asociados").removeChild(document.getElementById(uid+"asociado-fila-VII"));
          //Se elimina del array "productos"
          this.datosRegistro.productos.splice(this.datosRegistro.productos.indexOf(this.datosRegistro.productos.find(element => element.producto.id == aux.id)), 1);
          //Sí la tabla se quedó sin elementos
          if(this.datosRegistro.productos.length == 0) {
          	swal.getConfirmButton().disabled = true;//Se deshabilita botón "Actualizar"
            document.getElementById("body-tabla-productos-asociados").innerHTML = 
            "<tr>"+
              "<td colspan='4'>"+
                "<center>"+
                  "<p id='avisoSinAsociacionesProducto' style='color:#c8c8c8;display:inline;'>Ningún producto a asociar</p>"+
                "</center>"+
              "</td>"+
            "</tr>";
          }
          //--------------------------------------------------------------------------------
          //--------------------------------------------------------------------------------
          //--------------------------------------------------------------------------------
        });
        //------------------------------------------------------------------------------
        //*****************************************************************************************
        //-----------------------------------------------------------------------------------------------------------------
      }
      //------------------------------------------------------------------------------------------------------------------
    }
    //***********************************************************************************************************
    //##################################################################################################################################
    //##################################################################################################################################
    //##################################################################################################################################
	actualizarP = () => {
		swal.fire({
		  title:"<p style='font-size: 14pt;font-weight:600;color: #282828;'>Registro de compra</p>",
		  width: 1000,
		  html: "<p style='font-size: 10pt;color: #0000008A;'>Alumno</p>"+
		  		"<p style='font-size: 11pt;color: #646464;'>"+this.state.datos[0].nombre+" "+this.state.datos[0].apellidos+"</p>"+
		  		"<form style='margin:0;padding:0;margin-top:20px;'><div class='row'>"+
                  "<div class='col-sm-12 col-md-12 col-lg-6'>"+
                    "<div style='border-radius:8px;background-color:#e6e6e6;width:437px;margin:auto;margin-bottom:30px;'>"+
                      "<div class='wrap'>"+
                         "<div class='search'>"+
                            "<input type='search' class='searchTerm' id='busqueda-producto' placeholder='Buscar producto por clave o nombre' style='font-size:10pt;'/>"+
                            "<button class='searchButton' id='boton-busqueda-producto'>"+
                              "<img src='"+lupa+"' style='margin-top:-6px;margin-left:-2px;opacity:0.6;' width='18px' />"+
                            "</button>"+
                         "</div>"+
                      "</div>"+
                      "<div style='border-radius:0px 0px 8px 8px;overflow:hidden;box-shadow:0px 1px 2px 1px #BDC3C7;width: 433px;margin:auto;'>"+
                        "<div id='tabla-productos-busqueda' style='width: 433px;height: 200px; overflow-y: scroll;overflow-x: scroll;background-color: #fff;margin: auto;'>"+
                          "<table class='table'>"+
                            "<thead>"+
                               "<tr style='background-color:#fafafa;'>"+
                                  "<th>Clave</th>"+
                                  "<th>Nombre</th>"+
                                  "<th width='50px'></th>"+
                               "</tr>"+
                            "</thead>"+
                            "<tbody id='body-tabla-productos-busqueda'>"+
                              "<tr>"+
                                "<td colspan='3' style='text-align:center;'>"+
                                  "<center>"+
                                    "<p id='avisoSinResultadosBusquedaProducto' style='color:#c8c8c8;display:inline;'>No hay resultados a mostrar</p>"+
                                  "</center>"+
                                "</td>"+
                              "</tr>"+
                            "</tbody>"+
                          "</table>"+
                        "</div>"+
                      "</div>"+
                    "</div>"+
                  "</div>"+
                  "<div class='col-sm-12 col-md-12 col-lg-6'>"+
                    "<div style='margin:auto;background-color:#e6e6e6;border-radius:9px;padding:1px;width: 404px;'>"+
                        "<p style='font-size:11pt;font-weight:500;color:#868686;margin-bottom:11px;margin-left:10px;margin-top:8px;float:left;'>Productos o servicios adquiridos</p>"+
                        "<a href='/administrador/alta/producto' target='_blank'>"+
                          "<img class='botonImagen' title='Registrar producto' style='float:right;margin-right:12px;margin-top:11px;' src='"+nuevo+"' width='17px' />"+
                        "</a>"+
                        "<div style='border-radius:0px 0px 8px 8px;overflow:hidden;box-shadow:0px 1px 2px 1px #BDC3C7;width: 400px;margin:auto;'>"+
                          "<div id='tabla-productos-asociados' style='width: 400px;height: 200px; overflow-y: scroll;overflow-x: scroll;background-color: #fff;margin: auto;'>"+
                            "<table class='table'>"+
                              "<thead>"+
                                 "<tr style='background-color:#fafafa;'>"+
                                    "<th>Clave</th>"+
                                    "<th>Nombre</th>"+
                                    "<th>Cantidad</th>"+
                                    "<th width='50px'></th>"+
                                 "</tr>"+
                              "</thead>"+
                              "<tbody id='body-tabla-productos-asociados'>"+
                                "<tr>"+
                                  "<td colspan='4' style='text-align:center;'>"+
                                    "<center>"+
                                      "<p id='avisoSinAsociacionesProductos' style='color:#c8c8c8;display:inline;'>Ningún producto a asociar</p>"+
                                    "</center>"+
                                  "</td>"+
                                "</tr>"+
                              "</tbody>"+
                            "</table>"+
                          "</div>"+
                        "</div>"+
                    "</div>"+
                  "</div>"+
                "</div></form>",
		  showCancelButton: true,
		  confirmButtonColor: "#17a2b8",
		  confirmButtonText: "<div style='font-size: 10pt;font-weight: 500;'>Registrar compra</div>",
		  cancelButtonText: "<div style='font-size: 10pt;font-weight: 600;'>Cancelar</div>",
		  customClass: {
		    cancelButton: 'cancelButton',
		    confirmButton: 'confirmButton',
		    actions: 'alert-buttons',
		    title: 'titulo-alert-update-nombre',
		    content: 'container-alert'
		  },
		  didOpen: () => {
		  	//Se deshabilita el botón "Actualizar"
		  	swal.getConfirmButton().disabled = true;
		  	//Se reinicia el objeto de arreglos
        	this.datosRegistro.reset();
		    //Se añaden funciones onChange ----------------------------------------------------------------
		    document.getElementById("boton-busqueda-producto").addEventListener('click', (e) => {
		    	this.buscarProducto(e);
		    });
		    document.getElementById("busqueda-producto").addEventListener('search', (e) => {
		    	this.buscarProducto(e);
		    });
		    //---------------------------------------------------------------------------------------------
		  }
		}).then((result) => {
		  if (result.isConfirmed) {
		  	//*******************************************************************************************************************************
		  	//*******************************************Administrador confirmó la actualización*********************************************
		  	//*******************************************************************************************************************************
		  	//Alert "loading"----------------------
			const alert_cargando = swal.fire({
			  width: '300px',
			  allowOutsideClick: false,
			  showConfirmButton: false,
			  didOpen: () => {
			    swal.showLoading()
			  },
			  customClass: {
				   title: 'titulo-alert-loading',
			     actions: 'alert-loader'
				}
			})
			//-------------------------------------
			//Se actualizan los datos****************************************************************************
			this.datosRegistro.productos.forEach((producto) => {
	          db.collection("alumnos").doc(this.state.uid).update({
	              productos: firebase.firestore.FieldValue.arrayUnion(producto)
	          });
	          db.collection("compras").doc().set({
			      alumno: db.collection('alumnos').doc(this.state.uid),
			      cantidad: producto.cantidad,
			      costo: producto.costo,
			      fechaCompra: producto.fechaCompra,
			      producto: producto.producto
			  });
	        });
	        alert_cargando.close();
			//Mensaje de éxito
			swal.fire({
			  title:"<p style='font-size:13pt;font-weight:600;color: #282828;'>Compra registrada</p>",
			  icon: 'success',
			  iconColor: '#17a2b8',
			  width: '300px',
			  showConfirmButton: false,
			  timer: 2500
			}).then((result) => {
			  	//se ejecuta después de mensaje de éxito
			  	window.location.reload(false);
			});
		  	//***************************************************************************************************
		  	//*******************************************************************************************************************************
		  	//*******************************************************************************************************************************
		  	//*******************************************************************************************************************************
		  }
		});
	}
	//--------------------------------------------------------------------------------------------------------
	//--------------------------------------------------------------------------------------------------------
	//--------------------------------------------------------------------------------------------------------
	//--------------------------------------------------------------------------------------------------------
	//-------------------------------------------Actualiza logros---------------------------------------------
	//--------------------------------------------------------------------------------------------------------
	registrarLogro = () => {
		swal.fire({
		  title:"<p style='font-size: 14pt;font-weight:600;color: #282828;'>Registro de logro</p>",
		  html: "<p style='font-size: 10pt;color: #0000008A;'>Alumno</p>"+
		  		"<p style='font-size: 11pt;color: #646464;'>"+this.state.datos[0].nombre+" "+this.state.datos[0].apellidos+"</p>"+
	  			"<p style='font-size: 10pt;color: #0000008A;margin-bottom: 0px !important;'>Nivel del avance</p>"+
        	    "<p style='text-align:center;margin:0px;direction:rtl;unicode-bidi:bidi-override;font-size:10px;'>"+
        	      "<input id='nivel5' type='radio' name='nivel' value='5' class='input-nivel'><!--"+
        	      "--><label for='nivel5' class='label-nivel'>■</label><!--"+
        	      "--><input id='nivel4' type='radio' name='nivel' value='4' class='input-nivel'><!--"+
        	      "--><label for='nivel4' class='label-nivel'>■</label><!--"+
        	      "--><input id='nivel3' type='radio' name='nivel' value='3' class='input-nivel'><!--"+
        	      "--><label for='nivel3' class='label-nivel'>■</label><!--"+
        	      "--><input id='nivel2' type='radio' name='nivel' value='2' class='input-nivel'><!--"+
        	      "--><label for='nivel2' class='label-nivel'>■</label><!--"+
        	      "--><input id='nivel1' type='radio' name='nivel' value='1' class='input-nivel' checked><!--"+
        	      "--><label for='nivel1' class='label-nivel'>■</label>"+
        	    "</p>"+
				"<div class='form-group input-field' style='margin-top:20px;width:100%;'>"+
					"<input class='input_correcto input' type='text' id='logroRA' name='logro' required />"+
					"<label class='label' for='logroRA'>Logro o avance</label>"+
				"</div>",
		  showCancelButton: true,
		  confirmButtonColor: "#17a2b8",
		  confirmButtonText: "<div style='font-size: 10pt;font-weight: 500;'>Guardar</div>",
		  cancelButtonText: "<div style='font-size: 10pt;font-weight: 600;'>Cancelar</div>",
		  customClass: {
		    cancelButton: 'cancelButton',
		    confirmButton: 'confirmButton',
		    actions: 'alert-buttons',
		    title: 'titulo-alert-update-nombre',
		    content: 'container-alert'
		  },
		  didOpen: () => {
		  	swal.getConfirmButton().disabled = true;//Se deshabilita el botón "Actualizar"
			document.getElementById("logroRA").focus();//Se selecciona input
		    //Se añaden funciones onChange a los inputs ---------------------------------------------------
		    document.getElementById("logroRA").addEventListener('keyup', (e) => {
		    	//----------------------------------------------------------------------------
				//Condición para continuar
				if(document.getElementById("logroRA").value == ""){
					//Error
					swal.getConfirmButton().disabled = true;//Se deshabilita el botón "Actualizar"
				} else {
					//Correcto
					swal.getConfirmButton().disabled = false;//Se habilita el botón "Actualizar"
				}
		    	//----------------------------------------------------------------------------
		    });
		    //---------------------------------------------------------------------------------------------
		  }
		}).then((result) => {
		  if (result.isConfirmed) {
		  	//*******************************************************************************************************************************
		  	//*******************************************Administrador confirmó la actualización*********************************************
		  	//*******************************************************************************************************************************
		  	var logro_temp = document.getElementById("logroRA").value;//Se guarda dato no perderlo al cerrar alert
		  	var nivel_temp = 1;
    		if(document.getElementById("nivel2").checked){nivel_temp=2;}
    		if(document.getElementById("nivel3").checked){nivel_temp=3;}
    		if(document.getElementById("nivel4").checked){nivel_temp=4;}
    		if(document.getElementById("nivel5").checked){nivel_temp=5;}
		  	//Alert "loading"----------------------
			const alert_cargando = swal.fire({
			  width: '300px',
			  allowOutsideClick: false,
			  showConfirmButton: false,
			  didOpen: () => {
			    swal.showLoading()
			  },
			  customClass: {
				title: 'titulo-alert-loading',
			    actions: 'alert-loader'
			  }
			})
			//-------------------------------------
			//Se actualizan los datos****************************************************************************
			//Se crea objeto a guardar en arreglo **************************************
	        var objAux = {
	          fechaRegistro: firebase.firestore.Timestamp.fromDate(new Date()),
	          nivel: nivel_temp,
	          logro: logro_temp
	        }
	        //**************************************************************************
			db.collection("alumnos").doc(this.state.uid).update({
			    logros: firebase.firestore.FieldValue.arrayUnion(objAux)
			}).then(() => {
			    //Los datos se actualizaron correctamente en firestore
			    //Se guarda logro en colección "logros", para las notificaciones-------
			    db.collection("logros").doc().set({
				  alumno: db.collection('alumnos').doc(this.state.uid),
				  fechaRegistro: firebase.firestore.Timestamp.fromDate(new Date()),
				  nivel: nivel_temp,
	          	  logro: logro_temp
				});
				//---------------------------------------------------------------------
			    //Se cierra "alert cargando"
			    alert_cargando.close();
			    //Mensaje de éxito
				swal.fire({
				  title:"<p style='font-size:13pt;font-weight:600;color: #282828;'>Logro guardado</p>",
				  icon: 'success',
				  iconColor: '#17a2b8',
				  width: '300px',
				  showConfirmButton: false,
				  timer: 2500
				}).then((result) => {
				  	//se ejecuta después de mensaje de éxito
				  	window.location.reload(false);
				});
			}).catch(function(error) {
			    //Error al actualizar datos en firestore
			    alert_cargando.close();
			    //Mensaje de error
		  		swal.fire({
				  title:"<p style='font-size:13pt;font-weight:600;color: #282828;'>Error al guardar logro</p>",
				  icon: 'error',
				  width: '400px',
				  showConfirmButton: false,
				  timer: 2500
				})
			});
		  	//***************************************************************************************************
		  	//*******************************************************************************************************************************
		  	//*******************************************************************************************************************************
		  	//*******************************************************************************************************************************
		  }
		});
	}
	//--------------------------------------------------------------------------------------------------------
	//--------------------------------------------------------------------------------------------------------
	//--------------------------------------------------------------------------------------------------------
	//***********************************************************************************************************************************
	//***********************************************************************************************************************************
	//***********************************************************************************************************************************
	//***********************************************************************************************************************************
	render(){
		//*********************************************************************************************************************
		const controlSeleccion = (e) => {
			var opciones = document.getElementById("menuLateral").childNodes;
			for (var i = 0; i < opciones.length; i++) {
				document.getElementById(opciones[i].id).classList.remove('cardMenuInfo-selected');
				document.getElementById(opciones[i].id).classList.add('cardMenuInfo');
				document.getElementById("seccion"+opciones[i].id.slice(5)).style.display = "none";
			}
			document.getElementById("boton"+e.target.id).classList.remove('cardMenuInfo');
			document.getElementById("boton"+e.target.id).classList.add('cardMenuInfo-selected');
			document.getElementById("seccion"+e.target.id).style.display = "flex";
		}
		//*********************************************************************************************************************
		return(
			<React.Fragment>

			<Link to="/administrador/alumnos" id="boton_RA" style={{display:'none'}}></Link>

			<div id="menuLateral" className="global-container" style={{paddingBottom:'0px',paddingTop:'0px',borderBottom:'1px solid #dadce0',marginTop:'5px'}}>
				<div className="cardMenuInfo-selected" id="botonIG">
					<div><p id="IG" onClick={controlSeleccion}>Información general</p></div>
				</div>
				<div className="cardMenuInfo" id="botonDM">
					<div><p id="DM" onClick={controlSeleccion}>Datos médicos</p></div>
				</div>
				<div className="cardMenuInfo" id="botonTR">
					<div><p id="TR" onClick={controlSeleccion}>Tutores y responsables</p></div>
				</div>
				<div className="cardMenuInfo" id="botonCE">
					<div><p id="CE" onClick={controlSeleccion}>Contactos de emergencia</p></div>
				</div>
				<div className="cardMenuInfo" id="botonC">
					<div><p id="C" onClick={controlSeleccion}>Cursos</p></div>
				</div>
				<div className="cardMenuInfo" id="botonP">
					<div><p id="P" onClick={controlSeleccion}>Productos</p></div>
				</div>
				<div className="cardMenuInfo" id="botonA">
					<div><p id="A" onClick={controlSeleccion}>Avances</p></div>
				</div>
			</div>

			<div className="global-container" id="seccionIG">
				<div className="card login-form" style={{ width: '1000px', paddingTop:'0px' }}>
					<div className="card-body">
						<div className="row" style={{marginBottom:'0px'}}>
							<div className="col-sm-12 col-md-6 col-lg-6">
								<h4 className="card-title" style={{fontSize:'18pt',fontWeight:'500'}}>Información del alumno</h4>
							</div>
							<div className="col-sm-12 col-md-6 col-lg-6">
								<div className="cambiarPassword" style={{cursor:'pointer',overflow:'hidden',width:'152px',float:'right'}} onClick={this.actualizarContraseña}>
									<div style={{float:'right'}}>
										<p style={{fontWeight:'500',fontSize:'9pt',width:'100%',color:'#5f6368',padding:'0px',margin:'0px'}}>Cambiar contraseña</p>
										<p style={{fontSize:'9pt',width:'100%',color:'#5f6368',padding:'0px',margin:'0px'}}>Seguridad</p>
									</div>
									<img src={key} width="32px" style={{float:'right',marginRight:'7px',marginTop:'3px'}} />
								</div>
							</div>
						</div>
						<div className="card-text" style={{marginTop:'30px'}}>
							{this.state.datos.map(datos => {var genero;if(datos.genero == 'M'){genero="Masculino"}else{genero="Femenino"}return(
								<React.Fragment>
								<div className="row borderTop borderBottom" style={{marginBottom:'30px'}}>
									<div className="col-sm-12 col-md-3 col-lg-3">
										<p className="textoNormal">Nombre completo</p>
									</div>
									<div className="col-sm-12 col-md-6 col-lg-6">
										<p className="textoBold">{datos.nombre} {datos.apellidos}</p>
									</div>
									<div className="col-sm-12 col-md-3 col-lg-3">
										<p className="textoEnlace" style={{float: 'right'}} onClick={this.actualizarNombre}>Editar nombre</p>
									</div>
								</div>

								<div className="row">
									<div className="col-sm-12 col-md-8 col-lg-8">
										<p className="textoBoldSub">Información básica</p>
									</div>
									<div className="col-sm-12 col-md-4 col-lg-4">
										<p className="textoEnlace" style={{float: 'right'}} onClick={this.actualizarIB}>Editar información básica</p>
									</div>
								</div>

								<div className="row borderTop borderBottom" style={{marginBottom:'0px'}}>
									<div className="col-sm-12 col-md-3 col-lg-3">
										<p className="textoNormal">Fecha de nacimiento</p>
									</div>
									<div className="col-sm-12 col-md-7 col-lg-7">
										<p className="textoBold">{moment(datos.fechaNacimiento.toDate()).format('DD/MM/YYYY')}</p>
									</div>
								</div>

								<div className="row borderBottom" style={{marginBottom:'0px'}}>
									<div className="col-sm-12 col-md-3 col-lg-3">
										<p className="textoNormal">Género</p>
									</div>
									<div className="col-sm-12 col-md-7 col-lg-7">
										<p className="textoBold">{genero}</p>
									</div>
								</div>

								<div className="row borderBottom" style={{marginBottom:'30px'}}>
									<div className="col-sm-12 col-md-3 col-lg-3">
										<p className="textoNormal">Dirección</p>
									</div>
									<div className="col-sm-12 col-md-7 col-lg-7">
										<p className="textoBold">{datos.direccion.cadenaDireccion} Interior {datos.direccion.numeroInterior}</p>
									</div>
									<div className="col-sm-12 col-md-2 col-lg-2">
										<a href={`https://maps.google.com/?q=${datos.direccion.lat},${datos.direccion.lng}`} target="_blank" className="textoEnlace" style={{float: 'right'}}>Ver en Maps</a>
									</div>
								</div>

								<div className="row">
									<div className="col-sm-12 col-md-7 col-lg-7">
										<p className="textoBoldSub">Información de cuenta</p>
									</div>
									<div className="col-sm-12 col-md-5 col-lg-5">
										<p className="textoEnlace" style={{float: 'right'}} onClick={this.actualizarIC}>Editar la información de la cuenta</p>
									</div>
								</div>

								<div className="row borderTop borderBottom" style={{marginBottom:'0px'}}>
									<div className="col-sm-12 col-md-3 col-lg-3">
										<p className="textoNormal">Dirección de correo electrónico</p>
									</div>
									<div className="col-sm-12 col-md-7 col-lg-7">
										<p className="textoBold">{datos.correoElectronico}</p>
									</div>
								</div>

								<div className="row borderBottom" style={{marginBottom:'0px'}}>
									<div className="col-sm-12 col-md-3 col-lg-3">
										<p className="textoNormal">Fecha de inscripción</p>
									</div>
									<div className="col-sm-12 col-md-7 col-lg-7">
										<p className="textoBold">{moment(datos.fechaInscripcion.toDate()).format('DD/MM/YYYY')}</p>
									</div>
								</div>

								<div className="row borderBottom" style={{marginBottom:'0px'}}>
									<div className="col-sm-12 col-md-3 col-lg-3">
										<p className="textoNormal">ID de la cuenta</p>
									</div>
									<div className="col-sm-12 col-md-7 col-lg-7">
										<p className="textoNormal" style={{color:'#BCBDBD'}}>{datos.id_usuario}</p>
									</div>
								</div>
								</React.Fragment>
							);})}
						</div>
					</div>
				</div>
			</div>

			<div className="global-container" id="seccionDM" style={{display:'none'}}>
				<div className="card login-form" style={{ width: '1000px', paddingTop:'0px' }}>
					<div className="card-body">
						<div className="card-text" style={{marginTop:'0px'}}>
							{this.state.datos.map(datos => {return(
								<React.Fragment>
								<div className="row" style={{marginBottom:'15px'}}>
									<div className="col-sm-12 col-md-6 col-lg-6">
										<h4 className="card-title" style={{fontSize:'16pt',fontWeight:'500'}}>Datos médicos</h4>
									</div>
									<div className="col-sm-12 col-md-6 col-lg-6">
										<RB.Dropdown drop="left">
											<RB.Dropdown.Toggle as="p" bsPrefix="none" className="toggle-botonTabla" title="Ver opciones">
												<center><img src={mas} width="17px" className="botonTabla"/></center>
											</RB.Dropdown.Toggle>
											<RB.Dropdown.Menu>
												<RB.Dropdown.Item style={{fontSize:'14px'}} className="itemDropdown" onClick={this.actualizarDM}>
													Editar datos médicos
												</RB.Dropdown.Item>
											</RB.Dropdown.Menu>
										</RB.Dropdown>
									</div>
								</div>

								<div className="row borderTop borderBottom" style={{marginBottom:'0px'}}>
									<div className="col-sm-12 col-md-3 col-lg-3">
										<p className="textoNormal">Grupo sanguíneo</p>
									</div>
									<div className="col-sm-12 col-md-7 col-lg-7">
										<p className="textoBold">{datos.datosMedicos.grupoSanguineo}</p>
									</div>
								</div>

								<div className="row borderBottom" style={{marginBottom:'0px'}}>
									<div className="col-sm-12 col-md-3 col-lg-3">
										<p className="textoNormal">Alergias</p>
									</div>
									<div className="col-sm-12 col-md-7 col-lg-7">
										<p className="textoBold">{datos.datosMedicos.alergias}</p>
									</div>
								</div>

								<div className="row borderBottom" style={{marginBottom:'0px'}}>
									<div className="col-sm-12 col-md-3 col-lg-3">
										<p className="textoNormal">Enfermedades</p>
									</div>
									<div className="col-sm-12 col-md-7 col-lg-7">
										<p className="textoBold">{datos.datosMedicos.enfermedades}</p>
									</div>
								</div>

								<div className="row borderBottom" style={{marginBottom:'0px'}}>
									<div className="col-sm-12 col-md-3 col-lg-3">
										<p className="textoNormal">Medicamentos</p>
									</div>
									<div className="col-sm-12 col-md-7 col-lg-7">
										<p className="textoBold">{datos.datosMedicos.medicamentos}</p>
									</div>
								</div>

								<div className="row borderBottom" style={{marginBottom:'0px'}}>
									<div className="col-sm-12 col-md-3 col-lg-3">
										<p className="textoNormal">Tratamiento médico</p>
									</div>
									<div className="col-sm-12 col-md-7 col-lg-7">
										<p className="textoBold">{datos.datosMedicos.tratamientoMedico}</p>
									</div>
								</div>

								<div className="row borderBottom" style={{marginBottom:'0px'}}>
									<div className="col-sm-12 col-md-3 col-lg-3">
										<p className="textoNormal">Limitaciones físicas</p>
									</div>
									<div className="col-sm-12 col-md-7 col-lg-7">
										<p className="textoBold">{datos.datosMedicos.limitacionesFisicas}</p>
									</div>
								</div>
								</React.Fragment>
							);})}
						</div>
					</div>
				</div>
			</div>

			<div className="global-container" id="seccionTR" style={{display:'none'}}>
				<div className="card login-form" style={{ width: '1000px', paddingTop:'0px' }}>
					<div className="card-body">
						<div className="card-text" style={{marginTop:'0px'}}>
							{this.state.datos.map(datos => {return(
								<React.Fragment>
								<div style={{border:'1px solid #D2D5D8',borderRadius:'8px',overflow:'hidden',paddingTop:'6px',backgroundColor:'#fafafa',marginTop:'15px'}}>
									<div className="row" style={{margin:'3px',marginBottom:'0px'}}>
										<div className="col-sm-12 col-md-6 col-lg-6">
											<h4 className="card-title" style={{fontSize:'16pt',fontWeight:'500'}}>Tutores</h4>
										</div>
										<div className="col-sm-12 col-md-6 col-lg-6">
											<RB.Dropdown drop="left">
												<RB.Dropdown.Toggle as="p" bsPrefix="none" className="toggle-botonTabla" title="Ver opciones">
													<center><img src={mas} width="17px" className="botonTabla"/></center>
												</RB.Dropdown.Toggle>
												<RB.Dropdown.Menu>
														<RB.Dropdown.Item style={{fontSize:'14px'}} className="itemDropdown" onClick={this.actualizarT}>
															Editar tutores asociados
														</RB.Dropdown.Item>
												</RB.Dropdown.Menu>
											</RB.Dropdown>
										</div>
									</div>

									<div style={{marginTop:'4px',overflow:'hidden',border:'none',borderTop:'1px solid #dee2e6'}}>
										<div id="tabla" style={{
											width: '100%',
										    height: '250px', 
										    overflowY: 'auto',
										    overflowX: 'auto',
										    backgroundColor: '#fff'
										}}>
											<RB.Table>
												<thead>
												   <tr style={{backgroundColor:'#fafafa'}}>
												      <th style={{borderTopStyle:'none'}}>Nombre</th>
												      <th style={{borderTopStyle:'none'}}>Apellidos</th>
												      <th style={{borderTopStyle:'none'}}>Correo</th>
												      <th style={{borderTopStyle:'none'}}>Teléfono</th>
												   </tr>
												</thead>
												{datos.tutores.map(tutores => {this.consultaTutores(tutores.parent.id,tutores.id);})}
												<tbody id="tablaTutores">
												</tbody>
											</RB.Table>
										</div>
									</div>
								</div>

								<div style={{border:'1px solid #D2D5D8',borderRadius:'8px',overflow:'hidden',paddingTop:'6px',backgroundColor:'#fafafa',marginTop:'30px'}}>
									<div className="row" style={{margin:'3px',marginBottom:'0px'}}>
										<div className="col-sm-12 col-md-8 col-lg-8">
											<h4 className="card-title" style={{fontSize:'16pt',fontWeight:'500'}}>Personas autorizadas a recoger al alumno</h4>
										</div>
										<div className="col-sm-12 col-md-4 col-lg-4">
											<RB.Dropdown drop="left">
												<RB.Dropdown.Toggle as="p" bsPrefix="none" className="toggle-botonTabla" title="Ver opciones">
													<center><img src={mas} width="17px" className="botonTabla"/></center>
												</RB.Dropdown.Toggle>
												<RB.Dropdown.Menu>
													<RB.Dropdown.Item style={{fontSize:'14px'}} className="itemDropdown" onClick={this.actualizarPA}>
														Editar responsables asociados
													</RB.Dropdown.Item>
												</RB.Dropdown.Menu>
											</RB.Dropdown>
										</div>
									</div>

									<div style={{marginTop:'4px',overflow:'hidden',border:'none',borderTop:'1px solid #dee2e6'}}>
										<div id="tabla" style={{
											width: '100%',
										    height: '250px', 
										    overflowY: 'auto',
										    overflowX: 'auto',
										    backgroundColor: '#fff'
										}}>
											<RB.Table>
												<thead>
												   <tr style={{backgroundColor:'#fafafa'}}>
												      <th style={{borderTopStyle:'none'}}>Nombre</th>
												      <th style={{borderTopStyle:'none'}}>Apellidos</th>
												      <th style={{borderTopStyle:'none'}}>Correo</th>
												      <th style={{borderTopStyle:'none'}}>Teléfono</th>
												   </tr>
												</thead>
												{datos.personasAutorizadas.map(pA => {this.consultarPersonasAutorizadas(pA.parent.id,pA.id);})}
												<tbody id="tablaPersonasAutorizadas">
												</tbody>
											</RB.Table>
										</div>
									</div>
								</div>
								</React.Fragment>
							);})}
						</div>
					</div>
				</div>
			</div>

			<div className="global-container" id="seccionCE" style={{display:'none'}}>
				<div className="card login-form" style={{width:'1000px',paddingTop:'0px',padding:'0px',paddingTop:'15px',overflow:'hidden'}}>
					<div className="card-body" style={{padding:'0px',overflow:'hidden'}}>
						<div className="card-text" style={{margin:'0px',backgroundColor:'#fafafa',overflow:'hidden'}}>
							{this.state.datos.map(datos => {return(
								<React.Fragment>
								<div className="row" style={{marginBottom:'0px',padding:'20px',paddingBottom:'0px'}}>
									<div className="col-sm-12 col-md-6 col-lg-6">
										<h4 className="card-title" style={{fontSize:'16pt',fontWeight:'500'}}>Contactos de emergencia</h4>
									</div>
									<div className="col-sm-12 col-md-6 col-lg-6">
										<RB.Dropdown drop="left">
											<RB.Dropdown.Toggle as="p" bsPrefix="none" className="toggle-botonTabla" title="Ver opciones">
												<center><img src={mas} width="17px" className="botonTabla"/></center>
											</RB.Dropdown.Toggle>
											<RB.Dropdown.Menu>
												<RB.Dropdown.Item style={{fontSize:'14px'}} className="itemDropdown" onClick={this.actualizarCE}>
													Editar contactos asociados
												</RB.Dropdown.Item>
											</RB.Dropdown.Menu>
										</RB.Dropdown>
									</div>
								</div>

								<div style={{marginTop:'10px',overflow:'hidden'}}>
									<div id="tabla" style={{
										width: '100%',
									    height: '350px', 
									    overflowY: 'auto',
									    overflowX: 'auto',
									    backgroundColor: '#fff'
									}}>
										<RB.Table>
											<thead>
											   <tr style={{backgroundColor:'#fafafa'}}>
											      <th>Nombre</th>
											      <th>Apellidos</th>
											      <th>Correo</th>
											      <th>Teléfono</th>
											   </tr>
											</thead>
											{datos.contactosEmergencia.map(cE => {this.consultaContactosEmergencia(cE.parent.id,cE.id);})}
											<tbody id="tablaContactosEmergencia">
											</tbody>
										</RB.Table>
									</div>
								</div>
								</React.Fragment>
							);})}
						</div>
					</div>
				</div>
			</div>

			<div className="global-container" id="seccionC" style={{display:'none'}}>
				<div className="card login-form" style={{width:'1000px',paddingTop:'0px',padding:'0px',paddingTop:'15px',overflow:'hidden'}}>
					<div className="card-body" style={{padding:'0px',overflow:'hidden'}}>
						<div className="card-text" style={{margin:'0px',backgroundColor:'#fafafa',overflow:'hidden'}}>
							{this.state.datos.map(datos => {return(
								<React.Fragment>
								<div className="row" style={{marginBottom:'0px',padding:'20px',paddingBottom:'0px'}}>
									<div className="col-sm-12 col-md-6 col-lg-6">
										<h4 className="card-title" style={{fontSize:'16pt',fontWeight:'500'}}>Cursos inscritos</h4>
									</div>
									<div className="col-sm-12 col-md-6 col-lg-6">
										<RB.Dropdown drop="left">
											<RB.Dropdown.Toggle as="p" bsPrefix="none" className="toggle-botonTabla" title="Ver opciones">
												<center><img src={mas} width="17px" className="botonTabla"/></center>
											</RB.Dropdown.Toggle>
											<RB.Dropdown.Menu>
												<RB.Dropdown.Item style={{fontSize:'14px'}} className="itemDropdown" onClick={this.actualizarA}>
													Editar lista de cursos
												</RB.Dropdown.Item>
											</RB.Dropdown.Menu>
										</RB.Dropdown>
									</div>
								</div>

								<div style={{marginTop:'10px',overflow:'hidden'}}>
									<div id="tabla" style={{
										width: '100%',
									    height: '350px', 
									    overflowY: 'auto',
									    overflowX: 'auto',
									    backgroundColor: '#fff'
									}}>
										<RB.Table>
											<thead>
											   <tr style={{backgroundColor:'#fafafa'}}>
											      <th>Clave</th>
											      <th>Nombre</th>
											      <th>Lunes</th>
											      <th>Martes</th>
											      <th>Miércoles</th>
											      <th>Jueves</th>
											      <th>Viernes</th>
											      <th>Sábado</th>
											      <th>Domingo</th>
											   </tr>
											</thead>
											{datos.actividades.map(actividades => {this.consultaActividades(actividades.actividad.parent.id,actividades.actividad.id);})}
											<tbody id="tablaCursos">
											</tbody>
										</RB.Table>
									</div>
								</div>
								</React.Fragment>
							);})}
						</div>
					</div>
				</div>
			</div>

			<div className="global-container" id="seccionP" style={{display:'none'}}>
				<div className="card login-form" style={{width:'1000px',paddingTop:'0px',padding:'0px',paddingTop:'15px',overflow:'hidden'}}>
					<div className="card-body" style={{padding:'0px',overflow:'hidden'}}>
						<div className="card-text" style={{margin:'0px',backgroundColor:'#fafafa',overflow:'hidden'}}>
								<React.Fragment>
								<div className="row" style={{marginBottom:'0px',padding:'20px',paddingBottom:'0px'}}>
									<div className="col-sm-12 col-md-7 col-lg-7">
										<h4 className="card-title" style={{fontSize:'16pt',fontWeight:'500'}}>Historial de productos y servicios adquiridos</h4>
									</div>
									<div className="col-sm-12 col-md-4 col-lg-4" style={{overflow:'hidden'}}>
										<div style={{width:'100%'}}>
											<div className="circle" style={{marginRight:'5px',background:'#B62406',float:'left',marginTop:'4px'}}></div>
											<p style={{fontSize:'8pt',color:'#8E8F92',float:'left',marginBottom:'5px'}}>Producto o servicio sin disponibilidad</p>
										</div>
										<div style={{width:'100%',clear:'both'}}>
											<div className="circle" style={{marginRight:'5px',background:'#E4AA10',float:'left',marginTop:'4px'}}></div>
											<p style={{fontSize:'8pt',color:'#8E8F92',float:'left',marginBottom:'8px'}}>Producto o servicio modificado</p>
										</div>
									</div>
									<div className="col-sm-12 col-md-1 col-lg-1">
										<RB.Dropdown drop="left">
											<RB.Dropdown.Toggle as="p" bsPrefix="none" className="toggle-botonTabla" title="Ver opciones">
												<center><img src={mas} width="17px" className="botonTabla"/></center>
											</RB.Dropdown.Toggle>
											<RB.Dropdown.Menu>
												<RB.Dropdown.Item style={{fontSize:'14px'}} className="itemDropdown" onClick={this.actualizarP}>
													Registrar compra nueva
												</RB.Dropdown.Item>
											</RB.Dropdown.Menu>
										</RB.Dropdown>
									</div>
								</div>

								<div style={{marginTop:'10px',overflow:'hidden'}}>
									<div id="tabla" style={{
										width: '100%',
									    height: '350px', 
									    overflowY: 'auto',
									    overflowX: 'auto',
									    backgroundColor: '#fff'
									}}>
										<RB.Table>
											<thead>
											   <tr style={{backgroundColor:'#fafafa'}}>
											      <th>Clave</th>
											      <th>Nombre</th>
											      <th>Cantidad</th>
											      <th>Tipo</th>
											      <th>Fecha de compra</th>
											   </tr>
											</thead>
											<tbody id="tablaProductos">
											</tbody>
											{this.state.datos.map(datos => {
												document.getElementById("tablaProductos").innerHTML = "";
												return(
													<React.Fragment>
													{datos.productos.map((productos,index) => {
														//Sí es el primer resultado, se crean las filas de la tabla
														if(index == 0){
															var num_productos = datos.productos.length;
															for (var i = 0; i < num_productos; i++) {
																document.getElementById("tablaProductos").insertAdjacentHTML("beforeend",
																	"<tr class='filaTabla filaTabla-hover' id='pro_"+i.toString()+"'>"+
																	"<td id='pro_clave_"+((num_productos-1)-i).toString()+"'></td>"+
																	"<td id='pro_nombre_"+((num_productos-1)-i).toString()+"'></td>"+
																	"<td id='pro_cantidad_"+((num_productos-1)-i).toString()+"'></td>"+
																	"<td id='pro_tipo_"+((num_productos-1)-i).toString()+"'></td>"+
																	"<td id='pro_fechaCompra_"+((num_productos-1)-i).toString()+"'></td>"+
																	"</tr>"
																);
															}
														}
														//Se rellenan las filas
														this.consultaProductos(productos.producto.parent.id,productos.producto.id,productos.fechaCompra,index,productos.clave,productos.nombre,productos.cantidad,productos.tipo,productos.costo);
													})}
													</React.Fragment>
												);
											})}
										</RB.Table>
									</div>
								</div>
								</React.Fragment>
						</div>
					</div>
				</div>
			</div>

			<div className="global-container" id="seccionA" style={{display:'none'}}>
				<div className="card login-form" style={{width:'1000px',paddingTop:'0px',padding:'0px',paddingTop:'15px',overflow:'hidden'}}>
					<div className="card-body" style={{padding:'0px',overflow:'hidden'}}>
						<div className="card-text" style={{margin:'0px',backgroundColor:'#fafafa',overflow:'hidden'}}>
							{this.state.datos.map(datos => {return(
								<React.Fragment>
								<div className="row" style={{marginBottom:'0px',padding:'20px',paddingBottom:'0px'}}>
									<div className="col-sm-12 col-md-6 col-lg-6">
										<h4 className="card-title" style={{fontSize:'16pt',fontWeight:'500'}}>Historial de avances y logros</h4>
									</div>
									<div className="col-sm-12 col-md-6 col-lg-6">
										<RB.Dropdown drop="left">
											<RB.Dropdown.Toggle as="p" bsPrefix="none" className="toggle-botonTabla" title="Ver opciones">
												<center><img src={mas} width="17px" className="botonTabla"/></center>
											</RB.Dropdown.Toggle>
											<RB.Dropdown.Menu>
												<RB.Dropdown.Item style={{fontSize:'14px'}} className="itemDropdown" onClick={this.registrarLogro}>
													Registrar logro nuevo
												</RB.Dropdown.Item>
											</RB.Dropdown.Menu>
										</RB.Dropdown>
									</div>
								</div>

								<div style={{marginTop:'10px',overflow:'hidden'}}>
									<div id="tabla" style={{
										width: '100%',
									    height: '350px', 
									    overflowY: 'auto',
									    overflowX: 'auto',
									    backgroundColor: '#fff'
									}}>
										<RB.Table>
											<thead>
											   <tr style={{backgroundColor:'#fafafa'}}>
											      <th>Fecha de registro</th>
											      <th>Descripción</th>
											      <th>Nivel del avance</th>
											      <th></th>
											   </tr>
											</thead>
											<tbody id="tablaAvances">
											{datos.logros.map(logros => {var contador = -1;return(
												<tr className="filaTabla filaTabla-hover" id={"logro_"+(contador++).toString()}>
													<td>{moment(logros.fechaRegistro.toDate()).format('DD/MM/YYYY')}</td>
													<td>{logros.logro}</td>
													<td>{logros.nivel}</td>
													<td>
														<div className="toggle-botonTabla" title="Eliminar" style={{marginRight:'15px'}} onClick={() => {
															swal.fire({
															  title:"<p style='font-size: 15pt;font-weight:600;color: #282828;'>Eliminar logro del registro</p>",
															  html: "<p style='font-size: 10pt;color: #0000008A;'>Alumno</p>"+
		  															"<p style='font-size: 11pt;color: #646464;'>"+this.state.datos[0].nombre+" "+this.state.datos[0].apellidos+"</p>"+
															  		"<p style='font-size: 10pt;color: #0000008A;'>Logro o avance</p>"+
															  		"<p style='font-size: 11pt;color: #646464;'>"+logros.logro+"</p>",
															  footer: "<img width='20px' height='20px' style='margin-right:5px' src='"+advertencia+
															  		"'/><b style='font-size: 14;color: #D45454;'>Esta acción no se puede deshacer.</b>",
															  showCancelButton: true,
															  confirmButtonColor: "#D45454",
															  confirmButtonText: "<div style='font-size: 10pt;font-weight: 500;'>Eliminar</div>",
															  cancelButtonText: "<div style='font-size: 10pt;font-weight: 600;'>Cancelar</div>",
															  customClass: {
															    cancelButton: 'cancelButton',
															    confirmButton: 'confirmButton',
															    actions: 'alert-buttons',
															    title: 'titulo-alert-logro',
															    content: 'container-alert'
															  }
															}).then((result) => {
															  if (result.isConfirmed) {
															  	db.collection("alumnos").doc(this.state.uid).update({
																	logros: firebase.firestore.FieldValue.arrayRemove(logros)
																}).then(() => {
																	//Mensaje de éxito
																	swal.fire({
																		title:"<p style='font-size:13pt;font-weight:600;color: #282828;'>Cambio realizado</p>",
																		icon: 'success',
																		iconColor: '#17a2b8',
																		width: '300px',
																		showConfirmButton: false,
																		timer: 1500
																	}).then((result) => {
																		//se ejecuta después de mensaje de éxito
																		window.location.reload(false);
																	});
																});
															  }
															});
														}}>
															<img className="botonTabla" src={eliminar} width="20px" />
														</div>
														<div className="toggle-botonTabla" title="Editar" style={{marginRight:'15px'}} onClick={() => {
															swal.fire({
															  title:"<p style='font-size: 14pt;font-weight:600;color: #282828;'>Edición de logro</p>",
															  html: "<p style='font-size: 10pt;color: #0000008A;'>Alumno</p>"+
		  															"<p style='font-size: 11pt;color: #646464;'>"+this.state.datos[0].nombre+" "+this.state.datos[0].apellidos+"</p>"+
		  															"<p style='font-size: 10pt;color: #0000008A;margin-bottom: 10px !important;'>Nivel del avance</p>"+
																	"<div style='width:50px !important;'>"+
																		"<input class='input-cantidad' type='number' id='nivelRA' name='nivel' min='1' max='5' value='"+logros.nivel+"' required/>"+
																	"</div>"+
															  		"<div class='col-sm-12' style='margin-top:25px;padding:0px !important;'>"+
																		"<div class='form-group input-field' style='margin:auto;width:100%;'>"+
																			"<input class='input_correcto input' type='text' id='logroEditRA' name='logroEdit' value='"+logros.logro+"' required />"+
																			"<label class='label' for='logroEditRA'>Logro o avance</label>"+
																		"</div>"+
																	"</div>",
															  footer: "<img width='20px' height='20px' style='margin-right:5px' src='"+advertencia+
															  		"'/><b style='font-size: 14;color: #D45454;'>Esta acción no se puede deshacer.</b>",
															  showCancelButton: true,
															  confirmButtonColor: "#17a2b8",
															  confirmButtonText: "<div style='font-size: 10pt;font-weight: 500;'>Actualizar</div>",
															  cancelButtonText: "<div style='font-size: 10pt;font-weight: 600;'>Cancelar</div>",
															  customClass: {
															    cancelButton: 'cancelButton',
															    confirmButton: 'confirmButton',
															    actions: 'alert-buttons',
															    title: 'titulo-alert-update-nombre',
															    content: 'container-alert'
															  },
															  didOpen: () => {
															  	swal.getConfirmButton().disabled = true;//Se deshabilita el botón "Actualizar"
															    //Se añaden funciones onChange a los inputs ---------------------------------------------------
															    document.getElementById("logroEditRA").addEventListener('keyup', (e) => {
															    	//----------------------------------------------------------------------------
																	//Condición para continuar
																	if((document.getElementById("logroEditRA").value==logros.logro && document.getElementById("nivelRA").value==logros.nivel) || 
																		document.getElementById("logroEditRA").value==""){
																		//Error
																		swal.getConfirmButton().disabled = true;//Se deshabilita el botón "Actualizar"
																	} else {
																		//Correcto
																		swal.getConfirmButton().disabled = false;//Se habilita el botón "Actualizar"
																	}
															    	//----------------------------------------------------------------------------
															    });

															    document.getElementById("nivelRA").addEventListener('change', (e) => {
															    	//----------------------------------------------------------------------------
															    	//VALIDACIÓN---------------------------------------------------------
															    	if(e.target.value < 1){e.target.value = 1;}
															    	if(e.target.value > 5){e.target.value = 5;}
															    	//-------------------------------------------------------------------
															    	//Condición para continuar
																	if((document.getElementById("logroEditRA").value==logros.logro && document.getElementById("nivelRA").value==logros.nivel) || 
																		document.getElementById("logroEditRA").value==""){
																		//Error
																		swal.getConfirmButton().disabled = true;//Se deshabilita el botón "Actualizar"
																	} else {
																		//Correcto
																		swal.getConfirmButton().disabled = false;//Se habilita el botón "Actualizar"
																	}
																	//----------------------------------------------------------------------------
															    });
															    //---------------------------------------------------------------------------------------------
															  }
															}).then((result) => {
															  if (result.isConfirmed) {
															  	//*******************************************************************************************************************************
															  	//*******************************************Administrador confirmó la actualización*********************************************
															  	//*******************************************************************************************************************************
															  	//Se guardan datos para no perderlos al cerrar alert
															  	var logroEdit_temp = document.getElementById("logroEditRA").value;
															  	var nivelEdit_temp = document.getElementById("nivelRA").value;
															  	var fecha_temp = logros.fechaRegistro;
															  	//--------------------------------------------------
															  	//Alert "loading"----------------------
																const alert_cargando = swal.fire({
																  width: '300px',
																  allowOutsideClick: false,
																  showConfirmButton: false,
																  didOpen: () => {
																    swal.showLoading()
																  },
																  customClass: {
																	title: 'titulo-alert-loading',
																    actions: 'alert-loader'
																  }
																});
																//-------------------------------------
																//Se actualizan los datos****************************************************************************
																db.collection("alumnos").doc(this.state.uid).update({
																	logros: firebase.firestore.FieldValue.arrayRemove(logros)
																}).then(() => {
																	//Se crea objeto a guardar en arreglo **************************************
															        var objAux = {
															          fechaRegistro: fecha_temp,
															          nivel: nivelEdit_temp,
															          logro: logroEdit_temp
															        }
															        //**************************************************************************
															        //******************************************************************************************************
															        db.collection("alumnos").doc(this.state.uid).update({
																	    logros: firebase.firestore.FieldValue.arrayUnion(objAux)
																	}).then(() => {
																	    //Los datos se actualizaron correctamente en firestore
																	    //Se guarda logro en colección "logros", para las notificaciones-------
																	    db.collection("logros").doc().set({
																		  alumno: db.collection('alumnos').doc(this.state.uid),
																		  fechaRegistro: fecha_temp,
																		  nivel: nivelEdit_temp,
															          	  logro: logroEdit_temp
																		});
																		//---------------------------------------------------------------------
																	    //Se cierra "alert cargando"
																	    alert_cargando.close();
																	    //Mensaje de éxito
																		swal.fire({
																		  title:"<p style='font-size:13pt;font-weight:600;color: #282828;'>Cambio realizado</p>",
																		  icon: 'success',
																		  iconColor: '#17a2b8',
																		  width: '300px',
																		  showConfirmButton: false,
																		  timer: 2500
																		}).then((result) => {
																		  	//se ejecuta después de mensaje de éxito
																		  	window.location.reload(false);
																		});
																	}).catch(function(error) {
																	    //Error al actualizar datos en firestore
																	    alert_cargando.close();
																	    //Mensaje de error
																  		swal.fire({
																		  title:"<p style='font-size:13pt;font-weight:600;color: #282828;'>Error al editar logro</p>",
																		  icon: 'error',
																		  width: '400px',
																		  showConfirmButton: false,
																		  timer: 2500
																		})
																	});
															        //******************************************************************************************************
																}).catch(function(error) {
																    //Error al actualizar datos en firestore
																    alert_cargando.close();
																    //Mensaje de error
															  		swal.fire({
																	  title:"<p style='font-size:13pt;font-weight:600;color: #282828;'>Error al editar logro</p>",
																	  icon: 'error',
																	  width: '400px',
																	  showConfirmButton: false,
																	  timer: 2500
																	})
																});
															  	//***************************************************************************************************
															  	//*******************************************************************************************************************************
															  	//*******************************************************************************************************************************
															  	//*******************************************************************************************************************************
															  }
															});
														}}>
															<img className="botonTabla" src={lapiz} width="20px" />
														</div>
													</td>
												</tr>
											);})}
											</tbody>
										</RB.Table>
									</div>
								</div>
								</React.Fragment>
							);})}
						</div>
					</div>
				</div>
			</div>

			</React.Fragment>
		);
	}
}

export default InfoAlumno;