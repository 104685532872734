import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import * as RB from "react-bootstrap";
import {db} from '../firebase-config';
import {Link} from 'react-router-dom';
import moment from 'moment';
import lupa from '../assets/images/lupa.png';
import info from '../assets/images/info.png';
import cancel from '../assets/images/cancel.png';
import mas from '../assets/images/mas.png';
import advertencia from '../assets/images/advertencia.png';
import swal from 'sweetalert2';

class TablaSesiones extends React.Component{
	//constructor del componente
	constructor(props){
		super(props)
		//Estado inicial del componente
		this.state = {
			datos: [],
			last: null
		}
	}
	//Se ejecuta al cargar componente
	componentDidMount() {
		//-----------------------------------------------------------------------------------------------------------------
		db.collection("sesiones").orderBy("fechaRealizacion", "desc").limit(10).get().then(querySnapshot => {
	    	const docs = [];
	    	var index = this.state.datos.length;//index del arreglo de datos guardado en el estado
	    	this.setState({last: querySnapshot.docs[querySnapshot.docs.length-1]});//Se guarda en el estado el último objeto consultado
	        querySnapshot.forEach(doc => {
	            //-------------------------------------------------------------------------
	            const data = doc.data();
	            docs.push({
	            	...data,
	            	id_doc: doc.id,
	            	index_state: index,
	            });
	            index++;
	            //-------------------------------------------------------------------------
	        });
	        this.setState({datos: docs});
	        //Sí hay más de 10 datos se muestra botón "mostrar más"
	        if(docs.length == 10){
	        	document.getElementById("botonMostrarMas").style.display = 'block';
	        }
	        //Sí no hay datos a mostrar
	        if(docs.length == 0){
    			document.getElementById("avisoVacio").style.display = 'block';
	        }
	    });
		//-----------------------------------------------------------------------------------------------------------------
	}
	//--------------------------------------------------------------------------------
	//--------------------------MOSTRAR MÁS RESULTADOS--------------------------------
	//--------------------------------------------------------------------------------
	mostrarMas = () => {
		//-----------------------------------------------------------------------------------------------------------------
		db.collection("sesiones").orderBy("fechaRealizacion", "desc").limit(10).startAfter(this.state.last).get().then(querySnapshot => {
	    	const docs = [];
	    	//Sí aún hay datos que mostrar
	    	if(querySnapshot.docs.length != 0){
	    		var index = this.state.datos.length;//index del arreglo de datos guardado en el estado
		    	this.setState({last: querySnapshot.docs[querySnapshot.docs.length-1]});//Se guarda en el estado el último objeto consultado
		        querySnapshot.forEach(doc => {
		            //-------------------------------------------------------------------------
		            const data = doc.data();
		            docs.push({
		            	...data,
		            	id_doc: doc.id,
		            	index_state: index,
		            });
		            index++;
		            //-------------------------------------------------------------------------
		        });
		        this.setState((state) => {
				    return {datos: state.datos.concat(docs)}
				});
	    	} else {
	    		//No hay más datos que mostrar
	    		document.getElementById("avisoFin").style.display = 'block';
	    		document.getElementById("botonMostrarMas").style.display = 'none';
	    	}
	    });
		//-----------------------------------------------------------------------------------------------------------------
	}
	//--------------------------------------------------------------------------------
	//--------------------------------------------------------------------------------
	//--------------------------------------------------------------------------------
	render(){
		//Llenar filas en campos: "instructores" y "cursos"---------------------------------
		const llenarFila = (id_instructor, id_curso, id_doc) => {
			db.collection("instructores").doc(id_instructor).get().then(instructor => {
				if (instructor.exists) {
					document.getElementById(id_doc+"-ins").innerHTML = instructor.data().nombre+" "+instructor.data().apellidos;
				} else {
					document.getElementById(id_doc+"-ins").innerHTML = "<i>INSTRUCTOR ELIMINADO</i>";
				}
			});
			db.collection("actividades").doc(id_curso).get().then(actividad => {
				if (actividad.exists) {
					document.getElementById(id_doc+"-cur").innerHTML = "<p style='font-weight:500;margin:0px;padding:0px;display:inline;'>"+actividad.data().clave+"</p> "+actividad.data().nombre;
				} else {
					document.getElementById(id_doc+"-cur").innerHTML = "<i>CURSO ELIMINADO</i>";
				}
			});
		}
		//----------------------------------------------------------------------------------
		return(

			<div className="global-container">
				<div className="card login-form tablas-adm" style={{ width: '82%', backgroundColor: '#fafafa' }}>
					<div className="card-body card-tablas-adm">

						<div className="row">
							<div className="col-sm-12 col-md-7 col-lg-9">
								<h3 className="card-title" style={{fontSize:'32px',fontWeight:'500'}}>Historial de sesiones</h3>
							</div>

							<div className="col-sm-12 col-md-5 col-lg-3" style={{paddingTop: "8px"}}>
								<Link to="/administrador/alta/sesion" style={{float: 'right'}}>
									<RB.Button variant="info" style={{fontSize: '10pt'}} className="boton-presionado">Registrar sesión</RB.Button>
								</Link>
							</div>
						</div>

						<div className="card-text" id="subtext">
							<div id="tabla" style={{
								width: '100%',
							    height: '400px', 
							    overflowY: 'scroll',
							    overflowX: 'scroll',
							    marginTop: '25px',
							    backgroundColor: '#fff'
							}}>
								<RB.Table>
									<thead>
									   <tr style={{backgroundColor:'#fafafa'}}>
									   	  <th>Instructor</th>
									      <th>Curso</th>
									      <th>Horas</th>
									      <th>Fecha ↓</th>
									   </tr>
									</thead>
									<tbody>
										{
											this.state.datos.map(sesion => {
												return(
													<tr className="filaTabla filaTabla-hover">
														<td id={sesion.id_doc+"-ins"}></td>
														<td id={sesion.id_doc+"-cur"}></td>
														{llenarFila(sesion.instructor.id,sesion.actividad.id,sesion.id_doc)}
														<td>{sesion.horas}</td>
														<td>{moment(sesion.fechaRealizacion.toDate()).format('DD-MM-YYYY')}</td>
													</tr>
												);
											})
										}
										<tr>
											<td colSpan="4">
												<center>
													<RB.Button id="botonMostrarMas" className="boton-presionado" variant="outline-info" type="button" style={{fontSize: '9pt',display:'none'}} onClick={(e) => this.mostrarMas()}>
														Mostrar más resultados
													</RB.Button>
													<p id="avisoFin" style={{color: '#c8c8c8', display: 'none'}}>No hay más resultados a mostrar</p>
													<p id="avisoVacio" style={{color: '#c8c8c8', display: 'none'}}>No hay resultados a mostrar</p>
												</center>
											</td>
										</tr>
									</tbody>
								</RB.Table>
							</div>
						</div>

					</div>
				</div>
			</div>

		);
	}
}

export default TablaSesiones;