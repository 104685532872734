import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import * as RB from "react-bootstrap";
import {db} from '../../firebase-config';
import {Link} from 'react-router-dom';
import moment from 'moment';
import lupa from '../../assets/images/lupa.png';
import info from '../../assets/images/info.png';
import cancel from '../../assets/images/cancel.png';
import mas from '../../assets/images/mas.png';
import nuevo from '../../assets/images/nuevo.png';
import eliminar from '../../assets/images/eliminar.png';
import advertencia from '../../assets/images/advertencia.png';
import swal from 'sweetalert2';
import firebase from 'firebase/app';

class SeccionAlumnos extends React.Component{
	//constructor del componente
	constructor(props){
		super(props)
		//Estado inicial del componente
		this.state = {
			datos: [],
			last: null,
			busquedaActiva: false,
			criterioBusqueda: "",
			textoBusqueda: ""
		}
	}
	//Se ejecuta al cargar componente
	componentDidMount() {
		//-----------------------------------------------------------------------------------------------------------------
			db.collection("alumnos")
			.limit(10)
			.orderBy("apellidos")
			.get()
		    .then(querySnapshot => {
		    	const docs = [];
		    	var index = this.state.datos.length;//index del arreglo de datos guardado en el estado
		    	this.setState({last: querySnapshot.docs[querySnapshot.docs.length-1]});//Se guarda en el estado el último objeto consultado
		        querySnapshot.forEach(doc => {
		            //-------------------------------------------------------------------------
		            const data = doc.data();
		            docs.push({
		            	...data,
		            	index_state: index,
		            });
		            index++;
		            //-------------------------------------------------------------------------
		        });
		        this.setState({datos: docs});
		        //Sí hay más de 10 datos se muestra botón "mostrar más"
		        if(docs.length == 10){
		        	document.getElementById("botonMostrarMas").style.display = 'block';
		        }
		        //Sí no hay datos a mostrar
		        if(docs.length == 0){
	    			document.getElementById("avisoVacio").style.display = 'block';
		        }
		    });
		//-----------------------------------------------------------------------------------------------------------------
	}
	//--------------------------------------------------------------------------------
	//--------------------------MOSTRAR MÁS RESULTADOS--------------------------------
	//--------------------------------------------------------------------------------
	mostrarMas = () => {
		//Sí hay una búsqueda activa
		if(this.state.busquedaActiva){
			//-----------------------------------------------------------------------------------------------------------------
			db.collection("alumnos").where(this.state.criterioBusqueda, "==", this.state.textoBusqueda)
			.limit(10)
			.startAfter(this.state.last)
			.get()
		    .then(querySnapshot => {
		    	const docs = [];
		    	//Sí aún hay datos que mostrar
		    	if(querySnapshot.docs.length != 0){
		    		var index = this.state.datos.length;//index del arreglo de datos guardado en el estado
			    	this.setState({last: querySnapshot.docs[querySnapshot.docs.length-1]});//Se guarda en el estado el último objeto consultado
			        querySnapshot.forEach(doc => {
			            //-------------------------------------------------------------------------
			            const data = doc.data();
			            docs.push({
			            	...data,
			            	index_state: index,
			            });
			            index++;
			            //-------------------------------------------------------------------------
			        });
			        this.setState((state) => {
					    return {datos: state.datos.concat(docs)}
					});
		    	} else {
		    		//No hay más datos que mostrar
		    		document.getElementById("avisoFin").style.display = 'block';
		    		document.getElementById("botonMostrarMas").style.display = 'none';
		    	}
		    });
			//-----------------------------------------------------------------------------------------------------------------
		} else {
			//Sí no hay una búsqueda activa
			//-----------------------------------------------------------------------------------------------------------------
			db.collection("alumnos")
			.limit(10)
			.orderBy("apellidos")
			.startAfter(this.state.last)
			.get()
		    .then(querySnapshot => {
		    	const docs = [];
		    	//Sí aún hay datos que mostrar
		    	if(querySnapshot.docs.length != 0){
		    		var index = this.state.datos.length;//index del arreglo de datos guardado en el estado
			    	this.setState({last: querySnapshot.docs[querySnapshot.docs.length-1]});//Se guarda en el estado el último objeto consultado
			        querySnapshot.forEach(doc => {
			            //-------------------------------------------------------------------------
			            const data = doc.data();
			            docs.push({
			            	...data,
			            	index_state: index,
			            });
			            index++;
			            //-------------------------------------------------------------------------
			        });
			        this.setState((state) => {
					    return {datos: state.datos.concat(docs)}
					});
		    	} else {
		    		//No hay más datos que mostrar
		    		document.getElementById("avisoFin").style.display = 'block';
		    		document.getElementById("botonMostrarMas").style.display = 'none';
		    	}
		    });
			//-----------------------------------------------------------------------------------------------------------------
		}
	}
	//--------------------------------------------------------------------------------
	//--------------------------------------------------------------------------------
	//--------------------------------------------------------------------------------
	//-------------------------------------------------------------------------------------------------
	//---------------------------------EDICIÓN DE LOGROS DEL ALUMNO------------------------------------
	//-------------------------------------------------------------------------------------------------
	editarLogros = (id, arrayLogros, nombre, apellidos) => {
		swal.fire({
		  title:"<p style='font-size: 16pt;font-weight:600;color: #282828;'>Edición de avances y logros</p>",
		  width: 900,
		  html: "<p style='font-size: 10pt;color: #0000008A;'>Alumno</p>"+
		  		"<p style='font-size: 11pt;color: #646464;margin-bottom:20px;'>"+nombre+" "+apellidos+"</p>"+
		  		"<div id='datosNuevo' style='height:356px;display:none;'>"+
		  			"<h4 class='card-title' style='font-size:12pt;font-weight:500;margin:0px;margin-top:30px;margin-bottom:10px'>Nuevo logro o avance</h4>"+
			  		"<p style='font-size: 10pt;color: #0000008A;margin-bottom: 0px !important;'>Nivel</p>"+
	        	    "<p style='text-align:center;margin:0px;direction:rtl;unicode-bidi:bidi-override;font-size:10px;'>"+
	        	      "<input id='nivel5' type='radio' name='nivel' value='5' class='input-nivel'><!--"+
	        	      "--><label for='nivel5' class='label-nivel'>■</label><!--"+
	        	      "--><input id='nivel4' type='radio' name='nivel' value='4' class='input-nivel'><!--"+
	        	      "--><label for='nivel4' class='label-nivel'>■</label><!--"+
	        	      "--><input id='nivel3' type='radio' name='nivel' value='3' class='input-nivel'><!--"+
	        	      "--><label for='nivel3' class='label-nivel'>■</label><!--"+
	        	      "--><input id='nivel2' type='radio' name='nivel' value='2' class='input-nivel'><!--"+
	        	      "--><label for='nivel2' class='label-nivel'>■</label><!--"+
	        	      "--><input id='nivel1' type='radio' name='nivel' value='1' class='input-nivel' checked><!--"+
	        	      "--><label for='nivel1' class='label-nivel'>■</label>"+
	        	    "</p>"+
					"<div class='form-group input-field' style='margin:0px !important;margin-top:20px;width:100%;'>"+
						"<input class='input_correcto input' type='text' id='logroRA' name='logro' required />"+
						"<label class='label' for='logroRA'>Descripción</label>"+
					"</div>"+
					"<div style='margin-top:30px;overflow:hidden;'>"+
						"<button class='btn btn-info boton-presionado' id='botonRegistrar' type='button' style='float:right;margin-right:7px;width:100px;font-size:10pt;'>Registrar</button>"+
						"<a id='botonCancelarNuevo' style='cursor:pointer;float:right;margin-right:35px;line-height:2.4;color:#17a2b8;font-size:10pt;text-decoration:none'>Cancelar</a>"+
					"</div>"+
		  		"</div>"+
		  		"<div id='historial' style='border:1px solid #dee2e6;border-radius:8px;overflow:hidden;padding-top:6px;background-color:#fafafa;margin-top:15px;margin-bottom:30px;'>"+
					"<div class='row' style='margin:3px;margin-bottom:0px;'>"+
						"<div class='col-sm-6 col-md-6 col-lg-6' style='margin-bottom:2px;'>"+
							"<h4 class='card-title' style='font-size:13pt;font-weight:400;float:left;margin:0px;'>Historial del alumno</h4>"+
						"</div>"+
						"<div class='col-sm-6 col-md-6 col-lg-6' style='margin-bottom:2px;'>"+
							"<div class='toggle-botonTabla' title='Agregar' style='float:right;' id='botonAgregar'>"+
								"<img class='botonTabla' src='"+nuevo+"' width='17px' style='margin-top:-5px !important;'/>"+
							"</div>"+
						"</div>"+
					"</div>"+
					"<div style='margin-top:4px;overflow:hidden;border:none;border-top:1px solid #dee2e6;'>"+
						"<div id='tabla' style='width: 100%;height: 300px; overflow-y: auto;overflow-x: auto;background-color: #fff;'>"+
							"<table class='table'>"+
								"<thead>"+
								   "<tr style='background-color:#fafafa;'>"+
								      "<th style='border-top-style:none;'>Fecha de registro</th>"+
								      "<th style='border-top-style:none;'>Descripción</th>"+
								      "<th style='border-top-style:none;'>Nivel del avance</th>"+
								      "<th style='border-top-style:none;'></th>"+
								   "</tr>"+
								"</thead>"+
								"<tbody id='tablaAvances'>"+
									"<tr>"+
										"<td colspan='4' style='text-align:center;>"+
											"<center>"+
												"<p style='color: #c8c8c8;display: inline;'>No hay resultados a mostrar</p>"+
											"</center>"+
										"</td>"+
									"</tr>"+
								"</tbody>"+
							"</table>"+
						"</div>"+
					"</div>"+
				"</div>",
		  showCancelButton: false,
		  showConfirmButton: false,
		  showCloseButton: true,
		  customClass: {
		    closeButton: 'boton-presionado',
		    actions: 'alert-buttons',
		    title: 'titulo-alert-update-nombre',
		    content: 'container-alert'
		  },
		  didOpen: () => {
		  	//SE MUESTRAN LOS LOGROS ACTUALES EN LA TABLA "HISTORIAL DEL ALUMNO"---------------------------
		    const cargarLogros = () => {

		    	db.collection("alumnos").doc(id).get().then(doc => {
		    		document.getElementById("tablaAvances").innerHTML = "<tr>"+
						"<td colspan='4' style='text-align:center;>"+
							"<center>"+
								"<p style='color: #c8c8c8;display: inline;'>No hay resultados a mostrar</p>"+
							"</center>"+
						"</td>"+
					"</tr>";
		    		var contador = -1;
		    		doc.data().logros.map(fila => {
				    	contador++;
				    	if(contador==0){document.getElementById("tablaAvances").innerHTML = "";}
				    	document.getElementById("tablaAvances").insertAdjacentHTML("beforeend", 
				        "<tr class='filaTabla filaTabla-hover' id='logro_"+contador+"'>"+
				          "<td>"+moment(fila.fechaRegistro.toDate()).format('DD/MM/YYYY')+"</td>"+
				          "<td>"+fila.logro+"</td>"+
				          "<td>"+fila.nivel+"</td>"+
				          "<td>"+
				            "<div class='toggle-botonTabla' id='eliminarLogro_"+contador+"' title='Eliminar' style='margin-right:15px'>"+
				              "<img class='botonTabla' src="+eliminar+" width='17px' />"+
				            "</div>"+
				          "</td>"+
				        "</tr>");
				        //ELIMINAR LOGRO**********************************************************************************
				        document.getElementById("eliminarLogro_"+contador).addEventListener('click', (e) => {
				        	db.collection("alumnos").doc(id).update({
								logros: firebase.firestore.FieldValue.arrayRemove(fila)
							}).then(() => {
								//SE BORRÓ CORRECTAMENTE
								cargarLogros();
							});
				        });
				        //************************************************************************************************
				    });
		    	});

		    };
		    cargarLogros();
		    //---------------------------------------------------------------------------------------------

		  	document.getElementById("botonRegistrar").disabled = true;//Se deshabilita el botón "Registrar"
		  	var cancelarNuevoDisabled = false;

		    //Se añaden funciones onChange a los inputs ---------------------------------------------------
		    document.getElementById("botonAgregar").addEventListener('click', (e) => {
		    	//----------------------------------------------------------------------------
		    	document.getElementById("datosNuevo").style.display = "block";
		    	document.getElementById("historial").style.display = "none";
		    	//----------------------------------------------------------------------------
		    });

		    document.getElementById("botonCancelarNuevo").addEventListener('click', (e) => {
		    	//----------------------------------------------------------------------------
		    	if(!cancelarNuevoDisabled){
			    	document.getElementById("logroRA").value = "";
			    	document.getElementById("nivel1").checked = true;
			    	document.getElementById("botonRegistrar").disabled = true;
			    	document.getElementById("datosNuevo").style.display = "none";
			    	document.getElementById("historial").style.display = "block";
		    	}
		    	//----------------------------------------------------------------------------
		    });

		    document.getElementById("logroRA").addEventListener('keyup', (e) => {
		    	//----------------------------------------------------------------------------
				//Condición para continuar
				if(e.target.value == ""){
					//Error
					document.getElementById("botonRegistrar").disabled = true;
				} else {
					//Correcto
					document.getElementById("botonRegistrar").disabled = false;
				}
		    	//----------------------------------------------------------------------------
		    });

		    document.getElementById("botonRegistrar").addEventListener('click', (e) => {
		    	//----------------------------------------------------------------------------
		    	e.target.disabled = true;//Se deshabilita el botón "Registrar"
		    	cancelarNuevoDisabled = true;//Se deshabilita el botón "Cancelar"

		    	//*******************************************************************************************************************************
				//********************************************Instructor confirmó la actualización***********************************************
				//*******************************************************************************************************************************
				var logro_temp = document.getElementById("logroRA").value;
				var nivel_temp = 1;
					if(document.getElementById("nivel2").checked){nivel_temp=2;}
					if(document.getElementById("nivel3").checked){nivel_temp=3;}
					if(document.getElementById("nivel4").checked){nivel_temp=4;}
					if(document.getElementById("nivel5").checked){nivel_temp=5;}
				//Se actualizan los datos****************************************************************************
				//Se crea objeto a guardar en arreglo **************************************
				var objAux = {
				  fechaRegistro: firebase.firestore.Timestamp.fromDate(new Date()),
				  nivel: nivel_temp,
				  logro: logro_temp
				}
				//**************************************************************************
				db.collection("alumnos").doc(id).update({
				    logros: firebase.firestore.FieldValue.arrayUnion(objAux)
				}).then(() => {
				    //Los datos se actualizaron correctamente en firestore
				    //Se guarda logro en colección "logros", para las notificaciones-------
				    db.collection("logros").doc().set({
					  alumno: db.collection('alumnos').doc(id),
					  fechaRegistro: firebase.firestore.Timestamp.fromDate(new Date()),
					  nivel: nivel_temp,
				      logro: logro_temp
					}).then(() => {
						//LOGRO GUARDADO CON ÉXITO
						cancelarNuevoDisabled = false;//Se habilita el botón "Cancelar"
						document.getElementById("logroRA").value = "";
				    	document.getElementById("nivel1").checked = true;
				    	document.getElementById("datosNuevo").style.display = "none";
				    	document.getElementById("historial").style.display = "block";
				    	cargarLogros();
					});
					//---------------------------------------------------------------------
				});
				//***************************************************************************************************
				//*******************************************************************************************************************************
				//*******************************************************************************************************************************
				//*******************************************************************************************************************************
		    	//----------------------------------------------------------------------------
		    });
		    //---------------------------------------------------------------------------------------------

		    //
		  }
		});
	}
	//-------------------------------------------------------------------------------------------------
	//-------------------------------------------------------------------------------------------------
	//-------------------------------------------------------------------------------------------------
	render(){
		//****************************************************************************************************************
		//************************************************** BÚSQUEDA ****************************************************
		//****************************************************************************************************************
		//Control del estado de la búsqueda
		const controlBusqueda = (e) => {
		  //Sí existe una búsqueda activa y se presiona "cerrar búsqueda"
		  if(this.state.busquedaActiva && e.target.type != "search"){
		    cerrarBusqueda();
		  } else {
		    var textoBusqueda = document.getElementById("busqueda-general").value;
		    //Sí existe una cadena de búsqueda
		    if(textoBusqueda !== ""){
		        busqueda(textoBusqueda);//Comienza la búsqueda
		        //Valores del front (indicar que búsqueda está activa)
		        document.getElementById("img-searchBar").src = cancel;
		        document.getElementById("busqueda-general").classList.add('onSearch');
		        document.getElementById("boton-busqueda-general").classList.add('onSearch');
		        document.getElementById("busqueda-general").blur();
		        this.setState({busquedaActiva: true});//Se cambia estado de la búsqueda
		    }
		  }
		}
		//Se realiza la búsqueda seleccionada
		const busqueda = (cadenaBusqueda) => {
			//------------------------------------------------------------------------------------------------------------------
			//----------------------------------------- Se realiza la búsqueda -------------------------------------------------
			//------------------------------------------------------------------------------------------------------------------
			document.getElementById("avisoVacio").style.display = 'none';
			document.getElementById("botonMostrarMas").style.display = 'none';
			document.getElementById("avisoFin").style.display = 'none';
			document.getElementById("avisoBusqueda").style.display = 'none';
			document.getElementById("cadenaAvisoBusqueda").style.display = 'none';
			this.setState({datos: []});//Se limpia la tabla
			//COMIENZA LA BÚSQUEDA**********************************************************************************************
			db.collection("alumnos").where("correoElectronico", "==", cadenaBusqueda.trim()).limit(10).get().then(querySnapshot => {
			    var contador = 0;
			    const docs = [];
			    var index = 0;//index del arreglo de datos incial
			    this.setState({last: querySnapshot.docs[querySnapshot.docs.length-1]});//Se guarda en el estado el último objeto consultado
			    querySnapshot.forEach(doc => {
			        contador++;
			        //-------------------------------------------------------------------------
			        const data = doc.data();
			        docs.push({
			          ...data,
			          index_state: index,
			        });
			        index++;
			        //-------------------------------------------------------------------------
			    });
			    if(contador != 0){
			      this.setState({criterioBusqueda: "correoElectronico"});//Se cambia el criterio actual de la búsqueda
			      this.setState({textoBusqueda: cadenaBusqueda});
			      this.setState({datos: docs});
			      //Sí hay más de 10 datos se muestra botón "mostrar más"
			      if(docs.length == 10){
			        document.getElementById("botonMostrarMas").style.display = 'block';
			      }
			    } else {
			      //No hubo resultados para "correoElectronico"
			      //------------------------------------------------------------------------------------------------------------
			      //------------------------------------------------------------------------------------------------------------
			      //------------------------------------------------------------------------------------------------------------
			      db.collection("alumnos").where("apellidos", "==", cadenaBusqueda.trim().toUpperCase()).limit(10).get().then(querySnapshot => {
			          this.setState({last: querySnapshot.docs[querySnapshot.docs.length-1]});//Se guarda en el estado el último objeto consultado
			          querySnapshot.forEach(doc => {
			              contador++;
			              //-------------------------------------------------------------------------
			              const data = doc.data();
			              docs.push({
			                ...data,
			                index_state: index,
			              });
			              index++;
			              //-------------------------------------------------------------------------
			          });
			          if(contador != 0){
			            this.setState({criterioBusqueda: "apellidos"});//Se cambia el criterio actual de la búsqueda
				        this.setState({textoBusqueda: cadenaBusqueda});
			            this.setState({datos: docs});
			            //Sí hay más de 10 datos se muestra botón "mostrar más"
				        if(docs.length == 10){
				          document.getElementById("botonMostrarMas").style.display = 'block';
				        }
			          } else {
			            //No hubo resultados para "telefono"
			            //------------------------------------------------------------------------------------------------------------
			            //------------------------------------------------------------------------------------------------------------
			            //------------------------------------------------------------------------------------------------------------
			            db.collection("alumnos").where("nombre", "==", cadenaBusqueda.trim().toUpperCase()).limit(10).get().then(querySnapshot => {
			                this.setState({last: querySnapshot.docs[querySnapshot.docs.length-1]});//Se guarda en el estado el último objeto consultado
			                querySnapshot.forEach(doc => {
			                    contador++;
			                    //-------------------------------------------------------------------------
			                    const data = doc.data();
			                    docs.push({
			                      ...data,
			                      index_state: index,
			                    });
			                    index++;
			                    //-------------------------------------------------------------------------
			                });
			                if(contador != 0){
			                  this.setState({criterioBusqueda: "nombre"});//Se cambia el criterio actual de la búsqueda
						      this.setState({textoBusqueda: cadenaBusqueda});
			                  this.setState({datos: docs});
			                  //Sí hay más de 10 datos se muestra botón "mostrar más"
						      if(docs.length == 10){
						        document.getElementById("botonMostrarMas").style.display = 'block';
						      }
			                } else {
			                  //No hubo resultados para nombre (NINGÚN RESULTADO PARA LA BÚSQUEDA)
			                  document.getElementById("cadenaAvisoBusqueda").innerHTML = '<b>"'+cadenaBusqueda+'"</b>.';
			                  document.getElementById("botonMostrarMas").style.display = 'none';
			                  document.getElementById("avisoBusqueda").style.display = 'inline';
			                  document.getElementById("cadenaAvisoBusqueda").style.display = 'inline';
			                }
			            }).catch(function(error) {
			                //Error al realizar consulta
			            });
			            //------------------------------------------------------------------------------------------------------------
			            //------------------------------------------------------------------------------------------------------------
			            //------------------------------------------------------------------------------------------------------------
			          }
			      }).catch(function(error) {
			          //Error al realizar consulta
			      });
			      //------------------------------------------------------------------------------------------------------------
			      //------------------------------------------------------------------------------------------------------------
			      //------------------------------------------------------------------------------------------------------------
			    }
			}).catch(function(error) {
			    //Error al realizar consulta
			});
			//******************************************************************************************************************
			//------------------------------------------------------------------------------------------------------------------
			//------------------------------------------------------------------------------------------------------------------
			//------------------------------------------------------------------------------------------------------------------
		}
		//Cierra la búsqueda actual
		const cerrarBusqueda = () => {
			//------------------------------------------------------------------------------------------------------------------
			//------------------------------------------- Cerrando búsqueda actual ---------------------------------------------
			//------------------------------------------------------------------------------------------------------------------
			document.getElementById("avisoVacio").style.display = 'none';
			document.getElementById("botonMostrarMas").style.display = 'none';
			document.getElementById("avisoFin").style.display = 'none';
			document.getElementById("avisoBusqueda").style.display = 'none';
			document.getElementById("cadenaAvisoBusqueda").style.display = 'none';
			this.setState({datos: []});//Se limpia la tabla
			//Se muestran todos los registros, como al cargar componente
			db.collection("alumnos")
			.limit(10)
			.orderBy("apellidos")
			.get()
			.then(querySnapshot => {
				const docs = [];
				var index = this.state.datos.length;//index del arreglo de datos guardado en el estado
				this.setState({last: querySnapshot.docs[querySnapshot.docs.length-1]});//Se guarda en el estado el último objeto consultado
				querySnapshot.forEach(doc => {
					//-------------------------------------------------------------------------
					const data = doc.data();
					docs.push({
						...data,
						index_state: index,
					});
					index++;
					//-------------------------------------------------------------------------
				});
				this.setState({datos: docs});
				//Sí hay más de 10 datos se muestra botón "mostrar más"
				if(docs.length == 10){
					document.getElementById("botonMostrarMas").style.display = 'block';
				}
				//Sí no hay datos a mostrar
				if(docs.length == 0){
					document.getElementById("avisoVacio").style.display = 'block';
				}
			});
			//------------------------------------------------------------------------------------------------------------------
			//------------------------------------------------------------------------------------------------------------------
			//------------------------------------------------------------------------------------------------------------------
			//Valores del front (indicar que búsqueda no está activa)
			document.getElementById("img-searchBar").src = lupa;
			document.getElementById("busqueda-general").classList.remove('onSearch');
			document.getElementById("boton-busqueda-general").classList.remove('onSearch');
			document.getElementById("busqueda-general").value= "";
			this.setState({busquedaActiva: false});//Se cambia estado de la búsqueda
		}
		//****************************************************************************************************************
		//****************************************************************************************************************
		//****************************************************************************************************************
		return(

			<div className="global-container">
				<div className="card login-form tablas-adm" style={{ width: '82%', backgroundColor: '#fafafa' }}>
					<div className="card-body card-tablas-adm">

						<div className="row">
							<div className="col-sm-12 col-md-5 col-lg-4">
								<h3 className="card-title" style={{fontSize:'32px',fontWeight:'500'}}>Alumnos</h3>
							</div>
							
							<div className="col-sm-12 col-md-7 col-lg-5" style={{overflow:'hidden'}}>
								<div className="wrap-general">
									<div className="search-general">
										<input type="search" onKeyPress={(e) => {if(e.charCode === 13){controlBusqueda(e)}}} className="searchTerm-general" id="busqueda-general" placeholder="Buscar por correo electrónico, nombre o apellidos"/>
										<button type="button" className="searchButton-general" onClick={controlBusqueda} id="boton-busqueda-general">
											<img src={lupa} id="img-searchBar" style={{marginTop:'-6px',marginLeft:'-2px',opacity:'0.6'}} width="20px" />
										</button>
									</div>
								</div>
							</div>

							<div className="col-sm-12 col-md-12 col-lg-3" style={{paddingTop: "8px"}}></div>
						</div>

						<div className="card-text" id="subtext">
							<div id="tabla" style={{
								width: '100%',
							    height: '400px', 
							    overflowY: 'scroll',
							    overflowX: 'scroll',
							    marginTop: '25px',
							    backgroundColor: '#fff'
							}}>
								<RB.Table>
									<thead>
									   <tr style={{backgroundColor:'#fafafa'}}>
									      <th>Apellidos ↑</th>
									      <th>Nombre</th>
									      <th>Correo</th>
									      <th width="110px"></th>
									   </tr>
									</thead>
									<tbody>
										{
											this.state.datos.map(row => {
												return(
													<tr className="filaTabla filaTabla-hover">
														<td>{row.apellidos}</td>
														<td>{row.nombre}</td>
														<td>{row.correoElectronico}</td>
														<td>
															<RB.Dropdown drop="left" onToggle={(e) => {
																//Sí el menú desplegable se abre
																if(e){
																	const array = document.getElementsByClassName('filaTabla');
																	[].slice.call(array).map((element) => {
																		element.classList.remove('filaTabla-hover');
																	});
																} else {//Sí se cerró el menú
																	const array = document.getElementsByClassName('filaTabla');
																	[].slice.call(array).map((element) => {
																		element.classList.add('filaTabla-hover');
																	});
																}
															}}>
															  <RB.Dropdown.Toggle as="p" bsPrefix="none" className="toggle-botonTabla" title="Opciones">
															    <center><img src={mas} width="17px" className="botonTabla"/></center>
															  </RB.Dropdown.Toggle>
															  <RB.Dropdown.Menu>
															    <RB.Dropdown.Item style={{fontSize:'14px'}} className="itemDropdown"
															    onClick={(e) => this.editarLogros(row.id_usuario, row.logros, row.nombre, row.apellidos)}
															    >
															      Editar logros
															    </RB.Dropdown.Item>
															  </RB.Dropdown.Menu>
															</RB.Dropdown>
														</td>
													</tr>
												);
											})
										}
										<tr>
											<td colSpan="4">
												<center>
													<RB.Button id="botonMostrarMas" className="boton-presionado" variant="outline-info" type="button" style={{fontSize: '9pt',display:'none'}} onClick={(e) => this.mostrarMas()}>
														Mostrar más resultados
													</RB.Button>
													<p id="avisoFin" style={{color: '#c8c8c8', display: 'none'}}>No hay más resultados a mostrar</p>
													<p id="avisoVacio" style={{color: '#c8c8c8', display: 'none'}}>No hay resultados a mostrar</p>
													<p id="avisoBusqueda" style={{color: '#4d5156', display: 'none',fontSize:'15px'}}>No se han encontrado resultados para</p>
													<p id="cadenaAvisoBusqueda" style={{marginLeft:'5px',display:'none',color:'#4d5156',fontSize:'15px'}}></p>
												</center>
											</td>
										</tr>
									</tbody>
								</RB.Table>
							</div>
						</div>

					</div>
				</div>
			</div>

		);
	}
}

export default SeccionAlumnos;