import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import {auth} from '../firebase-config';
import {auth_aux} from '../firebase-config';
import {db} from '../firebase-config';
import logo_header from '../assets/images/logo_header.png';
import {Link} from 'react-router-dom';
import * as RB from "react-bootstrap";
import swal from 'sweetalert2';
import firebase from 'firebase/app';

class RegistroResponsable extends React.Component{
	//Se ejecuta al cargar componente
	componentDidMount() {
		//Al cargar sección se posiciona en el primer input
		document.getElementById("nombreRA").focus();
	}
	render(){
		//-------------------------------------------------------------
		//-------------------------------------------------------------------------------------------------------------------------
		//--------------------------------------------------INICIAR REGISTRO-------------------------------------------------------
		//-------------------------------------------------------------------------------------------------------------------------
		const handleSubmit = e =>{
			e.preventDefault();//Evita la recarga de la página

			//***************************************************************************************
			//************************** VALIDACIÓN DE CAMPOS ***************************************
			//***************************************************************************************
			document.getElementById("alerta-RA").innerHTML = '';//Borra alertas pasadas
			var formulario_incorrecto = false;
			const aux_inputs = e.target.querySelectorAll('input');
			aux_inputs.forEach((input) => {
				switch(input.name){
					case "nombre":
						if(!expresiones.nombre.test(input.value)){
							//Error en el nombre
							formulario_incorrecto = true;
						}
					break;
					case "apellidos":
						if(!expresiones.apellidos.test(input.value)){
							//Error en los apellidos
							formulario_incorrecto = true;
						}
					break;
					case "telefono":
						if(!expresiones.telefono.test(input.value)){
							//Error en el teléfono
							formulario_incorrecto = true;
						}
					break;
					case "correo":
						if(!expresiones.correo.test(input.value)){
							//Error en el correo
							formulario_incorrecto = true;
						}
					break;
					case "contraseña":
						if(!expresiones.contraseña.test(input.value)){
							//Error en la contraseña
							formulario_incorrecto = true;
						}
					break;
					case "confirmacionContraseña":
						if(document.getElementById("contraseñaRA").value!==document.getElementById("confirmacionContraseñaRA").value){
							//Error en la confirmación de contraseña
							formulario_incorrecto = true;
						}
					break;
				}
				if(formulario_incorrecto){
					document.getElementById("alerta-RA").innerHTML = '<p>Complete los campos correctamente</p>';
				}
			});
			//***************************************************************************************
			//***************************************************************************************
			//***************************************************************************************

			if(!formulario_incorrecto){

			//Alert "loading"----------------------
			const alert_cargando = swal.fire({
			  width: '300px',
			  allowOutsideClick: false,
			  showConfirmButton: false,
			  didOpen: () => {
			    swal.showLoading()
			  },
			  customClass: {
				   title: 'titulo-alert-loading',
			     actions: 'alert-loader'
				}
			})
			//-------------------------------------
			//-------------------------------------------------------------------------------------------------------------------------------------
			//Se empieza intentando crear la cuenta------------------------------------------------------------------------------------------------
			//La creación de la cuenta se crea con una segunda referencia de firebase para mantener la sesión--------------------------------------
			//-------------------------------------------------------------------------------------------------------------------------------------
			auth_aux.createUserWithEmailAndPassword(document.getElementById("correoRA").value, document.getElementById("contraseñaRA").value)
			.then(function() {
			    //-----------------------------------------------------------------------------------------------------------------------------
			    //----------------------------------------------SE CREAN LOS NUEVOS DOCUMENTOS-------------------------------------------------
			    //-----------------------------------------------------------------------------------------------------------------------------
			    //Primero se crea la relación de usuario en colección "usuarios"
			    db.collection("usuarios").doc(auth_aux.currentUser.uid).set({
				    id_usuario: auth_aux.currentUser.uid,
				    tipo_usuario: "responsable"
				})
				.then(function() {
					//-------------------------------------------------------------------------------------------------
					//Ahora se crea su documento en la colección "responsables"----------------------------------------
					//-------------------------------------------------------------------------------------------------
					db.collection("responsables").doc(auth_aux.currentUser.uid).set({
						apellidos: document.getElementById("apellidosRA").value.trim().toUpperCase(),
						correoElectronico: document.getElementById("correoRA").value,
					    id_usuario: auth_aux.currentUser.uid,
					    nombre: document.getElementById("nombreRA").value.trim().toUpperCase(),
					    telefono: document.getElementById("telefonoRA").value,
					    alumnos: []
					})
					.then(function() {
						//EL REGISTRO SE COMPLETÓ POR COMPLETO CON ÉXITO-------------
						//Se cierra sesión en la app secundaria
						auth_aux.signOut().then(function() {
						  alert_cargando.close();
						  // Sign-out successful.
						  swal.fire({
						      title:"<p style='font-size: 13pt;font-weight:600;color: #282828;'>Responsable registrado</p>",
						      icon: 'success',
						      iconColor: '#17a2b8',
						      width: '350px',
						      showConfirmButton: false,
						      timer: 2500
						    }).then((result) => {
							  	//Se regresa a inicio
						    	document.getElementById("boton_RA").click();
							});
						}).catch(function(error) {
						  	// An error happened.
						  	alert_cargando.close();
						  	//alerta por error
							document.getElementById("alerta-RA").innerHTML = 
							'<p">'+
								'Error al registrar: '+
								error+
							'</p>';
						});
					    //-----------------------------------------------------------
					})
					.catch(function(error) {
						alert_cargando.close();
					    //alerta por error
						document.getElementById("alerta-RA").innerHTML = 
						'<p">'+
							'Error al registrar: '+
							error+
						'</p>';
					});
					//-------------------------------------------------------------------------------------------------
					//-------------------------------------------------------------------------------------------------
					//-------------------------------------------------------------------------------------------------
				})
				.catch(function(error) {
					alert_cargando.close();
				    //alerta por error
					document.getElementById("alerta-RA").innerHTML = 
					'<p">'+
						'Error al registrar: '+
						error+
					'</p>';
				});
			    //-----------------------------------------------------------------------------------------------------------------------------
			    //-----------------------------------------------------------------------------------------------------------------------------
			    //-----------------------------------------------------------------------------------------------------------------------------
			})
			.catch(function(error) {
			  // Errores
			  alert_cargando.close();
			  //var errorCode = error.code;
			  var errorMessage = error.message;
			    //alerta por error
				document.getElementById("alerta-RA").innerHTML = 
				'<p">'+
					'Error al registrar: '+
					errorMessage+
				'</p>';
			});
			//-------------------------------------------------------------------------------------------------------------------------------------
			//-------------------------------------------------------------------------------------------------------------------------------------
			//-------------------------------------------------------------------------------------------------------------------------------------
			//-------------------------------------------------------------------------------------------------------------------------------------

			}
		}
		//-------------------------------------------------------------------------------------------------------------------------
		//-------------------------------------------------------------------------------------------------------------------------
		//-------------------------------------------------------------------------------------------------------------------------
		//*************************************************************************************************************************************
		//***************************************************VALIDACIÓN DE LOS DATOS***********************************************************
		//*************************************************************************************************************************************
		const expresiones = {
			nombre: /^[a-zA-ZÀ-ÿ\s]{1,40}$/, // Letras y espacios, pueden llevar acentos
			apellidos: /^[a-zA-ZÀ-ÿ\s]{1,40}$/, // Letras y espacios, pueden llevar acentos
			correo: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
			telefono: /^\d{10,13}$/, // 10 numeros
			contraseña: /^.{6,40}$/ //cualquier caracter
		}
		const validarFormulario = (e) => {
			switch(e.target.name){
				case "nombre":
					validarCampo(expresiones.nombre, e.target, e.target.name, "Campo nombre solo acepta letras y espacios");
				break;
				case "apellidos":
					validarCampo(expresiones.apellidos, e.target, e.target.name, "Campo apellidos solo acepta letras y espacios");
				break;
				case "telefono":
					validarCampo(expresiones.telefono, e.target, e.target.name, "Ingrese número telefónico válido");
				break;
				case "correo":
					validarCampo(expresiones.correo, e.target, e.target.name, "Ingrese un correo válido");
				break;
				case "contraseña":
					validarCampo(expresiones.contraseña, e.target, e.target.name, "La contraseña debe contener al menos 6 caracteres");
					validarConfirmacionContraseña();
				break;
				case "confirmacionContraseña":
					validarConfirmacionContraseña();
				break;
			}
		}
		const validarCampo = (expresion, input, campo, mensaje_error) => {
			if(expresion.test(input.value)){
				document.getElementById(`${campo}RA`).classList.remove('input_incorrecto');
				document.getElementById(`${campo}RA`).classList.add('input_correcto');
				document.getElementById(`error-${campo}RA`).innerHTML = '';
			} else {
				document.getElementById(`${campo}RA`).classList.remove('input_correcto');
				document.getElementById(`${campo}RA`).classList.add('input_incorrecto');
				document.getElementById(`error-${campo}RA`).innerHTML = mensaje_error;
			}
		}
		const validarConfirmacionContraseña = () => {
			if(document.getElementById("contraseñaRA").value!==document.getElementById("confirmacionContraseñaRA").value){
				document.getElementById("confirmacionContraseñaRA").classList.remove('input_correcto');
				document.getElementById("confirmacionContraseñaRA").classList.add('input_incorrecto');
				document.getElementById("error-confirmacionContraseñaRA").innerHTML = 'Ambas contraseñas deben coincidir';
			} else {
				document.getElementById("confirmacionContraseñaRA").classList.remove('input_incorrecto');
				document.getElementById("confirmacionContraseñaRA").classList.add('input_correcto');
				document.getElementById("error-confirmacionContraseñaRA").innerHTML = '';
			}
		}
		//Solo permite ingresar numeros
		const validarNumerico = (e) => {
     		if(isNaN(e.target.value+String.fromCharCode(e.charCode))){
        		e.preventDefault();
     		}
  		}
		//*************************************************************************************************************************************
		//*************************************************************************************************************************************
		//*************************************************************************************************************************************
		return(
			<React.Fragment>

			<div className="global-container">
				<div className="card login-form" id="cardFRA" style={{ width: '800px', paddingTop:'10px' }}>
					<div className="card-body" id="tarjeta">
						<h4 className="card-title" style={{fontSize:'18pt',fontWeight:'500'}}>Crear cuenta de responsable</h4>
						<div className="card-text" style={{marginTop:'40px'}}>

							<form autoComplete="off" onSubmit={handleSubmit} style={{margin:'0px',overflow:'hidden'}}>
								<div className="row">
									<div className="col-sm-12 col-md-6 col-lg-6">
										<div className="form-group input-field" style={{margin: 'auto'}}>
											<input className="input_correcto input" type="text" id="nombreRA" name="nombre" onChange={validarFormulario} required />
											<label className="label" for="nombreRA">Nombre</label>
											<p id="error-nombreRA"></p>
										</div>
									</div>
									
									<div className="col-sm-12 col-md-6 col-lg-6">
										<div className="form-group input-field" style={{margin: 'auto'}}>
											<input className="input_correcto input" type="text" id="apellidosRA" name="apellidos" onChange={validarFormulario} required />
											<label className="label" for="apellidosRA">Apellidos</label>
											<p id="error-apellidosRA"></p>
										</div>
									</div>

									<div className="col-sm-12 col-md-6 col-lg-6">
										<div className="form-group input-field" style={{margin: 'auto'}}>
											<input className="input_correcto input" type="text" id="telefonoRA" name="telefono" onChange={validarFormulario} onKeyPress={validarNumerico} required />
											<label className="label" for="telefonoRA">Teléfono</label>
											<p id="error-telefonoRA"></p>
										</div>
									</div>

									<div className="col-sm-12 col-md-6 col-lg-6">
										<div className="form-group input-field" style={{margin: 'auto'}}>
											<input className="input_correcto input" type="text" id="correoRA" name="correo" onChange={validarFormulario} required />
											<label className="label" for="correoRA">Correo electrónico</label>
											<p id="error-correoRA"></p>
										</div>
									</div>

									<div className="col-sm-12 col-md-6 col-lg-6">
										<div className="form-group input-field" style={{margin: 'auto'}}>
											<input className="input_correcto input" type="password" id="contraseñaRA" name="contraseña" onChange={validarFormulario} required />
											<label className="label" for="contraseñaRA">Contraseña</label>
											<p id="error-contraseñaRA"></p>
										</div>
									</div>

									<div className="col-sm-12 col-md-6 col-lg-6">
										<div className="form-group input-field" style={{margin: 'auto'}}>
											<input className="input_correcto input" type="password" id="confirmacionContraseñaRA" name="confirmacionContraseña" onChange={validarFormulario} required />
											<label className="label" for="confirmacionContraseñaRA">Confirmar contraseña</label>
											<p id="error-confirmacionContraseñaRA"></p>
										</div>
									</div>
								</div>

								<div id="alerta-RA" style={{fontSize:'11pt',color:'#D45454',marginBottom:"10px",marginTop:"10px"}}></div>

								<RB.Button variant="info" id="boton1_RA" className="boton-presionado" type="submit" style={{float:'right',marginRight:'10px',width:'110px',fontSize:'10pt'}}>Crear cuenta</RB.Button>

								<Link to="/administrador/responsables" id="boton_RA" style={{float:'right',marginRight:'35px',lineHeight:'2.4',color:'#17a2b8',fontSize:'10pt',textDecoration:'none'}}>Cancelar</Link>
							</form>
							
						</div>
					</div>
				</div>
			</div>

			</React.Fragment>
		);
	}
}

export default RegistroResponsable;